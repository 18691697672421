import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import Progress from './CustomTool/Progress'
import { Grid, Box } from '@material-ui/core'
import TableList from './CustomTool/TableList'
import {fetchTour} from './API/API'
import { Delete, Edit } from '@material-ui/icons'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";

class TourList extends Component {

    state = {
        dataLoadStatus: false,
        columns: [
            { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            { id: 'no', label: 'Sr. No.', align: 'center' },
            { id: 'tourname', label: 'Tour Name' },
            { id: 'team1bto', label: 'Team 1 BTO', align: 'right' },
            { id: 'team2bto', label: 'Team 2 BTO', align: 'right' },
            { id: 'selfrepurchase', label: 'Self Repurchase', align: 'right' },
            { id: 'fromdt', label: 'From Date', align: 'center' },
            { id: 'todt', label: 'To Date', align: 'center' },
            { id: 'srno', label: 'Tour Sr No', align: 'center',hide: 'true' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 5,
        actionList: [
            {
                name: 'Edit Tour',
                icon: <Edit />,
                link: 'Tour:',
                actionType: 'AlertResponsive'
            },
            {
                name: 'Delete Tour',
                icon: <Delete />,
                link: 'DelTour:',
                actionType: 'AlertResponsive'
            },
        ],
        searchData: {
            tourname:'',
            team1bto:'',
            team2bto:'',
            selfrepurchase:'',
            fromdt:'',
            todt:''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        keyColumn: [
            'srno','tourname'
        ],
        ARD: null,
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.setSearchColumns()
        this.loadTourList()
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    ((this.state.searchData.tourname.length === 0 || row['tourname'].toString().includes(this.state.searchData.tourname))) && ((this.state.searchData.team1bto.length === 0 || row['team1bto'].toString().includes(this.state.searchData.team1bto))) && ((this.state.searchData.team2bto.length === 0 || row['team2bto'].toString().includes(this.state.searchData.team2bto))) && ((this.state.searchData.selfrepurchase.length === 0 || row['selfrepurchase'].toString().includes(this.state.searchData.selfrepurchase)))
                ) {
                    searchRows.push(row)
                    // console.log('searchRows at push in loop', searchRows)
                }
            })
            const searchRowsCount = searchRows.length
            // console.log("searchRowsCount",searchRowsCount);
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
                // console.log('searchRows on end of Function', searchRows)
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleARDonActionClick = (values) => {
        const EditSrNo = values[0].value,
            actionName = values[0].actionName,
            EditTourName = values[1].value
        if (actionName === 'Edit Tour') {
            this.navigateTo('TourEntry:' + EditSrNo)
        } else if (actionName === 'Delete Tour') {
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Tour : ' + EditTourName + '?'}
                    // alertMessage={}
                    handleOnClickYes={() => { return this.deleteTour(EditSrNo) }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }

    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {},
            {
                cType: 'CtTxt',
                id: 'tourname',
                label: 'Tour Name',
                value: this.state.searchData.tourname,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'team1bto',
                label: 'Team 1 BTO',
                value: this.state.searchData.team1bto,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'team2bto',
                label: 'Team 2 BTO',
                value: this.state.searchData.team2bto,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'selfrepurchase',
                label: 'Self Repurchase',
                value: this.state.searchData.selfrepurchase,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            }
        )
        this.setState({ searchColumns })
    }

    loadTourList = () => {
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = { Op: 'TourList' }
            fetchTour(reqData)
                .then(res => {
                    const rows = res.data.tourmasterlist
                    const rowsCount = rows.length
                    this.setState({ rows, rowsCount, dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in loadTourList. ' + error)
                })
        })
    }

    deleteTour =(EditSrNo)=>{
        if (EditSrNo > 0){
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'DeleteTour',
                    EditSrNo: EditSrNo,
                }
                fetchTour(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.setState({
                            dataLoadStatus: true
                        }, () => {
                            if (res.data.msgType === 'success') {
                                this.loadTourList()
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('deleteTour', error)
                    })
            })
        }else{
            this.props.toastMsg("info", "There are no detail to delete")
        }
        
        return true
    }

    onNewEntryClick = () => {
        this.navigateTo('TourEntry')
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>Tour List</label>
                    </Grid>
                </Grid>
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={this.onNewEntryClick}
                    toolTipNewEntry='New Tour Entry'
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(TourList))
