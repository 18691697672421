import React, { Component, createRef } from 'react'
import { Grid, Box, Button } from '@material-ui/core'
import CtCmb from './CustomTool/CtCmb'
import CtTxt from './CustomTool/CtTxt'
import CtTxtAdornNum from './CustomTool/CtTxtAdornNum'
import CtTxtPWD from './CustomTool/CtTxtPWD'
import CtBtn from './CustomTool/CtBtn'
import CtPhoneNumber from './CustomTool/CtPhoneNumber'
import ImageUploadIcon from './CustomTool/ImageUploadIcon'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'
import CtDtpDOB from './CustomTool/CtDtpDOB'
import 'react-toastify/dist/ReactToastify.css'
import { fetchMember } from './API/API'
import { getAgeOfDOB, validatePANNo, validateIFSCCode } from './SystemUtility/SystemUtility'
import Progress from './CustomTool/Progress'
import TextAreaAutoSize from './CustomTool/TextAreaAutoSize'
import PH_PROFILE from '../png/ProfileImagePlaceHolder.png'
import PH_PANCARD from '../png/PanCardImagePlaceHolder.png'
import AlertResponsiveDialog from './CustomTool/AlertResponsiveDialog'
import CtCmbEditable from './CustomTool/CtCmbEditable'

class UserProfile extends Component {

  state = {
    profileData: {
      ID: '',
      Scheme: '',
      ReferenceCode: '',
      EditReferenceCode: '',
      SponsorID: '',
      Name: '',
      MobileNo: '',
      DOB: '',
      DOA: '',
      Photo: '',
      PhotoPath: '',
      PhotoLocation: '',
      Address: '',
      PanCardNo: '',
      PhotoOfPanCard: '',
      PANNoImgPath: '',
      PANNoImgLocation: '',
      BankName: '',
      BranchName: '',
      IFSCCode: '',
      AccountNumber: '',
      AccountType: 'Saving',
      Password: '',
      CPassword: '',
      cmbCity: '',
      Nominee: '',
      NomineeRelation: '',
      Pincode: '',
      State: '',
      District: '',
    },
    disabledProfileData: {
      NameDS: false,
      MobileNoDS: false,
      DOBDS: false,
      DOADS: false,
      PhotoDS: false,
      AddressDS: false,
      PanCardNoDS: false,
      PhotoOfPanCardDS: false,
      BankNameDS: false,
      BranchNameDS: false,
      IFSCCodeDS: false,
      AccountNumberDS: false,
      AccountTypeDS: false,
      cmbCityDS: false,
      NomineeDS: false,
      NomineeRelationDS: false,
      PincodeDS: false,
      StateDS: false,
      DistrictDS: false,
    },
    AccountTypes: [
      { name: 'Saving', disabled: false },
      { name: 'Current', disabled: false },
    ],
    cmbNomineeRelation: [],
    City: [],
    DistrictList: [],
    StateList: [],
    dataLoadStatus: false,
    otpVerified: false,
    ARD: null,
    TxtOTP: '',
  }

  constructor(props) {
    super(props)
    this.ProfileImg = createRef()
    this.PanCardImg = createRef()
    this.verifyOTP = this.verifyOTP.bind(this)
    this.handleOnClickYesRemoveProfileImage = this.handleOnClickYesRemoveProfileImage.bind(this)
    this.handleOnClickYesRemovePANImage = this.handleOnClickYesRemovePANImage.bind(this)
  }

  handleOnChange = (e) => {
    this.setState({
      profileData: { ...this.state.profileData, [e.target.name]: e.target.value }
    })
  }

  handleOnOTPChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onPhotoImageChange = (Photo, PhotoLocation, PhotoPath, skipVerifyOTP = false) => {
    this.setState({
      profileData: { ...this.state.profileData, Photo, PhotoLocation, PhotoPath }
    }, () => {
      this.updateProfile(skipVerifyOTP)
    })
  }

  onPANImageChange = (PhotoOfPanCard, PANNoImgLocation, PANNoImgPath, skipVerifyOTP = false) => {
    this.setState({
      profileData: { ...this.state.profileData, PhotoOfPanCard, PANNoImgLocation, PANNoImgPath }
    }, () => {
      this.updateProfile(skipVerifyOTP)
    })
  }

  setEnabled = (value) => {
    if (this.props.IsAdmin === true || value.length === 0) {
      return true
    } else {
      return false
    }
  }

  handleOnChangeTrim = (e) => {
    this.setState({
      profileData: { ...this.state.profileData, [e.target.name]: e.target.value.trim() }
    })
  }

  validateProfiledata = (isReferenceCodeValidated = false) => {
    let validateProfiledata = false

    if (this.state.profileData.Name.trim().length <= 0) {
      const Name = this.state.profileData.Name.trim()
      this.setState({
        profileData: { ...this.state.profileData, Name }
      }, () => {
        this.props.toastErrorMsg('Enter Name', 'Name')
      })
    } else if (this.state.profileData.MobileNo.length <= 0) {
      this.props.toastErrorMsg('Enter Mobile No', 'MobileNo')
    } else if (this.state.profileData.MobileNo.length < 10) {
      this.props.toastErrorMsg('Enter Valid Mobile No', 'MobileNo')
    } else if (this.state.profileData.DOB.length <= 0) {
      this.props.toastErrorMsg('Enter Date of Birth', 'DOB')
    } else if (getAgeOfDOB(this.state.profileData.DOB) < 18) {
      this.props.toastErrorMsg('Person with Age of 18 Year or elder can only Registered', 'DOB')
    } else if (isReferenceCodeValidated === false && this.state.profileData.ReferenceCode !== this.state.profileData.EditReferenceCode) {

      const reqData = {
        'Op': 'IsIDExists',
        'ID': this.state.profileData.ReferenceCode,
      }
      fetchMember(reqData)
        .then(res => {
          if (res.data.IsIDExists === true) {
            this.validateProfiledata(true)
          } else {
            this.props.toastErrorMsg("Enter valid Reference Code", "ReferenceCode")
            this.setState({ dataLoadStatus: true })
          }
        })
        .catch(error => {
          this.props.toastErrorMsg("Error occurred while validate Reference Code. " + error)
        })
    } else if (this.props.IsAdmin === true && (this.state.profileData.Password.length > 0 || this.state.profileData.CPassword.length > 0)) {
      validateProfiledata = false
      if (this.state.profileData.Password.length <= 0) {
        this.props.toastErrorMsg('Enter Password', 'Password')
      } else if (this.state.profileData.Password.length < 4) {
        this.props.toastErrorMsg('Enter Password of Atleast 4 Charaters', 'Password')
      } else if (this.state.profileData.CPassword.length <= 0) {
        this.props.toastErrorMsg('Enter Confirm Password', 'CPassword')
      } else if (this.state.profileData.Password != this.state.profileData.CPassword) {
        this.props.toastErrorMsg('Password and Confirm Password must be same', 'Password')
      } else {
        validateProfiledata = true
      }
    } else if (this.state.profileData.cmbCity.length <= 0) {
      this.props.toastErrorMsg('Enter Taluka', 'cmbCity')
    } else if (this.state.profileData.District.length <= 0) {
      this.props.toastErrorMsg('Enter District', 'District')
    } else if (this.state.profileData.State.length <= 0) {
      this.props.toastErrorMsg('Enter State', 'State')
      // } else if (this.state.profileData.PanCardNo.length !== 0) {
      //   this.props.toastErrorMsg('Enter PAN Card No', 'PanCardNo')
    } else if (this.state.profileData.PanCardNo.length > 0 && validatePANNo(this.state.profileData.PanCardNo) === false) {
      this.props.toastErrorMsg('Enter valid Pan No', 'PanCardNo')
    } else if (this.state.profileData.IFSCCode.length > 0 && validateIFSCCode(this.state.profileData.IFSCCode) === false) {
      this.props.toastErrorMsg('Enter valid IFSC Code', 'IFSCCode')
    } else {
      validateProfiledata = true
    }

    if (isReferenceCodeValidated && validateProfiledata) {
      this.updateProfile(false, true)
    } else {
      return validateProfiledata
    }
  }

  validateOTP = () => {
    let validateProfiledata = false
    if (this.state.TxtOTP.trim().length <= 0) {
      this.props.toastErrorMsg('Enter OTP', 'TxtOTP')
    } else {
      validateProfiledata = true
    }
    return validateProfiledata
  }

  /* verifyOTP = (functionCallOnOTPVerify) => {
    if (this.validateOTP() === true) {
      this.props.notifyProcessing()
      let reqData = ""
      reqData = {
        Op: 'VerifyOTP',
        ID: this.state.profileData.ID,
        OTP: this.state.TxtOTP,
      }
      fetchMember(reqData)
        .then(res => {
          if (res.data.msgType == "success" && res.data.message == "OTP Verified.") {
            this.props.updateProcessing('success', 'OTP Verified')
            this.setState({ otpVerified: true, dataLoadStatus: true }, () => {
              functionCallOnOTPVerify()
            })
            return true
          } else {
            this.props.updateProcessing('error', res.data.message)
          }
        })
        .catch(error => {
          this.props.updateProcessing('error', 'Unknown error occurred in verifyOTP. ' + error)
        })
    }
  } */

  verifyOTP = async (functionCallOnOTPVerify) => {
    let result = false
    if (this.validateOTP() === true) {
      try {
        this.props.notifyProcessing()
        let reqData = ""
        reqData = {
          Op: 'VerifyOTP',
          ID: this.state.profileData.ID,
          OTP: this.state.TxtOTP,
        }
        let res = await fetchMember(reqData)
        if (res.data.msgType == "success" && res.data.message == "OTP Verified.") {
          this.props.updateProcessing('success', 'OTP Verified')
          this.setState({ otpVerified: true, dataLoadStatus: true }, () => {
            functionCallOnOTPVerify()
          })
          result = true
        } else {
          this.props.updateProcessing('error', res.data.message)
        }
      } catch (error) {
        this.props.updateProcessing('error', 'Unknown error occurred in verifyOTP. ' + error)
      }
    }
    return result
  }

  updateProfile = (skipVerifyOTP = false, bypassValidateProfileData = false) => {
    let ARD = null
    this.setState({
      otpVerified: false,
      dataLoadStatus: false,
      ARD
    }, () => {
      if (bypassValidateProfileData === true || this.validateProfiledata() === true) {
        if (this.props.IsAdmin === true || skipVerifyOTP === true) {
          this.updateProfileData()
        } else {
          this.updateProfileData()
        }
      }
    })
  }

  updateProfileData = () => {
    this.setState({
      ARD: null,
      dataLoadStatus: true,
    })
    this.props.notifyProcessing()
    let reqData = {
      Op: 'SaveUserProfile',
      EditID: this.state.profileData.ID,
      IsAdmin: this.props.IsAdmin,
      Name: this.state.profileData.Name,
      MobileNo: this.state.profileData.MobileNo,
      DateOfBirth: this.state.profileData.DOB,
      ReferenceCode: this.state.profileData.ReferenceCode,
      Password: this.state.profileData.Password,
      ConfirmPassword: this.state.profileData.CPassword,
      Address: this.state.profileData.Address,
      PanCardNo: this.state.profileData.PanCardNo,
      PANNoImgPath: this.state.profileData.PANNoImgPath,
      PhotoOfPanCard: this.state.profileData.PhotoOfPanCard,
      PANNoImgLocation: this.state.profileData.PANNoImgLocation,
      BankName: this.state.profileData.BankName.toString().toUpperCase(),
      BranchName: this.state.profileData.BranchName.toString().toUpperCase(),
      IFSCCode: this.state.profileData.IFSCCode.toString().toUpperCase(),
      AccountNumber: this.state.profileData.AccountNumber.toString().toUpperCase(),
      AccountType: this.state.profileData.AccountType,
      PhotoPath: this.state.profileData.PhotoPath,
      Photo: this.state.profileData.Photo,
      PhotoLocation: this.state.profileData.PhotoLocation,
      City: this.state.profileData.cmbCity,
      Nominee: this.state.profileData.Nominee,
      NomineeRelation: this.state.profileData.NomineeRelation,
      Pincode: this.state.profileData.Pincode,
      State: this.state.profileData.State,
      District: this.state.profileData.District,
    }

    fetchMember(reqData)
      .then(res => {
        // this.setState({ res: res.data })
        this.props.updateProcessing(res.data.msgType, res.data.message)
      })
      .catch(err => {
        this.props.updateProcessing('error', 'Unknown error occured in Save UserProfile.\n\n' + err)
      })
  }

  loadProfileInfo = (userID) => {
    this.setState({ userID })
    const reqData = {
      'Op': 'GetProfileInfo_LoadRegistrationCity_State_District_LoadNomineeRelation',
      'UserID': userID,
      'IsAdmin': this.props.IsAdmin,
    }
    fetchMember(reqData)
      .then(res => {
        let City = res.data.CityList
        let StateList = res.data.StateList
        let DistrictList = res.data.DistrictList
        let cmbNomineeRelation = res.data.NomineeRelationList
        if (res.data.status === '11') {
          const ID = res.data.ID,
            Scheme = res.data.Scheme,
            ReferenceCode = res.data.ReferenceCode,
            SponsorID = res.data.SponsorID,
            Name = res.data.Name,
            MobileNo = res.data.Mobile,
            DOB = res.data.DateofBirth,
            DOA = res.data.DateofApproval,
            Photo = res.data.PhotoName,
            PhotoPath = res.data.PhotoPath,
            PhotoLocation = res.data.PhotoLocation,
            Address = res.data.Address,
            PanCardNo = res.data.PanCardNo,
            PhotoOfPanCard = res.data.PhotoofPanCard,
            PANNoImgPath = res.data.PanCardImgPath,
            PANNoImgLocation = res.data.PanCardImgLocation,
            BankName = res.data.BankName,
            BranchName = res.data.Branchname,
            IFSCCode = res.data.IFSCCode,
            AccountNumber = res.data.AccountNumber,
            AccountType = res.data.AccountType,
            cmbCity = res.data.City,
            Nominee = res.data.Nominee,
            NomineeRelation = res.data.NomineeRelation,
            Pincode = res.data.Pincode,
            State = res.data.State,
            District = res.data.District,
            Password = '',
            CPassword = '',
            NameDS = this.props.IsAdmin === true || Name.length === 0 ? false : true,
            MobileNoDS = this.props.IsAdmin === true || MobileNo.length === 0 ? false : true,
            DOBDS = this.props.IsAdmin === true || DOB.length === 0 ? false : true,
            DOADS = this.props.IsAdmin === true || DOA.length === 0 ? false : true,
            PhotoDS = this.props.IsAdmin === true || Photo.length === 0 ? false : true,
            AddressDS = this.props.IsAdmin === true || Address.length === 0 ? false : true,
            PanCardNoDS = this.props.IsAdmin === true || PanCardNo.length === 0 ? false : true,
            PhotoOfPanCardDS = this.props.IsAdmin === true || PhotoOfPanCard.length === 0 ? false : true,
            BankNameDS = this.props.IsAdmin === true || BankName.length === 0 ? false : true,
            BranchNameDS = this.props.IsAdmin === true || BranchName.length === 0 ? false : true,
            IFSCCodeDS = this.props.IsAdmin === true || IFSCCode.length === 0 ? false : true,
            AccountNumberDS = this.props.IsAdmin === true || AccountNumber.length === 0 ? false : true,
            AccountTypeDS = this.props.IsAdmin === true || AccountType.length === 0 ? false : true,
            cmbCityDS = this.props.IsAdmin === true || cmbCity.length === 0 ? false : true,
            NomineeDS = this.props.IsAdmin === true || Nominee.length === 0 ? false : true,
            NomineeRelationDS = this.props.IsAdmin === true || NomineeRelation.length === 0 ? false : true,
            PincodeDS = this.props.IsAdmin === true || Pincode.length === 0 ? false : true,
            StateDS = this.props.IsAdmin === true || State.length === 0 ? false : true,
            DistrictDS = this.props.IsAdmin === true || District.length === 0 ? false : true
          this.setState({
            profileData: {
              ...this.state.profileData,
              ID,
              Scheme,
              ReferenceCode,
              EditReferenceCode: ReferenceCode,
              SponsorID,
              Name,
              MobileNo,
              DOB,
              DOA,
              Photo,
              PhotoPath,
              PhotoLocation,
              Address,
              PanCardNo,
              PhotoOfPanCard,
              PANNoImgPath,
              PANNoImgLocation,
              BankName,
              BranchName,
              IFSCCode,
              AccountNumber,
              AccountType,
              Password,
              CPassword,
              cmbCity,
              Nominee,
              NomineeRelation,
              Pincode,
              State,
              District
            },
            disabledProfileData: {
              ...this.state.disabledProfileData,
              NameDS,
              MobileNoDS,
              DOBDS,
              DOADS,
              PhotoDS,
              AddressDS,
              PanCardNoDS,
              PhotoOfPanCardDS,
              BankNameDS,
              BranchNameDS,
              IFSCCodeDS,
              AccountNumberDS,
              AccountTypeDS,
              cmbCityDS,
              NomineeDS,
              NomineeRelationDS,
              PincodeDS,
              StateDS,
              DistrictDS
            },
            dataLoadStatus: true,
            City, StateList, DistrictList, cmbNomineeRelation
          }, () => {
            this.props.updateProcessing('info', 'Details Loaded')
          })
        } else {
          this.props.history.push('/LogIn')
        }
      })
  }

  componentWillMount() {
    this.props.validateUserOrAdminWithUserID(
      this.props.IsAdmin,
      this.props.match.params.userID,
      this.props.setActiveLink,
      this.navigateTo
    )
  }

  componentDidMount() {
    this.props.setActiveLink({ LinkSlug: "UserProfile" })
    let userID = ''
    if (
      this.props.IsAdmin === true &&
      this.props.match.params.userID &&
      Number(this.props.match.params.userID.substring(1)) > 99999
    ) {
      userID = this.props.match.params.userID.substring(1)
    } else if (
      localStorage.getItem('logInID') &&
      Number(localStorage.getItem('logInID')) > 99999
    ) {
      userID = Number(localStorage.getItem('logInID'))
    } /* else {
      toast.error('Login Timeout')
      navigated = true;
      this.props.history.push('/LogIn')
    } */

    this.loadProfileInfo(Number(userID))
  }

  navigateTo = (link) => {
    this.props.history.push('/' + link)
  }

  removeProfileImage = () => {
    let ARD = null
    this.setState({
      otpVerified: false,
      dataLoadStatus: false,
      ARD
    })
    if (this.validateProfiledata() === true) {
      // this.props.notifyProcessing()
      if (this.props.IsAdmin === true) {
        this.ProfileImg.current.removeImage()
      } else {
        let reqData = ""
        reqData = {
          Op: 'SendOTPOnUserProfileUp',
          ID: this.state.profileData.ID,
        }
        fetchMember(reqData)
          .then(res => {
            if (res.data.msgType == "success" && res.data.message == "Done") {
              this.props.updateProcessing("success", "OTP is sent to your registered Mobile No. : " + res.data.mobnowithxx + ". Please check")
              this.setState({
                ARD,
                dataLoadStatus: true
              }, () => {
                let ARD = <AlertResponsiveDialog
                  label={"Enter OTP"}
                  alertTitle={'OTP is sent to your registered Mobile No. : ' + res.data.mobnowithxx + ' Please check'}
                  alertMessage={
                    <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={0} >
                      <Grid item>
                        <CtTxt
                          id='TxtOTP'
                          label='Enter OTP'
                          // value={this.state.TxtOTP}
                          handleOnChange={this.handleOnOTPChange}
                          width='200'
                          maxLength='6'
                        />
                      </Grid>
                    </Grid>
                  }
                  labelAgree='Verify'
                  handleOnClickYes={this.handleOnClickYesRemoveProfileImage}
                  labelDisagree='Cancel'
                  defaultOpenDialog={true}
                  onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
              })
            }
          })
          .catch(err => {
            this.props.updateProcessing('error', 'Unknown error occured in removeProfileImage.\n\n' + err)
          })
      }
    }
  }

  handleOnClickYesRemoveProfileImage = async () => {
    let result = await this.verifyOTP(this.ProfileImg.current.removeImage)
    return result
  }

  removePancardImage = () => {
    let ARD = null
    this.setState({
      otpVerified: false,
      dataLoadStatus: false,
      ARD
    })
    if (this.validateProfiledata() === true) {
      // this.props.notifyProcessing()
      if (this.props.IsAdmin === true) {
        this.PanCardImg.current.removeImage()
      } else {
        let reqData = ""
        reqData = {
          Op: 'SendOTPOnUserProfileUp',
          ID: this.state.profileData.ID,
        }
        fetchMember(reqData)
          .then(res => {
            if (res.data.msgType == "success" && res.data.message == "Done") {
              this.props.updateProcessing("success", "OTP is sent to your registered Mobile No. : " + res.data.mobnowithxx + ". Please check")
              this.setState({
                ARD,
                dataLoadStatus: true
              }, () => {
                let ARD = <AlertResponsiveDialog
                  label={"Enter OTP"}
                  alertTitle={'OTP is sent to your registered Mobile No. : ' + res.data.mobnowithxx + ' Please check'}
                  alertMessage={
                    <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={0} >
                      <Grid item>
                        <CtTxt
                          id='TxtOTP'
                          label='Enter OTP'
                          // value={this.state.TxtOTP}
                          handleOnChange={this.handleOnOTPChange}
                          width='200'
                          maxLength='6'
                        />
                      </Grid>
                    </Grid>
                  }
                  labelAgree='Verify'
                  handleOnClickYes={this.handleOnClickYesRemovePANImage}
                  labelDisagree='Cancel'
                  defaultOpenDialog={true}
                  onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
              })
            }
          })
          .catch(err => {
            this.props.updateProcessing('error', 'Unknown error occured in removePancardImage.\n\n' + err)
          })
      }
    }
  }

  handleOnClickYesRemovePANImage = async () => {
    let result = await this.verifyOTP(this.PanCardImg.current.removeImage)
    return result
  }

  render() {

    let fixProfileFileName = 'ProfileImage_' + this.state.profileData.ID
    let fixPanCardFileName = 'PanCardImage_' + this.state.profileData.ID

    return (
      <div className="UserProfile">
        <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item className="pageTitle">
            <label htmlFor="Title" className='pageTitleText' >{this.state.profileData.Name} Profile</label>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item>
            {
              this.props.IsAdmin === true ? ('') : (
                <Button style={{ fontSize: '12px' }} onClick={() => { this.navigateTo('ChangePassword') }} color="primary">Change Password</Button>
              )
            }
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
          <Grid item><b>ID</b> {this.state.profileData.ID}</Grid>
          <Grid item><b>Scheme</b> {this.state.profileData.Scheme}</Grid>
          <Grid item><b>Approve Date</b> {this.state.profileData.DOA}</Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
          <Grid item><p></p></Grid>
        </Grid>
        <form>
          <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
            <Grid item>
              <CtTxt
                id='Name'
                label='Name'
                handleOnChange={this.handleOnChange}
                value={this.state.profileData.Name}
                width='200'
                maxLength='100'
                disabled={this.state.disabledProfileData.NameDS}
                onKeyDown={this.props.onKeyDown}
              />
            </Grid>
            <Grid item>
              <CtPhoneNumber
                id='MobileNo'
                label='Mobile No'
                width='200'
                handleOnChange={this.handleOnChangeTrim}
                value={this.state.profileData.MobileNo}
                disabled={this.state.disabledProfileData.MobileNoDS}
                onKeyDown={this.props.onKeyDown}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
            <Grid item>
              <CtDtpDOB
                id='DOB'
                label='Date of birth'
                width='200'
                handleOnChange={this.handleOnChangeTrim}
                defaultValue={this.state.profileData.DOB}
                value={this.state.profileData.DOB}
                disabled={this.state.disabledProfileData.DOBDS}
                onKeyDown={this.props.onKeyDown}
              />
            </Grid>
            <Grid item>
              <CtTxtAdornNum
                id='ReferenceCode'
                label='Reference Code'
                adornment='WG'
                value={this.state.profileData.ReferenceCode}
                // disabled={RegLinkDisabled}
                handleOnChange={this.handleOnChangeTrim}
                onKeyDown={this.props.onKeyDown}
                disabled={this.props.IsSuperAdmin && this.props.IsSuperAdmin === true ? false : true}
                width='200'
              />
            </Grid>
            {/* <Box item style={{ width: '240px' }} display={{ xs: 'none', sm: 'block' }}>
            &nbsp;
          </Box> */}
            {/* <Grid item><UploadImage label='Upload Member Photo' /></Grid> */}
          </Grid>
        </form>
        {
          (this.props.IsAdmin === true) ? (
            <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
              <Grid item>
                <CtTxtPWD
                  id='Password'
                  label='Password'
                  handleOnChange={this.handleOnChange}
                  width='200'
                  maxLength='100'
                  value={this.state.profileData.Password}
                />
              </Grid>
              <Grid item>
                <CtTxtPWD
                  id='CPassword'
                  label='Confirm Password'
                  handleOnChange={this.handleOnChange}
                  width='200'
                  maxLength='100'
                  value={this.state.profileData.CPassword}
                />
              </Grid>
            </Grid>
          ) : ('')
        }
        <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
          <Grid item>
            <TextAreaAutoSize
              id='Address'
              label='Address'
              value={this.state.profileData.Address}
              disabled={this.state.disabledProfileData.AddressDS}
              width='440'
              handleOnChange={this.handleOnChange}
              maxLength='500'
              placeholder='Address'
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
          <Grid item>
            <CtTxt
              id='Pincode'
              label='Pincode'
              handleOnChange={this.handleOnChange}
              value={this.state.profileData.Pincode}
              onKeyDown={this.props.onKeyDown}
              width='200'
              maxLength='6'
              disabled={this.state.disabledProfileData.PincodeDS}
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id='cmbCity'
              label='Taluka'
              freeSolo={true}
              items={this.state.City}
              handleOnChange={this.handleOnChange}
              value={this.state.profileData.cmbCity}
              width="200"
              maxLength='100'
              onKeyDown={this.props.onKeyDown}
              disabled={this.state.disabledProfileData.cmbCityDS}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
          <Grid item>
            <CtCmbEditable
              id='District'
              label='District'
              freeSolo={true}
              items={this.state.DistrictList}
              handleOnChange={this.handleOnChange}
              value={this.state.profileData.District}
              width="200"
              maxLength='50'
              onKeyDown={this.props.onKeyDown}
              disabled={this.state.disabledProfileData.DistrictDS}
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id='State'
              label='State'
              freeSolo={true}
              items={this.state.StateList}
              handleOnChange={this.handleOnChange}
              value={this.state.profileData.State}
              width="200"
              maxLength='50'
              onKeyDown={this.props.onKeyDown}
              disabled={this.state.disabledProfileData.StateDS}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={5}>
          <Grid item>
            <CtTxt
              id='Nominee'
              label='Nominee'
              handleOnChange={this.handleOnChange}
              value={this.state.profileData.Nominee}
              width='200'
              maxLength='50'
              disabled={this.state.disabledProfileData.NomineeDS}
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id='NomineeRelation'
              label='Nominee Relation'
              freeSolo={true}
              items={this.state.cmbNomineeRelation}
              handleOnChange={this.handleOnChange}
              value={this.state.profileData.NomineeRelation}
              width="200"
              maxLength='50'
              onKeyDown={this.props.onKeyDown}
              disabled={this.state.disabledProfileData.NomineeRelationDS}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={5}>
          <Grid item>
            <CtTxt
              id='PanCardNo'
              label='PAN No'
              handleOnChange={this.handleOnChange}
              value={this.state.profileData.PanCardNo}
              disabled={this.state.disabledProfileData.PanCardNoDS}
              width='200'
              maxLength='10'
              isUpperCase={true}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id='BankName'
              label='Bank Name'
              handleOnChange={this.handleOnChange}
              value={this.state.profileData.BankName}
              disabled={this.state.disabledProfileData.BankNameDS}
              width='200'
              maxLength='100'
              isUpperCase={true}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={5}>
          <Grid item>
            <CtTxt
              id='BranchName'
              label='Branch Name'
              handleOnChange={this.handleOnChange}
              value={this.state.profileData.BranchName}
              disabled={this.state.disabledProfileData.BranchNameDS}
              width='200'
              maxLength='100'
              isUpperCase={true}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id='AccountType'
              label='Account Type'
              items={this.state.AccountTypes}
              value={this.state.profileData.AccountType}
              disabled={this.state.disabledProfileData.AccountTypeDS}
              handleOnChange={this.handleOnChange}
              width='200'
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={5}>
          <Grid item>
            <CtTxt
              id='AccountNumber'
              label='Account Number'
              handleOnChange={this.handleOnChange}
              value={this.state.profileData.AccountNumber}
              disabled={this.state.disabledProfileData.AccountNumberDS}
              width='200'
              maxLength='50'
              isUpperCase={true}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id='IFSCCode'
              label='IFSC Code'
              handleOnChange={this.handleOnChange}
              value={this.state.profileData.IFSCCode}
              disabled={this.state.disabledProfileData.IFSCCodeDS}
              width='200'
              maxLength='11'
              isUpperCase={true}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={5}>
          <Grid item>
            <CtBtn label='Update Profile' onClick={this.updateProfile} />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={5}>
          <Grid item>
            <ImageUploadIcon
              id='ProfileImage'
              ref={this.ProfileImg}
              default={PH_PROFILE}
              curImageName={this.state.profileData.Photo}
              curImageLocation={this.state.profileData.PhotoLocation}
              curImageFullPath={this.state.profileData.PhotoPath}
              onCurImageChange={this.onPhotoImageChange}
              notResetImageOnUpload={true}
              fixFileName={fixProfileFileName}
              uploadLocation='Profile'
              showDeleteIcon={true}
              maxSizeInKB={2048}
              changeImagePreValidation={this.validateProfiledata}
              displayFileUploadedMsg={false}
              removeImage={this.removeProfileImage}
              toastMsg={this.props.toastMsg}
              toastErrorMsg={this.props.toastErrorMsg}
            />
          </Grid>
          <Grid item>
            <ImageUploadIcon
              id='PanCardImage'
              ref={this.PanCardImg}
              default={PH_PANCARD}
              curImageName={this.state.profileData.PhotoOfPanCard}
              curImageLocation={this.state.profileData.PANNoImgLocation}
              curImageFullPath={this.state.profileData.PANNoImgPath}
              onCurImageChange={this.onPANImageChange}
              notResetImageOnUpload={true}
              fixFileName={fixPanCardFileName}
              uploadLocation='PanCard'
              showDeleteIcon={true}
              maxSizeInKB={2048}
              changeImagePreValidation={this.validateProfiledata}
              displayFileUploadedMsg={false}
              removeImage={this.removePancardImage}
              toastMsg={this.props.toastMsg}
              toastErrorMsg={this.props.toastErrorMsg}
            />
          </Grid>
        </Grid>
        <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
          {this.state.ARD}
        </Box>
      </div>
    )
  }
}

export default HOCVerifyIsUser(HoCtToastContainer(UserProfile))