import React from 'react';
import {
  Button, IconButton, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, useMediaQuery,
  Tooltip
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export default function AlertResponsiveDialog({
  label, labelAgree, labelDisagree, alertTitle, alertMessage, noOutLine, color, disableAgree, autoFocusAgreeButton,
  handleOnClickYes, handleOnClickNo, defaultOpenDialog, disabled, ToolTipLabel, style, onYesClickCloseIfExeSuccessfully
}) {
  const openDefaultStatus = (defaultOpenDialog && defaultOpenDialog == true) ? (true) : (false)
  const [open, setOpen] = React.useState(openDefaultStatus);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (handleOnClickNo) {
      handleOnClickNo()
    }
  };

  const handleYes = () => {
    if (onYesClickCloseIfExeSuccessfully) {
      if (handleOnClickYes()) {
        setOpen(false)
      }
    } else {
      setOpen(false)
      handleOnClickYes()
    }
  }

  const alertBtnStyle = style

  const AutoFocusAgree = autoFocusAgreeButton && autoFocusAgreeButton === true ? true : false

  let btnColor = color ? color : 'primary'
  let StyleBtn = { fontWeight: 'bolder' }
  let disableAgreeButton = false
  if (disableAgree && disableAgree === true) {
    disableAgreeButton = true
  }

  return (
    <div>
      {
        ToolTipLabel && ToolTipLabel.length > 0 ? (
          noOutLine && noOutLine === true ? (
            <Tooltip title={ToolTipLabel}>
              <IconButton color={btnColor} onClick={handleClickOpen} disabled={disabled} style={alertBtnStyle}>
                {label}
              </IconButton>
            </Tooltip>
          ) : (
              <Tooltip title={ToolTipLabel}>
                <Button variant="outlined" color={btnColor} onClick={handleClickOpen} disabled={disabled} style={alertBtnStyle}>
                  {label}
                </Button>
              </Tooltip >
            )
        ) : (
            noOutLine && noOutLine === true ? (
              <IconButton color={btnColor} onClick={handleClickOpen} disabled={disabled} style={alertBtnStyle}>
                { label}
              </IconButton>
            ) : (
                <Button variant="outlined" color={btnColor} onClick={handleClickOpen} disabled={disabled} style={alertBtnStyle}>
                  { label}
                </Button>
              )

          )
      }
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{alertTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {alertMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} style={StyleBtn}>
            {labelDisagree}
          </Button>
          <Button onClick={handleYes} color="primary" disabled={disableAgreeButton} style={StyleBtn} autoFocus={AutoFocusAgree}>
            {labelAgree}
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}
