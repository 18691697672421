import React from 'react'
import { toast } from 'react-toastify'
import { VerifyIsUser, VerifyIsAdmin } from '../API/API'

const HOCVerifyIsLogInUserOrAdmin = (UserAdminArea) => {

    const clearLocalStorage = () => {
        localStorage.setItem('logInID', '');
        localStorage.setItem('logInUserType', '');
    }

    const displayLoginTimeoutMsg = () => {
        toast.error('Login Timeout')
    }

    const validateLocalStorageLogInID = (IsAdmin, UserID) => {
        if (
            (
                localStorage.getItem('logInUserType') &&
                localStorage.getItem('logInUserType') == 'U' &&
                localStorage.getItem('logInID') &&
                Number(localStorage.getItem('logInID')) > 99999 &&
                (UserID === '' || Number(localStorage.getItem('logInID')) === Number(UserID))
            ) || (
                localStorage.getItem('logInUserType') &&
                localStorage.getItem('logInUserType') == 'A' &&
                localStorage.getItem('logInID') &&
                IsAdmin == true
            )
        ) {
            return true
        } else {
            return false
        }
    }

    const verifyUserID = (userID, navigateTo) => {
        VerifyIsUser(userID)
            .then(res => {
                if (res.data.status == 11) { } else {
                    clearLocalStorage()
                    displayLoginTimeoutMsg()
                    navigateTo('LogIn')
                }
            })
    }

    const verifyAdminID = (userID, navigateTo) => {
        VerifyIsAdmin(userID)
            .then(res => {
                if (
                    res.data.name && res.data.name.trim().length > 0 &&
                    res.data.status && res.data.status == 1
                ) {
                } else {
                    clearLocalStorage()
                    displayLoginTimeoutMsg()
                    navigateTo('wgar')
                }
            })
    }

    const validateIsLogInUserOrAdmin = (IsAdmin, setActiveLink, navigateTo, UserID = '') => {
        let navigated = false;
        if (validateLocalStorageLogInID(IsAdmin, UserID)) {
            if (localStorage.getItem('logInUserType') == 'A') {
                verifyAdminID(localStorage.getItem('logInID'), navigateTo)
            } else {
                verifyUserID(Number(localStorage.getItem('logInID')), navigateTo)
            }
        } else {
            navigated = true;
            clearLocalStorage()
            displayLoginTimeoutMsg()
            navigateTo('LogIn')
        }
        if (navigated === false) {
            setActiveLink({ LinkSlug: "UserAdminArea" })
        }
    }

    return (props) => {
        return (
            <div className='UserAdminArea'>
                <UserAdminArea
                    {...props}
                    validateIsLogInUserOrAdmin={validateIsLogInUserOrAdmin}
                />
            </div>
        )
    }
}
export default HOCVerifyIsLogInUserOrAdmin