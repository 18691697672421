import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import Progress from './CustomTool/Progress'
import { Grid, Box } from '@material-ui/core'
import CtTxt from './CustomTool/CtTxt'
import CtCmbEditable from './CustomTool/CtCmbEditable'
import CtCheckBox from './CustomTool/CtCheckBox'
import CtBtn from './CustomTool/CtBtn'
import CtTxtPWD from './CustomTool/CtTxtPWD'
import { fetchAdmin } from './API/API'
import { Delete, Edit } from '@material-ui/icons'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";

class UserEntry extends Component {

    state = {
        formData: {
            EditUserID: '',
            txtUserID: '',
            txtName: '',
            txtMobileNo: '',
            txtEmailID: '',
            txtPassword: '',
            txtCPassword: '',
            cmbActiveStatus: '1',
            chkAdmin: false,
            cmbPrivDashboard: '',
            cmbPrivMember: '',
            cmbPrivApproveMember: '',
            cmbPrivPersonalTree: '',
            cmbPrivAchievement: '',
            cmbPrivTurnoverCommission: '',
            cmbPrivDirectReference: '',
            cmbPrivWithdrawal: '',
            cmbPrivTDSDeduction: '',
            cmbPrivProduct: '',
            cmbPrivOffer: '',
            cmbPrivTour: '',
            cmbPrivActivationKit: '',
            cmbPrivInwardStock: '',
            cmbPrivSalesRWM: '',
            cmbPrivAdminChargeDeduction: '',
            txtRootMember: '',
            boolDisabledPassword: false,
            boolDisabledCPassword: false,
        },
        PrivilegesOption: [
            { name: 'Allow', disabled: false },
            { name: 'Denied', disabled: false }
        ],
        PrivilegesOptionRMD: [
            { name: 'Read', disabled: false },
            { name: 'Modify', disabled: false },
            { name: 'Denied', disabled: false }
        ],
        SalesPrivilegesOption: [
            { name: 'Read', disabled: false },
            { name: 'Write', disabled: false },
            { name: 'Modify', disabled: false },
            { name: 'Denied', disabled: false }
        ],
        dataLoadStatus: false,
        cWidth: 80,
        ARD: null,
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.onFormLoad()
        document.getElementById('txtUserID').focus()
    }
    //#endregion

    //#region Handles
    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }

    handleOnChkChange = (accepted) => {
        this.setState({
            formData: {
                ...this.state.formData,
                chkAdmin: accepted
            }
        }, () => {
            if (accepted == true) {
                this.onChkAdminChecked()
            }
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    onFormLoad = () => {
        if (this.props.match.params.EditUserID && this.props.match.params.EditUserID.substring(1).length > 0) {
            console.log("this.props.match.params.EditUserID", this.props.match.params.EditUserID.substring(1));
            let reqData = '',
                EditUserID = '',
                txtUserID = '',
                txtName = '',
                txtMobileNo = '',
                txtEmailID = '',
                txtPassword = '',
                txtCPassword = '',
                cmbActiveStatus = '',
                chkAdmin = false,
                cmbPrivDashboard = '',
                cmbPrivMember = '',
                cmbPrivApproveMember = '',
                cmbPrivPersonalTree = '',
                cmbPrivAchievement = '',
                cmbPrivTurnoverCommission = '',
                cmbPrivDirectReference = '',
                cmbPrivWithdrawal = '',
                cmbPrivTDSDeduction = '',
                cmbPrivProduct = '',
                cmbPrivOffer = '',
                cmbPrivTour = '',
                cmbPrivActivationKit = '',
                cmbPrivInwardStock = '',
                cmbPrivSalesRWM = '',
                txtRootMember = '',
                boolDisabledPassword = false,
                boolDisabledCPassword = false,
                cmbPrivAdminChargeDeduction = ''

            EditUserID = this.props.match.params.EditUserID.substring(1)
            reqData = {
                Op: 'LoadAdminDetailOnUpdate',
                EditUserID: EditUserID
            }
            fetchAdmin(reqData)
                .then(res => {
                    const dataLoadStatus = true
                    if (EditUserID.length > 0) {
                        txtUserID = res.data[0].UserID
                        txtName = res.data[0].Name
                        txtMobileNo = res.data[0].MobileNo
                        txtEmailID = res.data[0].emailid
                        // txtPassword = res.data[0].password
                        // txtCPassword = res.data[0].password
                        cmbActiveStatus = res.data[0].ActiveStatus
                        chkAdmin = (res.data[0].admin == "1") ? true : false
                        cmbPrivDashboard = res.data[0].privdashboard
                        cmbPrivMember = res.data[0].privmember
                        cmbPrivApproveMember = res.data[0].privapprovemember
                        cmbPrivPersonalTree = res.data[0].privpersonaltree
                        cmbPrivAchievement = res.data[0].privachievement
                        cmbPrivTurnoverCommission = res.data[0].privturnovercom
                        cmbPrivDirectReference = res.data[0].privdirectreference
                        cmbPrivWithdrawal = res.data[0].privwithdrawal
                        cmbPrivTDSDeduction = res.data[0].privtdsdeduction
                        cmbPrivProduct = res.data[0].privproduct
                        cmbPrivOffer = res.data[0].privproduct
                        cmbPrivTour = res.data[0].privtour
                        cmbPrivActivationKit = res.data[0].privactivationkit
                        cmbPrivInwardStock = res.data[0].privinwardstock
                        cmbPrivSalesRWM = res.data[0].privsalesrwm
                        txtRootMember = res.data[0].rootmember
                        boolDisabledPassword = true
                        boolDisabledCPassword = true
                        cmbPrivAdminChargeDeduction = res.data[0].privadminchargededuction
                    }
                    this.setState({
                        dataLoadStatus,
                        formData: {
                            ...this.state.formData,
                            EditUserID,
                            txtUserID,
                            txtName,
                            txtMobileNo,
                            txtEmailID,
                            txtPassword,
                            txtCPassword,
                            cmbActiveStatus,
                            chkAdmin,
                            cmbPrivDashboard,
                            cmbPrivMember,
                            cmbPrivApproveMember,
                            cmbPrivPersonalTree,
                            cmbPrivAchievement,
                            cmbPrivTurnoverCommission,
                            cmbPrivDirectReference,
                            cmbPrivWithdrawal,
                            cmbPrivTDSDeduction,
                            cmbPrivProduct,
                            cmbPrivOffer,
                            cmbPrivTour,
                            cmbPrivActivationKit,
                            cmbPrivInwardStock,
                            cmbPrivSalesRWM,
                            txtRootMember,
                            boolDisabledPassword,
                            boolDisabledCPassword,
                            cmbPrivAdminChargeDeduction
                        }
                    }, () => {
                        // console.log("this.state", this.state.formData);
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('onFormLoad', error)
                })
        } else {
            const dataLoadStatus = true
            this.setState({ dataLoadStatus })
        }
    }

    validatedata = () => {
        let validatedata = false

        if (this.state.formData.txtUserID.trim().length <= 0) {
            this.props.toastErrorMsg('Enter User ID', 'txtUserID')
        } else if (Number(this.state.formData.txtName) <= 0) {
            this.props.toastErrorMsg('Enter Name', 'txtName')
        } else if (this.state.formData.txtMobileNo.length <= 0) {
            this.props.toastErrorMsg('Enter Mobile No', 'txtMobileNo')
        } else if (this.state.formData.txtMobileNo.length != 10) {
            this.props.toastErrorMsg('Enter Valid Mobile No', 'txtMobileNo')
        } else if (this.state.formData.EditUserID.length <= 0 && this.state.formData.txtPassword.length <= 0) {
            this.props.toastErrorMsg('Enter Password', 'txtPassword')
        } else if (this.state.formData.EditUserID.length <= 0 && this.state.formData.txtPassword.length != 4) {
            this.props.toastErrorMsg('Password must be of atleast 4 character', 'txtPassword')
        } else if (this.state.formData.EditUserID.length <= 0 && this.state.formData.txtCPassword.length <= 0) {
            this.props.toastErrorMsg('Enter Confirm Password', 'txtCPassword')
        } else if (this.state.formData.EditUserID.length <= 0 && this.state.formData.txtCPassword.length != 4) {
            this.props.toastErrorMsg('Confirm Password must be of atleast 4 character', 'txtCPassword')
        } else if (this.state.formData.EditUserID.length <= 0 && this.state.formData.txtCPassword != this.state.formData.txtPassword) {
            this.props.toastErrorMsg('Password and Confirm Password must be same', 'txtCPassword')
        } else if (this.state.formData.cmbPrivDashboard.length <= 0) {
            this.props.toastErrorMsg('Select Dashboard Privileges', 'cmbPrivDashboard')
        } else if (this.state.formData.cmbPrivMember.length <= 0) {
            this.props.toastErrorMsg('Select Member Privileges', 'cmbPrivMember')
        } else if (this.state.formData.cmbPrivApproveMember.length <= 0) {
            this.props.toastErrorMsg('Select Approve Member Privileges', 'cmbPrivApproveMember')
        } else if (this.state.formData.cmbPrivPersonalTree.length <= 0) {
            this.props.toastErrorMsg('Select Personal Tree Privileges', 'cmbPrivPersonalTree')
        } else if (this.state.formData.cmbPrivAchievement.length <= 0) {
            this.props.toastErrorMsg('Select Achievement Privileges', 'cmbPrivAchievement')
        } else if (this.state.formData.cmbPrivTurnoverCommission.length <= 0) {
            this.props.toastErrorMsg('Select Turnover Commission Privileges', 'cmbPrivTurnoverCommission')
        } else if (this.state.formData.cmbPrivDirectReference.length <= 0) {
            this.props.toastErrorMsg('Select Direct Reference Privileges', 'cmbPrivDirectReference')
        } else if (this.state.formData.cmbPrivWithdrawal.length <= 0) {
            this.props.toastErrorMsg('Select Withdrawal Privileges', 'cmbPrivWithdrawal')
        } else if (this.state.formData.cmbPrivTDSDeduction.length <= 0) {
            this.props.toastErrorMsg('Select TDS Deduction Privileges', 'cmbPrivTDSDeduction')
        } else if (this.state.formData.cmbPrivProduct.length <= 0) {
            this.props.toastErrorMsg('Select Product Privileges', 'cmbPrivProduct')
        } else if (this.state.formData.cmbPrivOffer.length <= 0) {
            this.props.toastErrorMsg('Select Offer Privileges', 'cmbPrivOffer')
        } else if (this.state.formData.cmbPrivTour.length <= 0) {
            this.props.toastErrorMsg('Select Tour Privileges', 'cmbPrivTour')
        } else if (this.state.formData.cmbPrivActivationKit.length <= 0) {
            this.props.toastErrorMsg('Select Activation Kit Privileges', 'cmbPrivActivationKit')
        } else if (this.state.formData.cmbPrivInwardStock.length <= 0) {
            this.props.toastErrorMsg('Select Inward Stock Privileges', 'cmbPrivInwardStock')
        } else if (this.state.formData.cmbPrivSalesRWM.length <= 0) {
            this.props.toastErrorMsg('Select Sales Privileges', 'cmbPrivSalesRWM')
        } else if (this.state.formData.cmbPrivAdminChargeDeduction.length <= 0) {
            this.props.toastErrorMsg('Select Admin Charge Deduction Privileges', 'cmbPrivAdminChargeDeduction')
        } else {
            validatedata = true
        }
        return validatedata
    }

    saveUser = () => {
        if (this.validatedata() === true) {
            this.props.notifyProcessing()

            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'SaveUser',
                    edituserid: this.state.formData.EditUserID,
                    userid: this.state.formData.txtUserID,
                    name: this.state.formData.txtName,
                    mobileno: this.state.formData.txtMobileNo,
                    emailid: this.state.formData.txtEmailID,
                    password: this.state.formData.txtPassword,
                    // cpassword: this.state.formData.txtCPassword,
                    activestatus: this.state.formData.cmbActiveStatus,
                    admin: this.state.formData.chkAdmin === true ? 1 : 0,
                    privdashboard: this.state.formData.cmbPrivDashboard,
                    privmember: this.state.formData.cmbPrivMember,
                    privapprovemember: this.state.formData.cmbPrivApproveMember,
                    privpersonaltree: this.state.formData.cmbPrivPersonalTree,
                    privachievement: this.state.formData.cmbPrivAchievement,
                    privturnovercom: this.state.formData.cmbPrivTurnoverCommission,
                    privdirectreference: this.state.formData.cmbPrivDirectReference,
                    privwithdrawal: this.state.formData.cmbPrivWithdrawal,
                    privtdsdeduction: this.state.formData.cmbPrivTDSDeduction,
                    privproduct: this.state.formData.cmbPrivProduct,
                    privoffer: this.state.formData.cmbPrivOffer,
                    privtour: this.state.formData.cmbPrivTour,
                    privactivationkit: this.state.formData.cmbPrivActivationKit,
                    privinwardstock: this.state.formData.cmbPrivInwardStock,
                    privsalesrwm: this.state.formData.cmbPrivSalesRWM,
                    rootmember: this.state.formData.txtRootMember,
                    privadminchargededuction: this.state.formData.cmbPrivAdminChargeDeduction,
                }
                fetchAdmin(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.setState({
                            dataLoadStatus: true,
                        }, () => {
                            if (res.data.msgType === 'success') {
                                this.newUser()
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('saveUser', error)
                    })
            })
        }
    }

    newUser = () => {
        const oldEditUserID = this.state.formData.EditUserID
        const EditUserID = '',
            txtUserID = '',
            txtName = '',
            txtMobileNo = '',
            txtEmailID = '',
            txtPassword = '',
            txtCPassword = '',
            cmbActiveStatus = '1',
            chkAdmin = false,
            cmbPrivDashboard = '',
            cmbPrivMember = '',
            cmbPrivApproveMember = '',
            cmbPrivPersonalTree = '',
            cmbPrivAchievement = '',
            cmbPrivTurnoverCommission = '',
            cmbPrivDirectReference = '',
            cmbPrivWithdrawal = '',
            cmbPrivTDSDeduction = '',
            cmbPrivProduct = '',
            cmbPrivOffer = '',
            cmbPrivTour = '',
            cmbPrivActivationKit = '',
            cmbPrivInwardStock = '',
            cmbPrivSalesRWM = '',
            txtRootMember = '',
            boolDisabledPassword = false,
            boolDisabledCPassword = false,
            cmbPrivAdminChargeDeduction = ''
        this.setState({
            formData: {
                ...this.state.formData,
                EditUserID,
                txtUserID,
                txtName,
                txtMobileNo,
                txtEmailID,
                txtPassword,
                txtCPassword,
                cmbActiveStatus,
                chkAdmin,
                cmbPrivDashboard,
                cmbPrivMember,
                cmbPrivApproveMember,
                cmbPrivPersonalTree,
                cmbPrivAchievement,
                cmbPrivTurnoverCommission,
                cmbPrivDirectReference,
                cmbPrivWithdrawal,
                cmbPrivTDSDeduction,
                cmbPrivProduct,
                cmbPrivOffer,
                cmbPrivTour,
                cmbPrivActivationKit,
                cmbPrivInwardStock,
                cmbPrivSalesRWM,
                txtRootMember,
                boolDisabledPassword,
                boolDisabledCPassword,
                cmbPrivAdminChargeDeduction
            },
        }, () => {
            if (oldEditUserID.length > 0) {
                this.navigateTo('UserEntry')
            }
            document.getElementById('txtUserID').focus()
        })
    }

    onDeleteBtnClick = () => {
        if (this.state.formData.EditUserID.length > 0) {
            const EditUserID = this.state.formData.EditUserID
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete User : ' + EditUserID + '?'}
                    // alertMessage={}
                    handleOnClickYes={() => { return this.deleteUser(EditUserID) }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } else {
            this.props.toastMsg("info", "There are no detail to delete")
        }
    }

    deleteUser = (EditUserID) => {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                Op: 'DeleteUser',
                edituserid: EditUserID,
            }
            fetchAdmin(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    this.setState({
                        dataLoadStatus: true
                    }, () => {
                        if (res.data.msgType === 'success') {
                            this.newUser()
                        }
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('deleteUser', error)
                })
        })
        return true
    }

    onChkAdminChecked = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                cmbPrivDashboard: 'Allow',
                cmbPrivMember: 'Allow',
                cmbPrivApproveMember: 'Allow',
                cmbPrivPersonalTree: 'Allow',
                cmbPrivAchievement: 'Allow',
                cmbPrivTurnoverCommission: 'Allow',
                cmbPrivDirectReference: 'Allow',
                cmbPrivWithdrawal: 'Allow',
                cmbPrivTDSDeduction: 'Allow',
                cmbPrivProduct: 'Allow',
                cmbPrivOffer: 'Allow',
                cmbPrivTour: 'Allow',
                cmbPrivActivationKit: 'Allow',
                cmbPrivInwardStock: 'Allow',
                cmbPrivSalesRWM: 'Modify',
                cmbPrivAdminChargeDeduction: 'Allow',
            }
        })
    }

    onClose = () => {
        this.navigateTo('UserList')
    }
    //#endregion */

    render() {
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <br />
                <form>
                    <Grid container direction="row" justify="space-evenly" alignItems="flex-start">
                        <Grid item className="pageTitle" xs={12} lg={4}>
                            <label htmlFor="Title" className='pageTitleTextLeft'>User Entry</label>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtUserID'
                                        label='User ID'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtUserID}
                                        width='400'
                                        maxLength='100'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtName'
                                        label='User Name'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtName}
                                        width='400'
                                        maxLength='100'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtMobileNo'
                                        label='Mobile No'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtMobileNo}
                                        width='120'
                                        maxLength='10'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtEmailID'
                                        label='Email ID'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtEmailID}
                                        width='260'
                                        maxLength='50'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxtPWD
                                        id='txtPassword'
                                        label='Password'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtPassword}
                                        width='400'
                                        maxLength='100'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={this.state.formData.boolDisabledPassword}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxtPWD
                                        id='txtCPassword'
                                        label='Confirm Password'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtCPassword}
                                        width='400'
                                        maxLength='100'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={this.state.formData.boolDisabledCPassword}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtRootMember'
                                        label='Root Member'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtRootMember}
                                        width='120'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCheckBox
                                        id='chkAdmin'
                                        name='chkAdmin'
                                        label='Super Admin'
                                        checked={this.state.formData.chkAdmin}
                                        handleCheckChange={this.handleOnChkChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <Box item style={{ width: '100px' }} display={{ xs: 'none', sm: 'block' }}>
                                        &nbsp;
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className="pageTitle" xs={12} lg={8}>
                            <label htmlFor="Title" className='pageTitleText'>Privileges</label>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbPrivDashboard'
                                        label='Dashboard'
                                        items={this.state.PrivilegesOption}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbPrivDashboard}
                                        width='150'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbPrivMember'
                                        label='Member'
                                        items={this.state.PrivilegesOption}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbPrivMember}
                                        width='130'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbPrivApproveMember'
                                        label='Approve Member'
                                        items={this.state.PrivilegesOptionRMD}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbPrivApproveMember}
                                        width='170'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbPrivPersonalTree'
                                        label='Personal Tree'
                                        items={this.state.PrivilegesOption}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbPrivPersonalTree}
                                        width='150'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbPrivAchievement'
                                        label='Achievement'
                                        items={this.state.PrivilegesOption}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbPrivAchievement}
                                        width='150'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbPrivTurnoverCommission'
                                        label='Turnover Com.'
                                        items={this.state.PrivilegesOption}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbPrivTurnoverCommission}
                                        width='130'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbPrivDirectReference'
                                        label='Direct Reference'
                                        items={this.state.PrivilegesOption}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbPrivDirectReference}
                                        width='170'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbPrivWithdrawal'
                                        label='Withdrawal'
                                        items={this.state.PrivilegesOption}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbPrivWithdrawal}
                                        width='150'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbPrivTDSDeduction'
                                        label='TDS Deduction'
                                        items={this.state.PrivilegesOption}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbPrivTDSDeduction}
                                        width='150'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbPrivProduct'
                                        label='Product'
                                        items={this.state.PrivilegesOption}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbPrivProduct}
                                        width='130'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbPrivOffer'
                                        label='Offer'
                                        items={this.state.PrivilegesOption}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbPrivOffer}
                                        width='170'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbPrivTour'
                                        label='Tour'
                                        items={this.state.PrivilegesOption}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbPrivTour}
                                        width='150'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbPrivActivationKit'
                                        label='Activation Kit'
                                        items={this.state.PrivilegesOption}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbPrivActivationKit}
                                        width='150'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbPrivInwardStock'
                                        label='Inward Stock'
                                        items={this.state.PrivilegesOption}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbPrivInwardStock}
                                        width='130'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbPrivSalesRWM'
                                        label='Sales'
                                        items={this.state.SalesPrivilegesOption}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbPrivSalesRWM}
                                        width='150'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbPrivAdminChargeDeduction'
                                        label='Admin Charge Ded.'
                                        items={this.state.PrivilegesOption}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbPrivAdminChargeDeduction}
                                        width='170'
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className="pageTitle" xs={12} lg={12}>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtBtn label='Save' onClick={() => { this.saveUser() }} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='New' onClick={this.newUser} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='Delete' onClick={this.onDeleteBtnClick} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='Close' onClick={this.onClose} width={this.state.cWidth} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(UserEntry))