import React, { Component } from 'react'
import { Grid } from '@material-ui/core'

class AboutUs extends Component {

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "AboutUs" })
    }

    render() {
        return (
            <div className="AboutUs">
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' >Coming Soon</label>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default AboutUs