import React from 'react'
import TextField from '@material-ui/core/TextField';

const CtMultiLineText = ({
    id, label, disabled, value, width, handleOnChange, rows, maxLength, foreColor,
    onKeyDown, defaultAction, validateInput
}) => {

    const useWidthStyle = { width: width + 'px' }
    // const custStyle = { color: foreColor }
    const handleOnKeyDown = (onKeyDown) ? ((event) => { onKeyDown(event, 1, defaultAction, validateInput) }) : (null)
    const applyMaxLength = { maxLength: maxLength, readOnly: disabled }
    const applyMaxLengthMissing = { maxLength: 1, readOnly: disabled }

    return (
        <TextField
            id={id}
            name={id}
            label={label}
            disabled={disabled}
            value={value}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            multiline
            rows={rows}
            style={width > 0 ? useWidthStyle : {}}
            inputProps={maxLength > 0 ? applyMaxLength : applyMaxLengthMissing, { style: { color: foreColor } }}
        />
    )
}

export default CtMultiLineText