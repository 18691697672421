import React, { Component } from 'react'
import { fetchInward } from "./API/API";
import HoCtToastContainer from './HOC/HoCtToastContainer'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";

class InwardStockDelete extends Component {
    deleteInward = (EditSrNo, EditDate) => {
        this.props.notifyProcessing()
        if (Number(EditSrNo) > 0 && EditDate.length > 0) {
            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'DeleteInwardStock',
                    EditSrNo: Number(EditSrNo),
                    EditDate: EditDate,
                }
                fetchInward(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.setState({
                            dataLoadStatus: true
                        }, () => {
                            if (res.data.msgType === 'success') {
                                if (this.props.onDeleteSuccess) {
                                    this.props.onDeleteSuccess()
                                }
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('deleteInward', error)
                    })
            })
        } else {
            this.props.toastMsg("info", "There are no detail to delete")
        }
        return true
    }
    render() {
        return (
            <AlertResponsiveDialog
                labelAgree='Delete'
                labelDisagree='Cancel'
                alertTitle={'Do you want to Delete selected Inward Stock Entry?'}
                // alertMessage={}
                handleOnClickYes={() => {
                    return this.deleteInward(
                        this.props.EditSrNo, this.props.EditDate
                    )
                }}
                defaultOpenDialog={true}
                onYesClickCloseIfExeSuccessfully={true}
            />
        )
    }
}
export default HoCtToastContainer(InwardStockDelete)
