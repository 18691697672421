import React, { Component } from 'react'
import { fetchMember } from './API/API'
import TableList from './CustomTool/TableList'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import Progress from './CustomTool/Progress'
import { AccountCircle, Dashboard, FiberPin } from "@material-ui/icons";
import { REGULAR, GIFT_PLAN } from './SystemConstants'
import { Grid } from "@material-ui/core";

class MemberList extends Component {

  state = {
    counter: 0,
    columns: [
      { id: 'Action', label: 'Action', minWidth: 10 },
      { id: 'ID', label: 'ID', minWidth: 70 },
      { id: 'Password', label: 'Password', minWidth: 70 },
      { id: 'Scheme', label: 'Scheme', minWidth: 100 },
      {
        id: 'ReferenceCode',
        label: 'Ref.\u00a0Code',
        minWidth: 70,
        format: (value) => value.toLocaleString('en-US'),
      },
      {
        id: 'SponsorId',
        label: 'Sponsor\u00a0ID',
        minWidth: 70,
        format: (value) => value.toLocaleString('en-US'),
      },
      {
        id: 'Name',
        label: 'Name',
        minWidth: 170,
        format: (value) => value.toFixed(2),
      },
      {
        id: 'MobileNo',
        label: 'Mobile\u00a0No',
        minWidth: 100,
      },
      { id: 'StrActiveStatus', label: 'Status', minWidth: 100 },
    ],
    searchData: {
      ID: '',
      Password: '',
      Scheme: 'ALL',
      ReferenceCode: '',
      SponsorId: '',
      Name: '',
      MobileNo: '',
      StrActiveStatus: '',
    },
    searchColumns: [],
    rows: [
      {
        Action: '',
        ID: '',
        Scheme: '',
        ReferenceCode: '',
        SponsorId: '',
        Name: '',
        MobileNo: '',
        StrActiveStatus: '',
      }
    ],
    searchRows: [],
    searchOpen: false,
    searchRowsCount: 0,
    rowsCount: 0,
    actionList: [
      { name: 'Profile', icon: <AccountCircle />, link: 'UserProfile:' },
      { name: 'Dashboard', icon: <Dashboard />, link: 'UserDashboard:' },
      {
        name: 'Generate Pin', icon: <FiberPin />, link: 'GeneratePin:', actionType: 'Conditional',
        condition: [{ colID: 'Scheme', value: GIFT_PLAN }],
      },
    ],
    dataLoadStatus: false,
    schemes: [
      { name: 'ALL', disabled: false },
      { name: REGULAR, disabled: false },
      { name: GIFT_PLAN, disabled: false }
    ],
    rowsPerPage: 10,
  }

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage })
  }

  setSearchColumns = () => {
    let searchColumns = []
    searchColumns.push({
      cType: 'CtTxtAdornNum',
      id: 'ID',
      label: 'ID',
      adornment: 'WG',
      value: this.state.searchData.ID,
      handleOnChange: this.handleOnSearchInputChange,
      width: '100'
    }, {
      cType: 'Lbl',
      // id: 'Password',
      label: 'Password',
      // items: this.state.schemes,
      // value: this.state.searchData.Scheme,
      // handleOnChange: this.handleOnSearchInputChange,
      width: '100',
    }, {
      cType: 'CtCmb',
      id: 'Scheme',
      label: 'Scheme',
      items: this.state.schemes,
      value: this.state.searchData.Scheme,
      handleOnChange: this.handleOnSearchInputChange,
      width: '100',
    }, {
      cType: 'CtTxtAdornNum',
      id: 'ReferenceCode',
      label: 'ReferenceCode',
      adornment: 'WG',
      value: this.state.searchData.ReferenceCode,
      handleOnChange: this.handleOnSearchInputChange,
      width: '100'
    }, {
      cType: 'CtTxtAdornNum',
      id: 'SponsorId',
      label: 'SponsorId',
      adornment: 'WG',
      value: this.state.searchData.SponsorId,
      handleOnChange: this.handleOnSearchInputChange,
      width: '100'
    }, {
      cType: 'CtTxt',
      id: 'Name',
      label: 'Name',
      value: this.state.searchData.Name,
      handleOnChange: this.handleOnSearchInputChange,
      width: '100',
      maxLength: '100'
    }, {
      cType: 'CtPhoneNumber',
      id: 'MobileNo',
      label: 'MobileNo',
      value: this.state.searchData.MobileNo,
      handleOnChange: this.handleOnSearchInputChange,
      width: '100'
    }, {
      cType: 'CtTxt',
      id: 'StrActiveStatus',
      label: 'Status',
      value: this.state.searchData.Status,
      handleOnChange: this.handleOnSearchInputChange,
      width: '100',
      maxLength: '100'
    })
    this.setState({ searchColumns })
  }

  handleOnSearchInputChange = (e) => {
    // console.log('handleOnSearchInputChange', [e.target.name], e.target.value, this.state.searchData);
    this.setState({
      searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
    }, () => {
      this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
      // console.log(this.state.searchData)
    })
  }

  handleOnSearchInputChangeTrim = (e) => {
    this.setState({
      searchData: { ...this.state.searchData, [e.target.name]: e.target.value.trim() }
    }, () => {
      this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
    })
  }

  componentDidMount() {
    this.props.setActiveLink({ LinkSlug: "MemberList" })
    this.listMembers()
    this.setSearchColumns()
  }

  componentWillMount() {
    this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
  }

  listMembers = () => {
    let rootmember = ''
    if (
      this.props.match.params.rootmember &&
      this.props.match.params.rootmember.length > 0 &&
      Number(this.props.match.params.rootmember.substring(1)) > 99999
    ) {
      rootmember = Number(this.props.match.params.rootmember.substring(1))
    } else if (this.props.rootmember && this.props.rootmember.length > 0 && Number(this.props.rootmember) > 99999) {
      rootmember = this.props.rootmember
    }
    const reqData = {
      Op: 'MemberList',
      rootmember
    }
    fetchMember(reqData)
      .then(res => {
        this.setState({
          rows: res.data,
          rowsCount: res.data.length,
          dataLoadStatus: true
        })
      })
  }

  navigateTo = (link) => {
    this.props.history.push('/' + link)
  }

  handleOnSearchChange = (searchOpen) => {
    let searchRows = []
    if (searchOpen === true && this.state.rows.length > 0) {
      this.state.rows.map((row) => {
        if (
          searchOpen === true &&
          (
            (this.state.searchData.ID.length === 0 || row['ID'].toString().includes(this.state.searchData.ID)) &&
            (this.state.searchData.Scheme === 'ALL' || this.state.searchData.Scheme === row['Scheme']) &&
            (this.state.searchData.ReferenceCode.length === 0 || row['ReferenceCode'].toString().includes(this.state.searchData.ReferenceCode)) &&
            (this.state.searchData.SponsorId.length === 0 || row['SponsorId'].toString().includes(this.state.searchData.SponsorId)) &&
            (this.state.searchData.Name.length === 0 || row['Name'].toString().includes(this.state.searchData.Name)) &&
            (this.state.searchData.MobileNo.length === 0 || row['MobileNo'].toString().includes(this.state.searchData.MobileNo.toString())) &&
            (this.state.searchData.StrActiveStatus.length === 0 || row['StrActiveStatus'].toString().includes(this.state.searchData.StrActiveStatus.toString()))
          )
        ) {
          searchRows.push(row)
        }
      })
      const searchRowsCount = searchRows.length
      this.setState({
        searchOpen,
        searchRows,
        searchRowsCount,
        counter: this.state.counter + 1,
      }, () => {
      })
    } else {
      this.setState({ searchOpen })
    }
  }

  render() {

    let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
    let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)

    return (
      <div className="MemberList">
        <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item className="pageTitle">
            <label htmlFor="Title" className='pageTitleText'>Member List</label>
          </Grid>
        </Grid>
        {
          (this.state.rowsCount > 0) ?
            <TableList
              columns={this.state.columns}
              counter={this.state.counter}
              rows={rows}
              rowsCount={rowsCount}
              rowsPerPage={this.state.rowsPerPage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              onActionClick={this.navigateTo}
              actionList={this.state.actionList}
              keyColumn={['ID']}
              handleOnSearchChange={this.handleOnSearchChange}
              searchColumns={this.state.searchColumns}
              searchData={this.state.searchData}
              provideSearch={true}
            />
            : "No Member Details Found"
        }
      </div>
    );
  }
}

export default HOCVerifyIsAdmin(MemberList)