import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import TableList from './CustomTool/TableList'
import { fetchCompanyCharges } from './API/API'
import { Grid, Box } from "@material-ui/core";
import Progress from './CustomTool/Progress'
import { get_YMD_from_SYS, getIndianFormatedNumber } from './SystemUtility/SystemUtility'
import CtDtpDOB from './CustomTool/CtDtpDOB'
import CtBtn from './CustomTool/CtBtn'
import CtCmb from './CustomTool/CtCmb'
import { Delete, Edit } from '@material-ui/icons'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";

class CompanyChargeList extends Component {

    state = {
        dataLoadStatus: false,
        counter: 0,
        columns: [
            { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            { id: 'no', label: 'Sr. No.', align: 'center' },
            { id: 'dateDMY', label: 'Date', align: 'center' },
            { id: 'ledger', label: 'Ledger' },
            { id: 'amount', label: 'Amount', align: 'right', format:(value) => getIndianFormatedNumber(value) },
            // { id: 'remark', label: 'Remark', align: 'center' },
            { id: 'actype', label: 'Ac Type', align: 'center' },
            { id: 'bankname', label: 'Bank Name', align: 'center' },
            { id: 'branchname', label: 'Branch Name', align: 'center' },
            { id: 'ifsccode', label: 'IFSC Code', align: 'center' },
            { id: 'acno', label: 'AC No', align: 'center' },
            { id: 'srno', label: 'srno', align: 'center', hide: 'true' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 5,
        searchData: {
            ledger: '',
            actype: '',
            bankname: '',
            branchname: '',
            ifsccode: '',
            acno: '',
        },
        actionList: [
            {
                name: 'Edit',
                icon: <Edit />,
                link: 'CompanyChargeEntry:',
                actionType: 'AlertResponsive'
            },
            {
                name: 'Delete',
                icon: <Delete />,
                link: 'DelCompanyChargeEntry:',
                actionType: 'AlertResponsive'
            },
        ],
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        formData: {
            dtpFromDate: get_YMD_from_SYS(new Date()),
            dtpToDate: get_YMD_from_SYS(new Date()),
            cmbLedger: ''
        },
        keyColumn: [
            'srno'
        ],
        ARD: null,
        LedgerList: [
            { name: 'Admin Charge', disabled: 'false' },
            { name: 'TDS Tax', disabled: 'false' }
        ],
        AccountTypes: [
            { name: 'Saving', disabled: false },
            { name: 'Current', disabled: false },
        ],
        ledgerSlug: '',
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "CompanyChargeList" })
        this.setSearchColumns()
    }

    componentWillReceiveProps(newProps) {
        this.listCompanyCharge(newProps.match.params.Ledger)
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    (
                        (this.state.searchData.ledger.length === 0 || row['ledger'].toString().includes(this.state.searchData.ledger)) &&
                        (this.state.searchData.actype.length === 0 || row['actype'].toString().includes(this.state.searchData.actype)) &&
                        (this.state.searchData.bankname.length === 0 || row['bankname'].toString().includes(this.state.searchData.bankname)) &&
                        (this.state.searchData.branchname.length === 0 || row['branchname'].toString().includes(this.state.searchData.branchname)) &&
                        (this.state.searchData.ifsccode.length === 0 || row['ifsccode'].toString().includes(this.state.searchData.ifsccode)) &&
                        (this.state.searchData.acno.length === 0 || row['acno'].toString().includes(this.state.searchData.acno))
                    )
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
                // console.log('searchRows on end of Function', searchRows)
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleOnSearchInputChange = (e) => {
        // console.log('handleOnSearchInputChange', [e.target.name], e.target.value, this.state.searchData);
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
            // console.log(this.state.searchData)
        })
    }

    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }

    handleARDonActionClick = (values) => {
        const EditSrNo = values[0].value,
            actionName = values[0].actionName
        if (actionName === 'Edit') {
            this.navigateTo('CompanyChargeEntry:' + this.state.ledgerSlug + ":" + EditSrNo)
        } else if (actionName === 'Delete') {
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete this record ?'}
                    // alertMessage={}
                    handleOnClickYes={() => { return this.deleteCompanyCharge(EditSrNo) }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {
                cType: 'Lbl',
                label: 'Sr No.',
                width: '50',
            },
            {
                cType: 'Lbl',
                label: 'Date',
                width: '100',
            },
            {
                cType: 'CtCmb',
                id: 'ledger',
                label: 'Ledger',
                items: this.state.LedgerList,
                value: this.state.searchData.ledger,
                handleOnChange: this.handleOnSearchInputChange,
                width: '150',
                maxLength: '100'
            },
            {
                cType: 'Lbl',
                label: 'Amount',
                width: '100',
            },
            {
                cType: 'CtCmb',
                id: 'actype',
                label: 'AC Type',
                items: this.state.AccountTypes,
                value: this.state.searchData.actype,
                handleOnChange: this.handleOnSearchInputChange,
                width: '120',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'bankname',
                label: 'Bank Name',
                value: this.state.searchData.bankname,
                handleOnChange: this.handleOnSearchInputChange,
                width: '150',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'branchname',
                label: 'Branch Name',
                value: this.state.searchData.branchname,
                handleOnChange: this.handleOnSearchInputChange,
                width: '150',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'ifsccode',
                label: 'IFSC Code',
                value: this.state.searchData.ifsccode,
                handleOnChange: this.handleOnSearchInputChange,
                width: '120',
                maxLength: '11'
            },
            {
                cType: 'CtTxt',
                id: 'acno',
                label: 'AC No',
                value: this.state.searchData.acno,
                handleOnChange: this.handleOnSearchInputChange,
                width: '120',
                maxLength: '50'
            }
        )
        this.setState({ searchColumns })
    }

    onNewEntryClick = () => {
        this.navigateTo('CompanyChargeEntry:' + this.state.ledgerSlug)
    }

    listCompanyCharge = (strLedgerName = '') => {
        let ledger = '', ledgerSlug
        if (strLedgerName && strLedgerName.length > 0) {
            ledgerSlug = strLedgerName.substring(1)
            if (ledgerSlug === "AdminCharge") {
                ledger = 'Admin Charge'
            } else {
                ledger = 'TDS Tax'
            }
            this.setState({
                dataLoadStatus: false,
                ledgerSlug,
                formData: {
                    ...this.state.formData,
                    cmbLedger: ledger
                }
            }, () => {
                this.loadCompanyChargeList()
            })
        } else {
            this.loadCompanyChargeList()
        }

    }

    loadCompanyChargeList = () => {
        let FromDate = this.state.formData.dtpFromDate,
            ToDate = this.state.formData.dtpToDate,
            Ledger = this.state.formData.cmbLedger
        const reqData = {
            Op: 'CompanyChargesList',
            FromDate,
            ToDate,
            Ledger
        }
        fetchCompanyCharges(reqData)
            .then(res => {
                const rows = res.data.companychargesList
                const rowsCount = rows.length
                this.setState({ rows, rowsCount, dataLoadStatus: true })
            })
            .catch(error => {
                this.props.toastMsg('error', 'Unkonwn Error occured in listCompanyCharge. ' + error)
            })
    }

    deleteCompanyCharge = (EditSrNo) => {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                Op: 'DeleteCompanyCharge',
                EditSrNo: EditSrNo,
            }
            fetchCompanyCharges(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    this.setState({
                        dataLoadStatus: true
                    }, () => {
                        if (res.data.msgType === 'success') {
                            this.listCompanyCharge()
                        }
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('deleteCompanyCharge', error)
                })
        })
        return true
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <form>
                    <br />
                    <Grid container direction="row" justify="left" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtDtpDOB
                                id='dtpFromDate'
                                label='From Date'
                                width='150'
                                handleOnChange={this.handleOnChange}
                                defaultValue={this.state.formData.dtpFromDate}
                                value={this.state.formData.dtpFromDate}
                                onKeyDown={this.props.onKeyDown}
                            // disabled={this.state.disabledProfileData.DOBDS}
                            />
                        </Grid>
                        <Grid item>
                            <CtDtpDOB
                                id='dtpToDate'
                                label='To Date'
                                width='150'
                                handleOnChange={this.handleOnChange}
                                defaultValue={this.state.formData.dtpToDate}
                                value={this.state.formData.dtpToDate}
                                onKeyDown={this.props.onKeyDown}
                            // disabled={this.state.disabledProfileData.DOBDS}
                            />
                        </Grid>
                        <Grid item>
                            <CtCmb
                                id='cmbLedger'
                                label='Ledger'
                                items={this.state.LedgerList}
                                value={this.state.formData.cmbLedger}
                                handleOnChange={this.handleOnChange}
                                width='150'
                                disabled={true}
                            />
                        </Grid>
                        <Grid item>
                            <CtBtn label='Load' width={150} onClick={this.listCompanyCharge} />
                        </Grid>
                    </Grid>
                </form>
                <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={this.onNewEntryClick}
                    toolTipNewEntry='New Company Charge Entry'
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }

}
export default HOCVerifyIsAdmin(HoCtToastContainer(CompanyChargeList))