import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import Progress from './CustomTool/Progress'
import { Grid, Box, Button } from '@material-ui/core'
import CtDtpDOB from './CustomTool/CtDtpDOB'
import CtTxt from './CustomTool/CtTxt'
import CtCmbEditable from './CustomTool/CtCmbEditable'
import CtCmb from './CustomTool/CtCmb'
import CtBtn from './CustomTool/CtBtn'
import { fetchInward } from './API/API'
import InwardStockDelete from './InwardStockDelete'
import { get_YMD_from_DMY, get_YMD_from_SYS } from './SystemUtility/SystemUtility'

class InwardStockEntry extends Component {

    state = {
        dataLoadStatus: false,
        formData: {
            EditDate: '',
            EditSrNo: '',
            dtpDate: '',
            cmbProductName: '',
            txtCurrentStock: '',
            txtQuantity: '',
            txtBatchNo: '',
        },
        cWidth: 80,
        ProductName: [],
    }

    //#region Component
    componentDidMount() {
        this.onFormLoad()
        document.getElementById('dtpDate').focus()
    }
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }
    //#endregion

    //#region Handles
    handleOnChange = (e) => {
        // console.log('e.target.name',e.target.name)
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }

    handleOnAutoCompleteChange = (e, id) => {
        // console.log('id',id)
        // console.log('e.target.value',e.target.value)
        this.setState({
            formData: { ...this.state.formData, [id]: e.target.value }
        }, () => {
            console.log('After Change ', this.state.formData)
        })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }

    handleARDonActionClick = () => {
        if (Number(this.state.formData.EditSrNo) > 0) {
            const EditSrNo = this.state.formData.EditSrNo,
                EditDate = this.state.formData.EditDate
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <InwardStockDelete
                    EditSrNo={EditSrNo}
                    EditDate={EditDate}
                    onDeleteSuccess={this.newInwardStock}
                />
                this.setState({ ARD })
            })
        } else {
            this.props.toastMsg("info", "There are no detail to delete")
        }
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    onFormLoad = () => {
        this.setState({
            dataLoadStatus: false
        }, () => {
            let reqData = {
                Op: 'Load_ProductName'
            },
                EditDate = '',
                EditSrNo = 0,
                dtpDate = get_YMD_from_SYS(new Date()),
                cmbProductName = '',
                txtCurrentStock = '',
                txtQuantity = '',
                txtBatchNo = ''
            if (this.props.match.params.EditSrNo && (this.props.match.params.EditSrNo.substring(1).length) > 0) {
                var res = this.props.match.params.EditSrNo.split(":")
                EditSrNo = res[1]
                EditDate = res[2]
                reqData = {
                    Op: 'Load_ProductName_InwardData',
                    EditSrNo,
                    EditDate
                }
            }
            fetchInward(reqData)
                .then(res => {
                    const ProductName = res.data.ProductName
                    const dataLoadStatus = true
                    if (EditSrNo > 0) {
                        // EditSrNo = res.data.InwardStockList[0].SrNo
                        // EditDate = res.data.InwardStockList[0].Date
                        dtpDate = EditDate
                        cmbProductName = res.data.InwardStockList[0].ProductName
                        txtCurrentStock = res.data.InwardStockList[0].CurrentStock
                        txtQuantity = res.data.InwardStockList[0].Quantity
                        txtBatchNo = res.data.InwardStockList[0].BatchNo
                    }
                    this.setState({
                        ProductName, dataLoadStatus,
                        formData: {
                            EditDate,
                            EditSrNo,
                            dtpDate,
                            cmbProductName,
                            txtCurrentStock,
                            txtQuantity,
                            txtBatchNo
                        }
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('onFormLoad', error)
                })
        })
    }

    validateInwarddata = () => {
        let validateInwarddata = false
        if (this.state.formData.dtpDate.length <= 0) {
            this.props.toastErrorMsg('Enter Date', 'dtpDate')
        } else if (this.state.formData.cmbProductName.trim().length <= 0) {
            this.props.toastErrorMsg('Select Product Name', 'cmbProductName')
        } else if (Number(this.state.formData.txtQuantity) === 0) {
            this.props.toastErrorMsg('Enter Inward Quantity', 'txtQuantity')
        // } else if (Number(this.state.formData.txtQuantity) == 0) {
        //     this.props.toastErrorMsg('Quantity cannot be 0', 'txtQuantity')
            // } else if (this.state.formData.txtBatchNo.length <= 0) {
            //     this.props.toastErrorMsg('Enter Batch No.', 'txtBatchNo')
        } else {
            validateInwarddata = true
        }
        return validateInwarddata
    }

    saveInwardStock = () => {
        let result = false
        if (this.validateInwarddata() === true) {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'SaveInwardStock',
                    EditSrNo: Number(this.state.formData.EditSrNo),
                    EditDate: this.state.formData.EditDate,
                    Date: this.state.formData.dtpDate,
                    ProductName: this.state.formData.cmbProductName,
                    Qty: Number(this.state.formData.txtQuantity),
                    BatchNo: this.state.formData.txtBatchNo
                }
                fetchInward(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.setState({
                            dataLoadStatus: true
                        }, () => {
                            if (res.data.msgType === 'success') {
                                result = true
                                this.newInwardStock()
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('saveInwardStock', error)
                    })
            })
        }
        return result
    }

    newInwardStock = () => {
        const oldEditSrNo = this.state.formData.EditSrNo
        const EditDate = '',
            EditSrNo = 0,
            dtpDate = get_YMD_from_SYS(new Date()),
            cmbProductName = '',
            txtCurrentStock = '',
            txtQuantity = '',
            txtBatchNo = ''
        this.setState({
            formData: {
                ...this.state.formData,
                EditDate,
                EditSrNo,
                dtpDate,
                cmbProductName,
                txtCurrentStock,
                txtQuantity,
                txtBatchNo
            },
        }, () => {
            if (oldEditSrNo > 0) {
                this.navigateTo('InwardStockEntry')
            }
            document.getElementById('dtpDate').focus()
        })
    }

    deleteInwardStock = () => {
        console.log("Code remain");
    }

    onClose = (link) => {
        this.navigateTo('InwardStock')
    }
    //#endregion

    render() {
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' >Inward Stock Entry</label>
                    </Grid>
                </Grid>
                <form>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtDtpDOB
                                id='dtpDate'
                                label='Date'
                                width='150'
                                handleOnChange={this.handleOnChangeTrim}
                                defaultValue={this.state.formData.dtpDate}
                                value={this.state.formData.dtpDate}
                                onKeyDown={this.props.onKeyDown}
                            // disabled={this.state.disabledProfileData.DOBDS}
                            />
                        </Grid>
                        <Grid item>
                            <CtCmbEditable
                                id='cmbProductName'
                                label='Product Name'
                                items={this.state.ProductName}
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.cmbProductName}
                                width='300'
                                maxLength='100'
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtTxt
                                id='txtCurrentStock'
                                label='Stock'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtCurrentStock}
                                width='80'
                                maxLength='15'
                                onKeyDown={this.props.onKeyDown}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtQuantity'
                                label='Quantity'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtQuantity}
                                width='80'
                                maxLength='15'
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtBatchNo'
                                label='Batch No'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtBatchNo}
                                width='250'
                                maxLength='100'
                                onKeyDown={this.props.onKeyDown}
                                defaultAction={() => { this.saveInwardStock() }}
                            />
                        </Grid>
                    </Grid>
                </form>
                <Grid container direction="row" justify="center" alignItems="flex-start" spacing={5}>
                    <Grid item>
                        <CtBtn label='Save' onClick={this.saveInwardStock} width={this.state.cWidth} />
                    </Grid>
                    <Grid item>
                        <CtBtn label='New' onClick={this.newInwardStock} width={this.state.cWidth} />
                    </Grid>
                    <Grid item>
                        <CtBtn label='Delete' onClick={this.handleARDonActionClick} width={this.state.cWidth} />
                    </Grid>
                    <Grid item>
                        <CtBtn label='Close' onClick={this.onClose} width={this.state.cWidth} />
                    </Grid>
                </Grid>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(InwardStockEntry))