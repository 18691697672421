export const localBackEndURL = "http://127.0.0.1:8080/Development/waygonmarketing.com/waygonmarketing-backend/"
// export const localBackEndURL = "http://127.0.0.1/Development/waygonmarketing.com/waygonmarketing-backend/"

export const domainURL = 'https://waygonmarketing.com/'
export const onlineBackEndURL = domainURL + 'BackEndAPI/'
export const apiURL = process.env.NODE_ENV === 'production' ?
    onlineBackEndURL : localBackEndURL

const axios = require("axios");
const axiosInstance = axios.create({
    baseURL: apiURL
});

const axiosGRCVInstance = axios.create({
    baseURL: domainURL
});

export function fetchRecaptcha(data) {
    return axiosGRCVInstance.post("recaptcha.verify.php", data);
}

export function fetchAdmin(data) {
    return axiosInstance.post("admin.php", data);
}

export function fetchMember(data) {
    return axiosInstance.post("member.php", data);
}

export function fetchTree(data) {
    return axiosInstance.post("tree.php", data);
}

export function fetchContact(data) {
    return axiosInstance.post("contact.php", data)
}

export function fetchProduct(data) {
    return axiosInstance.post("product.php", data)
}

export function fetchInward(data) {
    return axiosInstance.post("inward.php", data)
}

export function fetchSales(data) {
    return axiosInstance.post("sales.php", data)
}

export function fetchOffer(data) {
    return axiosInstance.post("offer.php", data)
}

export function fetchTour(data) {
    return axiosInstance.post("tour.php", data)
}

export function fetchActivationKit(data) {
    return axiosInstance.post("activationkit.php", data)
}

export function fetchCompanyCharges(data) {
    return axiosInstance.post("companycharge.php", data)
}

export function VerifyIsAdmin(userID) {
    const reqData = {
        'Op': 'AdminName',
        'UserID': userID,
    }
    return fetchAdmin(reqData)
}

export function VerifyIsUser(userID) {
    const reqData = {
        'Op': 'GetActiveUserName',
        'UserID': userID,
    }
    return fetchMember(reqData)
}

//#region Image API Links & Functions Start
export function imageFrontEndBaseURL() {
    return apiURL
}

export function imageBackEndBaseURL() {
    return 'images/'
}

export function removeImage(formData) {
    return axiosInstance.post("ImageUpload.php", formData);
}

export function imageUpload(formData, config) {
    return axiosInstance.post("ImageUpload.php", formData, config)
}
//#region Image API Links & Functions End