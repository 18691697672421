import React, { Component } from 'react'
import HOCVerifyIsLogInUserOrAdmin from './HOC/HOCVerifyIsLogInUserOrAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import TableList from "./CustomTool/TableList";
import CtDtpDOB from "./CustomTool/CtDtpDOB";
import CtBtn from "./CustomTool/CtBtn";
import { Grid, Box } from '@material-ui/core'
import { fetchMember } from './API/API'
import { PROCESSING, NO_DATA_FOUND, get_YMD_from_SYS } from "./SystemUtility/SystemUtility";
import Progress from './CustomTool/Progress'

class PinBook extends Component {

    state = {
        dataLoadStatus: false,
        processingStatus: PROCESSING,
        cWidth: 160,
        UserID: 0,
        formData: {
            FromDate: '',
            ToDate: '',
        },
        columns: [
            { id: 'SrNo', label: 'Sr\u00a0No', minWidth: 10 },
            { id: 'OpDate', label: 'Date', minWidth: 70 },
            { id: 'Operation', label: 'Operation', minWidth: 100 },
            { id: 'Operator', label: 'Operator', minWidth: 70 },
            { id: 'OperatedFor', label: 'Operated\u00a0For', minWidth: 70 },
            { id: 'Pin', label: 'PIN', minWidth: 170 },
            { id: 'PinStock', label: 'PIN Balance', minWidth: 70 },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 5,
    }

    //#region Component
    componentWillMount() {
        const userID = Number(this.props.match.params.userID.substring(1))
        this.props.validateIsLogInUserOrAdmin(
            this.props.IsAdmin,
            this.props.setActiveLink,
            this.navigateTo,
            userID
        )
    }

    componentDidMount() {
        const UserID = Number(this.props.match.params.userID.substring(1))
        const todayDt = get_YMD_from_SYS(new Date())
        const FromDate = todayDt
        const ToDate = todayDt
        this.setState({
            UserID,
            formData: { ...this.state.formData, FromDate, ToDate }
        }, () => {
            this.listPinBook()
        })
    }
    //#endregion

    //#region handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }
    //#endregion

    //#region Function
    listPinBook = () => {
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                'Op': 'PinBook',
                'UserID': this.state.UserID,
                'FromDate': this.state.formData.FromDate,
                'ToDate': this.state.formData.ToDate
            }
            fetchMember(reqData)
                .then(res => {
                    let dataLoadStatus = true,
                        rows = [],
                        rowsCount = 0,
                        processingStatus = NO_DATA_FOUND
                    if (res.data.msgType === 'success') {
                        rows = res.data.PinBook
                        rowsCount = rows.length
                        processingStatus = 'success'
                    }
                    this.props.toastMsg(res.data.msgType, res.data.message)
                    this.setState({ dataLoadStatus, rows, rowsCount, processingStatus })
                })
                .catch(error => {
                    this.props.toastErrorMsg('Unknown error occurred')
                    console.log('Unknown Error Occurred in listPinBook. ', error)
                })
        })
    }
    //#endregion

    render() {
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' >PIN Book ( {this.state.UserID} )</label><br />
                    </Grid>
                </Grid>
                <form>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtDtpDOB
                                id='FromDate'
                                label='From Date'
                                width={this.state.cWidth}
                                handleOnChange={this.handleOnChangeTrim}
                                onKeyDown={this.props.onKeyDown}
                                defaultValue={this.state.formData.FromDate}
                                value={this.state.formData.FromDate}
                            />
                        </Grid>
                        <Grid item>
                            <CtDtpDOB
                                id='ToDate'
                                label='To Date'
                                width={this.state.cWidth}
                                handleOnChange={this.handleOnChangeTrim}
                                onKeyDown={this.props.onKeyDown}
                                defaultAction={this.listPinBook}
                                defaultValue={this.state.formData.ToDate}
                                value={this.state.formData.ToDate}
                            />
                        </Grid>
                        <Grid item>
                            <CtBtn
                                label='PIN Book'
                                onClick={this.listPinBook}
                                width={this.state.cWidth}
                            />
                        </Grid>
                    </Grid>
                </form>
                {
                    this.state.processingStatus !== 'success' ?
                        <label style={{ fontWeight: 'bolder' }}>{this.state.processingStatus}</label> :
                        <TableList
                            columns={this.state.columns}
                            rows={this.state.rows}
                            rowsCount={this.state.rowsCount}
                            rowsPerPage={this.state.rowsPerPage}
                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                }
            </div>
        )
    }
}
export default HOCVerifyIsLogInUserOrAdmin(HoCtToastContainer(PinBook))