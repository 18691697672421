import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import TableList from './CustomTool/TableList'
import { fetchProduct } from "./API/API";
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { Grid, Box } from "@material-ui/core";
import Progress from './CustomTool/Progress'
import { Delete, Edit } from '@material-ui/icons'
import ProductDelete from './ProductDelete'
class Product extends Component {

    state = {
        dataLoadStatus: false,
        columns: [
            { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            { id: 'SrNo', label: 'Sr. No.', align: 'center' },
            { id: 'ProductName', label: 'Product Name' },
            { id: 'Category', label: 'Category' },
            { id: 'HSNCode', label: 'HSN Code' },
            { id: 'MRP', label: 'MRP', align: 'right' },
            { id: 'DP', label: 'DP', align: 'right' },
            { id: 'SalesRate', label: 'Sales Rate', align: 'right' },
            { id: 'GSTPer', label: 'GST %', align: 'center' },
            { id: 'BTO', label: 'Business Turn Over', align: 'center' },
            { id: 'IntStatus', label: 'intStatus', hide: 'true' },
            { id: 'Status', label: 'Status', align: 'center' },
            { id: 'CurStock', label: 'Current Stock', align: 'right' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 5,
        actionList: [
            {
                name: 'EditProduct',
                icon: <Edit />,
                link: 'Product:',
                actionType: 'AlertResponsive'
            },
            {
                name: 'DeleteProduct',
                icon: <Delete />,
                link: 'DelProduct:',
                actionType: 'AlertResponsive'
            },
        ],
        searchData: {
            ProductName: ''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,

        keyColumn: [
            'SrNo', 'ProductName'
        ],

        ARD: null,
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "ProductList" })
        this.setSearchColumns()
        this.loadProductList()
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    ((this.state.searchData.ProductName.length === 0 || row['ProductName'].toString().includes(this.state.searchData.ProductName)))
                ) {
                    searchRows.push(row)
                    //console.log('searchRows at push in loop', searchRows)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
                // console.log('searchRows on end of Function', searchRows)
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleARDonActionClick = (values) => {
        const EditSrNo = values[0].value,
            actionName = values[0].actionName
        if (actionName === 'EditProduct') {
            this.navigateTo('ProductEntry:' + EditSrNo)
        } else if (actionName === 'DeleteProduct') {
            const EditProductName = values[1].value
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <ProductDelete
                    EditSrNo={EditSrNo}
                    EditProductName={EditProductName}
                    onDeleteSuccess={this.loadProductList}
                />
                this.setState({ ARD })
            })
        }
    }

    handleOnSearchInputChange = (e) => {
        // console.log('handleOnSearchInputChange', [e.target.name], e.target.value, this.state.searchData);
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
            // console.log(this.state.searchData)
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {},
            {
                cType: 'CtTxt',
                id: 'ProductName',
                label: 'Product Name',
                value: this.state.searchData.ProductName,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            }
        )
        this.setState({ searchColumns })
    }

    loadProductList = () => {
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = { Op: 'ProductList' }
            fetchProduct(reqData)
                .then(res => {
                    const rows = res.data.ProductList
                    const rowsCount = rows.length
                    this.setState({ rows, rowsCount, dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in loadProductList. ' + error)
                })
        })
    }

    onNewEntryClick = () => {
        this.navigateTo('ProductEntry')
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>Product List</label>
                    </Grid>
                </Grid>
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={this.onNewEntryClick}
                    toolTipNewEntry='New Product Entry'
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(Product))