import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import CtTxt from './CustomTool/CtTxt'
import CtBtn from './CustomTool/CtBtn'
import { Grid, Box } from '@material-ui/core'
import { fetchMember } from './API/API'
import Progress from './CustomTool/Progress'
import { PROCESSING, NO_DATA_FOUND } from "./SystemUtility/SystemUtility";
import AlertResponsiveDialog from './CustomTool/AlertResponsiveDialog'
import TableList from './CustomTool/TableList'

class GeneratePin extends Component {

    state = {
        txtPinCount: '',
        curScheme: PROCESSING,
        curUserID: PROCESSING,
        curName: PROCESSING,
        curAdminUserID: PROCESSING,
        dataLoadStatus: false,
        // PINs: [],
        genPINs: {
            rows: [],
            cols: [
                { id: 'SrNo', label: 'Sr. No.', minWidth: 10 },
                { id: 'PIN', label: 'PIN', minWidth: 50 },
            ],
            rowsCount: 0,
            rowsPerPage: 100
        },
        alertGeneratePinConfirm: null,
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "GeneratePin" })
        this.onLoad()
    }
    //#endregion

    //#region handle
    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleOnChangeNumber = (e) => {
        this.setState({ [e.target.name]: (isNaN(e.target.value) ? 0 : Number(e.target.value)) })
    }

    handleOnChangeNumberZeroBlank = (e) => {
        this.setState({ [e.target.name]: (isNaN(e.target.value) ? '' : Number(e.target.value)) })
    }
    //#endregion

    //#region Functions
    validateInput = () => {
        let result = false
        if (Number(this.state.txtPinCount) <= 0) {
            this.props.toastErrorMsg('Enter Pin Count greater than zero.', 'txtPinCount')
        } else {
            result = true
        }
        return result
    }

    onLoad = () => {
        const curUserID = this.props.match.params.userID.substring(1)
        const curAdminUserID = localStorage.getItem('logInID')
        let curScheme = NO_DATA_FOUND
        let curName = NO_DATA_FOUND
        const dataLoadStatus = true
        if (Number(curUserID) > 99999) {
            const reqData = {
                'Op': 'GetActiveNApproveMemberNameNScheme',
                'userID': curUserID,
            }
            fetchMember(reqData)
                .then(res => {
                    if (
                        Number(res.data.status) === 11 &&
                        res.data.name && res.data.name.length > 0 &&
                        res.data.scheme && res.data.scheme.length > 0
                    ) {
                        curName = res.data.name
                        curScheme = res.data.scheme
                    }
                    this.setState({ curUserID, curAdminUserID, curName, curScheme, dataLoadStatus })
                })
                .catch(error => {
                    this.props.toastErrorMsg('Unknown error occurred in onLoad. ' + error)
                })
        }
    }

    generatePin = () => {
        if (this.validateInput()) {
            this.setState({
                dataLoadStatus: false,
                genPINs: { ...this.state.genPINs, rows: [], rowsCount: 0 }
            })
            this.props.notifyProcessing()
            const reqData = {
                'Op': 'GeneratePinByAdmin',
                'AdminUserID': this.state.curAdminUserID,
                'UserID': this.state.curUserID,
                'Scheme': this.state.curScheme,
                'PINCount': Number(this.state.txtPinCount),
            }
            fetchMember(reqData)
                .then(res => {
                    const dataLoadStatus = true
                    const rows = res.data.NewPINs
                    const rowsCount = rows.length
                    const txtPinCount = ''
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    this.setState({
                        dataLoadStatus, txtPinCount,
                        genPINs: { ...this.state.genPINs, rows, rowsCount }
                    })
                })
                .catch(error => {
                    this.props.toastErrorMsg('Unknown error occurred in generatePin. ' + error)
                })
        }
    }

    generatePinConfirmation = () => {
        this.setState({ alertGeneratePinConfirm: null }, () => {
            if (this.validateInput()) {
                let alertGeneratePinConfirm = null
                alertGeneratePinConfirm = <AlertResponsiveDialog
                    label={'Confirm Pin Generation'}
                    labelAgree={'Generation PIN'}
                    labelDisagree={'Cancel'}
                    alertTitle={'Do you want to Generate ' + this.state.txtPinCount + ' PINs?'}
                    alertMessage={
                        <div className='AlertInfo'>
                            <p>PIN Count : {this.state.txtPinCount}</p>
                            <p>PIN Generate for User ID : {this.state.curUserID}</p>
                            <p>Name : {this.state.curName}</p>
                            <p>Scheme : {this.state.curScheme}</p>
                        </div>
                    }
                    handleOnClickYes={this.generatePin}
                    defaultOpenDialog={true}
                />

                this.setState({ alertGeneratePinConfirm })
            }
        })
    }
    //#endregion

    render() {
        const lblStyle = { minWidth: '200px' }
        const basicInfo = [
            { 'head': 'Admin User ID', 'value': this.state.curAdminUserID },
            { 'head': 'User ID', 'value': this.state.curUserID },
            { 'head': 'Name', 'value': this.state.curName },
            { 'head': 'Scheme', 'value': this.state.curScheme },
        ]
        const disabledBtnGeneratePIN = Number(this.state.txtPinCount) === 0 ||
            this.state.curName === PROCESSING ||
            this.state.curName === NO_DATA_FOUND ||
            this.state.curScheme === PROCESSING ||
            this.state.curScheme === NO_DATA_FOUND
        const disabledTxtGeneratePIN = this.state.curName === PROCESSING ||
            this.state.curName === NO_DATA_FOUND ||
            this.state.curScheme === PROCESSING ||
            this.state.curScheme === NO_DATA_FOUND

        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <form>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                        <Grid item>
                            <table>
                                {
                                    basicInfo.map((row) => {
                                        return (
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>{row.head}</td>
                                                <td style={{ fontWeight: 'bolder', color: '#0000ff' }}><i>{row.value}</i></td>
                                            </tr>
                                        )
                                    })
                                }
                            </table>
                        </Grid>
                        <Grid item style={lblStyle}>
                            <CtTxt
                                id='txtPinCount'
                                label='Pin Count'
                                value={this.state.txtPinCount}
                                handleOnChange={this.handleOnChangeNumberZeroBlank}
                                with='200'
                                maxLength={6}
                                onKeyDown={this.props.onKeyDown}
                                validateInput={this.validateInput}
                                disabled={disabledTxtGeneratePIN}
                            />
                        </Grid>
                        <Grid item>
                            <CtBtn
                                label='Generate Pin'
                                disabled={disabledBtnGeneratePIN}
                                onClick={this.generatePinConfirmation}
                            />
                        </Grid>
                        <Grid item>
                            <span style={{ fontWeight: 'bolder' }}>Generated Pin List</span>
                        </Grid>
                        <Grid item>
                            <TableList
                                columns={this.state.genPINs.cols}
                                rows={this.state.genPINs.rows}
                                rowsCount={this.state.genPINs.rowsCount}
                                rowsPerPage={this.state.genPINs.rowsPerPage}
                                keyColumn={['ID']}
                                width={100}
                                height={200}
                            />
                        </Grid>
                    </Grid>
                </form>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.alertGeneratePinConfirm}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(GeneratePin))