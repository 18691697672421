import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import TableList from './CustomTool/TableList'
import { fetchAdmin, apiURL } from './API/API'
import { Grid } from "@material-ui/core";
import Progress from './CustomTool/Progress'
import { get_YMD_from_SYS, getIndianFormatedNumber } from './SystemUtility/SystemUtility'
import CtDtpDOB from './CustomTool/CtDtpDOB'
import CtBtn from './CustomTool/CtBtn'

class TDSDeductionList extends Component {

    state = {
        dataLoadStatus: false,
        counter:0,
        columns: [
            { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            { id: 'SrNo', label: 'Sr. No.', align: 'center' },
            { id: 'PANNo', label: 'PAN No' },
            { id: 'Name', label: 'Name' },
            { id: 'ID', label: 'ID', align: 'center' },
            { id: 'TotalPayoutAmt', label: 'Total Payout Amount', align: 'right', format: (value) => getIndianFormatedNumber(value) },
            { id: 'TotalTDSAmt', label: 'Total TDS Amount', align: 'right', format: (value) => getIndianFormatedNumber(value) },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 5,
        searchData: {
            PANNo: '',
            Name:'',
            ID:''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,

        formData: {
            dtpFromDate: get_YMD_from_SYS(new Date()),
            dtpToDate: get_YMD_from_SYS(new Date())
        },
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "TDSDeductionList" })
        this.setSearchColumns()
        this.listTDSDeduction()
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    (
                        (this.state.searchData.PANNo.length === 0 || row['PANNo'].toString().includes(this.state.searchData.PANNo)) && 
                        (this.state.searchData.Name.length === 0 || row['Name'].toString().includes(this.state.searchData.Name)) && 
                        (this.state.searchData.ID.length === 0 || row['ID'].toString().includes(this.state.searchData.ID))
                    )
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
                // console.log('searchRows on end of Function', searchRows)
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleOnSearchInputChange = (e) => {
        // console.log('handleOnSearchInputChange', [e.target.name], e.target.value, this.state.searchData);
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
            // console.log(this.state.searchData)
        })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {
                cType: 'Lbl',
                label: 'Sr No.',
                width: '50',
            },
            {
                cType: 'CtTxt',
                id: 'PANNo',
                label: 'PAN no',
                value: this.state.searchData.PANNo,
                handleOnChange: this.handleOnSearchInputChange,
                width: '150',
                maxLength: '10'
            },
            {
                cType: 'CtTxt',
                id: 'Name',
                label: 'Name',
                value: this.state.searchData.Name,
                handleOnChange: this.handleOnSearchInputChange,
                width: '250',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'ID',
                label: 'ID',
                value: this.state.searchData.ID,
                handleOnChange: this.handleOnSearchInputChange,
                width: '120',
                maxLength: '10'
            },
            {
                cType: 'Lbl',
                label: 'Total Payout Amount',
                width: '80',
            },
            {
                cType: 'Lbl',
                label: 'Total TDS Amount',
                width: '80',
            }
        )
        this.setState({ searchColumns })
    }

    onNewEntryClick = () => {
        this.navigateTo('InwardStockEntry')
    }

    listTDSDeduction = () => {
        this.setState({ dataLoadStatus: false }, () => {
            let FromDate = this.state.formData.dtpFromDate,
                ToDate = this.state.formData.dtpToDate
            const reqData = { 
                Op: 'TDSDeductionList',
                FromDate,
                ToDate
            }
            fetchAdmin(reqData)
                .then(res => {
                    const rows = res.data.TDSDeductionList
                    const rowsCount = rows.length
                    this.setState({ rows, rowsCount, dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in listTDSDeduction. ' + error)
                })
        })
    }

    openTDSDeductionPDF = () => {
        let reqData = [
            { name: 'Op', value: 'TDSDeductionPDF' },
            { name: 'FromDate', value: this.state.formData.dtpFromDate },
            { name: 'ToDate', value: this.state.formData.dtpToDate },
        ]
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", apiURL + 'print.php');
        form.setAttribute("target", "view");

        reqData.forEach((data) => {
            console.log("data", data);
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", data.name);
            hiddenField.setAttribute("value", data.value);
            form.appendChild(hiddenField);
            document.body.appendChild(form);
        })
        window.open('', 'view');
        form.submit();
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>TDS Deduction List</label>
                    </Grid>
                </Grid>
                <form>
                    <br />
                    <Grid container direction="row" justify="left" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtDtpDOB
                                id='dtpFromDate'
                                label='From Date'
                                width='150'
                                handleOnChange={this.handleOnChangeTrim}
                                defaultValue={this.state.formData.dtpFromDate}
                                value={this.state.formData.dtpFromDate}
                                onKeyDown={this.props.onKeyDown}
                            // disabled={this.state.disabledProfileData.DOBDS}
                            />
                        </Grid>
                        <Grid item>
                            <CtDtpDOB
                                id='dtpToDate'
                                label='To Date'
                                width='150'
                                handleOnChange={this.handleOnChangeTrim}
                                defaultValue={this.state.formData.dtpToDate}
                                value={this.state.formData.dtpToDate}
                                onKeyDown={this.props.onKeyDown}
                            // disabled={this.state.disabledProfileData.DOBDS}
                            />
                        </Grid>
                        <Grid item>
                            <CtBtn label='Load' width={150} onClick={this.listTDSDeduction} />
                        </Grid>
                        <Grid item>
                            <CtBtn label='PDF' width={150} onClick={this.openTDSDeductionPDF} />
                        </Grid>
                    </Grid>
                </form>
                <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    // onNewEntryClick={this.onNewEntryClick}
                    // toolTipNewEntry='New Stock Entry'
                />
            </div>
        )
    }

}
export default HOCVerifyIsAdmin(HoCtToastContainer(TDSDeductionList))