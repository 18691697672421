import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
// import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import Progress from './CustomTool/Progress'
import { Grid, Box } from '@material-ui/core'
import TableList from './CustomTool/TableList'
import { fetchMember } from './API/API'
import { Delete, Edit } from '@material-ui/icons'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";

class TourInfo extends Component {

    state = {
        dataLoadStatus: false,
        memberid: '',
        membername: '',
        membermobileno: '',
        tourname: '',
        requiredrepurchase: 0,
        requiredteam1bto: 0,
        requiredteam2bto: 0,
        RequiredTourInfo: [],

        columns: [
            // { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            { id: 'no', label: 'Sr. No.', align: 'center' },
            { id: 'fromdt', label: 'From Date' },
            { id: 'todt', label: 'To Date' },
            { id: 'minteam1bto', label: 'Min Team 1 BTO', align: 'right' },
            { id: 'minteam2bto', label: 'Min Team 2 BTO', align: 'right' },
            { id: 'repurchase', label: 'Repurchase', align: 'right' },
            { id: 'team1bto', label: 'Team 1 BTO', align: 'right' },
            { id: 'team2bto', label: 'Team 2 BTO', align: 'right' },
            { id: 'remainteam1bto', label: 'Remain Team 1 BTO', align: 'right' },
            { id: 'remainteam2bto', label: 'Remain Team 2 BTO', align: 'right' },
        ],
        rowsPerPage: { 0: 10 },
    }

    //#region Component
    componentWillMount() {
        // this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.setMemberIDState()
        // this.loadTourInfo()
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (tblRowsPerPage, tblIndex) => {
        let rowsPerPage = this.state.rowsPerPage
        rowsPerPage[tblIndex] = tblRowsPerPage
        this.setState({
            rowsPerPage
        })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    ((this.state.searchData.tourname.length === 0 || row['tourname'].toString().includes(this.state.searchData.tourname))) && ((this.state.searchData.team1bto.length === 0 || row['team1bto'].toString().includes(this.state.searchData.team1bto))) && ((this.state.searchData.team2bto.length === 0 || row['team2bto'].toString().includes(this.state.searchData.team2bto))) && ((this.state.searchData.selfrepurchase.length === 0 || row['selfrepurchase'].toString().includes(this.state.searchData.selfrepurchase)))
                ) {
                    searchRows.push(row)
                    // console.log('searchRows at push in loop', searchRows)
                }
            })
            const searchRowsCount = searchRows.length
            // console.log("searchRowsCount",searchRowsCount);
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
                // console.log('searchRows on end of Function', searchRows)
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleARDonActionClick = (values) => {
        const EditSrNo = values[0].value,
            actionName = values[0].actionName,
            EditTourName = values[1].value
        if (actionName === 'Edit Tour') {
            this.navigateTo('TourEntry:' + EditSrNo)
        } else if (actionName === 'Delete Tour') {
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Tour : ' + EditTourName + '?'}
                    // alertMessage={}
                    handleOnClickYes={() => { return this.deleteTour(EditSrNo) }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }

    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    setMemberIDState = () => {
        let memberid = ''
        if (
            this.props.match.params.memberid &&
            Number(this.props.match.params.memberid.substring(1)) > 99999
        ) {
            memberid = Number(this.props.match.params.memberid.substring(1))
        }
        if (memberid > 99999) {
            this.setState({
                ...this.state,
                memberid
            }, () => {
                this.loadTourInfo()
            })
        }
    }

    loadTourInfo = () => {
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                Op: 'GetRequiredTourInfo',
                MemberID: this.state.memberid
            }
            fetchMember(reqData)
                .then(res => {
                    let membermobileno = res.data.MemberDetail[0].membermobileno,
                        membername = res.data.MemberDetail[0].membername

                    let tourname = '',
                        requiredrepurchase = 0,
                        requiredteam1bto = 0,
                        requiredteam2bto = 0,
                        RequiredTourInfo = [],
                        rowsPerPage = { 0: 10 }

                    if (res.data.RequiredTourInfo && res.data.RequiredTourInfo.length > 0) {
                        RequiredTourInfo = res.data.RequiredTourInfo
                        let tourCount = RequiredTourInfo.length
                        for (let i = 1; i < tourCount; i++) {
                            rowsPerPage[i] = 10
                        }
                        tourname = res.data.RequiredTourInfo[0].tourname
                        requiredrepurchase = res.data.RequiredTourInfo[0].requiredrepurchase
                        requiredteam1bto = res.data.RequiredTourInfo[0].required1bto
                        requiredteam2bto = res.data.RequiredTourInfo[0].required2bto
                    }
                    this.setState({
                        membername,
                        membermobileno,
                        tourname,
                        requiredrepurchase,
                        requiredteam1bto,
                        requiredteam2bto,
                        dataLoadStatus: true,
                        RequiredTourInfo,
                        rowsPerPage
                    })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in loadTourInfo. ' + error)
                })
        })
    }
    //#endregion

    render() {

        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>Tour Info</label>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                    <Grid item style={{ fontSize: '18px' }}><b>Member ID : </b> {this.state.memberid}</Grid>
                    <Grid item style={{ fontSize: '18px' }}><b>Name : </b> {this.state.membername}</Grid>
                    <Grid item style={{ fontSize: '18px' }}><b>Mobile No. : </b> {this.state.membermobileno}</Grid>
                </Grid>
                {

                    (this.state.RequiredTourInfo.length > 0) ? (
                        this.state.RequiredTourInfo.map((info, tblIndex) => {
                            return <div>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={5}>
                                    <Grid item style={{ fontSize: '16px' }}><b>Tour : </b> {info['tourname']}</Grid>
                                    <Grid item style={{ fontSize: '16px' }}><b>Tour Period: </b> {info['tourperiod']}</Grid>
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={5}>
                                    <Grid item style={{ fontSize: '16px' }}><b>Required</b></Grid>
                                    <Grid item><b>Repurchase : </b> {info['requiredrepurchase']}</Grid>
                                    <Grid item><b>Team 1 BTO : </b> {info['required1bto']}</Grid>
                                    <Grid item><b>Team 2 BTO : </b> {info['required2bto']}</Grid>
                                </Grid>
                                <br />
                                <TableList
                                    columns={this.state.columns}
                                    counter={this.state.counter}
                                    rows={info['tourcycle']}
                                    rowsCount={info['tourcycle'].length}
                                    rowsPerPage={this.state.rowsPerPage[tblIndex]}
                                    handleChangeRowsPerPage={(e)=>{this.handleChangeRowsPerPage(e,tblIndex)}}
                                />
                                <br />
                            </div>
                        })
                    ) : ('')
                }
            </div>
        )
    }
}
export default HoCtToastContainer(TourInfo)
