import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import TableList from './CustomTool/TableList'
import { fetchInward } from './API/API'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { Grid, Box } from "@material-ui/core";
import Progress from './CustomTool/Progress'
import { Delete, Edit } from '@material-ui/icons'
import { get_YMD_from_DMY, get_YMD_from_SYS } from './SystemUtility/SystemUtility'
import InwardStockDelete from './InwardStockDelete'
import CtDtpDOB from './CustomTool/CtDtpDOB'
import CtBtn from './CustomTool/CtBtn'

class InwardStock extends Component {

    state = {
        dataLoadStatus: false,
        columns: [
            { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            { id: 'SrNo', label: 'Sr. No.', align: 'center' },
            { id: 'Date', label: 'Date' },
            { id: 'ProductName', label: 'Product Name' },
            { id: 'CurrentStock', label: 'Current Stock', align: 'right' },
            { id: 'Quantity', label: 'Quantity', align: 'center' },
            { id: 'BatchNo', label: 'Batch No' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 10,
        actionList: [
            {
                name: 'Edit Inward',
                icon: <Edit />,
                link: 'Inward:',
                actionType: 'AlertResponsive'
            },
            {
                name: 'Delete Inward',
                icon: <Delete />,
                link: 'DelInward:',
                actionType: 'AlertResponsive'
            },
        ],
        searchData: {
            ProductName: ''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,

        keyColumn: [
            'SrNo', 'Date'
        ],
        ARD: null,
        formData: {
            dtpFromDate: get_YMD_from_SYS(new Date()),
            dtpToDate: get_YMD_from_SYS(new Date())
        },
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "InwardStockList" })
        this.setSearchColumns()
        this.listInwardStock()
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    ((this.state.searchData.ProductName.length === 0 || row['ProductName'].toString().includes(this.state.searchData.ProductName)))
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
                // console.log('searchRows on end of Function', searchRows)
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleOnSearchInputChange = (e) => {
        // console.log('handleOnSearchInputChange', [e.target.name], e.target.value, this.state.searchData);
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
            // console.log(this.state.searchData)
        })
    }

    handleARDonActionClick = (values) => {
        let EditDate = get_YMD_from_DMY(values[1].value)
        const EditSrNo = values[0].value,
            actionName = values[0].actionName,
            EditNewSrNo = EditSrNo + ":" + EditDate
        if (actionName === 'Edit Inward') {
            this.navigateTo('InwardStockEntry:' + EditNewSrNo)
        } else if (actionName === 'Delete Inward') {
            // EditDate = values[1].value
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <InwardStockDelete
                    EditSrNo={EditSrNo}
                    EditDate={EditDate}
                    onDeleteSuccess={this.listInwardStock}
                />
                this.setState({ ARD })
            })
        }
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {},
            {},
            {
                cType: 'CtTxt',
                id: 'ProductName',
                label: 'Product Name',
                value: this.state.searchData.ProductName,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            }
        )
        this.setState({ searchColumns })
    }

    onNewEntryClick = () => {
        this.navigateTo('InwardStockEntry')
    }

    listInwardStock = () => {
        this.setState({ dataLoadStatus: false }, () => {
            let FromDate = this.state.formData.dtpFromDate,
                ToDate = this.state.formData.dtpToDate
            const reqData = { 
                Op: 'InwardStockList',
                FromDate,
                ToDate
            }
            fetchInward(reqData)
                .then(res => {
                    const rows = res.data.InwardStockList
                    const rowsCount = rows.length
                    this.setState({ rows, rowsCount, dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in listInwardStock. ' + error)
                })
        })
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>Inward Stock List</label>
                    </Grid>
                </Grid>
                <form>
                    <br />
                    <Grid container direction="row" justify="left" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtDtpDOB
                                id='dtpFromDate'
                                label='From Date'
                                width='150'
                                handleOnChange={this.handleOnChangeTrim}
                                defaultValue={this.state.formData.dtpFromDate}
                                value={this.state.formData.dtpFromDate}
                                onKeyDown={this.props.onKeyDown}
                            // disabled={this.state.disabledProfileData.DOBDS}
                            />
                        </Grid>
                        <Grid item>
                            <CtDtpDOB
                                id='dtpToDate'
                                label='To Date'
                                width='150'
                                handleOnChange={this.handleOnChangeTrim}
                                defaultValue={this.state.formData.dtpToDate}
                                value={this.state.formData.dtpToDate}
                                onKeyDown={this.props.onKeyDown}
                            // disabled={this.state.disabledProfileData.DOBDS}
                            />
                        </Grid>
                        <Grid item>
                            <CtBtn label='Load' width={this.state.cWidth - 150} onClick={this.listInwardStock} />
                        </Grid>
                    </Grid>
                </form>
                <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={this.onNewEntryClick}
                    toolTipNewEntry='New Stock Entry'
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }

}
export default HOCVerifyIsAdmin(HoCtToastContainer(InwardStock))