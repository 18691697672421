import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import TableList from './CustomTool/TableList'
import { fetchMember } from './API/API'
import Progress from './CustomTool/Progress'
import { Grid } from "@material-ui/core";
import CtBtn from './CustomTool/CtBtn'
import CtCmb from './CustomTool/CtCmb'
import { AccountCircle } from "@material-ui/icons";

class MemberPanNoBankDetailMissingList extends Component {

    state = {
        dataLoadStatus: false,
        counter: 0,
        columns: [
            { id: 'Action', name: 'Search', label: 'Action', width: '20px' },
            { id: 'SrNo', label: 'Sr. No.', align: 'center', width: '30px' },
            { id: 'ID', label: 'ID', align: 'center', width: '50px' },
            { id: 'Name', label: 'Name', width: '500px' },
            { id: 'MobileNo', label: 'Mobile No', align: 'center', width: '50px' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 10,
        searchData: {
            Name: '',
            ID: '',
            MobileNo: ''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        formData: {
            cmbOperation: '',
            rootmember: ''
        },
        OperationList: [
            { name: 'PAN', disabled: false },
            { name: 'BANK', disabled: false }
        ],
        actionList: [
            { name: 'Profile', icon: <AccountCircle />, link: 'UserProfile:' },
        ],
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "MemberPanNoBankDetailMissingList" })
        this.setSearchColumns()
        this.onFormLoad()
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    (
                        (this.state.searchData.MobileNo.length === 0 || row['MobileNo'].toString().includes(this.state.searchData.MobileNo)) &&
                        (this.state.searchData.Name.length === 0 || row['Name'].toString().includes(this.state.searchData.Name)) &&
                        (this.state.searchData.ID.length === 0 || row['ID'].toString().includes(this.state.searchData.ID))
                    )
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
                // console.log('searchRows on end of Function', searchRows)
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleOnSearchInputChange = (e) => {
        // console.log('handleOnSearchInputChange', [e.target.name], e.target.value, this.state.searchData);
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
            // console.log(this.state.searchData)
        })
    }

    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {},
            {
                cType: 'CtTxt',
                id: 'ID',
                label: 'ID',
                value: this.state.searchData.ID,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'Name',
                label: 'Name',
                value: this.state.searchData.Name,
                handleOnChange: this.handleOnSearchInputChange,
                width: '400',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'MobileNo',
                label: 'Mobile no',
                value: this.state.searchData.MobileNo,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '10'
            }
        )
        this.setState({ searchColumns })
    }

    onFormLoad = () => {
        if (this.props.match.params.Operation && this.props.match.params.Operation.substring(1).length > 0) {
            let param = this.props.match.params.Operation.substring(1).split(":")
            let cmbOperation = param[0], rootmember = param[1]
            this.setState({
                formData: {
                    dataLoadStatus: true,
                    ...this.state.formData,
                    cmbOperation,
                    rootmember
                }
            }, () => {
                this.listPANBankDetailMissing(this.state.formData.cmbOperation, this.state.formData.rootmember)
            })
        }
    }

    listPANBankDetailMissing = (Operation, rootmember) => {
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                Op: 'GetPAN_BankDetailMissingList',
                Operation,
                rootmember
            }
            fetchMember(reqData)
                .then(res => {
                    const rows = res.data.PANBankDetailMissingList
                    const rowsCount = rows.length
                    this.setState({ rows, rowsCount, dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in listPANBankDetailMissing. ' + error)
                })
        })
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <form>
                    <Grid container direction="row" justify="left" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtCmb
                                id='cmbOperation'
                                label='PAN / Bank'
                                items={this.state.OperationList}
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.cmbOperation}
                                width="150"
                                maxLength='10'
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtBtn label='Refesh List' width={150} onClick={() => this.listPANBankDetailMissing(this.state.formData.cmbOperation, this.state.formData.rootmember)} />
                        </Grid>
                    </Grid>
                </form>
                <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.navigateTo}
                    actionList={this.state.actionList}
                    keyColumn={['ID']}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                />
            </div>
        )
    }

}
export default HOCVerifyIsAdmin(HoCtToastContainer(MemberPanNoBankDetailMissingList))