import React, { Component, useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import {
  CssBaseline, Box, Grid,
  AppBar, Toolbar, List, Typography, Divider, IconButton,
  ListItem, ListItemIcon, ListItemText, Collapse
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {
  ChevronLeft, DashboardOutlined,
  InfoOutlined, Facebook, Twitter, Instagram, YouTube,
  LinkedIn, PowerSettingsNew, EmailOutlined, PostAdd, PeopleOutline,
  AccountBalanceWalletOutlined, ExpandLess, ExpandMore, StarBorder,
  CheckCircleOutline, AssignmentIndOutlined, ListAlt, AccountTreeOutlined,
  MoneyOutlined, CompareArrowsOutlined, ListAltOutlined,
  ViewHeadlineOutlined, LocalAtmOutlined, LocalMallOutlined,
  CommuteOutlined, LocalOfferOutlined, AssessmentOutlined, ShoppingBasketOutlined,
  PaymentOutlined, AccountCircleOutlined, Assessment, PersonOutline, BallotOutlined,
  DescriptionOutlined, BarChartOutlined, SettingsApplicationsOutlined, DirectionsRailwayOutlined
} from '@material-ui/icons';
import Logo from '../png/logo.png'
import { Link, Route, Switch } from 'react-router-dom'
import { fetchAdmin } from './API/API'
import Home from './Home'
import AboutUs from './AboutUs'
import Product from './Product'
import ProductEntry from './ProductEntry'
import Registration from './Registration'
import LogIn from './LogIn'
import AdminLogIn from './AdminLogIn'
import ContactUs from './ContactUs/ContactUs'
import PersonalTree from './PersonalTree'
import SystemTree from './SystemTree'
import ForgotPassword from './ForgotPassword'
import ChangePassword from './ChangePassword'
import Otp from './Otp'
import ReSetPassword from './ReSetPassword'
import MemberList from './MemberList'
import ApproveMemberList from './ApproveMemberList'
import UserDashboard from './UserDashboard'
import AdminDashboard from './AdminDashboard'
import UserProfile from './UserProfile'
import WithdrawalList from "./WithdrawalList"
import GeneratePin from "./GeneratePin"
import PinDashboard from "./PinDashboard"
import PinTransfer from "./pintransfer/PinTransfer"
import PinBook from "./PinBook"
import BuyPin from "./BuyPin"
import WalletDashboard from "./WalletDashboard"
import InwardStock from "./InwardStock"
import InwardStockEntry from "./InwardStockEntry"
import Sales from "./Sales"
import SalesEntry from "./SalesEntry"
import OfferList from './OfferList'
import OfferEntry from './OfferEntry'
import TourList from './TourList'
import TourEntry from './TourEntry'
import ActivationKitList from './ActivationKitList'
import AchievementListForAdminOnly from './AchievementListForAdminOnly'
import TurnoverCommission from './TurnoverCommission'
import DirectReference from './DirectReference'
import TDSDeductionList from './TDSDeductionList'
import MemberPanNoBankDetailMissingList from './MemberPanNoBankDetailMissingList'
import UserList from './UserList'
import UserEntry from './UserEntry'
import StockRegister from './StockRegister'
import CompanyChargeList from './CompanyChargeList'
import CompanyChargeEntry from './CompanyChargeEntry'
import AdminChargeDeductionList from './AdminChargeDeductionList'
import SalesListOfNetBTO from './SalesListOfNetBTO'
import TurnoverHistory from './TurnoverHistory';
import MemberSetting from './MemberSetting';
import TeamInfo from './TeamInfo';
import TourInfo from './TourInfo';
import LevelWiseMemberCount from './LevelWiseMemberCount';
import TourAchievementList from './TourAchievementList'


const drawerWidth = 240;

const useStyles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -(drawerWidth + 10),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  nestedSecondLevel: {
    paddingLeft: theme.spacing(8)
  },
});

const LinkTo = ({ ItemName, LinkSlug, activeMenu, handleLinkToOnClick, activeStyle }) => {
  return (
    <Link
      to={LinkSlug}
      className='LinkTo'
      style={activeMenu === LinkSlug ? activeStyle : {}}
      onClick={() => handleLinkToOnClick({ LinkSlug })}>
      {ItemName}
    </Link>
  )
}

const MenuListItemIcon = ({ ItemName, LinkSlug, ItemIcon, activeMenu, handleDrawerClose, activeStyle, handleLinkToOnClick, OnlyForAdmin, IsAdmin, subMenuItems, handleOnSubMenuClick }) => {
  const [subMenuOpenState, setSubMenuOpenState] = useState(false)
  let result = (
    <div>
      <Link
        to={LinkSlug}
        className='menulink'
        style={activeMenu === LinkSlug ? activeStyle : {}}
        onClick={() => { subMenuItems ? setSubMenuOpenState(!subMenuOpenState) : handleLinkToOnClick({ LinkSlug }) }}
      >
        <ListItem
          button
          key={ItemName}
          onClick={handleDrawerClose ? handleDrawerClose : null}
        >
          <ListItemIcon>
            {<ItemIcon className='muicon' />}
          </ListItemIcon>
          <ListItemText primary={ItemName} />
        </ListItem>
      </Link>
    </div>
  )

  if (subMenuItems && OnlyForAdmin !== undefined) {
    if (IsAdmin !== undefined && IsAdmin() === true) {
      result = (
        <div>
          <div
            className='menulink'
            style={activeMenu === LinkSlug ? activeStyle : {}}
            onClick={() => { subMenuItems ? setSubMenuOpenState(!subMenuOpenState) : handleLinkToOnClick({ LinkSlug }) }}
          >
            <ListItem
              button
              key={ItemName}
              onClick={handleOnSubMenuClick ? handleOnSubMenuClick : null}
            >
              <ListItemIcon>
                {<ItemIcon className='muicon' />}
              </ListItemIcon>
              <ListItemText primary={ItemName} />
              {subMenuItems ? (subMenuOpenState ? <ExpandLess /> : <ExpandMore />) : null}
            </ListItem>
          </div>
          {
            subMenuItems ?
              <Collapse
                in={subMenuOpenState}
                timeout="auto" unmountOnExit
              >
                {
                  subMenuItems.map((curSubMenu) => {
                    if (curSubMenu.privilege === 'A' || curSubMenu.privilege === 'R' || curSubMenu.privilege === 'W' || curSubMenu.privilege === 'M') {
                      return (
                        <List
                          component="div" disablePadding
                          style={{ backgroundColor: '#ECF6FA' }}
                        >
                          <Link
                            to={curSubMenu.LinkSlug}
                            className='menulink'
                            style={activeMenu === curSubMenu.LinkSlug ? activeStyle : {}}
                          >
                            <ListItem
                              button
                              onClick={handleDrawerClose}
                            >
                              <ListItemIcon>
                                {/* {curSubMenu.menuIcon} */}
                                {<curSubMenu.menuIcon className='muicon' />}
                              </ListItemIcon>
                              <ListItemText primary={curSubMenu.menuText} />
                            </ListItem>
                          </Link>
                        </List>
                      )
                    } else {
                      return ''
                    }

                  })
                }
              </Collapse>
              : ''
          }
        </div>
      )
    } else {
      result = ''
    }
  }

  return (
    result
  )
}
class Menu extends Component {

  state = {
    theme: "",
    open: false,
    submenuMember: [
      { menuIcon: AssignmentIndOutlined, menuText: 'Registration', LinkSlug: 'Registration', privilege: 'A' },
      { menuIcon: CheckCircleOutline, menuText: 'Approve Member List', LinkSlug: 'ApproveMemberList', privilege: '' },
      { menuIcon: ListAlt, menuText: 'Member List', LinkSlug: 'MemberList', privilege: '' },
      { menuIcon: AccountTreeOutlined, menuText: 'Personal Tree', LinkSlug: 'PersonalTree', privilege: '' },
      { menuIcon: ViewHeadlineOutlined, menuText: 'Achievement List', LinkSlug: 'AchievementListForAdminOnly', privilege: '' },
      { menuIcon: SettingsApplicationsOutlined, menuText: 'Setting', LinkSlug: 'MemberSetting', privilege: '' },
      { menuIcon: DirectionsRailwayOutlined, menuText: 'Admin Tour Achievement List', LinkSlug: 'TourAchievementList', privilege: '' },
    ],
    submenuWallet: [
      { menuIcon: MoneyOutlined, menuText: 'Turnover Commission', LinkSlug: 'TurnoverCommission', privilege: '' },
      { menuIcon: CompareArrowsOutlined, menuText: 'Direct Reference', LinkSlug: 'DirectReference', privilege: '' },
      { menuIcon: PaymentOutlined, menuText: 'Withdrawal', LinkSlug: 'WithdrawalList', privilege: '' },
      { menuIcon: DescriptionOutlined, menuText: 'TDS Deduction List', LinkSlug: 'TDSDeductionList', privilege: '' },
      { menuIcon: PersonOutline, menuText: 'Admin Charge Deduction List', LinkSlug: 'AdminChargeDeductionList', privilege: '' },
    ],
    submenuSales: [
      { menuIcon: ShoppingBasketOutlined, menuText: 'Product Master', LinkSlug: 'Product', privilege: '' },
      { menuIcon: LocalOfferOutlined, menuText: 'Offer Master', LinkSlug: 'OfferList', privilege: '' },
      { menuIcon: CommuteOutlined, menuText: 'Tour Master', LinkSlug: 'TourList', privilege: '' },
      { menuIcon: LocalMallOutlined, menuText: 'Activation Kit Master', LinkSlug: 'ActivationKitList', privilege: '' },
      { menuIcon: AssessmentOutlined, menuText: 'Inward Stock', LinkSlug: 'InwardStock', privilege: '' },
      { menuIcon: LocalAtmOutlined, menuText: 'Sales Entry', LinkSlug: 'Sales', privilege: '' },
      { menuIcon: Assessment, menuText: 'Stock Register', LinkSlug: 'StockRegister', privilege: 'A' },
      { menuIcon: BarChartOutlined, menuText: 'Max Sales List', LinkSlug: 'SalesListOfNetBTO', privilege: 'A' },
    ],
    subMenuOpen: false,
    openSecondLevel: true,
    activeMenu: "Home",
    logInData: {
      logInID: "",
      logInUserType: "",
      logInLinkText: "Log In",
      logInDashboardLink: "",
    },
    privileges: {
      admin: '',
      privdashboard: '',
      privmember: '',
      privapprovemember: '',
      privpersonaltree: '',
      privachievement: '',
      privturnovercom: '',
      privdirectreference: '',
      privwithdrawal: '',
      privtdsdeduction: '',
      privadminchargededuction: '',
      privproduct: '',
      privoffer: '',
      privtour: '',
      privactivationkit: '',
      privinwardstock: '',
      privsalesrwm: '',
      rootmember: '',
      mainmenumember: '',
      mainmenuwallet: '',
      mainmenusales: '',
    },
  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  };

  handleDrawerClose = () => {
    this.setState({ open: false })
  };

  bgColor = 'transparent'

  activeStyle = { color: '#42AA46' } //{ color: '#6bcf78' }

  handleLinkToOnClick = ({ LinkSlug }) => {
    this.setState({ activeMenu: LinkSlug })
  }

  clearLocalStorage = () => {
    localStorage.removeItem('logInID')
    localStorage.removeItem('logInUserType')
    localStorage.removeItem('privwithdrawal')
    localStorage.removeItem('SA')
  }

  getIsAdmin = () => {
    return (localStorage.getItem('logInUserType') && localStorage.getItem('logInUserType') === 'A') ? true : false
  }

  getIsSuperAdmin = () => {
    return (localStorage.getItem('SA') && localStorage.getItem('SA') === "Sad") ? true : false
  }

  handleUserLoginID = (logInID) => {
    const userType = 'U'
    const logInUserType = logInID && logInID.length > 0 ? userType : ''
    const logInDashboardLink = logInID && logInID.length > 0 ? 'UserDashboard' : ''
    this.setState({
      logInData: { ...this.state.logInData, logInID, logInUserType, logInDashboardLink }
    }, () => {
      let logInLinkText = "Log In"
      this.clearLocalStorage()
      if (this.state.logInData.logInID && this.state.logInData.logInID.length > 0 && Number(this.state.logInData.logInID) > 99999) {
        localStorage.setItem('logInID', this.state.logInData.logInID);
        localStorage.setItem('logInUserType', userType);
        localStorage.setItem('SA', 'Nsad')
        logInLinkText = "Log Out"
      }
      this.setState({ logInData: { ...this.state.logInData, logInLinkText } })
    })
  }

  handleAdminLoginID = (logInID) => {
    const userType = 'A'
    const logInUserType = logInID && logInID.length > 0 ? userType : ''
    const logInDashboardLink = logInID && logInID.length > 0 ? 'AdminDashboard' : ''
    this.setState({
      logInData: { ...this.state.logInData, logInID, logInUserType, logInDashboardLink }
    }, () => {
      let logInLinkText = "Log In"
      this.clearLocalStorage()
      if (this.state.logInData.logInID && this.state.logInData.logInID.length > 0) {
        localStorage.setItem('logInID', this.state.logInData.logInID);
        localStorage.setItem('logInUserType', userType);
        logInLinkText = "Log Out"
        this.getLoaginUserPrivileges(this.state.logInData.logInID)
      }
      this.setState({ logInData: { ...this.state.logInData, logInLinkText } })
    })
  }

  getLoaginUserPrivileges = (userid) => {
    const reqData = {
      userid: userid,
      Op: 'GetLoginUserPrivileges',
    }
    fetchAdmin(reqData)
      .then(res => {
        this.setState({
          privileges: {
            admin: res.data.PrivilegesList['admin'],
            privdashboard: res.data.PrivilegesList['privdashboard'],
            privmember: res.data.PrivilegesList['privmember'],
            privapprovemember: res.data.PrivilegesList['privapprovemember'],
            privpersonaltree: res.data.PrivilegesList['privpersonaltree'],
            privachievement: res.data.PrivilegesList['privachievement'],
            privturnovercom: res.data.PrivilegesList['privturnovercom'],
            privdirectreference: res.data.PrivilegesList['privdirectreference'],
            privwithdrawal: res.data.PrivilegesList['privwithdrawal'],
            privtdsdeduction: res.data.PrivilegesList['privtdsdeduction'],
            privproduct: res.data.PrivilegesList['privproduct'],
            privoffer: res.data.PrivilegesList['privoffer'],
            privtour: res.data.PrivilegesList['privtour'],
            privactivationkit: res.data.PrivilegesList['privactivationkit'],
            privinwardstock: res.data.PrivilegesList['privinwardstock'],
            privsalesrwm: res.data.PrivilegesList['privsalesrwm'],
            rootmember: res.data.PrivilegesList['rootmember'],
            privadminchargededuction: res.data.PrivilegesList['privadminchargededuction'],
          }
        }, () => {
          this.setPrivileges(this.state.privileges)
        })
      })
      .catch(err => {
        // this.updateProcessing('error', 'Unknown error occured in getLoaginUserPrivileges.\n\n' + err)
      })
  }

  componentDidMount() {
    if (localStorage.getItem('logInID') && localStorage.getItem('logInUserType')) {
      if (localStorage.getItem('logInID').length > 0 && localStorage.getItem('logInUserType') === 'U'
      ) {
        this.handleUserLoginID(localStorage.getItem('logInID'))
      } else if (localStorage.getItem('logInID').length > 0 && localStorage.getItem('logInUserType') === 'A') {
        this.handleAdminLoginID(localStorage.getItem('logInID'))
      } else {
        this.clearLocalStorage()
      }
    } else {
      this.clearLocalStorage()
    }
  }

  setSubMenuOpen = (subMenuOpen) => {
    this.setState({ subMenuOpen })
  }

  handleOnSubMenuClick = () => {
    this.setSubMenuOpen(!this.state.subMenuOpen);
  }

  setPrivileges = (privileges) => {
    let admin = privileges.admin, privdashboard = privileges.privdashboard, privmember = privileges.privmember, privapprovemember = privileges.privapprovemember, privpersonaltree = privileges.privpersonaltree, privachievement = privileges.privachievement, privturnovercom = privileges.privturnovercom, privdirectreference = privileges.privdirectreference, privwithdrawal = privileges.privwithdrawal, privtdsdeduction = privileges.privtdsdeduction, privproduct = privileges.privproduct, privoffer = privileges.privoffer, privtour = privileges.privtour, privactivationkit = privileges.privactivationkit, privinwardstock = privileges.privinwardstock, privsalesrwm = privileges.privsalesrwm, rootmember = privileges.rootmember,
      submenuMember = this.state.submenuMember,
      submenuWallet = this.state.submenuWallet,
      submenuSales = this.state.submenuSales,
      mainmenumember = '', mainmenuwallet = '', mainmenusales = '',
      privadminchargededuction = privileges.privadminchargededuction

    submenuMember[1].privilege = privapprovemember
    submenuMember[2].privilege = privmember
    submenuMember[3].privilege = privpersonaltree
    submenuMember[4].privilege = privachievement
    submenuMember[5].privilege = (admin === '1') ? 'A' : 'D'
    submenuMember[6].privilege = (admin === '1') ? 'A' : 'D'
    mainmenumember = (privapprovemember == 'A' || privmember == 'A' || privpersonaltree == 'A' || privachievement == 'A') ? 'A' : 'D'

    submenuWallet[0].privilege = privturnovercom
    submenuWallet[1].privilege = privdirectreference
    submenuWallet[2].privilege = privwithdrawal
    submenuWallet[3].privilege = privtdsdeduction
    submenuWallet[4].privilege = privadminchargededuction
    mainmenuwallet = (privturnovercom == 'A' || privdirectreference == 'A' || privwithdrawal == 'A' || privtdsdeduction == 'A' || privadminchargededuction == 'A') ? 'A' : 'D'

    submenuSales[0].privilege = privproduct
    submenuSales[1].privilege = privoffer
    submenuSales[2].privilege = privtour
    submenuSales[3].privilege = privactivationkit
    submenuSales[4].privilege = privinwardstock
    submenuSales[5].privilege = privsalesrwm
    submenuSales[6].privilege = privsalesrwm
    mainmenusales = (privproduct == 'A' || privoffer == 'A' || privtour == 'A' || privactivationkit == 'A' || privinwardstock == 'A' || privsalesrwm == 'R' || privsalesrwm == 'W' || privsalesrwm == 'M') ? 'A' : 'D'

    localStorage.setItem('SA', admin === "0" ? 'Nsad' : 'Sad')

    this.setState({
      ...this.state,
      privileges: {
        admin,
        privdashboard,
        privmember,
        privapprovemember,
        privpersonaltree,
        privachievement,
        privturnovercom,
        privdirectreference,
        privwithdrawal,
        privtdsdeduction,
        privadminchargededuction,
        privproduct,
        privoffer,
        privtour,
        privactivationkit,
        privinwardstock,
        privsalesrwm,
        rootmember,
        mainmenumember,
        mainmenuwallet,
        mainmenusales
      },
      submenuMember,
      submenuWallet,
      submenuSales
    }, () => {
      localStorage.setItem('privwithdrawal', this.state.privileges.privwithdrawal);
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Toolbar />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
          style={{ color: '#42AA46', backgroundColor: '#fff' }}
        >
          <Toolbar style={{ backgroundColor: this.bgColor }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, this.state.open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap style={{ width: '100%' }}>
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <Grid item md={2} xs={12} >
                  <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => this.handleLinkToOnClick('Home')}>
                    <img src={Logo} alt='Logo' style={{ width: '75px', height: 'auto' }} />
                  </Link>
                </Grid>
                <Grid item md>
                  <Box display={{ xs: 'none', md: 'block' }} style={{ textAlign: 'right' }}>
                    <List>
                      {
                        (this.state.logInData.logInDashboardLink.length > 0) ? (
                          <LinkTo ItemName={'Dashboard'} LinkSlug={this.state.logInData.logInDashboardLink} activeMenu={this.state.activeMenu} handleLinkToOnClick={this.handleLinkToOnClick} activeStyle={this.activeStyle} />
                        ) : ""
                      }
                      <LinkTo ItemName={'About Us'} LinkSlug={'AboutUs'} activeMenu={this.state.activeMenu} handleLinkToOnClick={this.handleLinkToOnClick} activeStyle={this.activeStyle} />
                      {/* <LinkTo ItemName={'Products'} LinkSlug={'Products'} activeMenu={this.state.activeMenu} handleLinkToOnClick={this.handleLinkToOnClick} activeStyle={this.activeStyle} /> */}
                      <LinkTo ItemName={'Registration'} LinkSlug={'Registration'} activeMenu={this.state.activeMenu} handleLinkToOnClick={this.handleLinkToOnClick} activeStyle={this.activeStyle} />
                      <LinkTo ItemName={this.state.logInData.logInLinkText} LinkSlug={this.state.logInData.logInUserType === 'A' ? 'wgar' : 'LogIn'} activeMenu={this.state.activeMenu} handleLinkToOnClick={this.handleLinkToOnClick} activeStyle={this.activeStyle} />
                      <LinkTo ItemName={'Contact Us'} LinkSlug={'ContactUs'} activeMenu={this.state.activeMenu} handleLinkToOnClick={this.handleLinkToOnClick} activeStyle={this.activeStyle} />
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={this.state.open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeft />
            </IconButton>
          </div>
          <Divider />
          <List>
            {
              (this.state.logInData.logInDashboardLink.length > 0) ? (
                <MenuListItemIcon ItemName={'Dashboard'} LinkSlug={this.state.logInData.logInDashboardLink} ItemIcon={DashboardOutlined} activeMenu={this.state.activeMenu} handleDrawerClose={this.handleDrawerClose} activeStyle={this.activeStyle} handleLinkToOnClick={this.handleLinkToOnClick} OnlyForAdmin={true} IsAdmin={this.getIsAdmin} />
              ) : ""
            }

            {
              (this.state.privileges.mainmenumember === 'A') ? (<MenuListItemIcon ItemName={'Member'} LinkSlug={'Member'} ItemIcon={PeopleOutline} activeMenu={this.state.activeMenu} handleDrawerClose={this.handleDrawerClose} activeStyle={this.activeStyle} handleLinkToOnClick={this.handleLinkToOnClick} OnlyForAdmin={true} IsAdmin={this.getIsAdmin} subMenuItems={this.state.submenuMember} handleOnSubMenuClick={this.handleOnSubMenuClick} />) : ""
            }
            {
              (this.state.privileges.mainmenuwallet === 'A') ? (<MenuListItemIcon ItemName={'Wallet'} LinkSlug={'Wallet'} ItemIcon={AccountBalanceWalletOutlined} activeMenu={this.state.activeMenu} handleDrawerClose={this.handleDrawerClose} activeStyle={this.activeStyle} handleLinkToOnClick={this.handleLinkToOnClick} OnlyForAdmin={true} IsAdmin={this.getIsAdmin} subMenuItems={this.state.submenuWallet} handleOnSubMenuClick={this.handleOnSubMenuClick} />) : ""
            }
            {
              (this.state.privileges.mainmenusales === 'A') ? (<MenuListItemIcon ItemName={'Sales'} LinkSlug={'Sales'} ItemIcon={ListAltOutlined} activeMenu={this.state.activeMenu} handleDrawerClose={this.handleDrawerClose} activeStyle={this.activeStyle} handleLinkToOnClick={this.handleLinkToOnClick} OnlyForAdmin={true} IsAdmin={this.getIsAdmin} subMenuItems={this.state.submenuSales} handleOnSubMenuClick={this.handleOnSubMenuClick} />) : ""
            }
            {
              (this.state.privileges.admin === "1") ?
                <MenuListItemIcon ItemName={'User'} LinkSlug={'UserList'} ItemIcon={AccountCircleOutlined} activeMenu={this.state.activeMenu} handleDrawerClose={this.handleDrawerClose} activeStyle={this.activeStyle} handleLinkToOnClick={this.handleLinkToOnClick} /> : ""
            }
            {
              (this.state.privileges.admin === "1") ?
                <MenuListItemIcon ItemName={'Admin Charge List'} LinkSlug={'CompanyChargeList:AdminCharge'} ItemIcon={PersonOutline} activeMenu={this.state.activeMenu} handleDrawerClose={this.handleDrawerClose} activeStyle={this.activeStyle} handleLinkToOnClick={this.handleLinkToOnClick} /> : ""
            }
            {
              (this.state.privileges.admin === "1") ?
                <MenuListItemIcon ItemName={'TDS Tax List'} LinkSlug={'CompanyChargeList:TDSTax'} ItemIcon={BallotOutlined} activeMenu={this.state.activeMenu} handleDrawerClose={this.handleDrawerClose} activeStyle={this.activeStyle} handleLinkToOnClick={this.handleLinkToOnClick} /> : ""
            }
            {(this.state.logInData.logInDashboardLink.length > 0 && this.getIsAdmin() === true) ?
              <Divider /> : ""
            }
            <MenuListItemIcon ItemName={'About Us'} LinkSlug={'AboutUs'} ItemIcon={InfoOutlined} activeMenu={this.state.activeMenu} handleDrawerClose={this.handleDrawerClose} activeStyle={this.activeStyle} handleLinkToOnClick={this.handleLinkToOnClick} />
            <MenuListItemIcon ItemName={'Registration'} LinkSlug={'Registration'} ItemIcon={AssignmentIndOutlined} activeMenu={this.state.activeMenu} handleDrawerClose={this.handleDrawerClose} activeStyle={this.activeStyle} handleLinkToOnClick={this.handleLinkToOnClick} />
            <MenuListItemIcon ItemName={this.state.logInData.logInLinkText} LinkSlug={this.state.logInData.logInUserType === 'A' ? 'wgar' : 'LogIn'} ItemIcon={PowerSettingsNew} activeMenu={this.state.activeMenu} handleDrawerClose={this.handleDrawerClose} activeStyle={this.activeStyle} handleLinkToOnClick={this.handleLinkToOnClick} />
            <MenuListItemIcon ItemName={'Contact Us'} LinkSlug={'ContactUs'} ItemIcon={EmailOutlined} activeMenu={this.state.activeMenu} handleDrawerClose={this.handleDrawerClose} activeStyle={this.activeStyle} handleLinkToOnClick={this.handleLinkToOnClick} />
          </List>
          <Divider />
          <List>
            <a href='https://facebook.com/' rel="noreferrer noopener" target='_blank' className='menulink'>
              <ListItem button key='Facebook' >
                <ListItemIcon>{<Facebook className='muicon' />}</ListItemIcon>
                <ListItemText primary='Facebook' />
              </ListItem>
            </a>
            <Link to='' className='menulink'>
              <ListItem button key='Twitter' >
                <ListItemIcon>{<Twitter className='muicon' />}</ListItemIcon>
                <ListItemText primary='Twitter' />
              </ListItem>
            </Link>
            <Link to='' className='menulink'>
              <ListItem button key='Instagram' >
                <ListItemIcon>{<Instagram className='muicon' />}</ListItemIcon>
                <ListItemText primary='Instagram' />
              </ListItem>
            </Link>
            <Link to='' className='menulink'>
              <ListItem button key='Linked In' >
                <ListItemIcon>{<LinkedIn className='muicon' />}</ListItemIcon>
                <ListItemText primary='Linked In' />
              </ListItem>
            </Link>
            <Link to='' className='menulink'>
              <ListItem button key='Youtube' >
                <ListItemIcon>{<YouTube className='muicon' />}</ListItemIcon>
                <ListItemText primary='Youtube' />
              </ListItem>
            </Link>
          </List>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: this.state.open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Switch>
            <Route exact path='/' render={(props) => <Home {...props} setActiveLink={this.handleLinkToOnClick} />} />
            <Route path='/AboutUs' render={(props) => <AboutUs {...props} setActiveLink={this.handleLinkToOnClick} />} />
            {/* <Route path='/Products' render={(props) => <Products {...props} setActiveLink={this.handleLinkToOnClick} />} /> */}
            <Route path='/Registration' render={(props) => <Registration {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/Registration:regLink' render={(props) => <Registration {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/LogIn' render={(props) => <LogIn {...props} setActiveLink={this.handleLinkToOnClick} handleUserLoginID={this.handleUserLoginID} />} />
            <Route path='/wgar' render={(props) => <AdminLogIn {...props} setActiveLink={this.handleLinkToOnClick} handleAdminLoginID={this.handleAdminLoginID} setPrivileges={this.setPrivileges} />} />
            <Route path='/ContactUs' render={(props) => <ContactUs {...props} setActiveLink={this.handleLinkToOnClick} />} />
            <Route path='/PersonalTree:root' render={(props) => <PersonalTree {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/PersonalTree' render={(props) => <PersonalTree {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} rootmember={this.state.privileges.rootmember} />} />
            <Route path='/SystemTree' render={(props) => <SystemTree {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/ForgotPassword' render={(props) => <ForgotPassword {...props} setActiveLink={this.handleLinkToOnClick} />} />
            <Route path='/ChangePassword' render={(props) => <ChangePassword {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/Otp' render={(props) => <Otp {...props} setActiveLink={this.handleLinkToOnClick} />} />
            <Route path='/ReSetPassword' render={(props) => <ReSetPassword {...props} setActiveLink={this.handleLinkToOnClick} />} />
            <Route path='/MemberSetting' render={(props) => <MemberSetting {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} rootmember={this.state.privileges.rootmember} />} />
            <Route path='/MemberList' render={(props) => <MemberList {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} rootmember={this.state.privileges.rootmember} />} />
            <Route path='/MemberList:rootmember' render={(props) => <MemberList {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} rootmember={this.state.privileges.rootmember} />} />
            <Route path='/ApproveMemberList' render={(props) => <ApproveMemberList {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} privapprovemember={this.state.privileges.privapprovemember} rootmember={this.state.privileges.rootmember} />} />
            <Route path='/ApproveMemberList:rootmember' render={(props) => <ApproveMemberList {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} privapprovemember={this.state.privileges.privapprovemember} rootmember={this.state.privileges.rootmember} />} />
            <Route path='/UserDashboard:userID' render={(props) => <UserDashboard {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/UserDashboard' render={(props) => <UserDashboard {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/UserProfile:userID' render={(props) => <UserProfile {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} IsSuperAdmin={this.getIsSuperAdmin()} />} />
            <Route path='/UserProfile' render={(props) => <UserProfile {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} IsSuperAdmin={this.getIsSuperAdmin()} />} />
            <Route path='/AdminDashboard' render={(props) => <AdminDashboard {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} privilegesList={this.state.privileges} />} />
            <Route path='/WithdrawalList' render={(props) => <WithdrawalList {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} rootmember={this.state.privileges.rootmember} privilegesList={this.state.privileges} />} />
            <Route path='/TeamInfo:memberid' render={(props) => <TeamInfo {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} rootmember={this.state.privileges.rootmember} />} />
            <Route path='/TourInfo:memberid' render={(props) => <TourInfo {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} rootmember={this.state.privileges.rootmember} />} />
            <Route path='/LevelWiseMemberCount:rootmember' render={(props) => <LevelWiseMemberCount {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} rootmember={this.state.privileges.rootmember} />} />

            <Route path='/WithdrawalList:rootmember' render={(props) => <WithdrawalList {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} rootmember={this.state.privileges.rootmember} privilegesList={this.state.privileges} />} />
            <Route path='/GeneratePin:userID' render={(props) => <GeneratePin {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/PinDashboard:userID' render={(props) => <PinDashboard {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/PinTransfer:userID' render={(props) => <PinTransfer {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/PinBook:userID' render={(props) => <PinBook {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/BuyPin:userID' render={(props) => <BuyPin {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/Wallet:userID' render={(props) => <WalletDashboard {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/Product' render={(props) => <Product {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/ProductEntry' render={(props) => <ProductEntry {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/ProductEntry:EditSrNo' render={(props) => <ProductEntry {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/InwardStock' render={(props) => <InwardStock {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/InwardStockEntry' render={(props) => <InwardStockEntry {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/InwardStockEntry:EditSrNo' render={(props) => <InwardStockEntry {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />

            <Route path='/SalesEntry' render={(props) => <SalesEntry {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} privilegesList={this.state.privileges} />} />

            <Route path='/SalesEntry:EditNewSrNo' render={(props) => <SalesEntry {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} privilegesList={this.state.privileges} />} />

            <Route path='/SalesListOfNetBTO' render={(props) => <SalesListOfNetBTO {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} privilegesList={this.state.privileges} rootmember={this.state.privileges.rootmember} />} />

            <Route path='/Sales' render={(props) => <Sales {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} privilegesList={this.state.privileges} rootmember={this.state.privileges.rootmember} />} />

            <Route path='/Sales:rootmember' render={(props) => <Sales {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} privilegesList={this.state.privileges} />} />

            <Route path='/OfferList' render={(props) => <OfferList {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/OfferEntry' render={(props) => <OfferEntry {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/OfferEntry:EditOfferSrNo' render={(props) => <OfferEntry {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/TourList' render={(props) => <TourList {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/TourEntry' render={(props) => <TourEntry {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/TourEntry:EditSrNo' render={(props) => <TourEntry {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/ActivationKitList' render={(props) => <ActivationKitList {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/AchievementListForAdminOnly' render={(props) => <AchievementListForAdminOnly {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} rootmember={this.state.privileges.rootmember} />} />
            <Route path='/TourAchievementList' render={(props) => <TourAchievementList {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} rootmember={this.state.privileges.rootmember} />} />
            <Route path='/TurnoverHistory:memberid' render={(props) => <TurnoverHistory {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} rootmember={this.state.privileges.rootmember} privilegesList={this.state.privileges} />} />
            <Route path='/TurnoverCommission' render={(props) => <TurnoverCommission {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} rootmember={this.state.privileges.rootmember} privilegesList={this.state.privileges} />} />
            <Route path='/TurnoverCommission:rootmember' render={(props) => <TurnoverCommission {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} rootmember={this.state.privileges.rootmember} privilegesList={this.state.privileges} />} />
            <Route path='/DirectReference' render={(props) => <DirectReference {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} rootmember={this.state.privileges.rootmember} />} />
            <Route path='/DirectReference:rootmember' render={(props) => <DirectReference {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} rootmember={this.state.privileges.rootmember} />} />
            <Route path='/TDSDeductionList' render={(props) => <TDSDeductionList {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/AdminChargeDeductionList' render={(props) => <AdminChargeDeductionList {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/MemberPanNoBankDetailMissingList:Operation' render={(props) => <MemberPanNoBankDetailMissingList {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />

            <Route path='/UserList' render={(props) => <UserList {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/UserEntry' render={(props) => <UserEntry {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            <Route path='/UserEntry:EditUserID' render={(props) => <UserEntry {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />

            <Route path='/CompanyChargeList:Ledger' render={(props) => <CompanyChargeList {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
            {/* <Route path='/CompanyChargeEntry' render={(props) => <CompanyChargeEntry {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} /> */}
            <Route path='/CompanyChargeEntry:EditSrNo' render={(props) => <CompanyChargeEntry {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />

            <Route path='/StockRegister' render={(props) => <StockRegister {...props} setActiveLink={this.handleLinkToOnClick} IsAdmin={this.getIsAdmin()} />} />
          </Switch>
        </main>
      </div>
    );
  }
}
export default withStyles(useStyles)(Menu);