import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import TableList from './CustomTool/TableList'
import { fetchMember } from "./API/API";
import { Grid } from "@material-ui/core";
import Progress from './CustomTool/Progress'
import CtCmb from './CustomTool/CtCmb'
import { getIndianFormatedNumber } from "./SystemUtility/SystemUtility";

class TurnoverHistory extends Component {

    state = {
        dataLoadStatus: false,
        memberid: '',
        membername: '',
        membermobileno: '',
        rootmember: '',
        limitno: 6,
        columns: [
            { id: 'fromdt', label: 'From Date' },
            { id: 'todt', label: 'To Date' },
            { id: 'repurchase', label: 'Repurchase', align: 'right' },
            { id: 'team1bto', label: 'Team 1 BTO', align: 'right' },
            { id: 'team2bto', label: 'Team 2 BTO', align: 'right' },
            { id: 'totalbto', label: 'Calculation Total BTO', align: 'right' },
            { id: 'turnoverpercentage', label: 'Turnover %', align: 'right' },
            { id: 'powerleg', label: 'Power leg', align: 'right' },
            { id: 'powerlegcfamout', label: 'C/F BTO', align: 'right' },
            { id: 'turnovercommission', label: 'Turnover Commission', align: 'right', format: (value) => getIndianFormatedNumber(value) },
            { id: 'directreferral', label: 'Direct Referral', align: 'right', format: (value) => getIndianFormatedNumber(value) },
            { id: 'activebonus', label: 'Active Bonus', align: 'right', format: (value) => getIndianFormatedNumber(value) },
            { id: 'totalincome', label: 'Total Income', align: 'right', format: (value) => getIndianFormatedNumber(value) },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 10,
        limitnolist: [
            { name: '6', disabled: false },
            { name: '12', disabled: false },
            { name: '24', disabled: false },
        ],
    }

    //#region Component

    componentWillReceiveProps(newProps) {
        console.log(newProps)
        if(newProps.memberid){
            let memberid=newProps.memberid
            this.setState({ memberid }, () => {
                this.refreshTurnoverHistoryInfo()
            })
        }
    }

    componentWillMount() {
        // this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.setMemberIDState()
        this.setState({
            dataLoadStatus: true
        })
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (e.target.name == 'limitno') {
                this.loadTurnoverHistory()
            }
        })
    }
    //#endregion

    //#region Function
    setMemberIDState = () => {
        let memberid = ''
        if (
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.memberid &&
            Number(this.props.match.params.memberid.substring(1)) > 99999
        ) {
            memberid = Number(this.props.match.params.memberid.substring(1))
        } else if (this.props.memberid && Number(this.props.memberid)) {
            memberid = Number(this.props.memberid)
        } else {
            memberid = 0
        }
        let rootmember = ''
        if (
            this.props.match &&
            this.props.match.params.rootmember &&
            this.props.match.params.rootmember.length > 0 &&
            Number(this.props.match.params.rootmember.substring(1)) > 99999
        ) {
            rootmember = Number(this.props.match.params.rootmember.substring(1))
        } else if (this.props.rootmember && this.props.rootmember.length > 0 && Number(this.props.rootmember) > 99999) {
            rootmember = this.props.rootmember
        }
        if (memberid > 99999) {
            this.setState({
                ...this.state,
                memberid,
                rootmember
            }, () => {
                console.log("this.state.memberid",this.state.memberid);
                console.log("this.state.rootmember",this.state.rootmember);
                this.refreshTurnoverHistoryInfo()
            })
        }
    }

    refreshTurnoverHistoryInfo = () => {
        if (Number(this.state.memberid) > 0 && Number(this.state.memberid) > 99999) {
            this.loadTurnoverHistory()
        } else {
            this.clearTurnoverHistoryInfo()
        }
    }

    loadTurnoverHistory = () => {
        /* let rootmember = ''
        if (
            this.props.match &&
            this.props.match.params.rootmember &&
            this.props.match.params.rootmember.length > 0 &&
            Number(this.props.match.params.rootmember.substring(1)) > 99999
        ) {
            rootmember = Number(this.props.match.params.rootmember.substring(1))
        } else if (this.props.rootmember && this.props.rootmember.length > 0 && Number(this.props.rootmember) > 99999) {
            rootmember = this.props.rootmember
        } */

        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                Op: 'TurnoverHistoryList',
                rootmember: this.state.rootmember,
                MemberID: this.state.memberid,
                LimitNo: this.state.limitno
            }
            fetchMember(reqData)
                .then(res => {
                    const rows = res.data.TurnoverHistoryList
                    let membermobileno = res.data.MemberDetail[0].membermobileno
                    let membername = res.data.MemberDetail[0].membername
                    const rowsCount = rows.length
                    this.setState({ rows, rowsCount, dataLoadStatus: true, membermobileno, membername })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in loadTurnoverHistory. ' + error)
                })
        })
    }

    clearTurnoverHistoryInfo = () => {
        const rows = []
        let membermobileno = ''
        let membername = ''
        const rowsCount = rows.length
        this.setState({ rows, rowsCount, dataLoadStatus: true, membermobileno, membername })
    }
    //#endregion

    render() {
        let rows = this.state.rows
        let rowsCount = this.state.rowsCount
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                {
                    Number(this.state.memberid) > 99999 ?
                        <div>
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item className="pageTitle">
                                    <label htmlFor="Title" className='pageTitleText'>Turnover History</label>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={5}>
                                <Grid item><b>Member ID : </b> {this.state.memberid}</Grid>
                                <Grid item><b>Name : </b> {this.state.membername}</Grid>
                                <Grid item><b>Mobile No. : </b> {this.state.membermobileno}</Grid>
                                <Grid item>
                                    <CtCmb
                                        id='limitno'
                                        label='Cycle Count'
                                        items={this.state.limitnolist}
                                        value={this.state.limitno}
                                        handleOnChange={this.handleOnChange}
                                        width='100'
                                    />
                                </Grid>
                            </Grid>
                        </div> :
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={5}>
                            <Grid item>-</Grid>
                        </Grid>
                }
                <br />
                {
                    Number(this.state.memberid) > 99999 ?
                        <TableList
                            columns={this.state.columns}
                            // counter={this.state.counter}
                            rows={rows}
                            rowsCount={rowsCount}
                            rowsPerPage={this.state.rowsPerPage}
                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                            // onActionClick={this.handleARDonActionClick}
                            // keyColumn={this.state.keyColumn}
                            // handleOnSearchChange={this.handleOnSearchChange}
                            // searchColumns={this.state.searchColumns}
                            // searchData={this.state.searchData}
                            provideSearch={false}
                        />
                        : ''
                }
            </div>
        )
    }
}
export default HoCtToastContainer(TurnoverHistory)
