import React, { Component } from 'react'
import { fetchSales } from "./API/API";
import HoCtToastContainer from './HOC/HoCtToastContainer'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";

class SalesDelete extends Component {

    deleteSales = (EditAcYear, EditBillNo) => {
        this.props.notifyProcessing()
        if (Number(EditAcYear) > 0 && Number(EditBillNo) > 0) {
            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'DeleteSales',
                    EditAcYear: Number(EditAcYear),
                    EditBillNo: Number(EditBillNo),
                }
                fetchSales(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.setState({
                            dataLoadStatus: true
                        }, () => {
                            if (res.data.msgType === 'success') {
                                if (this.props.onDeleteSuccess) {
                                    this.props.onDeleteSuccess()
                                }
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('deleteSales', error)
                    })
            })
        } else {
            this.props.toastMsg("info", "There are no detail to delete")
        }
        return true
    }

    render() {
        return (
            <AlertResponsiveDialog
                labelAgree='Delete'
                labelDisagree='Cancel'
                alertTitle={'Do you want to Delete Sales of Bill No. : '+this.props.BillNo+' ?'}
                // alertMessage={}
                handleOnClickYes={() => {
                    return this.deleteSales(
                        this.props.EditAcYear, this.props.EditBillNo
                    )
                }}
                defaultOpenDialog={true}
                onYesClickCloseIfExeSuccessfully={true}
            />
        )
    }
}
export default HoCtToastContainer(SalesDelete)
