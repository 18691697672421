import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import Progress from './CustomTool/Progress'
import { Grid, Box, Button } from '@material-ui/core'
import CtTxt from './CustomTool/CtTxt'
import CtCmbEditable from './CustomTool/CtCmbEditable'
import CtBtn from './CustomTool/CtBtn'
import { fetchProduct } from './API/API'
import ImageUploadIcon from './CustomTool/ImageUploadIcon'
import PH_PRODUCT from '../png/ProductImagePlaceHolder.png'
// import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import ProductDelete from './ProductDelete'

class ProductEntry extends Component {

    state = {
        formData: {
            EditSrNo: 0,
            EditProductName: '',
            txtProductName: '',
            cmbCategory: '',
            txtMRP: '',
            txtDP: '',
            txtSalesRate: '',
            cmbGSTPer: '',
            txtBTO: '',
            txtHSNCode: '',
            txtDescription: '',
            cmbStatus: 'Active',
            txtCurrentStock: '',
            PhotoName: '',
            PhotoPath: '',
            PhotoLocation: '',
        },
        resetDefaultImage: false,
        Categories: [],
        GSTPer: [],
        Status: [
            { name: 'Active' },
            { name: 'Inactive' },
        ],
        dataLoadStatus: false,
        cWidth: 80,
        ARD: null,
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.onFormLoad()
        document.getElementById('txtProductName').focus()
    }
    //#endregion

    //#region Handles
    handleOnChange = (e) => {
        let curID = e.target.name
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        }, () => {
            if (curID === "cmbGSTPer" || curID === "txtDP") {
                this.calculateSalesRate()
            }
        })
    }

    handleOnAutoCompleteChange = (e) => {
        // console.log('e.target.name', e.target.name)
        // console.log('e.target.value', e.target.value)
        let value = e.target.value && e.target.value['name'] ? e.target.value['name'] : e.target.value,
            curID = e.target.name
        // console.log('value', value)
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: value }
        }, () => {
            // console.log("curID",curID);            
            if (curID === "cmbGSTPer" || curID === "txtDP") {
                this.calculateSalesRate()
            }
        })
    }

    handleOnAutoCompleteInputChange = (e) => {
        if (this.state.formData[e.target.id] === null && e.target.value.length > 0) {
            this.setState({
                formData: { ...this.state.formData, [e.target.name]: e.target.value }
            }, () => {
                if (e.target.name === "cmbGSTPer" || e.target.name === "txtDP") {
                    this.calculateSalesRate()
                }
            })
        }
    }

    handleARDonActionClick = () => {
        if (Number(this.state.formData.EditSrNo) > 0) {
            const EditSrNo = this.state.formData.EditSrNo,
                EditProductName = this.state.formData.EditProductName
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <ProductDelete
                    EditSrNo={EditSrNo}
                    EditProductName={EditProductName}
                    onDeleteSuccess={this.newProduct}
                />
                this.setState({ ARD })
            })
        } else {
            this.props.toastMsg("info", "There are no detail to delete")
        }
    }

    handleOnResetDefaultImage = () => {
        const resetDefaultImage = false
        this.setState({ resetDefaultImage })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    onFormLoad = () => {
        this.setState({
            dataLoadStatus: false
        }, () => {
            let reqData = {
                Op: 'Load_GST_Category'
            },
                EditSrNo = 0,
                EditProductName = '',
                txtProductName = '',
                cmbCategory = '',
                txtMRP = '',
                txtDP = '',
                txtSalesRate = '',
                cmbGSTPer = '',
                txtBTO = '',
                txtHSNCode = '',
                txtDescription = '',
                cmbStatus = 'Active',
                txtCurrentStock = '',
                PhotoName = '',
                PhotoPath = '',
                PhotoLocation = ''
            if (this.props.match.params.EditSrNo && Number(this.props.match.params.EditSrNo.substring(1)) > 0) {
                EditSrNo = Number(this.props.match.params.EditSrNo.substring(1))
                reqData = {
                    Op: 'Load_GST_Category_ProductData',
                    EditSrNo
                }
            }
            fetchProduct(reqData)
                .then(res => {
                    const GSTPer = res.data.GSTPer
                    const Categories = res.data.Categories
                    const dataLoadStatus = true
                    if (EditSrNo > 0 && res.data.ProductList.length>0) {
                        EditProductName = res.data.ProductList[0].ProductName
                        txtProductName = EditProductName
                        cmbCategory = res.data.ProductList[0].Category
                        txtMRP = res.data.ProductList[0].MRP
                        txtDP = res.data.ProductList[0].DP
                        txtSalesRate = res.data.ProductList[0].SalesRate
                        cmbGSTPer = res.data.ProductList[0].GSTPer
                        txtBTO = res.data.ProductList[0].BTO
                        txtHSNCode = res.data.ProductList[0].HSNCode
                        txtDescription = res.data.ProductList[0].Description
                        cmbStatus = res.data.ProductList[0].Status
                        txtCurrentStock = res.data.ProductList[0].CurStock
                        PhotoName = res.data.ProductList[0].PhotoName
                        PhotoPath = res.data.ProductList[0].PhotoPath
                        PhotoLocation = res.data.ProductList[0].PhotoLocation
                    }
                    this.setState({
                        GSTPer, Categories, dataLoadStatus,
                        formData: {
                            ...this.props.formData,
                            EditSrNo,
                            EditProductName,
                            txtProductName,
                            cmbCategory,
                            txtMRP,
                            txtDP,
                            txtSalesRate,
                            cmbGSTPer,
                            txtBTO,
                            txtHSNCode,
                            txtDescription,
                            cmbStatus,
                            txtCurrentStock,
                            PhotoName,
                            PhotoPath,
                            PhotoLocation
                        }
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('onFormLoad', error)
                })
        })
    }

    loadCategory = () => {
        this.setState({
            dataLoadStatus: false
        }, () => {
            const reqData = {
                Op: 'LoadProductCategory'
            }
            fetchProduct(reqData)
                .then(res => {
                    const Categories = res.data.Categories
                    const dataLoadStatus = true
                    this.setState({
                        Categories, dataLoadStatus
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('loadCategory', error)
                })
        })
    }

    loadStock = () => {
        this.props('info', 'Remain to code loadStock')
    }

    onPhotoImageChange = (PhotoName, PhotoLocation, PhotoPath) => {
        this.setState({
            formData: { ...this.state.formData, PhotoName, PhotoLocation, PhotoPath }
        }, () => {
            this.saveProduct(false)
        })
    }

    validateFormdata = () => {
        let validateFormdata = false

        if (this.state.formData.txtProductName.trim().length <= 0) {
            this.props.toastErrorMsg('Enter Product Name', 'txtProductName')
        } else if (Number(this.state.formData.txtMRP) <= 0) {
            this.props.toastErrorMsg('MRP must be greater than 0', 'txtMRP')
        } else if (Number(this.state.formData.txtDP) <= 0) {
            this.props.toastErrorMsg('Discount Price must be greater than 0', 'txtDP')
        } else if (Number(this.state.formData.txtSalesRate) <= 0) {
            this.props.toastErrorMsg('Sales Rate must be greater than 0', 'txtSalesRate')
        } else if (this.state.formData.cmbGSTPer.length <= 0) {
            this.props.toastErrorMsg('Select GST %', 'cmbGSTPer')
        } else if (this.state.formData.txtBTO < 0) {
            this.props.toastErrorMsg('Business Turn Over must be greater than 0', 'txtBTO')
        } else if (this.state.formData.cmbStatus.length <= 0) {
            this.props.toastErrorMsg('Select Active or Inactive', 'cmbStatus')
        } else {
            validateFormdata = true
        }
        return validateFormdata
    }

    saveProduct = (applyNewProduct) => {
        let result = false
        if (this.validateFormdata() === true) {
            this.props.notifyProcessing()
            const Status = this.state.formData.cmbStatus === 'Active' ? 1 : 0
            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'SaveProduct',
                    EditSrNo: Number(this.state.formData.EditSrNo),
                    EditProductName: this.state.formData.EditProductName,
                    ProductName: this.state.formData.txtProductName,
                    Category: this.state.formData.cmbCategory,
                    MRP: Number(this.state.formData.txtMRP),
                    DP: Number(this.state.formData.txtDP),
                    SalesRate: Number(this.state.formData.txtSalesRate),
                    GSTPer: this.state.formData.cmbGSTPer,
                    BTO: Number(this.state.formData.txtBTO),
                    HSNCode: this.state.formData.txtHSNCode,
                    Description: this.state.formData.txtDescription,
                    Status,
                    PhotoPath: this.state.formData.PhotoPath,
                    PhotoName: this.state.formData.PhotoName,
                    PhotoLocation: this.state.formData.PhotoLocation,
                }
                fetchProduct(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        let resetDefaultImage = false,
                            dataLoadStatus = true
                        if (applyNewProduct === true) {
                            resetDefaultImage = true
                        }
                        this.setState({
                            dataLoadStatus,
                            resetDefaultImage
                        }, () => {
                            if (res.data.msgType === 'success') {
                                result = true
                                if (Number(this.state.formData.EditSrNo) > 0 &&
                                    this.state.formData.EditProductName.length > 0 &&
                                    applyNewProduct === true
                                ) {
                                    this.newProduct()
                                } else if (
                                    Number(this.state.formData.EditSrNo) === 0 &&
                                    this.state.formData.EditProductName.length === 0 &&
                                    applyNewProduct === false
                                ) {
                                    const EditSrNo = Number(res.data.EditSrNo),
                                        EditProductName = this.state.formData.txtProductName
                                    this.setState({
                                        formData: {
                                            ...this.state.formData,
                                            EditSrNo,
                                            EditProductName
                                        }
                                    }, () => {
                                        document.getElementById('ProductImageUpload').click()
                                        return result
                                    })
                                }
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('saveProduct', error)
                        return result
                    })
            })
        }
    }

    newProduct = () => {
        const oldEditSrNo = this.state.formData.EditSrNo
        const EditSrNo = 0,
            EditProductName = '',
            txtProductName = '',
            cmbCategory = '',
            txtMRP = '',
            txtDP = '',
            txtSalesRate = '',
            cmbGSTPer = '',
            txtBTO = '',
            txtHSNCode = '',
            txtDescription = '',
            cmbStatus = 'Active',
            txtCurrentStock = '',
            PhotoName = '',
            PhotoPath = '',
            PhotoLocation = ''
        this.setState({
            formData: {
                ...this.state.formData,
                EditSrNo,
                EditProductName,
                txtProductName,
                cmbCategory,
                txtMRP,
                txtDP,
                txtSalesRate,
                cmbGSTPer,
                txtBTO,
                txtHSNCode,
                txtDescription,
                cmbStatus,
                txtCurrentStock,
                PhotoName,
                PhotoPath,
                PhotoLocation
            },
        }, () => {
            if (oldEditSrNo > 0) {
                this.navigateTo('ProductEntry')
            }
            document.getElementById('txtProductName').focus()
        })
    }

    onClose = (link) => {
        this.navigateTo('Product')
    }

    changeImagePreValidation = () => {
        if (Number(this.state.formData.EditSrNo) > 0 &&
            this.state.formData.EditProductName &&
            this.state.formData.EditProductName.length > 0
        ) {
            return true
        } else {
            this.saveProduct(false)
            return false
        }
    }

    calculateSalesRate = () => {
        const txtDP = Number(this.state.formData.txtDP),
            cmbGSTPer = this.state.formData.cmbGSTPer
        let txtSalesRate = 0, GSTPer = '', GSTNum = 0
        // console.log("txtMRP", txtMRP);
        // console.log("cmbGSTPer", cmbGSTPer);

        if (txtDP > 0 && cmbGSTPer.length > 0) {
            if (cmbGSTPer.includes("%")) {
                GSTPer = cmbGSTPer.split("%")
                GSTNum = Number(GSTPer[0])
                // console.log("GSTNum", GSTNum);
                //Sales Rate = RoundOff(((MRP / (100+GST%)) * 100),2)
                txtSalesRate = ((txtDP / (100 + GSTNum)) * 100)
                txtSalesRate = txtSalesRate.toFixed(2)
            } else {
                txtSalesRate = txtDP
            }
        } else {
            txtSalesRate = txtDP
        }
        this.setState({
            formData: { ...this.state.formData, txtSalesRate }
        })
    }
    //#endregion

    render() {

        let fixProductFileName = 'ProductImage_' +
            (Number(this.state.formData.EditSrNo) > 0 ? Number(this.state.formData.EditSrNo) : '')

        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' >Product Entry</label>
                    </Grid>
                </Grid>
                <form>
                    <Grid container direction="row" justify="space-evenly" alignItems="flex-start" spacing={2}>
                        <Grid item>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtProductName'
                                        label='Product Name'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtProductName}
                                        width='440'
                                        maxLength='100'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbCategory'
                                        label='Category'
                                        freeSolo={true}
                                        items={this.state.Categories}
                                        handleonCmbInputChange={this.handleOnAutoCompleteInputChange}
                                        handleOnChange={this.handleOnAutoCompleteChange}
                                        // handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbCategory}
                                        width='440'
                                        maxLength='100'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtMRP'
                                        label='MRP'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtMRP}
                                        width='210'
                                        maxLength='15'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtDP'
                                        label='DP'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtDP}
                                        width='210'
                                        maxLength='15'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>                                
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtSalesRate'
                                        label='Sales Rate'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtSalesRate}
                                        width='210'
                                        maxLength='15'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbGSTPer'
                                        label='GST %'
                                        items={this.state.GSTPer}
                                        value={this.state.formData.cmbGSTPer}
                                        handleonCmbInputChange={this.handleOnAutoCompleteInputChange}
                                        handleOnChange={this.handleOnAutoCompleteChange}
                                        // handleOnChange={this.handleOnChange}
                                        width='210'
                                        onKeyDown={this.props.onKeyDown}
                                        maxLength='100'
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtBTO'
                                        label='Buisness Turn Over'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtBTO}
                                        width='210'
                                        maxLength='15'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtHSNCode'
                                        label='HSN Code'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtHSNCode}
                                        width='210'
                                        maxLength='10'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtDescription'
                                        label='Description'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtDescription}
                                        width='440'
                                        maxLength='1000'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>                                
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtCmbEditable
                                        id='cmbStatus'
                                        label='Status'
                                        items={this.state.Status}
                                        value={this.state.formData.cmbStatus}
                                        maxLength='8'
                                        handleonCmbInputChange={this.handleOnAutoCompleteInputChange}
                                        handleOnChange={this.handleOnAutoCompleteChange}
                                        width='210'
                                        onKeyDown={this.props.onKeyDown}
                                        validateInput={this.validateFormdata}
                                        defaultAction={() => { this.saveProduct(true) }}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtCurrentStock'
                                        label='Current Stock'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtCurrentStock}
                                        width='210'
                                        maxLength='10'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" justify="flext-start" alignItems="flex-start" spacing={5}>
                                <Grid item>
                                    <ImageUploadIcon
                                        id='ProductImage'
                                        btnUploadID='ProductImageUpload'
                                        default={PH_PRODUCT}
                                        curImageName={this.state.formData.PhotoName}
                                        curImageLocation={this.state.formData.PhotoLocation}
                                        curImageFullPath={this.state.formData.PhotoPath}
                                        onCurImageChange={this.onPhotoImageChange}
                                        notResetImageOnUpload={true}
                                        fixFileName={fixProductFileName}
                                        uploadLocation='Product'
                                        showDeleteIcon={true}
                                        maxSizeInKB={2048}
                                        changeImagePreValidation={this.changeImagePreValidation}
                                        handleOnResetDefaultImage={this.handleOnResetDefaultImage}
                                        resetDefaultImage={this.state.resetDefaultImage}
                                        toastMsg={this.props.toastMsg}
                                        toastErrorMsg={this.props.toastErrorMsg}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="flex-start" spacing={3}>
                                <Grid item>
                                    <CtBtn label='Save' onClick={() => { this.saveProduct(true) }} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='New' onClick={this.newProduct} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='Delete' onClick={this.handleARDonActionClick} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='Close' onClick={this.onClose} width={this.state.cWidth} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>

                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(ProductEntry))