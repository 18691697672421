import React, { Component } from 'react'
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@material-ui/core'

function Item(props) {
    return (
        <Paper>
            <img
                src={props.item.PhotoPath}
                style={{
                    display: 'block',
                    height: 'auto',
                    width: '90vw',
                    maxHeight: '80vh',
                    margin: '0 auto'
                }}
            />
            {/* <p>{props.item.PhotoPath}</p> */}
        </Paper>
    )
}

class HomeProductSlider extends Component {
    render() {
        return (
            <Carousel
                animation='slide'
            >
                {
                    this.props.items.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>
        )
    }
}
export default HomeProductSlider
