import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { fetchMember } from "./API/API";
import { Grid } from "@material-ui/core";
import Progress from './CustomTool/Progress'
import CtTxt from './CustomTool/CtTxt'
import CtBtn from './CustomTool/CtBtn';


class MemberSetting extends Component {

    state = {
        dataLoadStatus: false,
        txtMaxTotalIncome: '',
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.loadMemberSetting()
    }
    //#endregion

    //#region Handle
    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    //#endregion

    //#region Function
    loadMemberSetting = () => {
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                Op: 'LoadMemberSetting'
            }
            fetchMember(reqData)
                .then(res => {
                    let txtMaxTotalIncome = 0
                    if (res.data.LoadMemberTotalIncomeSetting.length > 0) {
                        txtMaxTotalIncome = res.data.LoadMemberTotalIncomeSetting[0].TotalIncome
                    }
                    this.setState({ txtMaxTotalIncome, dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in loadMemberSetting. ' + error)
                })
        })
    }

    setMemberTotalIncomeSetting = () => {
        if (Number(this.state.txtMaxTotalIncome) < 0) {
            this.props.toastErrorMsg('Total Income cannot be less than 0, Enter valid Total Income', 'txtMaxTotalIncome')
        } else {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'SetMemberTotalIncomeSetting',
                    TotalIncome: this.state.txtMaxTotalIncome
                }
                fetchMember(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        let txtMaxTotalIncome = 0
                        if (res.data.LoadMemberTotalIncomeSetting.length > 0) {
                            txtMaxTotalIncome = res.data.LoadMemberTotalIncomeSetting[0].TotalIncome
                        }
                        this.setState({ txtMaxTotalIncome, dataLoadStatus: true })
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in setMemberTotalIncomeSetting. ' + error)
                    })
            })
        }
    }
    //#endregion

    render() {
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>Member Setting</label>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                    <Grid item>
                        <CtTxt
                            id='txtMaxTotalIncome'
                            label='Total Income'
                            handleOnChange={this.handleOnChange}
                            value={this.state.txtMaxTotalIncome}
                            width={100}
                            maxLength='10'
                        />
                    </Grid>
                    <Grid item>
                        <CtBtn label='Set' onClick={this.setMemberTotalIncomeSetting} />
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(MemberSetting))
