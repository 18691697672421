import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import Progress from './CustomTool/Progress'
import { Grid, Box } from '@material-ui/core'
import CtTxt from './CustomTool/CtTxt'
import CtCmbEditable from './CustomTool/CtCmbEditable'
import CtDtpDOB from './CustomTool/CtDtpDOB'
import CtCheckBox from './CustomTool/CtCheckBox'
import CtBtn from './CustomTool/CtBtn'
import TableList from './CustomTool/TableList'
import { fetchTour } from './API/API'
import {
    get_YMD_from_SYS, get_YMD_from_DMY, get_DMY_from_YMD,
    getLastDayofMonthofGivenDate,
    get_NextDate_of_Given_YMD_Date,
    get_DayMonthYear_of_Given_YMD_Date,
    getTowDigits_DayORMonth
} from './SystemUtility/SystemUtility'
import { Delete, Edit } from '@material-ui/icons'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";

class TourEntry extends Component {

    state = {
        formDataTour: {
            EditSrNo: 0,
            EditTourName: '',
            txtTourName: '',
            txtTeam1BTO: '',
            txtTeam2BTO: '',
            txtSelfRepurchase: '',
            dtpFromDate: get_YMD_from_SYS(new Date()),
            dtpToDate: get_YMD_from_SYS(new Date()),
        },
        dataLoadStatus: false,
        ARD: null,
        keyColumn: [
            'srno', 'tourname', 'qty'
        ],
        columns: [],
        rows: [],
        rowsCount: 7,
        rowsPerPage: 10,
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.onFormLoad()
        document.getElementById('txtTourName').focus()
    }
    //#endregion

    //#region Handles
    handleOnChange = (e) => {
        this.setState({
            formDataTour: { ...this.state.formDataTour, [e.target.name]: e.target.value }
        })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formDataTour: { ...this.state.formDataTour, [e.target.name]: e.target.value.trim() }
        })
    }

    handleOnChangeTableRow = (e, rowIndex) => {
        this.setState({
            rows: this.state.rows.map((row, index) => {
                if (rowIndex !== undefined && Number(index) === Number(rowIndex)) {
                    let dynamicRowDataID = row.dynamicRowDataID
                    dynamicRowDataID[e.target.name] = e.target.value
                    return {
                        ...row,
                        dynamicRowDataID
                    }
                } else {
                    return row
                }
            })
        })
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    getColumnList = () => {
        return [
            { id: 'no', label: 'Sr. No.', align: 'center' },
            { id: 'fromdt', label: 'From Date', align: 'center' },
            { id: 'todt', label: 'To Date', align: 'center' },
            {
                id: 'team1bto', label: 'Min Team 1 BTO', align: 'right',
                type: 'CtTxt', handleOnChange: this.handleOnChangeTableRow,
                dynamicRowDataID: 'team1bto', inputLabel: 'Min Team 1 BTO',
            },
            {
                id: 'team2bto', label: 'Min Team 2 BTO', align: 'right',
                type: 'CtTxt', handleOnChange: this.handleOnChangeTableRow,
                dynamicRowDataID: 'team2bto', inputLabel: 'Min Team 2 BTO',
            },
        ]
    }

    onFormLoad = () => {
        this.setState({
            dataLoadStatus: false
        }, () => {
            let reqData = '',
                EditSrNo = 0,
                EditTourName = '',
                txtTourName = '',
                txtTeam1BTO = '',
                txtTeam2BTO = '',
                txtSelfRepurchase = '',
                dtpFromDate = get_YMD_from_SYS(new Date()),
                dtpToDate = get_YMD_from_SYS(new Date()),
                rows = [],
                rowsCount = 0,
                columns = this.getColumnList()

            if (this.props.match.params.EditSrNo && Number(this.props.match.params.EditSrNo.substring(1)) > 0) {
                EditSrNo = Number(this.props.match.params.EditSrNo.substring(1))
                reqData = {
                    Op: 'LoadTourListForUpdate',
                    EditSrNo
                }
                fetchTour(reqData)
                    .then(res => {
                        const dataLoadStatus = true
                        if (EditSrNo > 0) {
                            EditTourName = res.data.tourmasterlist[0].tourname
                            txtTourName = EditTourName
                            txtTeam1BTO = res.data.tourmasterlist[0].team1bto
                            txtTeam2BTO = res.data.tourmasterlist[0].team2bto
                            txtSelfRepurchase = res.data.tourmasterlist[0].selfrepurchase
                            dtpFromDate = get_YMD_from_DMY(res.data.tourmasterlist[0].fromdt, "/")
                            dtpToDate = get_YMD_from_DMY(res.data.tourmasterlist[0].todt, "/")
                            rows = res.data.tourmasterlist[0].tourcycleminbtolist
                            rowsCount = rows.length
                            this.setState({
                                columns, rows, dataLoadStatus, rowsCount,
                                formDataTour: {
                                    ...this.state.formDataTour,
                                    EditSrNo,
                                    EditTourName,
                                    txtTourName,
                                    txtTeam1BTO,
                                    txtTeam2BTO,
                                    txtSelfRepurchase,
                                    dtpFromDate,
                                    dtpToDate
                                }
                            })

                        }
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('onFormLoad', error)
                    })
            } else {
                const dataLoadStatus = true
                this.setState({ dataLoadStatus })
            }
        })
    }

    validatedata = () => {
        let validatedata = false

        if (this.state.formDataTour.txtTourName.trim().length <= 0) {
            this.props.toastErrorMsg('Enter Tour Name', 'txtTourName')
        } else if (Number(this.state.formDataTour.txtTeam1BTO) <= 0) {
            this.props.toastErrorMsg('Enter Team 1 BTO', 'txtTeam1BTO')
        } else if (Number(this.state.formDataTour.txtTeam2BTO) <= 0) {
            this.props.toastErrorMsg('Enter Team 2 BTO', 'txtTeam2BTO')
        } else if (Number(this.state.formDataTour.txtSelfRepurchase) < 0) {
            this.props.toastErrorMsg('Enter Self Repurchase', 'txtSelfRepurchase')
        } else if (this.state.formDataTour.dtpFromDate === undefined || this.state.formDataTour.dtpFromDate.length <= 0) {
            this.props.toastErrorMsg('Enter From Date', 'dtpFromDate')
        } else if (this.state.formDataTour.dtpToDate === undefined || this.state.formDataTour.dtpToDate.length <= 0) {
            this.props.toastErrorMsg('Enter To Date', 'dtpToDate')
        } else {
            validatedata = true
        }
        return validatedata
    }

    saveTour = () => {
        let result = false
        if (this.validatedata() === true) {
            this.props.notifyProcessing()

            this.setState({ dataLoadStatus: false }, () => {
                let TourCycleMinBTO = []
                if (this.state.rows.length > 0) {
                    this.state.rows.map((row, index) => {
                        const team1bto = 'team1bto', team2bto = 'team2bto'
                        if (
                            (
                                row.dynamicRowDataID[team1bto] !== undefined &&
                                Number(row.dynamicRowDataID[team1bto]) > 0
                            ) ||
                            (
                                Number(row.dynamicRowDataID[team2bto]) !== undefined &&
                                Number(row.dynamicRowDataID[team2bto]) > 0
                            )
                        ) {
                            TourCycleMinBTO.push({
                                'fromdt': get_YMD_from_DMY(row['fromdt']),
                                'todt': get_YMD_from_DMY(row['todt']),
                                'team1bto': Number(row.dynamicRowDataID[team1bto]),
                                'team2bto': Number(row.dynamicRowDataID[team2bto])
                            })
                        }
                    })
                    // console.log("TourCycleMinBTO",TourCycleMinBTO);
                }
                const reqData = {
                    Op: 'SaveTour',
                    EditSrNo: this.state.formDataTour.EditSrNo,
                    TourName: this.state.formDataTour.txtTourName,
                    Team1BTO: this.state.formDataTour.txtTeam1BTO,
                    Team2BTO: this.state.formDataTour.txtTeam2BTO,
                    SelfRepurchase: this.state.formDataTour.txtSelfRepurchase,
                    FromDt: this.state.formDataTour.dtpFromDate,
                    ToDt: this.state.formDataTour.dtpToDate,
                    TourCycleMinBTO
                }
                fetchTour(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.setState({
                            dataLoadStatus: true
                        }, () => {
                            if (res.data.msgType === 'success') {
                                result = true
                                this.newTour()
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('saveTour', error)
                    })
            })
        }
    }

    newTour = () => {
        const oldEditSrNo = this.state.formDataTour.EditSrNo
        const EditSrNo = 0,
            EditTourName = '',
            txtTourName = '',
            txtTeam1BTO = 0,
            txtTeam2BTO = 0,
            txtSelfRepurchase = 0,
            dtpFromDate = get_YMD_from_SYS(new Date()),
            dtpToDate = get_YMD_from_SYS(new Date()),
            rows = [],
            rowsCount = 7
        this.setState({
            formDataTour: {
                ...this.state.formDataTour,
                EditSrNo,
                EditTourName,
                txtTourName,
                txtTeam1BTO,
                txtTeam2BTO,
                txtSelfRepurchase,
                dtpFromDate,
                dtpToDate
            },
            rows, rowsCount
        }, () => {
            if (oldEditSrNo > 0) {
                this.navigateTo('TourEntry')
            }
            document.getElementById('txtTourName').focus()
        })
    }

    onDeleteBtnClick = () => {
        if (Number(this.state.formDataTour.EditSrNo) > 0) {
            const EditSrNo = this.state.formDataTour.EditSrNo,
                EditTourName = this.state.formDataTour.EditTourName
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Tour : ' + EditTourName + '?'}
                    // alertMessage={}
                    handleOnClickYes={() => { return this.deleteTour(EditSrNo) }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } else {
            this.props.toastMsg("info", "There are no detail to delete")
        }
    }

    deleteTour = (EditSrNo) => {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                Op: 'DeleteTour',
                EditSrNo
            }
            fetchTour(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    this.setState({
                        dataLoadStatus: true
                    }, () => {
                        if (res.data.msgType === 'success') {
                            this.newTour()
                        }
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('deleteTour', error)
                })
        })
        return true
    }

    onClose = (link) => {
        this.navigateTo('TourList')
    }

    generateTourCycle = () => {
        if (this.state.formDataTour.dtpFromDate === undefined || this.state.formDataTour.dtpFromDate.length <= 0) {
            this.props.toastErrorMsg('Enter From Date', 'dtpFromDate')
        } else if (this.state.formDataTour.dtpToDate === undefined || this.state.formDataTour.dtpToDate.length <= 0) {
            this.props.toastErrorMsg('Enter To Date', 'dtpToDate')
        } else {
            let cycleInfo = [], columns = this.getColumnList(),
                fromdt = this.state.formDataTour.dtpFromDate,
                todt = this.state.formDataTour.dtpToDate
            let startDt = get_DayMonthYear_of_Given_YMD_Date(fromdt)
            let endDt = get_DayMonthYear_of_Given_YMD_Date(todt)
            let previousToDate = '',
                lastDtofStartDtInfo = getLastDayofMonthofGivenDate(fromdt),
                previousToDateInfo = 0,
                curFromDt = '',
                displayToDate = '',
                curFromDt_Day_Month_Year = ''

            for (let index = startDt.intMonth; index <= endDt.intMonth; index++) {
                if (index == startDt.intMonth) {
                    if (startDt.intDay <= 15) {
                        displayToDate = startDt.intYear + '-' + getTowDigits_DayORMonth(startDt.intMonth) + "-15"
                        cycleInfo.push({
                            'fromdt': get_DMY_from_YMD(fromdt),
                            'todt': get_DMY_from_YMD(displayToDate),
                            'no': Number(cycleInfo.length) + 1,
                            'dynamicRowDataID': {
                                'team1bto': '',
                                'team2bto': ''
                            }
                        })
                        previousToDate = displayToDate
                    } else {
                        cycleInfo.push({
                            'fromdt': get_DMY_from_YMD(fromdt),
                            'todt': get_DMY_from_YMD(lastDtofStartDtInfo),
                            'no': Number(cycleInfo.length) + 1,
                            'dynamicRowDataID': {
                                'team1bto': '',
                                'team2bto': ''
                            }
                        })
                        previousToDate = lastDtofStartDtInfo
                    }
                } else {
                    curFromDt = get_NextDate_of_Given_YMD_Date(previousToDate)
                    curFromDt_Day_Month_Year = get_DayMonthYear_of_Given_YMD_Date(curFromDt)
                    if (index === endDt.intMonth) {
                        if (endDt.intDay <= 15) {
                            displayToDate = endDt.intYear + "-" + getTowDigits_DayORMonth(endDt.intMonth) + "-" + getTowDigits_DayORMonth(15) //endDt.intDay
                            cycleInfo.push({
                                'fromdt': get_DMY_from_YMD(curFromDt),
                                'todt': get_DMY_from_YMD(displayToDate),
                                'no': Number(cycleInfo.length) + 1,
                                'dynamicRowDataID': {
                                    'team1bto': '',
                                    'team2bto': ''
                                }
                            })
                            previousToDate = displayToDate
                        } else {
                            displayToDate = endDt.intYear + '-' + getTowDigits_DayORMonth(endDt.intMonth) + "-15"
                            cycleInfo.push({
                                'fromdt': get_DMY_from_YMD(curFromDt),
                                'todt': get_DMY_from_YMD(displayToDate),
                                'no': Number(cycleInfo.length) + 1,
                                'dynamicRowDataID': {
                                    'team1bto': '',
                                    'team2bto': ''
                                }
                            })
                            previousToDate = displayToDate
                        }
                        curFromDt = get_NextDate_of_Given_YMD_Date(previousToDate)
                        if (endDt.intDay > 15) {
                            displayToDate = endDt.intYear + "-" + getTowDigits_DayORMonth(endDt.intMonth) + "-" + getTowDigits_DayORMonth(endDt.intDay)
                            cycleInfo.push({
                                'fromdt': get_DMY_from_YMD(curFromDt),
                                'todt': get_DMY_from_YMD(displayToDate),
                                'no': Number(cycleInfo.length) + 1,
                                'dynamicRowDataID': {
                                    'team1bto': '',
                                    'team2bto': ''
                                }
                            })
                            previousToDate = displayToDate
                        }
                    } else {
                        if (curFromDt_Day_Month_Year.intDay <= 15) {
                            displayToDate = curFromDt_Day_Month_Year.intYear + '-' + getTowDigits_DayORMonth(curFromDt_Day_Month_Year.intMonth) + "-15"
                            cycleInfo.push({
                                'fromdt': get_DMY_from_YMD(curFromDt),
                                'todt': get_DMY_from_YMD(displayToDate),
                                'no': Number(cycleInfo.length) + 1,
                                'dynamicRowDataID': {
                                    'team1bto': '',
                                    'team2bto': ''
                                }
                            })
                            previousToDate = displayToDate
                        } else {
                            cycleInfo.push({
                                'fromdt': get_DMY_from_YMD(curFromDt),
                                'todt': get_DMY_from_YMD(getLastDayofMonthofGivenDate(curFromDt)),
                                'no': Number(cycleInfo.length) + 1,
                                'dynamicRowDataID': {
                                    'team1bto': '',
                                    'team2bto': ''
                                }
                            })
                            previousToDate = getLastDayofMonthofGivenDate(curFromDt)
                        }
                        curFromDt = get_NextDate_of_Given_YMD_Date(previousToDate)
                        curFromDt_Day_Month_Year = get_DayMonthYear_of_Given_YMD_Date(curFromDt)
                        if (curFromDt_Day_Month_Year.intDay > 15) {
                            displayToDate = getLastDayofMonthofGivenDate(curFromDt)
                            cycleInfo.push({
                                'fromdt': get_DMY_from_YMD(curFromDt),
                                'todt': get_DMY_from_YMD(displayToDate),
                                'no': Number(cycleInfo.length) + 1,
                                'dynamicRowDataID': {
                                    'team1bto': '',
                                    'team2bto': ''
                                }
                            })
                            previousToDate = displayToDate
                        }
                    }
                }
                previousToDateInfo = get_DayMonthYear_of_Given_YMD_Date(previousToDate)
                index = previousToDateInfo.intMonth
            }
            this.setState({
                rows: cycleInfo,
                rowsCount: cycleInfo.length,
                columns,
            })
            // console.log("cycleInfo", cycleInfo);
        }
    }
    //#endregion */

    render() {
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <br />
                <form>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item className="pageTitle" xs={12} lg={12}>
                            <label htmlFor="Title" className='pageTitleText'>Tour</label>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtTourName'
                                        label='Tour Name'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formDataTour.txtTourName}
                                        width='500'
                                        maxLength='100'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtTeam1BTO'
                                        label='Team 1 BTO'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formDataTour.txtTeam1BTO}
                                        width={150}
                                        maxLength='150'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtTeam2BTO'
                                        label='Team 2 BTO'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formDataTour.txtTeam2BTO}
                                        width={150}
                                        maxLength='150'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtSelfRepurchase'
                                        label='Self Repurchase'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formDataTour.txtSelfRepurchase}
                                        width={150}
                                        maxLength='150'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtDtpDOB
                                        id='dtpFromDate'
                                        label='From Date'
                                        width='150'
                                        handleOnChange={this.handleOnChangeTrim}
                                        value={this.state.formDataTour.dtpFromDate}
                                        defaultValue={this.state.formDataTour.dtpFromDate}
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtDtpDOB
                                        id='dtpToDate'
                                        label='To Date'
                                        width='150'
                                        handleOnChange={this.handleOnChangeTrim}
                                        defaultValue={this.state.formDataTour.dtpToDate}
                                        value={this.state.formDataTour.dtpToDate}
                                    // onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtBtn label='Generate Tour Cycle' onClick={this.generateTourCycle} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='Save' onClick={() => { this.saveTour() }} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='New' onClick={this.newTour} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='Delete' onClick={this.onDeleteBtnClick} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='Close' onClick={this.onClose} width={this.state.cWidth} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={this.state.rows}
                    rowsCount={this.state.rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(TourEntry))