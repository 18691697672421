import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import TableList from './CustomTool/TableList'
import { fetchSales, apiURL } from "./API/API";
import { Grid, Box } from "@material-ui/core";
import Progress from './CustomTool/Progress'
import { Delete, Edit, Print } from '@material-ui/icons'
import { get_YMD_from_DMY, get_YMD_from_SYS,getIndianFormatedNumber } from './SystemUtility/SystemUtility'
import SalesEntry from './SalesEntry'
import SalesDelete from './SalesDelete'
import CtDtpDOB from './CustomTool/CtDtpDOB'
import CtBtn from './CustomTool/CtBtn'
import CtTxt from './CustomTool/CtTxt'
import SalesMultiplePrint from './SalesMultiplePrint'
class Sales extends Component {

    state = {
        cWidth: 200,
        dataLoadStatus: false,
        formData: {
            dtpFromDate: get_YMD_from_SYS(new Date()),
            dtpToDate: get_YMD_from_SYS(new Date())
        },
        columns: [
            { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            { id: 'SrNo', label: 'Sr. No.', align: 'center' },
            { id: 'Date', label: 'Date' },
            { id: 'StrBillNo', label: 'Bill No.' },
            { id: 'BuyerID', label: 'Buyer ID' },
            { id: 'BuyerName', label: 'Buyer Name' },
            { id: 'ActivationKitName', label: 'Activation Kit Name' },
            // { id: 'ServiceID', label: 'Service ID' },
            // { id: 'ServicePersonName', label: 'Service Person Name' },
            // { id: 'TotalBasicAmt', label: 'Basic Amount', align: 'right' },
            // { id: 'TotalDiscountAmt', label: 'Discount Amount', align: 'right' },            
            { id: 'NetAmt', label: 'Net Amount', align: 'right', format: (value) => getIndianFormatedNumber(value) },
            { id: 'WalletAmount', label: 'Wallet Amount', align: 'right' },
            { id: 'FinalAmount', label: 'Final Amount', align: 'right', format: (value) => getIndianFormatedNumber(value) },
            { id: 'TotalSalesBTO', label: 'Total BTO', align: 'right' },
            { id: 'IntAcYear', label: 'Year', align: 'right', hide: 'true' },
            { id: 'BillNo', label: 'Bill No.', hide: 'true' },
            { id: 'TaxableAmt', label: 'Taxable Amount', align: 'right', hide: 'true' },
            { id: 'CGST', label: 'CGST', align: 'right', hide: 'true' },
            { id: 'SGST', label: 'SGST', align: 'right', hide: 'true' },
            { id: 'IGST', label: 'IGST', align: 'right', hide: 'true' },
            { id: 'GrandTotal', label: 'Grand Total', align: 'right', hide: 'true' },
            { id: 'RoundOff', label: 'Round Off', align: 'right', hide: 'true' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 5,
        actionList: [
            {
                name: 'Edit Sales',
                icon: <Edit />,
                link: 'Sales:',
                actionType: 'AlertResponsive'
            },
            {
                name: 'Delete Sales',
                icon: <Delete />,
                link: 'DelSales:',
                actionType: 'AlertResponsive'
            },
            {
                name: 'Print Bill',
                icon: <Print />,
                link: 'PrintBill:',
                actionType: 'AlertResponsive'
            },
        ],
        searchData: {
            BillNo: '', BuyerID:'', BuyerName:''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,

        keyColumn: [
            'BillNo', 'Date', 'StrBillNo', 'IntAcYear'
        ],

        ARD: null,
        txtTotalSales: 0,
        txtTotalBTO: 0,
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "SalesList" })
        this.setSearchColumns()
        this.loadSalesList()
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    ((this.state.searchData.BillNo.length === 0 || row['StrBillNo'].toString().includes(this.state.searchData.BillNo))) && ((this.state.searchData.BuyerID.length === 0 || row['BuyerID'].toString().includes(this.state.searchData.BuyerID))) && ((this.state.searchData.BuyerName.length === 0 || row['BuyerName'].toString().includes(this.state.searchData.BuyerName)))
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleARDonActionClick = (values) => {
        let EditBillNo = values[0].value,
            EditDate = get_YMD_from_DMY(values[1].value),
            BillNo = values[2].value.replace("/", "-"),
            PrintBillNo = values[2].value,
            EditAcYear = values[3].value,
            actionName = values[0].actionName,
            EditNewSrNo = EditBillNo + ":" + EditDate + ":" + BillNo + ":" + EditAcYear
        if (actionName === 'Edit Sales') {
            this.navigateTo('SalesEntry:' + EditNewSrNo)
        } else if (actionName === 'Delete Sales') {
            // EditDate = values[1].value
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <SalesDelete
                    EditBillNo={EditBillNo}
                    BillNo={BillNo}
                    EditAcYear={EditAcYear}
                    onDeleteSuccess={this.loadSalesList}
                />
                this.setState({ ARD })
            })
        } else if (actionName == "Print Bill") {
            this.printBill(PrintBillNo, EditAcYear)
        }
    }

    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }

    handleOnAutoCompleteInputChange = (e) => {
        if (this.state.formData[e.target.id] === null && e.target.value.length > 0) {
            this.setState({
                formData: { ...this.state.formData, [e.target.name]: e.target.value }
            }, () => {
                // console.log('this.state.formData', this.state.formData)
            })
        }
    }

    handleOnSearchInputChange = (e) => {
        // console.log('handleOnSearchInputChange', [e.target.name], e.target.value, this.state.searchData);
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
            // console.log(this.state.searchData)
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {
                cType: 'Lbl',
                label: 'Sr No.',
                width: '50',
            },
            {
                cType: 'Lbl',
                label: 'Date',
                width: '50',
            },
            {
                cType: 'CtTxt',
                id: 'BillNo',
                label: 'Bill No',
                value: this.state.searchData.BillNo,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'BuyerID',
                label: 'Buyer ID',
                value: this.state.searchData.BuyerID,
                handleOnChange: this.handleOnSearchInputChange,
                width: '80',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'BuyerName',
                label: 'Buyer Name',
                value: this.state.searchData.BuyerName,
                handleOnChange: this.handleOnSearchInputChange,
                width: '150',
                maxLength: '100'
            },            
            {
                cType: 'Lbl',
                label: 'Activation Kit Name',
                width: '50',
            },                        
            {
                cType: 'Lbl',
                label: 'Net Amount',
                width: '50',
            },
            {
                cType: 'Lbl',
                label: 'Wallet Amount',
                width: '50',
            },
            {
                cType: 'Lbl',
                label: 'Final Amount',
                width: '50',
            },
            {
                cType: 'Lbl',
                label: 'Total BTO',
                width: '50',
            }
        )
        this.setState({ searchColumns })
    }

    loadSalesList = () => {
        this.setState({ dataLoadStatus: false }, () => {
            let FromDate = this.state.formData.dtpFromDate,
                ToDate = this.state.formData.dtpToDate
            let rootmember = ''
            if (
                this.props.match.params.rootmember &&
                this.props.match.params.rootmember.length > 0 &&
                Number(this.props.match.params.rootmember.substring(1)) > 99999
            ) {
                rootmember = Number(this.props.match.params.rootmember.substring(1))
            } else if (this.props.rootmember && this.props.rootmember.length > 0 && Number(this.props.rootmember) > 99999) {
                rootmember = this.props.rootmember
            }
            const reqData = {
                Op: 'SalesList',
                FromDate,
                ToDate,
                rootmember
            }
            fetchSales(reqData)
                .then(res => {
                    const rows = res.data.SalesList
                    const rowsCount = rows.length
                    const TotalSalesNet = res.data.SalesTotalNet
                    const SalesTotalBTO = res.data.SalesTotalBTO
                    this.setState({ rows, rowsCount, dataLoadStatus: true, txtTotalSales: TotalSalesNet,
                        txtTotalBTO: SalesTotalBTO })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in loadSalesList. ' + error)
                })
        })
    }

    onNewEntryClick = () => {
        this.navigateTo('SalesEntry')
    }

    printBill = (StrBillNo, AcYear) => {
        const fileName = StrBillNo.replace(/\//g, '-') + '.pdf'
        StrBillNo = StrBillNo.replace("/", '.')
        let reqData = [
            { name: 'Op', value: 'OpenReportFromList' },
            { name: 'StrBillNo', value: StrBillNo },
            { name: 'AcYear', value: Number(AcYear) },
            { name: 'fileName', value: fileName },
        ]

        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", apiURL + 'salesprint.php');

        form.setAttribute("target", "view");

        reqData.forEach((data) => {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", data.name);
            hiddenField.setAttribute("value", data.value);
            form.appendChild(hiddenField);
            document.body.appendChild(form);
        })

        window.open('', 'view');

        form.submit();
        this.props.updateProcessing('success', 'PDF Open in next Tab.')
    }

    getActionListComponent = () => {
        if (this.props.privilegesList.privsalesrwm === "W" || this.props.privilegesList.privsalesrwm === "R") {
            return [this.state.actionList[0], this.state.actionList[2]]
        } else if (this.props.privilegesList.privsalesrwm === "M") {
            return this.state.actionList
        } else {
            return []
        }
    }

    getNewIconComponent = () => {
        if (this.props.privilegesList.privsalesrwm === "W" || this.props.privilegesList.privsalesrwm === "M") {
            return this.onNewEntryClick
        } else {
            return null
        }
    }

    openSelector = ()=>{
        let ARD = null
        this.setState({ ARD }, () => {
            ARD = <SalesMultiplePrint />
            this.setState({ ARD })
        })
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <form>
                    <br />
                    <Grid container direction="row" justify="left" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtDtpDOB
                                id='dtpFromDate'
                                label='From Date'
                                width='150'
                                handleOnChange={this.handleOnChangeTrim}
                                defaultValue={this.state.formData.dtpFromDate}
                                value={this.state.formData.dtpFromDate}
                                onKeyDown={this.props.onKeyDown}
                            // disabled={this.state.disabledProfileData.DOBDS}
                            />
                        </Grid>
                        <Grid item>
                            <CtDtpDOB
                                id='dtpToDate'
                                label='To Date'
                                width='150'
                                handleOnChange={this.handleOnChangeTrim}
                                defaultValue={this.state.formData.dtpToDate}
                                value={this.state.formData.dtpToDate}
                                onKeyDown={this.props.onKeyDown}
                            // disabled={this.state.disabledProfileData.DOBDS}
                            />
                        </Grid>
                        <Grid item>
                            <CtBtn label='Load' width={this.state.cWidth - 150} onClick={this.loadSalesList} />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtTotalSales'
                                label='TotalSales'
                                handleOnChange={this.handleOnChange}
                                value={this.state.txtTotalSales}
                                width={200}
                                maxLength='20'
                                onKeyDown={this.props.onKeyDown}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtTotalBTO'
                                label='Total BTO'
                                handleOnChange={this.handleOnChange}
                                value={this.state.txtTotalBTO}
                                width={200}
                                maxLength='20'
                                onKeyDown={this.props.onKeyDown}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item>
                            <CtBtn label='Multiple Bill Print' width={200} onClick={this.openSelector} />
                        </Grid>                        
                    </Grid>
                </form>
                <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.getActionListComponent()}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={this.getNewIconComponent()}
                    // onNewEntryClick={()=>{this.navigateTo('SalesEntry')}}
                    toolTipNewEntry='New Sales Entry'
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(Sales))
