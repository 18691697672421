import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        // marginTop: theme.spacing(1),
        minWidth: 120,
    }
}));

const CtCmb = ({
    id, label, items, value, width, handleOnChange, disabled,
    onKeyDown, defaultAction, validateInput
}) => {
    const classes = useStyles();
    const [cmbText, setCmbText] = React.useState('');

    const handleChange = (event) => {
        setCmbText(event.target.value);
    };
    const disabledStyle = { disabled: true }
    const itemList = items.map(item => {
        const itemDisabled=item.disabled && item.disabled===true?true:false
        return (
            <MenuItem value={item.name} disabled={itemDisabled} key={item.name}>{item.name}</MenuItem>
        )
    })

    const handleOnKeyDown = (onKeyDown) ? ((event) => { onKeyDown(event, 1, defaultAction, validateInput) }) : (null)

    const useWidthStyle = { width: width + 'px' }
    return (
        <FormControl className={classes.formControl} >
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <Select
                labelId={`${id}-label`}
                id={id}
                name={id}
                value={value}
                // onChange={handleChange}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                style={width > 0 ? useWidthStyle : {}}
                // disabled={disabled}
                readOnly={disabled}
            >
                {itemList}
            </Select>
        </FormControl>
    )
}

export default CtCmb