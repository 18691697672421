import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import TableList from './CustomTool/TableList'
import { fetchMember } from "./API/API";
import { Grid } from "@material-ui/core";
import Progress from './CustomTool/Progress'
import CtDtpDOB from './CustomTool/CtDtpDOB'
import CtBtn from './CustomTool/CtBtn'
import { get_YMD_from_SYS, getIndianFormatedNumber } from './SystemUtility/SystemUtility'
import CtTxt from './CustomTool/CtTxt'

class TurnoverCommission extends Component {

    state = {
        dataLoadStatus: false,
        formData: {
            dtpFromDate: get_YMD_from_SYS(new Date()),
            dtpToDate: get_YMD_from_SYS(new Date())
        },
        columns: [
            { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            // { id: 'SrNo', label: 'Sr. No.', align: 'center' },
            { id: 'memberid', label: 'Member ID' },
            { id: 'fromdt', label: 'From Date' },
            { id: 'todt', label: 'To Date' },
            { id: 'team1bto', label: 'Team 1 BTO', align: 'right' },
            { id: 'team2bto', label: 'Team 2 BTO', align: 'right' },
            { id: 'turnovercommission', label: 'Turnover Commission', minWidth: 70, align: 'right', format: (value) => getIndianFormatedNumber(value) },
            { id: 'totalbto', label: 'Total BTO', align: 'right' },
            { id: 'turnoverpercentage', label: 'Turnover %', align: 'right' },
            { id: 'powerleg', label: 'Power leg', align: 'right' },
            { id: 'powerlegcfamout', label: 'C/F Amount', align: 'right' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 10,
        searchData: {
            memberid: ''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        ARD: null,
        txtTotalTCAmt: 0,
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.setSearchColumns()
        this.setState({
            dataLoadStatus: true
        })
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    ((this.state.searchData.memberid.length === 0 || row['memberid'].toString().includes(this.state.searchData.memberid)))
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }

    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
        })
    }
    //#endregion

    //#region Function
    validateDate = () => {
        let validateDate = false

        if (this.state.formData.dtpFromDate === undefined || this.state.formData.dtpFromDate.length <= 0) {
            this.props.toastErrorMsg('Enter From Date', 'dtpFromDate')
        } else if (this.state.formData.dtpToDate === undefined || this.state.formData.dtpToDate.length <= 0) {
            this.props.toastErrorMsg('Enter To Date', 'dtpToDate')
        } else {
            validateDate = true
        }
        return validateDate
    }

    loadTurnoverCommissionList = () => {
        if (this.validateDate()) {
            let rootmember = ''
            if (
                this.props.match.params.rootmember &&
                this.props.match.params.rootmember.length > 0 &&
                Number(this.props.match.params.rootmember.substring(1)) > 99999
            ) {
                rootmember = Number(this.props.match.params.rootmember.substring(1))
            } else if (this.props.rootmember && this.props.rootmember.length > 0 && Number(this.props.rootmember) > 99999) {
                rootmember = this.props.rootmember
            }
            this.setState({ dataLoadStatus: false }, () => {
                let FromDt = this.state.formData.dtpFromDate
                let ToDt = this.state.formData.dtpToDate
                const reqData = {
                    Op: 'TurnoverCommissionList',
                    FromDt,
                    ToDt,
                    rootmember
                }
                fetchMember(reqData)
                    .then(res => {
                        const rows = res.data.turnovercommissionlist
                        const rowsCount = rows.length
                        const txtTotalTCAmt = res.data.turnovercommissionsum
                        this.setState({ rows, rowsCount, dataLoadStatus: true, txtTotalTCAmt })
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in loadTurnoverCommissionList. ' + error)
                    })
            })
        }
    }

    setTurnoverCommission = () => {
        if (this.validateDate()) {
            let FromDt = this.state.formData.dtpFromDate
            let ToDt = this.state.formData.dtpToDate
            this.props.notifyProcessing()
            const reqData = {
                Op: 'SetTurnoverCommission',
                FromDt,
                ToDt
            }
            fetchMember(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    const rows = res.data.turnovercommissionlist
                    const rowsCount = rows !== undefined ? rows.length : 0
                    this.setState({
                        rows, rowsCount, dataLoadStatus: true
                    })
                })
        }
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {
                cType: 'CtTxt',
                id: 'memberid',
                label: 'Member ID',
                value: this.state.searchData.memberid,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            },
            {
                cType: 'Lbl',
                label: 'From date',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'To date',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Team 1 BTO',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Team 2 BTO',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Turnover Commission',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Total BTO',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Turnover %',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Powe leg',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'C/F Amount',
                // width: '100',
            },
        )
        this.setState({ searchColumns })
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>Turnover Commission List</label>
                    </Grid>
                </Grid>
                <form>
                    <br />
                    <Grid container direction="row" justify="left" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtDtpDOB
                                id='dtpFromDate'
                                label='From Date'
                                width='150'
                                handleOnChange={this.handleOnChangeTrim}
                                defaultValue={this.state.formData.dtpFromDate}
                                value={this.state.formData.dtpFromDate}
                                onKeyDown={this.props.onKeyDown}
                            // disabled={this.state.disabledProfileData.DOBDS}
                            />
                        </Grid>
                        <Grid item>
                            <CtDtpDOB
                                id='dtpToDate'
                                label='To Date'
                                width='150'
                                handleOnChange={this.handleOnChangeTrim}
                                defaultValue={this.state.formData.dtpToDate}
                                value={this.state.formData.dtpToDate}
                                onKeyDown={this.props.onKeyDown}
                            // disabled={this.state.disabledProfileData.DOBDS}
                            />
                        </Grid>
                        {
                            (this.props.privilegesList.privturnovercom && this.props.privilegesList.privturnovercom === "A") ?
                                <Grid item>
                                    <CtBtn label='Generate Turnover Income' width={300} onClick={this.setTurnoverCommission} />
                                </Grid> : ""
                        }
                        <Grid item>
                            <CtBtn label='Load Turnover Income' width={300} onClick={this.loadTurnoverCommissionList} />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtTotalTCAmt'
                                label='Turnover Commission'
                                handleOnChange={this.handleOnChange}
                                value={this.state.txtTotalTCAmt}
                                width={200}
                                maxLength='20'
                                onKeyDown={this.props.onKeyDown}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                </form>
                <br />
                <TableList
                    columns={this.state.columns}
                    // counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                />
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(TurnoverCommission))
