import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import TableList from './CustomTool/TableList'
import { fetchMember } from "./API/API";
import { Grid } from "@material-ui/core";
import Progress from './CustomTool/Progress'
import CtDtpDOB from './CustomTool/CtDtpDOB'
import CtBtn from './CustomTool/CtBtn'
import { getIndianFormatedNumber, get_YMD_from_SYS } from './SystemUtility/SystemUtility'
import CtTxt from './CustomTool/CtTxt'

class DirectReferal extends Component {

    state = {
        dataLoadStatus: false,
        formData: {
            dtpFromDate: get_YMD_from_SYS(new Date()),
            dtpToDate: get_YMD_from_SYS(new Date())
        },
        columns: [
            { id: 'Action', name: 'Search', label: 'Action', width: '20px' },
            { id: 'trandt', label: 'Transaction Date', width: '20px' },
            /* { id: 'memberid', label: 'Member ID', align: 'center', hide:'true' },
            { id: 'membername', label: 'Name', align: 'left', hide:'true' },
            { id: 'salesbto', label: 'Sales BTO', align: 'right', hide:'true' }, */
            { id: 'directreferalincome', label: 'Direct Referral Income', align: 'right', width: '20px', format: (value) => getIndianFormatedNumber(value) },
            { id: 'referenceid', label: 'Member ID', align: 'center', width: '100px' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 10,
        searchData: {
            referenceid: ''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        ARD: null,
        txtTotalDRAmt:0,
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.setSearchColumns()
        this.setState({
            dataLoadStatus: true
        })
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    ((this.state.searchData.referenceid.length === 0 || row['referenceid'].toString().includes(this.state.searchData.referenceid)))
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }

    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
        })
    }
    //#endregion

    //#region Function
    validateDate = () => {
        let validateDate = false

        if (this.state.formData.dtpFromDate === undefined || this.state.formData.dtpFromDate.length <= 0) {
            this.props.toastErrorMsg('Enter From Date', 'dtpFromDate')
        } else if (this.state.formData.dtpToDate === undefined || this.state.formData.dtpToDate.length <= 0) {
            this.props.toastErrorMsg('Enter To Date', 'dtpToDate')
        } else {
            validateDate = true
        }
        return validateDate
    }

    loadDirectReferalList = () => {
        if (this.validateDate()) {
            this.setState({ dataLoadStatus: false }, () => {
                let FromDt = this.state.formData.dtpFromDate
                let ToDt = this.state.formData.dtpToDate
                let rootmember = ''
                if (
                    this.props.match.params.rootmember &&
                    this.props.match.params.rootmember.length > 0 &&
                    Number(this.props.match.params.rootmember.substring(1)) > 99999
                ) {
                    rootmember = Number(this.props.match.params.rootmember.substring(1))
                } else if (this.props.rootmember && this.props.rootmember.length > 0 && Number(this.props.rootmember) > 99999) {
                    rootmember = this.props.rootmember
                }
                const reqData = {
                    Op: 'ListDirectReferal',
                    FromDt,
                    ToDt,
                    rootmember
                }
                fetchMember(reqData)
                    .then(res => {
                        const rows = res.data.ListDirectReferal
                        const rowsCount = rows.length
                        const txtTotalDRAmt = res.data.TotalDirectReferalIncome
                        this.setState({ rows, rowsCount, dataLoadStatus: true, txtTotalDRAmt, })
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in loadDirectReferalList. ' + error)
                    })
            })
        }
    }

    setDirectReferalIncome = () => {
        if (this.validateDate()) {
            let FromDt = this.state.formData.dtpFromDate
            let ToDt = this.state.formData.dtpToDate
            this.props.notifyProcessing()
            const reqData = {
                Op: 'SetDirectReferalIncome',
                FromDt,
                ToDt
            }
            fetchMember(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    const rows = res.data.ListDirectReferal
                    const rowsCount = rows !== undefined ? rows.length : 0
                    this.setState({
                        rows, rowsCount, dataLoadStatus: true
                    })
                })
        }
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {
                cType: 'Lbl',
                label: 'Transaction Date',
                width: '50',
            }, {
                cType: 'Lbl',
                label: 'Direct Referal Income',
                width: '50',
            },
            {
                cType: 'CtTxt',
                id: 'referenceid',
                label: 'Member ID',
                value: this.state.searchData.referenceid,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            }
        )
        this.setState({ searchColumns })
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>Direct Reference List</label>
                    </Grid>
                </Grid>
                <form>
                    {/* <br /> */}
                    <Grid container direction="row" justify="left" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtDtpDOB
                                id='dtpFromDate'
                                label='From Date'
                                width='150'
                                handleOnChange={this.handleOnChangeTrim}
                                defaultValue={this.state.formData.dtpFromDate}
                                value={this.state.formData.dtpFromDate}
                                onKeyDown={this.props.onKeyDown}
                            // disabled={this.state.disabledProfileData.DOBDS}
                            />
                        </Grid>
                        <Grid item>
                            <CtDtpDOB
                                id='dtpToDate'
                                label='To Date'
                                width='150'
                                handleOnChange={this.handleOnChangeTrim}
                                defaultValue={this.state.formData.dtpToDate}
                                value={this.state.formData.dtpToDate}
                                onKeyDown={this.props.onKeyDown}
                            // disabled={this.state.disabledProfileData.DOBDS}
                            />
                        </Grid>
                        {/* <Grid item>
                            <CtBtn label='Generate Direct Referal Income' width={320} onClick={this.setDirectReferalIncome} />
                        </Grid> */}
                        <Grid item>
                            <CtBtn label='Load Direct Referal Income' width={300} onClick={this.loadDirectReferalList} />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtTotalDRAmt'
                                label='Total Direct Referal Income'
                                handleOnChange={this.handleOnChange}
                                value={this.state.txtTotalDRAmt}
                                width={200}
                                maxLength='20'
                                onKeyDown={this.props.onKeyDown}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                </form>
                <br />
                <TableList
                    columns={this.state.columns}
                    // counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                />
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(DirectReferal))
