import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import Progress from './CustomTool/Progress'
import { Grid, Box } from '@material-ui/core'
import TableList from './CustomTool/TableList'
import {fetchOffer} from './API/API'
import { Delete, Edit } from '@material-ui/icons'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { getIndianFormatedNumber } from "./SystemUtility/SystemUtility";

class OfferList extends Component {

    state = {
        dataLoadStatus: false,
        columns: [
            { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            { id: 'no', label: 'Sr. No.', align: 'center' },
            { id: 'createdate', label: 'Create Date',hide: 'true' },
            { id: 'offername', label: 'Offer Name' },
            { id: 'mintotalamount', label: 'Minimum Total Amount', align: 'right', format: (value) => getIndianFormatedNumber(value) },
            { id: 'freemrp', label: 'Free MRP', align: 'right', hide:'true' },
            { id: 'startdate', label: 'Start Date', align: 'center' },
            { id: 'expirydate', label: 'Expiry Date', align: 'center' },
            { id: 'dispcommingsoon', label: 'Display Comming Soon', align: 'center',hide: 'true' },
            { id: 'offersrno', label: 'Offer Sr No', align: 'center',hide: 'true' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 5,
        actionList: [
            {
                name: 'Edit Offer',
                icon: <Edit />,
                link: 'Offer:',
                actionType: 'AlertResponsive'
            },
            {
                name: 'Delete Offer',
                icon: <Delete />,
                link: 'DelOffer:',
                actionType: 'AlertResponsive'
            },
        ],
        searchData: {
            offername:'',
            mintotalamount:'',
            freemrp:'',
            startdate:'',
            expirydate:''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        keyColumn: [
            'offersrno','offername'
        ],
        ARD: null,
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.setSearchColumns()
        this.loadOfferList()
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    ((this.state.searchData.offername.length === 0 || row['offername'].toString().includes(this.state.searchData.offername))) && ((this.state.searchData.mintotalamount.length === 0 || row['mintotalamount'].toString().includes(this.state.searchData.mintotalamount))) && ((this.state.searchData.freemrp.length === 0 || row['freemrp'].toString().includes(this.state.searchData.freemrp))) /* && ((this.state.searchData.startdate.length === 0 || row['startdate'].toString().includes(this.state.searchData.startdate))) && ((this.state.searchData.expirydate.length === 0 || row['expirydate'].toString().includes(this.state.searchData.expirydate)))*/
                ) {
                    searchRows.push(row)
                    // console.log('searchRows at push in loop', searchRows)
                }
            })
            const searchRowsCount = searchRows.length
            // console.log("searchRowsCount",searchRowsCount);
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
                // console.log('searchRows on end of Function', searchRows)
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleARDonActionClick = (values) => {
        const EditOfferSrNo = values[0].value,
            actionName = values[0].actionName,
            EditOfferName = values[1].value
        if (actionName === 'Edit Offer') {
            this.navigateTo('OfferEntry:' + EditOfferSrNo)
        } else if (actionName === 'Delete Offer') {
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Offer : ' + EditOfferName + '?'}
                    // alertMessage={}
                    handleOnClickYes={() => { return this.deleteOffer(EditOfferSrNo) }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }

    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {},{},
            {
                cType: 'CtTxt',
                id: 'offername',
                label: 'Offer Name',
                value: this.state.searchData.offername,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'mintotalamount',
                label: 'Minimum Total Amount',
                value: this.state.searchData.mintotalamount,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'freemrp',
                label: 'Free MRP',
                value: this.state.searchData.freemrp,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            }/*,
            {
                cType: 'CtTxt',
                id: 'startdate',
                label: 'Start Date',
                value: this.state.searchData.startdate,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'expirydate',
                label: 'Expiry Date',
                value: this.state.searchData.expirydate,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            }*/
        )
        this.setState({ searchColumns })
    }

    loadOfferList = () => {
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = { Op: 'OfferList' }
            fetchOffer(reqData)
                .then(res => {
                    const rows = res.data.offermasterList
                    const rowsCount = rows.length
                    this.setState({ rows, rowsCount, dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in loadOfferList. ' + error)
                })
        })
    }

    deleteOffer =(EditOfferSrNo)=>{
        if (EditOfferSrNo > 0){
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'DeleteOffer',
                    EditOfferSrNo: EditOfferSrNo,
                }
                fetchOffer(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.setState({
                            dataLoadStatus: true
                        }, () => {
                            if (res.data.msgType === 'success') {
                                this.loadOfferList()
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('deleteOffer', error)
                    })
            })
        }else{
            this.props.toastMsg("info", "There are no detail to delete")
        }
        
        return true
    }

    onNewEntryClick = () => {
        this.navigateTo('OfferEntry')
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' >Offer List</label>
                    </Grid>
                </Grid>
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={this.onNewEntryClick}
                    toolTipNewEntry='New Offer Entry'
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(OfferList))
