import React from 'react'
import Button from '@material-ui/core/Button';

const CtBtn = ({ label, disabled, onClick, width }) => {

    const useWidthStyle = width > 0 ? width+'px' : 'auto'
    const enableStyle = { backgroundColor: '#42AA46', color: '#ffffff', width: useWidthStyle }

    return (
        <Button
            variant="contained"
            disabled={disabled}
            style={disabled !== true ? enableStyle : {width:useWidthStyle}}
            onClick={onClick}
        >
            {label}
        </Button>
    )
}

export default CtBtn