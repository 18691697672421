import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import CtTextFieldPWD from './CustomTool/CtTxtPWD'
import CtBtn from './CustomTool/CtBtn'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Zoom } from 'react-toastify';
import { Error } from '@material-ui/icons';
import processing from '../gif/processing.gif'

const Msg = ({ MsgType, Message }) => (
    <div style={{ textAlign: 'center' }}>
        <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
            {MsgType === 'success' ? '' : <Error />}
            {Message && Message.length > 0 ? Message : 'Your Password is Re-Set Successfully'}
        </Grid>
    </div>
)

const ProcessingMsg = () => (
    <div style={{ textAlign: 'center' }}>
        <Grid container direction="row" justify="center" alignItems="center" spacing={1} style={{ fontSize: '16px' }}>
            <img src={processing} style={{ width: '100px', height: 'auto' }} alt='Processing' />&nbsp;&nbsp;&nbsp;<b>Please wait...</b>
        </Grid>

    </div>
)
class ReSetPassword extends Component {

    CtWidth = 250

    state = {
        reSetData: {
            Password: '',
            ConfirmPassword: '',
        }
    }

    notify1 = () => {
        const id = toast(<ProcessingMsg />, { autoClose: false });
        this.setState({
            toastId: id
        })
    }

    update1 = () => toast.update(this.state.toastId, {
        render: Msg,
        type: toast.TYPE.SUCCESS,
        autoClose: 2000
    });

    handleOnChange = (e) => {
        this.setState({
            reSetData: { ...this.state.reSetData, [e.target.name]: e.target.value }
        })
    }

    notify = () => {
        if (this.state.reSetData.Password.length <= 0) {
            toast.error(<Msg MsgType={'error'} Message={'Enter Password'} />)
            document.getElementById('Password').focus()
        } else if (this.state.reSetData.ConfirmPassword.length <= 0) {
            toast.error(<Msg MsgType={'error'} Message={'Enter Confirm Password'} />)
            document.getElementById('ConfirmPassword').focus()
        }
        else {
            toast.success(<Msg MsgType={'success'} />, {
                autoClose: 2000
            })
        }
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "ReSetPassword" })
        document.getElementById('Password').focus()
    }

    render() {
        return (
            <div className="ReSetPassword" >
                <ToastContainer
                    position="top-center"
                    autoClose={4000}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    transition={Zoom} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' >Re-Set Password</label>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                    <Grid item>
                        <CtTextFieldPWD
                            id='Password'
                            label='Password'
                            handleOnChange={this.handleOnChange}
                            width={this.CtWidth}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                    <Grid item>
                        <CtTextFieldPWD
                            id='ConfirmPassword'
                            label='Confirm Password'
                            handleOnChange={this.handleOnChange}
                            width={this.CtWidth}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                    <Grid item><CtBtn label='Set Password' onClick={this.notify} /></Grid>
                </Grid>
            </div >
        )
    }
}

export default ReSetPassword