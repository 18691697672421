import React, { Component } from 'react'
import { Grid, Box } from '@material-ui/core'
import { fetchMember } from './API/API'
import Progress from './CustomTool/Progress'
import HOCVerifyIsLogInUserOrAdmin from './HOC/HOCVerifyIsLogInUserOrAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import CtTxt from './CustomTool/CtTxt'
import CtBtn from './CustomTool/CtBtn'
import AlertResponsiveDialog from './CustomTool/AlertResponsiveDialog'

class BuyPin extends Component {

    state = {
        dataLoadStatus: false,
        cWidth: 180,
        UserID: 0,
        formData: {
            PurchaseQty: '',
        },
        Name: '',
        Scheme: '',
        WalletBalance: '',
        PinRate: '',
        FinalPinRate: '',
        MaxPurchasePinQty: '',
        alertBuyPinConfirm: null,
        alertBuyPinOTP: null,
        otpVerified: false,
        TxtOTP: '',
    }

    //#region Component
    componentWillMount() {
        const userID = Number(this.props.match.params.userID.substring(1))
        this.props.validateIsLogInUserOrAdmin(
            this.props.IsAdmin,
            this.props.setActiveLink,
            this.navigateTo,
            userID
        )
    }

    componentDidMount() {
        const UserID = Number(this.props.match.params.userID.substring(1))
        this.setState({ UserID }, () => {
            this.load_Scheme_WalletBalance()
        })
    }
    //#endregion

    //#region HandleOnChange
    handleOnChangeNum = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: Number(e.target.value) }
        })
    }

    handleOnOTPChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    load_Scheme_WalletBalance = () => {
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                'Op': 'GetBuyPinInfo',
                'UserID': this.state.UserID
            }
            fetchMember(reqData)
                .then(res => {
                    let dataLoadStatus = true,
                        Name = '',
                        Scheme = '',
                        WalletBalance = '0',
                        PinRate = '0',
                        FinalPinRate = '0',
                        PurchaseQty = '',
                        MaxPurchasePinQty = ''
                    if (res.data.msgType === 'success') {
                        Name = res.data.name
                        Scheme = res.data.scheme
                        WalletBalance = res.data.WalletBalance
                        PinRate = res.data.PinRate
                        FinalPinRate = res.data.FinalPinRate
                        MaxPurchasePinQty = res.data.MaxPurchasePinQty
                    }
                    this.setState({
                        dataLoadStatus,
                        Name, Scheme, WalletBalance, PinRate, FinalPinRate, MaxPurchasePinQty,
                        formData: { ...this.state.formData, PurchaseQty }
                    })
                })
                .catch(error => {
                    console.log('Unknown error occurred in load_Scheme_WalletBalance.', error)
                })
        })
    }

    validateInput = () => {
        let result = false
        if (Number(this.state.formData.PurchaseQty) <= 0) {
            this.props.toastErrorMsg('Purchase Quantity must be greater-than 0', 'PurchaseQty')
        }
        else if (Number(this.state.formData.PurchaseQty) > Number(this.state.MaxPurchasePinQty)) {
            this.props.toastErrorMsg('Purchase Quantity must be less or equal to ' + this.state.MaxPurchasePinQty, 'PurchaseQty')
        } else {
            result = true
        }
        return result
    }

    buyPinConfirmation = () => {
        this.setState({
            otpVerified: false,
        })
        if (this.validateInput()) {
            this.setState({ alertBuyPinConfirm: null }, () => {
                const alertBuyPinConfirm = <AlertResponsiveDialog
                    label={'Confirm To Purchase PIN'}
                    labelAgree={'Purchase PIN'}
                    labelDisagree={'Cancel'}
                    alertTitle={
                        'Do you want to Purchase ' + this.state.formData.PurchaseQty + ' PINs ?'
                    }
                    alertMessage={
                        <div style={{ color: '#000000' }}>
                            <p>Purchase Quantity : {this.state.formData.PurchaseQty}</p>
                            <p>Pin Rate : {this.state.FinalPinRate}</p>
                            <p>Pin Valuation : {this.getPinValuation()}</p>
                        </div>
                    }
                    handleOnClickYes={this.sendOTP}
                    defaultOpenDialog={true}
                />
                this.setState({ alertBuyPinConfirm })
            })
        }
    }

    getPinValuation = () => {
        return Number(this.state.formData.PurchaseQty) * Number(this.state.FinalPinRate)
    }

    sendOTP = () => {
        if (this.validateInput()) {
            this.props.notifyProcessing()
            let alertBuyPinOTP = null
            let reqData = {
                Op: 'SendOTPOnBuyPIN',
                ID: this.state.UserID,
            }
            fetchMember(reqData)
                .then(res => {
                    if (res.data.msgType == "success" && res.data.message == "Done") {
                        this.props.updateProcessing("success", "OTP is sent to your registered Mobile No. : "+res.data.mobnowithxx+". Please check")
                        this.setState({
                            alertBuyPinOTP,
                            dataLoadStatus: true,
                        }, () => {
                            alertBuyPinOTP = <AlertResponsiveDialog
                                label={'OTP To Purchase PIN'}
                                labelAgree={'Verify'}
                                labelDisagree={'Cancel'}
                                alertTitle={'OTP is sent to your registered Mobile No. : '+res.data.mobnowithxx+' Please check'}
                                alertMessage={
                                    <div style={{ color: '#000000' }}>
                                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={0} >
                                            <Grid item>
                                                <CtTxt
                                                    id='TxtOTP'
                                                    label='Enter OTP'
                                                    // value={this.state.TxtOTP}
                                                    handleOnChange={this.handleOnOTPChange}
                                                    width='200'
                                                    maxLength='6'
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                }
                                handleOnClickYes={this.verifyOTP}
                                defaultOpenDialog={true}
                                onYesClickCloseIfExeSuccessfully={true}
                            />
                            this.setState({ alertBuyPinOTP })
                        })
                    }
                })
                .catch(err => {
                    this.props.updateProcessing('error', 'Unknown error occured in sendOTP.\n\n' + err)
                })

        }
    }

    validateOTP = () => {
        let validateProfiledata = false
        this.setState({ otpVerified: false })
        if (this.state.TxtOTP.trim().length <= 0) {
            this.props.toastErrorMsg('Enter OTP', 'TxtOTP')
        } else {
            validateProfiledata = true
        }
        return validateProfiledata
    }

    verifyOTP = () => {
        if (this.validateOTP() === true) {
            this.props.notifyProcessing()
            let reqData = ""
            reqData = {
                Op: 'VerifyOTP',
                ID: this.state.UserID,
                OTP: this.state.TxtOTP,
            }
            fetchMember(reqData)
                .then(res => {
                    if (res.data.msgType == "success" && res.data.message == "OTP Verified.") {
                        this.props.updateProcessing('success', 'OTP Verified')
                        this.setState({ otpVerified: true,dataLoadStatus:true }, () => {
                            this.purchasePin()
                        })
                    } else {
                        this.props.updateProcessing('error', res.data.message)
                    }
                })
                .catch(error => {
                    this.props.updateProcessing('error', 'Unknown error occurred in verifyOTP. ' + error)
                })
        }
    }

    purchasePin = () => {
        this.setState({ dataLoadStatus: false, alertBuyPinOTP: null }, () => {
            // this.props.notifyProcessing()
            const reqData = {
                'Op': 'BuyPin',
                'UserID': this.state.UserID,
                'Scheme': this.state.Scheme,
                'PINCount': Number(this.state.formData.PurchaseQty),
                'WalletBalance': Number(this.state.WalletBalance),
                'PINValuation': Number(this.getPinValuation()),
                'AdditionalCharges': (
                    (Number(this.state.FinalPinRate) - Number(this.state.PinRate)) *
                    (Number(this.state.formData.PurchaseQty))
                ),
                'PinRate': Number(this.state.PinRate)
            }
            fetchMember(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    this.load_Scheme_WalletBalance()
                })
                .catch(error => {
                    this.props.updateProcessing('error', 'Unknown error occurred in purchasePin. ' +error)
                    console.log('Unknown error occurred in purchasePin.', error)
                })
        })
    }
    //#endregion

    render() {

        const disabledBuyPin = (
            this.state.Scheme.length > 0 &&
            Number(this.state.WalletBalance) > 0 &&
            Number(this.state.MaxPurchasePinQty) > 0 &&
            Number(this.state.formData.PurchaseQty) > 0 &&
            Number(this.state.FinalPinRate) > 0
        ) ? false : true

        const disabledPurchaseQty = (
            Number(this.state.MaxPurchasePinQty) > 0
        ) ? false : true

        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' >Purchase PIN</label><br />
                    </Grid>
                    <Grid item className='hc'
                        style={{ minWidth: '250px' }}
                    >Scheme : {this.state.Scheme}</Grid>
                    <Grid item className='hc'
                        style={{ minWidth: '250px' }}
                    >Wallet Balance : {this.state.WalletBalance}</Grid>
                    <Grid item className='hc'
                        style={{ minWidth: '250px' }}
                    >Max PIN can be Purchase : {this.state.MaxPurchasePinQty}</Grid>
                    <Grid item>
                        <CtTxt
                            id='PurchaseQty'
                            label='Purchase Quantity'
                            value={this.state.formData.PurchaseQty}
                            handleOnChange={this.handleOnChangeNum}
                            width={this.state.cWidth}
                            maxLength={6}
                            disabled={disabledPurchaseQty}
                        />
                    </Grid>
                    <Grid item className='hc'
                    // style={{ minWidth: '250px' }}
                    >PIN Valuation : {this.getPinValuation()}</Grid>
                    <Grid item>
                        <CtBtn
                            label='Purchase PIN'
                            onClick={this.buyPinConfirmation}
                            disabled={disabledBuyPin}
                        />
                    </Grid>
                    <Grid item>
                        <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                            {this.state.alertBuyPinConfirm}
                        </Box>
                        <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                            {this.state.alertBuyPinOTP}
                        </Box>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsLogInUserOrAdmin(HoCtToastContainer(BuyPin))