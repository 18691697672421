import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const Progress = ({ color, display }) => {
    const classes = useStyles();
    const curProgress = (display == true) ? (
        color && color != '' ?
            <LinearProgress color={color} /> : <LinearProgress />
    ) : ''
    return (
        <div className={classes.root}>
            {curProgress}
        </div>
    );
}
export default Progress