import React, { Component } from 'react'
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@material-ui/core'

function Item(props) {
    // console.log(props.item.offeritems)
    // console.log(props.item.offeritems.length)
    let offerItemsInfo = props.item.offeritems
    // console.log('offerItemsInfo.length',offerItemsInfo.length)
    return (
        <Paper style={{padding:'16px', backgroundColor:'#00A0E3', color:'#fff'}}>
        {/* style={{padding:'16px', backgroundColor:'#F9CAA1', color:'#62350B'}} */}
            {/* <img
                src={props.item.PhotoPath}
                style={{
                    display: 'block',
                    height: 'auto',
                    width: '90vw',
                    maxHeight: '80vh',
                    margin: '0 auto'
                }}
            /> */}
            <span style={{fontWeight:'bolder', fontSize:'21px'}}>{props.item.offername}</span>
            <p>Get Free Products</p>
            {
                props.item.offeritems.length>0 ?
                    props.item.offeritems.map((item)=>{
                        return(<span><b>{Number(item.qty)} {item.productname}</b><br /></span>)
                    })
                : ''
            }
            <p>On minimum purchase of {Number(props.item.mintotalamount)} DP</p>
        </Paper>
    )
}

class OfferSlider extends Component {
    render() {
        return (
            <Carousel
                animation='fade'
            >
                {
                    this.props.items.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>
        )
    }
}
export default OfferSlider
