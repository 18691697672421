import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { Grid } from '@material-ui/core'
import CtTxt from './CustomTool/CtTxt'
import CtTxtPWD from './CustomTool/CtTxtPWD'
import CtBtn from './CustomTool/CtBtn'
import 'react-toastify/dist/ReactToastify.css'
import { fetchAdmin } from './API/API'

class AdminLogIn extends Component {

    CtWidth = 250

    state = {
        loginData: {
            LoginID: '',
            Password: '',
        },
        privileges: {
            admin: '',
            privdashboard: '',
            privmember: '',
            privapprovemember: '',
            privpersonaltree: '',
            privachievement: '',
            privturnovercom: '',
            privdirectreference: '',
            privwithdrawal: '',
            privtdsdeduction: '',
            privproduct: '',
            privoffer: '',
            privtour: '',
            privactivationkit: '',
            privinwardstock: '',
            privsalesrwm: '',
            rootmember: '',
            privadminchargededuction:''
        },
        res: [],
    }

    handleOnChange = (e) => {
        this.setState({
            loginData: { ...this.state.loginData, [e.target.name]: e.target.value }
        })
    }

    validateInput = (index = null) => {
        let result = false
        if ((index === null || index === 0) && this.state.loginData.LoginID.length <= 0) {
            this.props.toastErrorMsg('Enter Login ID', 'LoginID')
        } else if ((index === null || index === 1) && this.state.loginData.Password.length <= 0) {
            this.props.toastErrorMsg('Enter Password', 'Password')
        } else if ((index === null || index === 1) && this.state.loginData.Password.length < 4) {
            this.props.toastErrorMsg('Enter Valid Password', 'Password')
        }
        else {
            result = true
        }
        return result
    }

    verifyCredential = () => {
        if (this.validateInput()) {
            this.props.notifyProcessing()

            const reqData = {
                UserID: this.state.loginData.LoginID,
                PWD: this.state.loginData.Password,
                Op: 'VerifyAdminCredential',
            }

            fetchAdmin(reqData)
                .then(res => {
                    let privileges = {}
                    if (res.data.PrivilegesList) {
                        privileges = {
                            admin: res.data.PrivilegesList['admin'],
                            privdashboard: res.data.PrivilegesList['privdashboard'],
                            privmember: res.data.PrivilegesList['privmember'],
                            privapprovemember: res.data.PrivilegesList['privapprovemember'],
                            privpersonaltree: res.data.PrivilegesList['privpersonaltree'],
                            privachievement: res.data.PrivilegesList['privachievement'],
                            privturnovercom: res.data.PrivilegesList['privturnovercom'],
                            privdirectreference: res.data.PrivilegesList['privdirectreference'],
                            privwithdrawal: res.data.PrivilegesList['privwithdrawal'],
                            privtdsdeduction: res.data.PrivilegesList['privtdsdeduction'],
                            privproduct: res.data.PrivilegesList['privproduct'],
                            privoffer: res.data.PrivilegesList['privoffer'],
                            privtour: res.data.PrivilegesList['privtour'],
                            privactivationkit: res.data.PrivilegesList['privactivationkit'],
                            privinwardstock: res.data.PrivilegesList['privinwardstock'],
                            privsalesrwm: res.data.PrivilegesList['privsalesrwm'],
                            rootmember: res.data.PrivilegesList['rootmember'],
                            privadminchargededuction: res.data.PrivilegesList['privadminchargededuction'],
                        }
                    }
                    this.setState({
                        res: res.data,
                        privileges
                    }, () => {
                        this.props.setPrivileges(this.state.privileges)
                    })
                    this.props.updateProcessing(this.state.res.msgType, this.state.res.message)
                    if (this.state.res.msgType === 'success') {
                        this.props.handleAdminLoginID(this.state.loginData.LoginID)
                        this.props.history.push('/AdminDashboard')
                    }
                })
                .catch(err => {
                    this.props.updateProcessing('error', 'Unknown error occured in Verify Credential.\n\n' + err)
                })
        }
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "wgar" })
        this.props.handleAdminLoginID('')
        document.getElementById('LoginID').focus()
    }

    render() {
        return (
            <div className='Login'>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' >Log In</label>
                    </Grid>
                </Grid>
                <form>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5} >
                        <Grid item>
                            <CtTxt
                                id='LoginID'
                                label='Log in ID'
                                value={this.state.loginData.LogInID}
                                handleOnChange={this.handleOnChange}
                                width={this.CtWidth}
                                maxLength={100}
                                onKeyDown={this.props.onKeyDown}
                                validateInput={this.validateInput}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtTxtPWD
                                id='Password'
                                label='Password'
                                handleOnChange={this.handleOnChange}
                                width={this.CtWidth}
                                maxLength={100}
                                onKeyDown={this.props.onKeyDown}
                                validateInput={this.validateInput}
                                defaultAction={this.verifyCredential}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item><CtBtn label='Log in' onClick={this.verifyCredential} /></Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

export default HoCtToastContainer(AdminLogIn)