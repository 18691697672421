import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export default function CtDtpDOB({
  id, label, disabled, defaultValue, value, width, handleOnChange,
  onKeyDown, defaultAction, validateInput
}) {
  const classes = useStyles();
  const useWidthStyle = { width: width + 'px' }
  const handleOnKeyDown = (onKeyDown) ? ((event) => { onKeyDown(event, 1, defaultAction, validateInput) }) : (null)

  return (
    <TextField
      id={id}
      name={id}
      label={label}
      type="date"
      // disabled={disabled}
      value={value}
      defaultValue={defaultValue}
      className={classes.textField}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{ readOnly: disabled }}
      style={width > 0 ? useWidthStyle : {}}
    />
  );
}



/* // npm i @material-ui/pickers
import React, { Fragment, useState } from "react";
import { DatePicker } from "@material-ui/pickers";

const CtDtpDOB = ({ id, label, width }) => {

    const [selectedDate, handleDateChange] = useState(new Date())
    const useWidthStyle = { width: width + 'px' }

    return (
        <Fragment>
            <DatePicker
                disableFuture
                name={id}
                id={id}
                openTo="year"
                format="dd/MM/yyyy"
                label={label}
                views={["year", "month", "date"]}
                value={selectedDate}
                onChange={handleDateChange}
                style={width > 0 ? useWidthStyle : {}}

            />
        </Fragment>
    )
}

export default CtDtpDOB */