import React, { Component } from 'react'
import { Grid, IconButton, Tooltip } from '@material-ui/core'
import { WhatsApp, LibraryBooksOutlined } from '@material-ui/icons'
import { green } from "@material-ui/core/colors";
import HoCtToastContainer from "./HOC/HoCtToastContainer";
import { fetchMember } from './API/API'
import CtCmb from './CustomTool/CtCmb'
import CtPhoneNumber from './CustomTool/CtPhoneNumber'
import TextAreaAutoSize from './CustomTool/TextAreaAutoSize'

class ShareRegLink extends Component {

    state = {
        userID: '',
        scheme: '',
        CmbSponsor: 'Auto',
        CmbSponsorID: '',
        MobMobileNo: '',
        TxaLink: '',//https://ekparivartannetwork.com/#/Registration',

        basicLink: 'https://ekparivartannetwork.com/#/Registration',
        whatsappBasicLink: 'https://api.whatsapp.com/send?',

        CmbItemSponsors: [
            { name: 'Auto', disabled: false },
            { name: 'FIX', disabled: false },
        ],
        CmbItemSponsorIDs: [],

    }

    ref = React.createRef()

    //#region Function
    loadScheme = (userID) => {
        const reqData = {
            Op: 'GetSchemeOfID',
            userID: userID
        }
        fetchMember(reqData)
            .then(res => {
                const scheme = res.data.Scheme
                this.setState({ userID, scheme }, () => {
                    this.setTxaLink()
                })
            })
            .catch(error => {
                this.props.toastMsg('error', 'Unknown error occurred in loadScheme @ ShareRegLink. ' + error)
            })
    }

    loadSponsorIDWithFreeChild = (userID) => {
        const reqData = {
            Op: 'GetFreeChildSponsorIDsOfUserID',
            userID: userID
        }
        fetchMember(reqData)
            .then(res => {
                let CmbItemSponsorIDs = []
                let CmbSponsorID = ''
                res.data.SponsorIdList.map((ID, index) => {
                    CmbItemSponsorIDs.push({ 'name': ID, disabled: false })
                    if (index === 0) {
                        CmbSponsorID = ID
                    }
                })
                this.setState({ CmbItemSponsorIDs, CmbSponsorID }, () => {
                    this.setTxaLink()
                })
            })
            .catch(error => {
                this.props.toastMsg('error', 'Unknown error occurred in loadSponsorIDWithFreeChild @ ShareRegLink. ' + error)
            })
    }

    copyToClipboard = () => {
        this.ref.current.select();
        document.execCommand('copy');
        this.props.toastMsg('success', 'Link has been Copy To Clipboard')
    }

    generateWhatsAppLink = () => {
        // https://api.whatsapp.com/send?phone=+918000516051&text=thisistrialmsg
        return this.state.whatsappBasicLink + 'phone=+91' + this.state.MobMobileNo + '&text=' + encodeURIComponent(this.state.TxaLink)
    }

    setTxaLink = () => {
        let TxaLink = this.state.basicLink +
            ':' +
            this.state.userID +
            this.state.scheme.substring(0, 1) +
            (this.state.CmbSponsor === 'Auto' ? 'A' : 'F') +
            (this.state.CmbSponsor === 'Auto' ? '' : this.state.CmbSponsorID) +
            (this.state.MobMobileNo.length === 10 ? 'M' + this.state.MobMobileNo : '')
        this.setState({ TxaLink })
    }
    //#endregion

    //#region handle
    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            if (e.target.name === 'CmbSponsor') {
                if (this.state.CmbItemSponsorIDs.length === 0) {
                    this.loadSponsorIDWithFreeChild(this.state.userID)
                } else {
                    this.setTxaLink()
                }
            } else if (e.target.name === "CmbSponsorID") {
                this.setTxaLink()
            }
        })
    }

    handleOnChangeTrim = (e) => {
        this.setState({ [e.target.name]: e.target.value.trim() }, () => {
            this.setTxaLink()
        })
    }
    //#endregion

    componentDidMount() {
        this.loadScheme(this.props.userID)
    }

    render() {
        return (
            <form>
                <Grid container direction="column" justify="flext-start" alignItems="flext-start">
                    <Grid item>Scheme : {this.state.scheme}</Grid>
                    <Grid item>Reference Code : {this.state.userID}</Grid>
                    <Grid item>
                        <CtCmb
                            id='CmbSponsor'
                            label='Sponsor'
                            items={this.state.CmbItemSponsors}
                            value={this.state.CmbSponsor}
                            handleOnChange={this.handleOnChange}
                            onKeyDown={this.props.onKeyDown}
                            width='200'
                        />
                    </Grid>
                    <Grid item style={this.state.CmbSponsor === 'FIX' ? { display: 'block' } : { display: 'none' }}>
                        <CtCmb
                            id='CmbSponsorID'
                            label='Sponsor ID'
                            items={this.state.CmbItemSponsorIDs}
                            value={this.state.CmbSponsorID}
                            handleOnChange={this.handleOnChange}
                            onKeyDown={this.props.onKeyDown}
                            width='200'
                        />
                    </Grid>
                    <Grid item>
                        <CtPhoneNumber
                            id='MobMobileNo'
                            label='Mobile No'
                            value={this.state.MobMobileNo}
                            handleOnChange={this.handleOnChangeTrim}
                            onKeyDown={this.props.onKeyDown}
                            width='200'
                        />
                    </Grid>
                    <Grid item>
                        <Tooltip title='Copy Link To Clipboard'>
                            <IconButton
                                color='primary'
                                onClick={this.copyToClipboard}
                            >
                                <LibraryBooksOutlined />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Share On WhatsApp'>
                            <IconButton
                                disabled={this.state.MobMobileNo.length !== 10}
                            // style={this.state.MobMobileNo.length !== 10 ? { color: 'grey' } : { color: green[500] }}
                            >
                                <a
                                    href={this.generateWhatsAppLink()}
                                    style={this.state.MobMobileNo.length !== 10 ?
                                        { color: 'grey', textDecoration: 'none' } :
                                        { color: green[500], textDecoration: 'none' }}
                                >
                                    <WhatsApp />
                                </a>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <TextAreaAutoSize
                            id='TxaLink'
                            label='Link'
                            inputRef={this.ref}
                            placeholder='Link'
                            value={this.state.TxaLink}
                            disabled={true}
                            width='200'
                            // handleOnChange={this.handleOnChange}
                            maxLength='500'
                        />
                    </Grid>
                </Grid>
            </form>
        )
    }
}
export default HoCtToastContainer(ShareRegLink)