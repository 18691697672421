import React, { useState } from 'react'
import { VerifyIsUser } from '../API/API'
import { toast } from 'react-toastify'

const HOCVerifyIsUser = (UserArea) => {

    const displayLoginTimeoutMsg = () => {
        toast.error('Login Timeout')
    }

    const verifyUserID = (userID, navigateTo) => {
        VerifyIsUser(userID)
            .then(res => {
                if (res.data.status == 11) { } else {
                    clearLocalStorage()
                    displayLoginTimeoutMsg()
                    navigateTo('LogIn')
                }
            })
    }

    const validateIsAdmin = (IsAdmin) => {
        if (IsAdmin == true) {
            return true
        } else {
            return false
        }
    }

    const valdiateIsParamUserID = (match_params_userID) => {
        if (
            match_params_userID &&
            Number(match_params_userID.substring(1)) > 99999
        ) {
            return true
        } else {
            return false
        }
    }

    const valdiateIsAdminWithParamUserID = (IsAdmin, match_params_userID) => {
        if (
            validateIsAdmin(IsAdmin) &&
            valdiateIsParamUserID(match_params_userID)
        ) {
            return true
        } else {
            return false
        }
    }

    const validateLocalStorageLogInID = () => {
        if (
            localStorage.getItem('logInID') &&
            Number(localStorage.getItem('logInID')) > 99999 &&
            localStorage.getItem('logInUserType') &&
            localStorage.getItem('logInUserType') == 'U'
        ) {
            return true
        } else {
            return false
        }
    }

    const validateUserOrAdminWithUserID = (IsAdmin, match_params_userID, setActiveLink, navigateTo) => {
        let navigated = false;
        // console.log("valdiateIsAdminWithParamUserID(IsAdmin, match_params_userID)", valdiateIsAdminWithParamUserID(IsAdmin, match_params_userID))
        // console.log("validateLocalStorageLogInID()", validateLocalStorageLogInID())
        if (valdiateIsAdminWithParamUserID(IsAdmin, match_params_userID)) {
            let userID = match_params_userID.substring(1)
            verifyUserID(Number(userID), navigateTo)
        } else if (validateLocalStorageLogInID()) {
            verifyUserID(Number(localStorage.getItem('logInID')), navigateTo)
        } else {
            navigated = true;
            clearLocalStorage()
            displayLoginTimeoutMsg()
            navigateTo('LogIn')
        }

        if (navigated === false) {
            setActiveLink({ LinkSlug: "UserArea" })
        }
    }

    const validateUserOrAdminWithOrWithoutUserID = (IsAdmin, match_params_userID, setActiveLink, navigateTo) => {
        let navigated = false;
        if (valdiateIsAdminWithParamUserID(IsAdmin, match_params_userID)) {
            let userID = match_params_userID.substring(1)
            verifyUserID(Number(userID), navigateTo)
        } else if (validateIsAdmin(IsAdmin)) {
        } else if (validateLocalStorageLogInID()) {
            verifyUserID(Number(localStorage.getItem('logInID')), navigateTo)
        } else {
            navigated = true;
            clearLocalStorage()
            displayLoginTimeoutMsg()
            navigateTo('LogIn')
        }

        if (navigated === false) {
            setActiveLink({ LinkSlug: "UserArea" })
        }
    }

    const validateUserWithParamIDOrAdminWithOrWithoutUserID = (IsAdmin, match_params_userID, setActiveLink, navigateTo) => {
        let navigated = false;
        if (valdiateIsAdminWithParamUserID(IsAdmin, match_params_userID)) {
            let userID = match_params_userID.substring(1)
            verifyUserID(Number(userID), navigateTo)
        } else if (validateIsAdmin(IsAdmin)) {
        } else if (validateLocalStorageLogInID() && valdiateIsParamUserID(match_params_userID)) {
            verifyUserID(Number(localStorage.getItem('logInID')), navigateTo)
        } else {
            navigated = true;
            clearLocalStorage()
            displayLoginTimeoutMsg()
            navigateTo('LogIn')
        }

        if (navigated === false) {
            setActiveLink({ LinkSlug: "UserArea" })
        }
    }

    const clearLocalStorage = () => {
        localStorage.setItem('logInID', '');
        localStorage.setItem('logInUserType', '');
    }

    return (props) => {
        return (
            <div className='UserArea'>
                <UserArea
                    {...props}
                    validateUserOrAdminWithUserID={validateUserOrAdminWithUserID}
                    validateUserOrAdminWithOrWithoutUserID={validateUserOrAdminWithOrWithoutUserID}
                    validateUserWithParamIDOrAdminWithOrWithoutUserID={validateUserWithParamIDOrAdminWithOrWithoutUserID}
                />
            </div>
        )
    }
}
export default HOCVerifyIsUser