import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

const TextAreaAutoSize = ({ 
    id, label, disabled, value, width, handleOnChange, maxLength, placeholder, inputRef,
    onKeyDown, defaultAction, validateInput
 }) => {
    const classes = useStyles();

    const useWidthStyle = { width: width + 'px' }
    const applyMaxLength = { maxLength: maxLength, readOnly: disabled }
    const applyMaxLengthMissing = { maxLength: 1, readOnly: disabled }
    const handleOnKeyDown = (onKeyDown) ? ((event) => { onKeyDown(event, 1, defaultAction, validateInput) }) : (null)

    return (
        // <form className={classes.root} noValidate autoComplete="off">
            <div className={classes.root} noValidate autoComplete="off">
                <TextField
                    id={id}
                    name={id}
                    inputRef={inputRef}
                    label={label}
                    // disabled={disabled}
                    placeholder={placeholder}
                    multiline
                    value={value}
                    onChange={handleOnChange}
                    onKeyDown={handleOnKeyDown}
                    style={width > 0 ? useWidthStyle : {}}
                    inputProps={maxLength > 0 ? applyMaxLength : applyMaxLengthMissing}
                />
            </div>
        // </form>
    )
}
export default TextAreaAutoSize