import React, { Component } from 'react'
import { fetchMember, apiURL } from './API/API'
import UserAchievementList from './UserAchievementList'
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'
import { Grid, Tooltip, Typography } from '@material-ui/core'
import AlertResponsiveDialog from './CustomTool/AlertResponsiveDialog'
import CtBtn from './CustomTool/CtBtn'
import Progress from './CustomTool/Progress'
import TableList from './CustomTool/TableList'
import ShareRegLink from "./ShareRegLink"
import { getIndianFormatedNumber } from "./SystemUtility/SystemUtility"
import { GIFT_PLAN } from './SystemConstants'
import CtCardSimple from './CustomTool/CtCardSimple'
import { DoubleArrowOutlined, ListAlt, AccountBalanceWalletOutlined, LoopOutlined } from '@material-ui/icons'
import OfferSlider from './OfferSlider'

class UserDashboard extends Component {

    state = {
        userID: '',
        name: '',
        scheme: '',
        CurWalletBalance: '',
        TotalIncome: '',
        IncomeLevel: '',
        RepurchaseWallet: '',
        TotalTurnoverCommission: '',
        ReferalIncome: '',
        ActiveBonus: '',
        CurrentAchivement: '',
        CurrentAchivementSum: '',
        CurrentDirectReferral: '',
        PastAchivement: [],
        TotalMemberCount: 0,
        TeamSize: 0,
        CurrentCycleTeam1BTO: 0,
        CurrentCycleTeam2BTO: 0,
        CurrentCycleRepurchase: 0,
        Team1Size: 0,
        Team2Size: 0,
        RegisteredSize: 0,
        TotalTeamSize: 0,
        CurCycleStartDt: '',
        CurCycleEndDt: '',
        CurCycleTime: '11:59 PM',
        DashboardContent: 'User Dashboard Data will be display',
        dashboardStatus: 0,
        UBW: 160,
        dataLoadStatus: false,
        LevelInfoRowsPerPage: 5,
        LevelInfoRowsCount: 0,
        LevelInfoRows: [],
        LevelInfoColumns: [
            { id: 'Level', label: 'Level', minWidth: 10 },
            { id: 'NoOfLevelPerson', label: 'Person Count', minWidth: 20 },
            { id: 'NoOfRegisteredPerson', label: 'Registered', minWidth: 20 },
            { id: 'LevelStatus', label: 'Status', minWidth: 20 },
        ],
        TourInfoRowsCount: 0,
        TourInfoRows: [],
        TourInfoColumns: [
            { id: 'tourname', label: 'Tour Name', minWidth: 10 },
            { id: 'cyclefromtodate', label: 'Cycle', minWidth: 20 },
            { id: 'required1bto', label: 'Required Team 1 BTO', minWidth: 20, align: 'right' },
            { id: 'required2bto', label: 'Required Team 2 BTO', minWidth: 20, align: 'right' },
            { id: 'requiredrepurchase', label: 'Required Repurchase Total DP', minWidth: 20, align: 'right' },
            { id: 'achieved1bto', label: 'Achieved Team 1 BTO', minWidth: 20, align: 'right' },
            { id: 'achieved2bto', label: 'Achieved Team 2 BTO', minWidth: 20, align: 'right' },
            { id: 'achievedrepurchase', label: 'Self Repurchase Total DP', minWidth: 20, align: 'right' },
        ],
        cardWalletBtnList: [],
        cardTeamSizeBtnList: [],
        cardCurrentMonthAchievementBtnList: [],
        cardPastMonthAchievementBtnList: [],
        offerList: [],
        totalIncomeSetting: 0,
        totalincomeAmtOnly: 0,

        columnsTourInfoBTO: [
            // { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            { id: 'no', label: 'Sr. No.', align: 'center' },
            { id: 'fromdt', label: 'From Date' },
            { id: 'todt', label: 'To Date' },
            { id: 'minteam1bto', label: 'Min Team 1 BTO', align: 'right' },
            { id: 'minteam2bto', label: 'Min Team 2 BTO', align: 'right' },
            { id: 'repurchase', label: 'Repurchase', align: 'right' },
            { id: 'team1bto', label: 'Team 1 BTO', align: 'right' },
            { id: 'team2bto', label: 'Team 2 BTO', align: 'right' },
            { id: 'remainteam1bto', label: 'Remain Team 1 BTO', align: 'right' },
            { id: 'remainteam2bto', label: 'Remain Team 2 BTO', align: 'right' },
        ],
        rowsPerPageTourInfoBTO: { 0: 10 },
    }

    loadDashboardInfo = (userID) => {
        const reqData = {
            Op: 'MDInfo',
            vac_id: userID,
        }
        fetchMember(reqData)
            .then(res => {
                if (res.data.status === 11) {
                    let CurrentAchivement = '';
                    let CurrentAchivementSum = '';
                    let CurrentDirectReferral = '';
                    let rowsPerPageTourInfoBTO = { 0: 10 }

                    if (res.data.lastcycle.length > 0) {
                        CurrentAchivement = (res.data.lastcycle[0].fromdt !== null) ? (res.data.lastcycle[0].fromdt + " to " + res.data.lastcycle[0].todt) : '';
                        CurrentAchivementSum = getIndianFormatedNumber(res.data.lastcycle[0].turnovercommissionsum);
                        CurrentDirectReferral = getIndianFormatedNumber(res.data.lastcycle[0].directreferal);
                    }
                    let
                        Team1Size = 0,
                        Team2Size = 0,
                        RegisteredSize = 0,
                        TotalTeamSize = 0

                    if (res.data.TeamSize && res.data.TeamSize.length > 0 && res.data.TeamSize[0].team1size) {
                        Team1Size = res.data.TeamSize[0].team1size
                        Team2Size = res.data.TeamSize[0].team2size
                        RegisteredSize = res.data.TeamSize[0].registeredteamsize
                        TotalTeamSize = res.data.TeamSize[0].totalteamsize
                    }
                    let totalIncomeSetting = 0;
                    if (res.data.LoadMemberTotalIncomeSetting && res.data.LoadMemberTotalIncomeSetting.length > 0) {
                        totalIncomeSetting = res.data.LoadMemberTotalIncomeSetting[0].TotalIncome;
                    }
                    let TourInfoRows= res.data.TourList,
                        TourInfoRowsCount = TourInfoRows.length
                    for (let i = 1; i < TourInfoRowsCount; i++) {
                        rowsPerPageTourInfoBTO[i] = 10
                    }
                    this.setState({
                        name: res.data.name,
                        scheme: res.data.scheme,
                        CurrentAchivement,
                        CurrentAchivementSum,
                        CurrentDirectReferral,
                        dataLoadStatus: true,
                        CurWalletBalance: getIndianFormatedNumber(res.data.CurWalletBalance),
                        TotalIncome: getIndianFormatedNumber(res.data.TotalIncome),
                        totalincomeAmtOnly: res.data.TotalIncome,
                        IncomeLevel: res.data.IncomeLevel,
                        RepurchaseWallet: getIndianFormatedNumber(res.data.RepurchaseWallet),
                        TotalTurnoverCommission: getIndianFormatedNumber(res.data.TurnoverCommission),
                        ReferalIncome: getIndianFormatedNumber(res.data.ReferalIncome),
                        ActiveBonus: getIndianFormatedNumber(res.data.ActiveBonus),
                        PastAchivement: res.data.PastAchivement,
                        dashboardStatus: res.data.status,
                        userID,
                        LevelInfoRows: res.data.LevelInfo,
                        LevelInfoRowsCount: res.data.LevelInfo.length,
                        TotalMemberCount: res.data.TotalMemberCount,
                        TeamSize: res.data.TeamSize,

                        TourInfoRowsCount,
                        TourInfoRows,

                        offerList: res.data.offerList,

                        CurrentCycleTeam1BTO: getIndianFormatedNumber(res.data.CurrentCycleBonus[0].team1bto),
                        CurrentCycleTeam2BTO: getIndianFormatedNumber(res.data.CurrentCycleBonus[0].team2bto),
                        CurrentCycleRepurchase: getIndianFormatedNumber(res.data.CurrentCycleBonus[0].repurchase),

                        CurCycleStartDt: res.data.CurrentCyclePeriod[0].startdt,
                        CurCycleEndDt: res.data.CurrentCyclePeriod[0].enddt,

                        Team1Size,
                        Team2Size,
                        RegisteredSize,
                        TotalTeamSize,
                        totalIncomeSetting,
                        rowsPerPageTourInfoBTO,
                    })
                } else {
                    this.navigateTo('LogIn')
                }
            })
    }

    refereshTeamList = () => {
        const reqData = {
            Op: 'GetTeamSize',
            UserID: this.state.userID,
        }
        fetchMember(reqData)
            .then(res => {
                let
                    Team1Size = 0,
                    Team2Size = 0,
                    RegisteredSize = 0,
                    TotalTeamSize = 0

                if (res.data.TeamSize && res.data.TeamSize.length > 0 && res.data.TeamSize[0].team1size) {
                    Team1Size = res.data.TeamSize[0].team1size
                    Team2Size = res.data.TeamSize[0].team2size
                    RegisteredSize = res.data.TeamSize[0].registeredteamsize
                    TotalTeamSize = res.data.TeamSize[0].totalteamsize
                }
                this.setState({
                    ...this.state,
                    Team1Size,
                    Team2Size,
                    RegisteredSize,
                    TotalTeamSize,
                })
            })
    }

    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    componentWillMount() {
        this.props.validateUserOrAdminWithUserID(
            this.props.IsAdmin,
            this.props.match.params.userID,
            this.props.setActiveLink,
            this.navigateTo)
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "UserDashboard" })
        let userID = ''
        if (
            this.props.IsAdmin === true &&
            this.props.match.params.userID &&
            Number(this.props.match.params.userID.substring(1)) > 99999
        ) {
            userID = Number(this.props.match.params.userID.substring(1))
        } else if (
            localStorage.getItem('logInID') &&
            Number(localStorage.getItem('logInID')) > 99999
        ) {
            userID = Number(localStorage.getItem('logInID'))
        }
        if (userID > 99999) {
            this.loadDashboardInfo(userID)
        } else {
            // this.navigateTo('LogIn')
            alert('move to login page code has been commented')
        }
    }

    openIDPrint = () => {
        // this.props.notifyProcessing()
        let reqData = [
            { name: 'Op', value: 'OpenIDPrint' },
            { name: 'MemberID', value: this.state.userID }
        ]

        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", apiURL + 'print.php');

        form.setAttribute("target", "view");

        reqData.forEach((data) => {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", data.name);
            hiddenField.setAttribute("value", data.value);
            form.appendChild(hiddenField);
            document.body.appendChild(form);
        })

        window.open('', 'view');

        form.submit();
        // this.props.updateProcessing('success', 'PDF Open in next Tab.')
    }

    handleChangeRowsPerPageLevelInfo = (LevelInfoRowsPerPage) => {
        this.setState({ LevelInfoRowsPerPage })
    }

    handleChangeRowsPerPageTourInfoBTO = (rowsPerPageTourInfoBTO) => {
        this.setState({ rowsPerPageTourInfoBTO })
    }

    handleChangeRowsPerPageTourInfoBTO = (tblRowsPerPage, tblIndex) => {
        let rowsPerPageTourInfoBTO = this.state.rowsPerPageTourInfoBTO
        rowsPerPageTourInfoBTO[tblIndex] = tblRowsPerPage
        this.setState({
            rowsPerPageTourInfoBTO
        })
    }

    render() {

        const typoCardContentStyle = { fontSize: '1em', lineHeight: 1.8 }

        const displayPINButton = this.state.scheme === GIFT_PLAN ? { display: 'block' } : { display: 'none' }

        const bull = <span>•</span>;

        const outPutDashboardContent = () => {
            let output

            if (this.state.cardWalletBtnList.length == 0) {
                let titleStr = 'Wallet ( ' + this.state.CurWalletBalance + ')'
                this.setState({
                    cardWalletBtnList: [
                        {
                            btnText:
                                <div style={{ display: 'flex' }}><span>Open Wallet List</span>&nbsp;
                                    <Tooltip title={titleStr}>
                                        <AccountBalanceWalletOutlined style={{ height: '0.9em' }} />
                                    </Tooltip>
                                </div>,
                            handleOnBtnClick: () => { this.navigateTo('Wallet:' + this.state.userID) }
                        }
                    ],
                })
            }

            if (this.state.cardTeamSizeBtnList.length == 0) {
                this.setState({
                    cardTeamSizeBtnList: [
                        {
                            btnText:
                                <div style={{ display: 'flex' }}><span>Referesh Size</span>&nbsp;
                                    <Tooltip title='Referesh Size'>
                                        <LoopOutlined style={{ height: '0.9em' }} />
                                    </Tooltip>
                                </div>,
                            handleOnBtnClick: () => { this.refereshTeamList() }
                        }
                    ]
                })
            }

            if (this.state.dashboardStatus === 11) {
                output = <div className="UserDashboardInfo">
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item className="pageTitle">
                            <label htmlFor="Title" className='pageTitleText' >{this.state.name} ({this.state.userID})</label><br /><br />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="baseline" spacing={5}>
                        <Grid item>
                            <CtCardSimple
                                minHeightCardContent='172px'
                                cardContent={
                                    <div>
                                        <Typography gutterBottom variant="h5">
                                            Total Income
                                        </Typography>
                                        <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                            Income Level  :  {this.state.IncomeLevel}
                                        </Typography>
                                        <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                            Total Income  :  {this.state.TotalIncome}
                                        </Typography>
                                        <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                            {/* Wallet  :  {this.state.CurWalletBalance} */}
                                            Turnover Income  :  {this.state.TotalTurnoverCommission}
                                        </Typography>
                                        <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                            {/* Repurchase Wallet  :   {this.state.RepurchaseWallet} */}
                                            Referal Income  :   {this.state.ReferalIncome}
                                        </Typography>
                                        <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                            Active Bonus  :   {this.state.ActiveBonus}
                                        </Typography>
                                    </div>
                                }
                                btnList={this.state.cardWalletBtnList}
                            />
                        </Grid>
                        <Grid item>
                            <CtCardSimple
                                minHeightCardContent='197px'
                                cardContent={
                                    <div>
                                        <Typography variant="h5">
                                            Last Cycle Income <br />
                                            {this.state.CurrentAchivement}
                                        </Typography>
                                        <Typography gutterBottom component="h2" style={{ fontSize: '1em' }}>
                                            {bull} {this.state.CurrentAchivementSum}
                                        </Typography>
                                        <Typography variant="h5">
                                            Direct Referral Income
                                        </Typography>
                                        <Typography component="h2" style={{ fontSize: '1em' }}>
                                            {bull} {this.state.CurrentDirectReferral}
                                        </Typography>
                                    </div>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <CtCardSimple
                                minHeightCardContent='197px'
                                cardContent={
                                    <div>
                                        <Typography gutterBottom variant="h5">
                                            Current Cycle BTO
                                        </Typography>
                                        <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                            Team 1 BTO :  {this.state.CurrentCycleTeam1BTO}
                                        </Typography>
                                        <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                            Team 2 BTO :  {this.state.CurrentCycleTeam2BTO}
                                        </Typography>
                                        <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                            Repurchase  :  {this.state.CurrentCycleRepurchase}
                                        </Typography>
                                    </div>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <CtCardSimple
                                minHeightCardContent='197px'
                                cardContent={
                                    <div>
                                        <Typography gutterBottom variant="h5">
                                            Team Size
                                        </Typography>
                                        <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                            Team 1 Count  :  {this.state.Team1Size}
                                        </Typography>
                                        <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                            Team 2 Count  :  {this.state.Team2Size}
                                        </Typography>
                                        <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                            Registered  :  {this.state.RegisteredSize}
                                        </Typography>
                                        <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                            Total Size  :  {this.state.TotalTeamSize}
                                        </Typography>
                                    </div>
                                }
                                btnList={this.state.cardTeamSizeBtnList}
                            />
                        </Grid>
                        <Grid item>
                            <CtCardSimple
                                minHeightCardContent='154px'
                                cardContent={
                                    <div>
                                        <Typography gutterBottom variant="h5">
                                            Current Cycle
                                        </Typography>
                                        <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                            Start :  {this.state.CurCycleStartDt}
                                        </Typography>
                                        <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                            End :  {this.state.CurCycleEndDt}
                                        </Typography>
                                        <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                            {this.state.CurCycleTime}
                                        </Typography>
                                    </div>
                                }
                            />
                        </Grid>
                        {
                            (this.state.offerList.length > 0) ? (
                                <Grid item
                                    style={{ minWidth: '313px' }}
                                >
                                    <OfferSlider
                                        items={this.state.offerList}
                                    />
                                </Grid>
                            ) : ('')
                        }
                        <Grid item>
                            {
                                <TableList
                                    columns={this.state.LevelInfoColumns}
                                    rows={this.state.LevelInfoRows}
                                    rowsCount={this.state.LevelInfoRowsCount}
                                    rowsPerPage={this.state.LevelInfoRowsPerPage}
                                    handleChangeRowsPerPage={this.handleChangeRowsPerPageLevelInfo}
                                    keyColumn={['ID']}
                                    width={100}
                                    height={200}
                                />
                            }
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" justify="flex-start" alignItems="baseline" spacing={2}>
                                {/* <Grid item>
                                    <label style={{ paddingLeft: '0px', fontWeight: 'bolder', fontSize: '1.0rem' }}>
                                        Team Size : {this.state.TeamSize}
                                    </label>
                                </Grid> */}
                                <Grid item>
                                    <CtBtn
                                        label='Personal Tree'
                                        onClick={() => { this.navigateTo('PersonalTree:' + this.state.userID) }}
                                        width={this.state.UBW}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtBtn
                                        label='Profile'
                                        onClick={() => { this.navigateTo('UserProfile' + (this.props.IsAdmin === true ? ':' + this.state.userID : '')) }}
                                        width={this.state.UBW}
                                    />
                                </Grid>
                                <Grid item style={displayPINButton}>
                                    <CtBtn
                                        label='PIN'
                                        onClick={() => { this.navigateTo('PinDashboard' + (this.props.IsAdmin === true ? ':' + this.state.userID : ':' + localStorage.getItem('logInID'))) }}
                                        width={this.state.UBW}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtBtn
                                        label='ID Print'
                                        onClick={() => { this.openIDPrint() }}
                                        width={this.state.UBW}
                                    />
                                </Grid>
                                {
                                    (Number(this.state.totalincomeAmtOnly) >= Number(this.state.totalIncomeSetting)) ?
                                        (<Grid item>
                                            <CtBtn
                                                label='Team Info'
                                                onClick={() => { this.navigateTo('TeamInfo:' + this.state.userID) }}
                                                width={this.state.UBW}
                                            />
                                        </Grid>) : ""
                                }
                                <Grid item>
                                    <CtBtn
                                        label='Turnover History'
                                        onClick={() => { this.navigateTo('TurnoverHistory:' + this.state.userID) }}
                                    // width={this.state.UBW}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="baseline" spacing={5}>

                        {
                            (this.state.TourInfoRows.length > 0) ? (
                                this.state.TourInfoRows.map((row, tblIndex) => {
                                    return <Grid item>
                                        <CtCardSimple
                                            displayCardAction={false}
                                            cardContent={
                                                <div>
                                                    <Typography gutterBottom variant="h5">
                                                        Tour
                                                    </Typography>
                                                    <Typography component="h2" style={typoCardContentStyle}>
                                                        Tour : {row['tourname']}
                                                    </Typography>
                                                    <Typography component="h2" style={typoCardContentStyle}>
                                                        Cycle : {row['cyclefromtodate']}
                                                    </Typography>
                                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={5}>
                                                        <Grid item>
                                                            <Typography component="h4" style={{ fontWeight: 'bold' }}>
                                                                Required :
                                                            </Typography>
                                                            <Typography component="h2" style={typoCardContentStyle}>
                                                                Team 1 BTO : {row['required1bto']}
                                                            </Typography>
                                                            <Typography component="h2" style={typoCardContentStyle}>
                                                                Team 2 BTO : {row['required2bto']}
                                                            </Typography>
                                                            <Typography component="h2" style={typoCardContentStyle}>
                                                                Repurchase Total DP : {row['requiredrepurchase']}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography component="h4" style={{ fontWeight: 'bold' }}>
                                                                Achieved :
                                                            </Typography>
                                                            <Typography component="h2" style={typoCardContentStyle}>
                                                                Team 1 BTO : {row['achieved1bto']}
                                                            </Typography>
                                                            <Typography component="h2" style={typoCardContentStyle}>
                                                                Team 2 BTO : {row['achieved2bto']}
                                                            </Typography>
                                                            <Typography component="h2" style={typoCardContentStyle}>
                                                                Self Repurchase Total DP : {row['achievedrepurchase']}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <br />
                                                    <TableList
                                                        columns={this.state.columnsTourInfoBTO}
                                                        counter={this.state.counter}
                                                        rows={row['tourcycleinfo']}
                                                        rowsCount={row['tourcycleinfo'].length}
                                                        rowsPerPage={this.state.rowsPerPageTourInfoBTO[tblIndex]}
                                                        handleChangeRowsPerPage={(e)=>{this.handleChangeRowsPerPageTourInfoBTO(e,tblIndex)}}
                                                    />
                                                </div>
                                            }
                                        />
                                    </Grid>
                                })
                            ) : ('')
                        }

                    </Grid>
                </div>
            } else {
                output = <div className="userDashboardInfo">{this.state.DashboardContent}</div>
            }
            return output
        }

        return (
            <div className='UserDashboard'>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                {outPutDashboardContent(this.navigateTo)}
            </div>
        )
    }
}

export default HOCVerifyIsUser(UserDashboard)