import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { fetchSales } from "./API/API";

class SalesItemDelete extends Component {

    deleteSalesItem = (EditAcYear, EditBillNo, EditItemSrNo, StrBillNo, StrSalesDate, FreeMRP, mselOfferList,ActivationKit) => {
        this.props.notifyProcessing()
        if (Number(EditAcYear) > 0 && Number(EditBillNo) > 0 && Number(EditItemSrNo) > 0 && StrBillNo != "" && StrSalesDate != "") {
            let txtTotalBasicAmount = 0,
                txtTotalDiscountAmount = 0,
                txtTotalTaxable = 0,
                txtTotalCGST = 0,
                txtTotalSGST = 0,
                txtTotalIGST = 0,
                txtTotalGrandTotal = 0,
                txtTotalRoundOff = 0,
                txtTotalNetAmount = 0,
                txtWalletAmount = 0,
                txtFinalAmount = 0,
                txtTotalSalesBTO = 0,
                txtAvailableFreeMRP
            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'DeleteSalesItem',
                    EditAcYear: Number(EditAcYear),
                    EditBillNo: Number(EditBillNo),
                    EditItemSrNo: Number(EditItemSrNo),
                    StrBillNo,
                    Date: StrSalesDate,
                    FreeMRP,
                    OfferList: mselOfferList,
                    ActivationKitName:ActivationKit
                }
                fetchSales(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.setState({
                            dataLoadStatus: true
                        }, () => {
                            if (res.data.msgType === 'success') {
                                txtTotalBasicAmount = res.data.TotalBasicAmt
                                txtTotalDiscountAmount = res.data.TotalDiscountAmt
                                txtTotalTaxable = res.data.TaxableAmt
                                txtTotalCGST = res.data.TotalCGST
                                txtTotalSGST = res.data.TotalSGST
                                txtTotalIGST = res.data.TotalIGST
                                txtTotalGrandTotal = res.data.GrandTotal
                                txtTotalRoundOff = res.data.RoundOff
                                txtTotalNetAmount = res.data.NetAmt
                                txtTotalSalesBTO = res.data.TotalSalesBTO
                                txtAvailableFreeMRP = res.data.AvailableFreeMRP
                                this.props.setTotalInfo
                                    (
                                        txtTotalBasicAmount,
                                        txtTotalDiscountAmount,
                                        txtTotalTaxable,
                                        txtTotalCGST,
                                        txtTotalSGST,
                                        txtTotalIGST,
                                        txtTotalGrandTotal,
                                        txtTotalRoundOff,
                                        txtTotalNetAmount,
                                        EditBillNo,
                                        EditAcYear,
                                        StrBillNo,
                                        txtTotalSalesBTO,
                                        txtAvailableFreeMRP
                                    )
                                if (this.props.onDeleteItemSuccess) {
                                    this.props.onDeleteItemSuccess(EditBillNo, EditAcYear)
                                }
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('deleteSalesItem', error)
                    })
            })
        } else {
            this.props.toastMsg("info", "There are no detail to delete")
        }
        return true
    }

    render() {
        return (
            <AlertResponsiveDialog
                labelAgree='Delete'
                labelDisagree='Cancel'
                alertTitle={'Do you want to Delete Sales Item of Product : ' + this.props.ProductName + ' ?'}
                // alertMessage={}
                handleOnClickYes={() => {
                    return this.deleteSalesItem(
                        this.props.EditAcYear,
                        this.props.EditBillNo,
                        this.props.EditItemSrNo,
                        this.props.StrBillNo,
                        this.props.SalesDate,
                        this.props.FreeMRP,
                        this.props.mselOfferList,
                        this.props.ActivationKit
                    )
                }}
                defaultOpenDialog={true}
                onYesClickCloseIfExeSuccessfully={true}
            />
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(SalesItemDelete))