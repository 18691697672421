import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const CtTextFieldPWD = ({
  id, label, value, width, handleOnChange, maxLength,
  onKeyDown, defaultAction, validateInput,disabled
}) => {

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const useWidthStyle = { width: width + 'px' }
  const applyMaxLength = { maxLength: maxLength }
  const applyMaxLengthMissing = { maxLength: 1 }
  const handleOnKeyDown = (onKeyDown)?((event) => { onKeyDown(event, 2, defaultAction, validateInput) }):(null)
  return (
    <FormControl>
      <InputLabel htmlFor="standard-adornment-password">{label}</InputLabel>
      <Input
        id={id}
        name={id}
        type={values.showPassword ? 'text' : 'password'}
        value={value}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        disabled={disabled}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        inputProps={maxLength > 0 ? applyMaxLength : applyMaxLengthMissing}
        style={width > 0 ? useWidthStyle : {}}
      />
    </FormControl>
  )
}

export default CtTextFieldPWD