import React, { Component } from 'react'
import "./PinTransfer.css"
import HOCVerifyIsLogInUserOrAdmin from '../HOC/HOCVerifyIsLogInUserOrAdmin'
import HoCtToastContainer from '../HOC/HoCtToastContainer'
import CtTxtAdornNum from '../CustomTool/CtTxtAdornNum'
import CtTxtNum from '../CustomTool/CtTxtNum'
import CtBtn from '../CustomTool/CtBtn'
import { Grid, Box } from '@material-ui/core'
import { fetchMember } from '../API/API'
import Progress from '../CustomTool/Progress'
import { PROCESSING, NO_DATA_FOUND } from "../SystemUtility/SystemUtility";
import AlertResponsiveDialog from '../CustomTool/AlertResponsiveDialog'

class PinTransfer extends Component {

    state = {
        dataLoadStatus: false,
        cWidth: 180,
        pinStock: 0,
        UserID: 0,
        formData: {
            TransferUserID: '',
            TransferUserIDVerify: '',
            Name: '',
            Scheme: '',
            ReferenceCode: '',
            SponsorID: '',
            TransferPinCount: '',
        },
        alertTransferPinConfirm: null,
    }

    //#region Component
    componentWillMount() {
        const userID = Number(this.props.match.params.userID.substring(1))
        this.props.validateIsLogInUserOrAdmin(
            this.props.IsAdmin,
            this.props.setActiveLink,
            this.navigateTo,
            userID
        )
    }

    componentDidMount() {
        const UserID = Number(this.props.match.params.userID.substring(1))
        this.setState({ UserID }, () => {
            this.getCurPinStock()
        })
    }
    //#endregion

    //#region HandleOnChange
    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    getCurPinStock = () => {
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                'Op': 'StockPinCount',
                'UserID': this.state.UserID,
            }
            fetchMember(reqData)
                .then(res => {
                    const pinStock = res.data.PinCount
                    const dataLoadStatus = true,
                        TransferUserID = '',
                        TransferUserIDVerify = '',
                        Name = '',
                        Scheme = '',
                        ReferenceCode = '',
                        SponsorID = '',
                        TransferPinCount = ''
                    this.setState({
                        pinStock, dataLoadStatus,
                        formData: {
                            ...this.state.formData,
                            TransferUserID,
                            TransferUserIDVerify,
                            Name,
                            Scheme,
                            ReferenceCode,
                            SponsorID,
                            TransferPinCount
                        }
                    })
                })
                .catch(error => {
                    console.log('Unknown error occurred in getCurPinStock. ', error)
                })
        })
    }

    validateUserID = () => {
        let result = false
        if (Number(this.state.formData.TransferUserID) <= 99999) {
            this.props.toastErrorMsg('Enter valid User ID', 'TransferUserID')
        } else if (Number(this.state.UserID) === Number(this.state.formData.TransferUserID)) {
            this.props.toastErrorMsg('The PIN cannot be Transfer to yourself.', 'TransferUserID')
        } else {
            result = true
        }
        return result
    }

    validatePinCount = () => {
        let result = false
        if (Number(this.state.pinStock) <= 0) {
            this.props.toastErrorMsg('You have no PIN to Transfer.')
        } else if (Number(this.state.formData.TransferPinCount) <= 0) {
            this.props.toastErrorMsg('Enter Transfer PIN Count greater than Zero.', 'TransferPinCount')
        }
        return result
    }

    getActiveApproveUserInfo = () => {
        if (this.validateUserID()) {
            this.setState({ dataLoadStatus: false }, () => {
                this.props.notifyProcessing()
                const reqData = {
                    'Op': 'GetActiveApproveUserInfo',
                    'UserID': this.state.formData.TransferUserID
                }
                fetchMember(reqData)
                    .then(res => {
                        const dataLoadStatus = true
                        let Name = '', Scheme = '',
                            ReferenceCode = '', SponsorID = '',
                            msgType = '', message = '', TransferUserIDVerify = ''

                        msgType = res.data.msgType
                        message = res.data.message

                        if (msgType === 'success') {
                            TransferUserIDVerify = this.state.formData.TransferUserID
                            Name = res.data.Name
                            Scheme = res.data.Scheme
                            ReferenceCode = res.data.ReferenceCode
                            SponsorID = res.data.SponsorID
                        }

                        this.setState({
                            dataLoadStatus,
                            formData: {
                                ...this.state.formData, Name, Scheme,
                                ReferenceCode, SponsorID, TransferUserIDVerify
                            }
                        }, () => {
                            this.props.updateProcessing(msgType, message)
                        })
                    })
                    .catch(error => {
                        console.log('Unknown error occurred in getActiveApproveUserInfo.', error)
                    })
            })
        }
    }

    transferPINConfirmation = () => {
        this.setState({ alertTransferPinConfirm: null }, () => {
            const alertTransferPinConfirm = <AlertResponsiveDialog
                label={'Confirm To Transfer PIN'}
                labelAgree={'Transfer PIN'}
                labelDisagree={'Cancel'}
                alertTitle={
                    'Do you want to Transfer ' +
                    this.state.formData.TransferPinCount +
                    ' PIN to bellow described user?'
                }
                alertMessage={
                    <div style={{ color: '#000000' }}>
                        <p>User ID : {this.state.formData.TransferUserID}</p>
                        <p>Name : {this.state.formData.Name}</p>
                        <p>Scheme : {this.state.formData.Scheme}</p>
                        <p>SponsorID : {this.state.formData.SponsorID}</p>
                        <p>ReferenceCode : {this.state.formData.ReferenceCode}</p>
                    </div>
                }
                handleOnClickYes={this.transferPIN}
                defaultOpenDialog={true}
            />
            this.setState({ alertTransferPinConfirm })
        })
    }

    transferPIN = () => {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                'Op': 'PinTransfer',
                'CurUserID': this.state.UserID,
                'StockPinCount': Number(this.state.pinStock),
                'TransferPinCount': Number(this.state.formData.TransferPinCount),
                'NewUserID': this.state.formData.TransferUserID,
                'NewUserScheme': this.state.formData.Scheme
            }
            fetchMember(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    if (res.data.msgType === 'success') {
                        this.getCurPinStock()
                    }
                    this.setState({ dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastErrorMsg('error', 'Unknown error occurred in transferPIN. ' + error)
                })
        })
    }
    //#endregion

    render() {

        const disabledUsePIN = (Number(this.state.pinStock) > 0) ? false : true

        const disabledTransferPin = (
            this.state.formData.Name.length > 0 &&
            this.state.formData.Scheme.length > 0 &&
            Number(this.state.formData.ReferenceCode) > 99999 &&
            Number(this.state.formData.SponsorID) > 99999 &&
            Number(this.state.formData.TransferUserID) > 99999 &&
            Number(this.state.formData.TransferUserID) === Number(this.state.formData.TransferUserIDVerify)
        ) ? false : true

        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' >PIN Transfer </label>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="baseline" spacing={5}>
                    <Grid item>
                        <label>PIN Stock ( {this.state.pinStock} )</label><br />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                    <Grid item>
                        <CtTxtAdornNum
                            id='TransferUserID'
                            label='PIN Transfer to User ID'
                            adornment='WG'
                            value={this.state.formData.TransferUserID}
                            handleOnChange={this.handleOnChange}
                            disabled={disabledUsePIN}
                            width={this.state.cWidth}
                        />
                    </Grid>
                    <Grid item>
                        <CtBtn
                            label='Get User Info'
                            onClick={this.getActiveApproveUserInfo}
                            disabled={disabledUsePIN}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                    <Grid item>
                        <CtTxtNum
                            id='TransferPinCount'
                            label='Transfer Pin Count'
                            value={this.state.formData.TransferPinCount}
                            handleOnChange={this.handleOnChange}
                            width={this.state.cWidth}
                            maxLength={6}
                            disabled={disabledTransferPin}
                        />
                    </Grid>
                    <Box item style={{ width: '190px' }} display={{ sm: 'block' }}>
                        &nbsp;
                    </Box>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                    <Grid item className='hc' style={{ minWidth: '250px' }}>Name : {this.state.formData.Name}</Grid>
                    <Grid item className='hc' style={{ minWidth: '250px' }}>Scheme : {this.state.formData.Scheme}</Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                    <Grid item className='hc' style={{ minWidth: '250px' }}>Sponsor ID : {this.state.formData.SponsorID}</Grid>
                    <Grid item className='hc' style={{ minWidth: '250px' }}>Reference Code : {this.state.formData.ReferenceCode}</Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                    <Grid item>
                        <CtBtn
                            label='Transfer PIN'
                            onClick={this.transferPINConfirmation}
                            disabled={disabledTransferPin}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.alertTransferPinConfirm}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsLogInUserOrAdmin(HoCtToastContainer(PinTransfer))