import React from 'react'
import { VerifyIsAdmin } from '../API/API'
import { toast } from 'react-toastify'

const HOCVerifyIsAdmin = (AdminArea) => {

    const displayLoginTimeoutMsg=()=>{
        toast.error('Login Timeout')
    }

    const verifyAdminID = (userID, navigateTo) => {
        VerifyIsAdmin(userID)
            .then(res => {
                if (
                    res.data.name && res.data.name.trim().length > 0 &&
                    res.data.status && res.data.status==1
                    ) {
                } else {
                    clearLocalStorage()
                    displayLoginTimeoutMsg()
                    navigateTo('wgar')
                }
            })
    }

    const validateIsAdmin = (setActiveLink, navigateTo) => {
        if (
            localStorage.getItem('logInID') &&
            localStorage.getItem('logInID').length > 0 &&
            localStorage.getItem('logInUserType') &&
            localStorage.getItem('logInUserType') == 'A'
        ) {
            setActiveLink({ LinkSlug: "AdminDashboard" })
            verifyAdminID(localStorage.getItem('logInID'), navigateTo)
            // console.log('If satisfied of validateIsAdmin')
        } else {
            clearLocalStorage()
            displayLoginTimeoutMsg()
            navigateTo('wgar')
            // console.log('else of validateIsAdmin')
        }
    }

    const clearLocalStorage = () => {
        localStorage.setItem('logInID', '');
        localStorage.setItem('logInUserType', '');
    }

    return (props) => {
        return (
            <div className="AdminArea">
                <AdminArea
                    {...props}
                    validateIsAdmin={validateIsAdmin}
                />
            </div>
        )
    }
}
export default HOCVerifyIsAdmin