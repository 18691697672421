import React, { Component } from 'react'
// import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import TableList from './CustomTool/TableList'
import { fetchMember } from "./API/API";
import { Grid } from "@material-ui/core";
import Progress from './CustomTool/Progress'
import CtBtn from './CustomTool/CtBtn'
import CtTxtNum from './CustomTool/CtTxtNum'
import CtTxt from './CustomTool/CtTxt'
import { CommuteOutlined, MoneyOutlined } from '@material-ui/icons';


class TeamInfo extends Component {

    state = {
        dataLoadStatus: false,
        formData: {
            txtMemberId: '',
            txtMemberName: '',
            txtMemberMobileNo: ''
        },
        columns: [
            { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            // { id: 'SrNo', label: 'Sr. No.', align: 'center' },
            { id: 'memberid', label: 'Member ID' },
            { id: 'name', label: 'Name' },
            { id: 'mobileno', label: 'Mobile No' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 10,
        actionList: [
            {
                name: 'Tour',
                icon: <CommuteOutlined />,
                link: 'Tour:',
                actionType: 'AlertResponsive'
            },
            {
                name: 'Turnover History',
                icon: <MoneyOutlined />,
                link: 'DelTour:',
                actionType: 'AlertResponsive'
            },
        ],
        keyColumn: [
            'memberid'
        ],
        searchData: {
            memberid: ''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        ARD: null,
        txtTotalTCAmt: 0,
    }

    //#region Component
    componentWillMount() {
        // this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        console.log("this.props.match.params.memberid", this.props.match.params.memberid);
        this.setSearchColumns()
        this.setState({
            dataLoadStatus: true
        })
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    ((this.state.searchData.memberid.length === 0 || row['memberid'].toString().includes(this.state.searchData.memberid)))
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }

    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
        })
    }

    handleARDonActionClick = (values) => {
        const memberid = values[0].value,
            actionName = values[0].actionName
        if (actionName === 'Tour') {
            // alert("MemberID : " + memberid + ", ActionName : " + actionName)
            this.navigateTo('TourInfo:' + memberid)
        } else if (actionName === 'Turnover History') {
            // alert("MemberID : " + memberid + ", ActionName : " + actionName)
            this.navigateTo('TurnoverHistory:' + memberid)
        }
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    validateDate = () => {
        let validateDate = false
        if (this.state.formData.txtMemberId.length == 0 && this.state.formData.txtMemberName.length == 0 && this.state.formData.txtMemberMobileNo.length == 0) {
            this.props.toastErrorMsg('Enter at least one detail', 'txtMemberId')
        } else {
            validateDate = true
        }
        return validateDate
    }

    loadMemberDetail = () => {
        if (this.validateDate()) {
            let rootmember = ''
            if (
                this.props.match.params.memberid &&
                this.props.match.params.memberid.length > 0 &&
                Number(this.props.match.params.memberid.substring(1)) > 99999
            ) {
                rootmember = Number(this.props.match.params.memberid.substring(1))
            }
            this.setState({ dataLoadStatus: false }, () => {
                let MemberID = this.state.formData.txtMemberId,
                    MemberName = this.state.formData.txtMemberName,
                    MobileNo = this.state.formData.txtMemberMobileNo
                const reqData = {
                    Op: 'MemberListforTeamInfo',
                    MemberID,
                    MemberName,
                    MobileNo,
                    rootmember
                }
                fetchMember(reqData)
                    .then(res => {
                        const rows = res.data.MemberListforTeamInfo
                        const rowsCount = rows.length
                        this.setState({ rows, rowsCount, dataLoadStatus: true })
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in loadMemberDetail. ' + error)
                    })
            })
        }
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {
                cType: 'CtTxt',
                id: 'memberid',
                label: 'Member ID',
                value: this.state.searchData.memberid,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            },
            {
                cType: 'Lbl',
                label: 'From date',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'To date',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Team 1 BTO',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Team 2 BTO',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Turnover Commission',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Total BTO',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Turnover %',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Powe leg',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'C/F Amount',
                // width: '100',
            },
        )
        this.setState({ searchColumns })
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>Team Info</label>
                    </Grid>
                </Grid>
                <form>
                    <br />
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtTxtNum
                                id='txtMemberId'
                                label='Member ID'
                                handleOnChange={this.handleOnChangeTrim}
                                value={this.state.formData.txtMemberId}
                                width={120}
                                maxLength='6'
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtMemberName'
                                label='Name'
                                handleOnChange={this.handleOnChangeTrim}
                                value={this.state.formData.txtMemberName}
                                width={250}
                                maxLength='100'
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtMemberMobileNo'
                                label='Mobile No'
                                handleOnChange={this.handleOnChangeTrim}
                                value={this.state.formData.txtMemberMobileNo}
                                width={150}
                                maxLength='10'
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtBtn label='Find Team Member' onClick={this.loadMemberDetail} />
                        </Grid>
                    </Grid>
                </form>
                <br />
                <TableList
                    columns={this.state.columns}
                    // counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                />
            </div>
        )
    }
}
export default HoCtToastContainer(TeamInfo)
