import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { fetchSales, apiURL } from './API/API'
import { Grid } from "@material-ui/core";
import Progress from './CustomTool/Progress'
import { get_YMD_from_SYS } from './SystemUtility/SystemUtility'
import CtDtpDOB from './CustomTool/CtDtpDOB'
import CtBtn from './CustomTool/CtBtn'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";

class SalesMultiplePrint extends Component {

    state = {
        dataLoadStatus: false,
        formData: {
            dtpFromDate: get_YMD_from_SYS(new Date()),
            dtpToDate: get_YMD_from_SYS(new Date())
        },
    }

    //#region Component
    // componentWillMount() {
    //     this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    // }

    // componentDidMount() {
    //     this.props.setActiveLink({ LinkSlug: "SalesMultiplePrint" })
    // }
    //#endregion

    //#region Handle
    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    openSalesMultiplePDF = () => {
        let reqData = [
            { name: 'Op', value: 'SalesMultiplePrint' },
            { name: 'FromDate', value: this.state.formData.dtpFromDate },
            { name: 'ToDate', value: this.state.formData.dtpToDate },
        ]
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", apiURL + 'salesprint.php');
        form.setAttribute("target", "view");

        reqData.forEach((data) => {
            console.log("data", data);
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", data.name);
            hiddenField.setAttribute("value", data.value);
            form.appendChild(hiddenField);
            document.body.appendChild(form);
        })
        window.open('', 'view');
        form.submit();
    }
    //#endregion

    render() {
        return (
            <AlertResponsiveDialog
                // labelAgree='Delete'
                labelDisagree='Close'
                alertTitle={
                    <div>
                        {/* <Progress color='secondary' display={this.state.dataLoadStatus !== true} /> */}
                        <form>
                            <br />
                            <Grid container direction="row" justify="left" alignItems="center" spacing={5}>
                                <Grid item>
                                    <CtDtpDOB
                                        id='dtpFromDate'
                                        label='From Date'
                                        width='150'
                                        handleOnChange={this.handleOnChangeTrim}
                                        defaultValue={this.state.formData.dtpFromDate}
                                        value={this.state.formData.dtpFromDate}
                                        onKeyDown={this.props.onKeyDown}
                                    // disabled={this.state.disabledProfileData.DOBDS}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtDtpDOB
                                        id='dtpToDate'
                                        label='To Date'
                                        width='150'
                                        handleOnChange={this.handleOnChangeTrim}
                                        defaultValue={this.state.formData.dtpToDate}
                                        value={this.state.formData.dtpToDate}
                                        onKeyDown={this.props.onKeyDown}
                                    // disabled={this.state.disabledProfileData.DOBDS}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='PDF' width={150} onClick={this.openSalesMultiplePDF} />
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                }
                // alertMessage={}
                handleOnClickYes={() => {
                    return this.deleteSales(
                        this.props.EditAcYear, this.props.EditBillNo
                    )
                }}
                defaultOpenDialog={true}
                onYesClickCloseIfExeSuccessfully={true}
            />

        )
    }

}
export default HOCVerifyIsAdmin(HoCtToastContainer(SalesMultiplePrint))