import React, { Component } from 'react'
import { Grid, Box } from '@material-ui/core'
import CtCmb from './CustomTool/CtCmb'
import CtTxt from './CustomTool/CtTxt'
import CtBtn from './CustomTool/CtBtn'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import CtDtpDOB from './CustomTool/CtDtpDOB'
import 'react-toastify/dist/ReactToastify.css'
import { fetchCompanyCharges } from './API/API'
import AlertResponsiveDialog from './CustomTool/AlertResponsiveDialog'
import TextAreaAutoSize from './CustomTool/TextAreaAutoSize'
import { get_YMD_from_SYS, get_YMD_from_DMY } from './SystemUtility/SystemUtility'

class CompanyChargeEntry extends Component {

    state = {
        formData: {
            EditSrNo: '',
            DtpDate: get_YMD_from_SYS(new Date()),
            cmbLedger: '',
            Amount: '',
            Remark: '',
            BankName: '',
            BranchName: '',
            IFSCCode: '',
            AccountNumber: '',
            AccountType: 'Saving',
        },
        AccountTypes: [
            { name: 'Saving', disabled: false },
            { name: 'Current', disabled: false },
        ],
        dataLoadStatus: false,
        ARD: null,
        LedgerList: [
            { name: 'Admin Charge', disabled: 'false' },
            { name: 'TDS Tax', disabled: 'false' }
        ],
        ledgerSlug: ''
    }

    //#region Component
    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "CompanyChargeList" })
        this.onFormLoad()
    }

    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }
    //#endregion

    //#region handle
    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }

    handleOnAutoCompleteChange = (e) => {
        // console.log('e.target.name', e.target.name)
        // console.log('e.target.value', e.target.value)
        let value = e.target.value && e.target.value['name'] ? e.target.value['name'] : e.target.value
        // console.log('value', value)
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: value }
        })
    }

    handleOnAutoCompleteInputChange = (e) => {
        if (this.state.formData[e.target.id] === null && e.target.value.length > 0) {
            this.setState({
                formData: { ...this.state.formData, [e.target.name]: e.target.value }
            })
        }
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }

    //#endregion

    //#region funtion

    validateInput = () => {
        let result = false
        if (this.state.formData.DtpDate.length <= 0) {
            this.props.toastErrorMsg('Select Date', 'DtpDate')
        } else if (this.state.formData.cmbLedger.length <= 0) {
            this.props.toastErrorMsg('Select Ledger', 'cmbLedger')
        } else if (this.state.formData.Amount.length <= 0 || Number(this.state.formData.Amount) <= 0) {
            this.props.toastErrorMsg('Enter Amount', 'Amount')
        } else if (this.state.formData.Remark.trim().length <= 0) {
            this.props.toastErrorMsg('Enter Remark', 'Remark')
        } else if (this.state.formData.AccountType.trim().length <= 0) {
            this.props.toastErrorMsg('Select Account Type', 'AccountType')
        } else if (this.state.formData.BankName.trim().length <= 0) {
            this.props.toastErrorMsg('Enter Bank Name', 'BankName')
        } else if (this.state.formData.BranchName.trim().length <= 0) {
            this.props.toastErrorMsg('Enter Branch Name', 'BranchName')
        } else if (this.state.formData.AccountNumber.trim().length <= 0) {
            this.props.toastErrorMsg('Enter Account Number', 'AccountNumber')
        } else if (this.state.formData.IFSCCode.trim().length <= 0) {
            this.props.toastErrorMsg('Enter IFSC Code', 'IFSCCode')
        } else if (this.state.formData.IFSCCode.trim().length != 11) {
            this.props.toastErrorMsg('Enter valid IFSC Code', 'IFSCCode')
        } else {
            result = true
        }
        return result
    }

    saveCompanyCharges = () => {
        if (this.validateInput()) {
            this.props.notifyProcessing()
            const reqData = {
                Op: 'SaveCompanyCharge',
                EditSrNo: this.state.formData.EditSrNo,
                Date: this.state.formData.DtpDate,
                Ledger: this.state.formData.cmbLedger,
                Amount: this.state.formData.Amount,
                Remark: this.state.formData.Remark,
                AcType: this.state.formData.AccountType,
                BankName: this.state.formData.BankName,
                BranchName: this.state.formData.BranchName,
                IFSCCode: this.state.formData.IFSCCode,
                AcNo: this.state.formData.AccountNumber
            }

            fetchCompanyCharges(reqData)
                .then(res => {
                    this.setState({ res: res.data })
                    this.props.updateProcessing(this.state.res.msgType, this.state.res.message)
                    if (this.state.res.msgType === 'success') {
                        this.clearFields()
                    }
                })
                .catch(err => {
                    this.props.updateProcessing('error', 'Unknown error occured in Save Registration.\n\n' + err)
                })
        }
    }

    getLastBankDetail = () => {
        this.setState({
            dataLoadStatus: false
        }, () => {
            let Ledger = this.state.formData.cmbLedger,
                AccountType = 'Saving',
                BankName = '',
                BranchName = '',
                IFSCCode = '',
                AccountNumber = '',
                Amount = 0
            const reqData = {
                Op: 'GetLastBankDetail',
                Ledger
            }
            fetchCompanyCharges(reqData)
                .then(res => {
                    Amount = res.data.CompanyChargeAmt
                    if (res.data.LastBankDetail && res.data.LastBankDetail.length > 0) {
                        AccountType = res.data.LastBankDetail[0].actype
                        BankName = res.data.LastBankDetail[0].bankname
                        BranchName = res.data.LastBankDetail[0].branchname
                        IFSCCode = res.data.LastBankDetail[0].ifsccode
                        AccountNumber = res.data.LastBankDetail[0].acno
                    }
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            AccountType,
                            BankName,
                            BranchName,
                            IFSCCode,
                            AccountNumber,
                            Amount,
                        },
                        dataLoadStatus: true
                    })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in getLastBankDetail. ' + error)
                })
        })
    }

    clearFields = () => {
        const oldEditSrNo = this.state.formData.EditSrNo
        let EditSrNo = 0,
            DtpDate = get_YMD_from_SYS(new Date()),
            cmbLedger = '',
            Amount = '',
            Remark = '',
            AccountType = 'Saving',
            BankName = '',
            BranchName = '',
            IFSCCode = '',
            AccountNumber = ''
        if (this.state.ledgerSlug === "AdminCharge") {
            cmbLedger = 'Admin Charge'
        } else {
            cmbLedger = 'TDS Tax'
        }

        this.setState({
            formData: {
                ...this.state.formData,
                EditSrNo,
                DtpDate,
                cmbLedger,
                Amount,
                Remark,
                AccountType,
                BankName,
                BranchName,
                IFSCCode,
                AccountNumber
            },
        }, () => {
            this.getLastBankDetail()
            if (oldEditSrNo > 0) {
                // this.navigateTo('CompanyChargeEntry:' + this.state.ledgerSlug)
            }
            document.getElementById('DtpDate').focus()
        })
    }

    onDeleteBtnClick = () => {
        if (Number(this.state.formData.EditSrNo) > 0) {
            const EditSrNo = this.state.formData.EditSrNo
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete this record ?'}
                    // alertMessage={}
                    handleOnClickYes={() => { return this.deleteCompanyCharge(EditSrNo) }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } else {
            this.props.toastMsg("info", "There are no detail to delete")
        }
    }

    deleteCompanyCharge = (EditSrNo) => {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                Op: 'DeleteCompanyCharge',
                EditSrNo: EditSrNo,
            }
            fetchCompanyCharges(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    this.setState({
                        dataLoadStatus: true
                    }, () => {
                        if (res.data.msgType === 'success') {
                            this.clearFields()
                        }
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('deleteCompanyCharge', error)
                })
        })
        return true
    }

    onFormLoad = () => {
        this.setState({
            dataLoadStatus: false
        }, () => {
            let reqData = '',
                EditSrNo = 0,
                DtpDate = get_YMD_from_SYS(new Date()),
                cmbLedger = '',
                Amount = '',
                Remark = '',
                AccountType = 'Saving',
                BankName = '',
                BranchName = '',
                IFSCCode = '',
                AccountNumber = '',
                ledgerSlug = '',
                dataLoadStatus = false

            if (this.props.match.params.EditSrNo && this.props.match.params.EditSrNo.length > 0) {
                let strprops = this.props.match.params.EditSrNo
                let arr = strprops.split(":")
                if (arr.length > 2) {
                    EditSrNo = Number(arr[2])
                }
                ledgerSlug = arr[1]
                if (ledgerSlug === "AdminCharge") {
                    cmbLedger = 'Admin Charge'
                } else {
                    cmbLedger = 'TDS Tax'
                }
                if (EditSrNo > 0) {
                    reqData = {
                        Op: 'CompanyChargesListOnUpdate',
                        EditSrNo
                    }
                    fetchCompanyCharges(reqData)
                        .then(res => {
                            dataLoadStatus = true
                            if (EditSrNo > 0) {
                                DtpDate = get_YMD_from_DMY(res.data.companychargesList[0].dateDMY)
                                cmbLedger = res.data.companychargesList[0].ledger
                                Amount = res.data.companychargesList[0].amount
                                Remark = res.data.companychargesList[0].remark
                                AccountType = res.data.companychargesList[0].actype
                                BankName = res.data.companychargesList[0].bankname
                                BranchName = res.data.companychargesList[0].branchname
                                IFSCCode = res.data.companychargesList[0].ifsccode
                                AccountNumber = res.data.companychargesList[0].acno
                            }
                            this.setState({
                                dataLoadStatus, ledgerSlug,
                                formData: {
                                    ...this.state.formData,
                                    EditSrNo,
                                    DtpDate,
                                    cmbLedger,
                                    Amount,
                                    Remark,
                                    AccountType,
                                    BankName,
                                    BranchName,
                                    IFSCCode,
                                    AccountNumber
                                }
                            })
                        })
                        .catch(error => {
                            this.props.toastUnknownErrorMsg('onFormLoad', error)
                        })
                } else {
                    this.setState({
                        dataLoadStatus, ledgerSlug,
                        formData: {
                            ...this.state.formData,
                            EditSrNo,
                            DtpDate,
                            cmbLedger,
                            Amount,
                            Remark,
                            AccountType,
                            BankName,
                            BranchName,
                            IFSCCode,
                            AccountNumber
                        }
                    }, () => {
                        this.getLastBankDetail()
                    })
                }
            }

        })
    }

    onClose = (link) => {
        this.navigateTo('CompanyChargeList:' + this.state.ledgerSlug)
    }

    //#endregion

    render() {

        return (
            <div>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>Company Charges</label>
                    </Grid>
                </Grid>
                <form>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtDtpDOB
                                id='DtpDate'
                                label='Date'
                                width='140'
                                handleOnChange={this.handleOnChangeTrim}
                                onKeyDown={this.props.onKeyDown}
                                defaultValue={this.state.formData.DtpDate}
                                value={this.state.formData.DtpDate}
                            />
                        </Grid>
                        <Grid item>
                            <CtCmb
                                id='cmbLedger'
                                label='Ledger'
                                items={this.state.LedgerList}
                                value={this.state.formData.cmbLedger}
                                handleOnChange={this.handleOnChange}
                                width='150'
                                disabled={true}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='Amount'
                                label='Amount'
                                value={this.state.formData.Amount}
                                handleOnChange={this.handleOnChange}
                                onKeyDown={this.props.onKeyDown}
                                width='130'
                                maxLength='18'
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item>
                            <TextAreaAutoSize
                                id='Remark'
                                label='Remark'
                                value={this.state.formData.Remark}
                                width='500'
                                handleOnChange={this.handleOnChange}
                                maxLength='1000'
                                placeholder='Remark'
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtCmb
                                id='AccountType'
                                label='Account Type'
                                items={this.state.AccountTypes}
                                value={this.state.formData.AccountType}
                                handleOnChange={this.handleOnChange}
                                width='150'
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='BankName'
                                label='Bank Name'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.BankName}
                                width='300'
                                maxLength='100'
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtTxt
                                id='BranchName'
                                label='Branch Name'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.BranchName}
                                width='220'
                                maxLength='100'
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='AccountNumber'
                                label='Account Number'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.AccountNumber}
                                width='230'
                                maxLength='50'
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtTxt
                                id='IFSCCode'
                                label='IFSC Code'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.IFSCCode}
                                width='220'
                                maxLength='11'
                            />
                        </Grid>
                        <Box item style={{ width: '270px' }} display={{ xs: 'none', sm: 'block' }}>
                            &nbsp;
                        </Box>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtBtn label='Save' onClick={this.saveCompanyCharges} />
                        </Grid>
                        <Grid item>
                            <CtBtn label='New' onClick={this.clearFields} />
                        </Grid>
                        <Grid item>
                            <CtBtn label='Delete' onClick={this.onDeleteBtnClick} />
                        </Grid>
                        <Grid item>
                            <CtBtn label='Close' onClick={this.onClose} />
                        </Grid>
                    </Grid>
                </form>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}

export default HOCVerifyIsAdmin(HoCtToastContainer(CompanyChargeEntry))