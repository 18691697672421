import React, { Component } from 'react'
import { fetchProduct } from "./API/API";
import HoCtToastContainer from './HOC/HoCtToastContainer'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";

class ProductDelete extends Component {
    deleteProduct = (EditSrNo, EditProductName) => {
        this.props.notifyProcessing()
        if (Number(EditSrNo) > 0) {
            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'DeleteProduct',
                    EditSrNo: Number(EditSrNo),
                    EditProductName: EditProductName,
                }
                fetchProduct(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.setState({
                            dataLoadStatus: true
                        }, () => {
                            if (res.data.msgType === 'success') {
                                if(this.props.onDeleteSuccess){
                                    this.props.onDeleteSuccess()
                                }
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('deleteProduct', error)
                    })
            })
        } else {
            this.props.toastMsg("info", "There are no detail to delete")
        }
        return true
    }

    render() {
        return (
            <AlertResponsiveDialog
                labelAgree='Delete'
                labelDisagree='Cancel'
                alertTitle={'Do you want to Delete Product ' + this.props.EditProductName + '?'}
                // alertMessage={}
                handleOnClickYes={() => { return this.deleteProduct(
                    this.props.EditSrNo, this.props.EditProductName
                    ) }}
                defaultOpenDialog={true}
                onYesClickCloseIfExeSuccessfully={true}
            />
        )
    }
}
export default HoCtToastContainer(ProductDelete)