import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import CtTextField from './CustomTool/CtTxt'
import CtBtn from './CustomTool/CtBtn'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Zoom } from 'react-toastify';
import { Error } from '@material-ui/icons';
import processing from '../gif/processing.gif'

const Msg = ({ MsgType, Message }) => (
    <div style={{ textAlign: 'center' }}>
        <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
            {MsgType === 'success' ? '' : <Error />}
            {Message && Message.length > 0 ? Message : 'Welcome Back'}
        </Grid>
    </div>
)

const ProcessingMsg = () => (
    <div style={{ textAlign: 'center' }}>
        <Grid container direction="row" justify="center" alignItems="center" spacing={1} style={{ fontSize: '16px' }}>
            <img src={processing} alt='Processing' style={{ width: '100px', height: 'auto' }} />&nbsp;&nbsp;&nbsp;<b>Please wait...</b>
        </Grid>

    </div>
)
class Otp extends Component {

    CtWidth = 250

    state = {
        otpData: {
            Otp: '',
        }
    }

    notify1 = () => {
        const id = toast(<ProcessingMsg />, { autoClose: false });
        this.setState({
            toastId: id
        })
    }

    update1 = () => toast.update(this.state.toastId, {
        render: Msg,
        type: toast.TYPE.SUCCESS,
        autoClose: 2000
    });

    handleOnChange = (e) => {
        this.setState({
            otpData: { ...this.state.otpData, [e.target.name]: e.target.value }
        })
    }

    notify = () => {
        if (this.state.otpData.Otp.length <= 0) {
            toast.error(<Msg MsgType={'error'} Message={'Enter Otp'} />)
            document.getElementById('Otp').focus()
        }
        else {
            toast.success(<Msg MsgType={'success'} />, {
                autoClose: 2000
            })
        }
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "Otp" })
        document.getElementById('Otp').focus()
    }

    render() {
        return (
            <div className="Otp" >
                <ToastContainer
                    position="top-center"
                    autoClose={4000}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    transition={Zoom} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' >OTP Verification</label>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={5} >
                    <Grid item>
                        <CtTextField
                            id='Otp'
                            label='OTP'
                            value={this.state.otpData.Otp}
                            handleOnChange={this.handleOnChange}
                            width={this.CtWidth}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                    <Grid item><CtBtn label='Verify' onClick={this.notify} /></Grid>
                    <Grid item><CtBtn label='Re-Send OTP' onClick={this.notify} /></Grid>
                </Grid>
            </div >
        )
    }
}

export default Otp