import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { fetchProduct } from './API/API'
import Progress from './CustomTool/Progress'
import HomeProductSlider from './HomeProductSlider'
import HoCtToastContainer from './HOC/HoCtToastContainer'

class Home extends Component {

    state = {
        ProductList: [],
        dataLoadStatus: false,
    }

    //#region Component
    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "Home" })
        this.loadProductList()
    }
    //#endregion

    //#region Function
    loadProductList = () => {
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = { Op: 'ProductListForHomePage' }
            fetchProduct(reqData)
                .then(res => {
                    const ProductList = res.data.ProductList
                    this.setState({ ProductList, dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in loadProductList. ' + error)
                })
        })
    }
    //#endregion

    render() {
        return (
            <div className="Home">
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />

                {/* <label htmlFor="Title" className='pageTitleText' >Coming Soon</label> */}
                {
                    <HomeProductSlider
                        items={this.state.ProductList}
                    />
                }

            </div>
        )
    }
}

export default HoCtToastContainer(Home)