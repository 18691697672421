import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import TableList from "./CustomTool/TableList";
import CtDtpDOB from "./CustomTool/CtDtpDOB";
import CtBtn from "./CustomTool/CtBtn";
import { Grid } from '@material-ui/core'
import { fetchMember } from './API/API'
import { getIndianFormatedNumber } from "./SystemUtility/SystemUtility";
import Progress from './CustomTool/Progress'

class WalletBook extends Component {

    state = {
        dataLoadStatus: false,
        cWidth: 160,
        columns: [
            { id: 'SrNo', label: 'Sr\u00a0No', minWidth: 10 },
            { id: 'TranDate', label: 'Date', minWidth: 70 },
            { id: 'Operator', label: 'Operator', minWidth: 70 },
            { id: 'Ledger', label: 'Ledger', minWidth: 100 },
            { id: 'Description', label: 'Description', minWidth: 170 },
            { id: 'TransactionNo', label: 'Tran ID', minWidth: 50 },
            { id: 'Amount', label: 'Amount', minWidth: 70, format: (value) => getIndianFormatedNumber(value) },
            { id: 'TranType', label: 'Tran.\u00a0Type', minWidth: 50 },
            { id: 'Balance', label: 'Balance', minWidth: 70, format: (value) => getIndianFormatedNumber(value) },
        ],
        rowsPerPage: 5,
    }

    //#region handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    render() {

        // console.log('this.props.rows', this.props.rows)
        // console.log('this.props.rowsCount', this.props.rowsCount)
        // console.log('this.state.rowsPerPage', this.state.rowsPerPage)

        return (
            <div>
                <Progress color='secondary' display={this.props.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' >Wallet Book ( {this.props.UserID} )</label><br />
                    </Grid>
                </Grid>
                <form>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtDtpDOB
                                id='FromDate'
                                label='From Date'
                                width={this.state.cWidth}
                                handleOnChange={this.props.handleOnChangeTrimWalletBookData}
                                onKeyDown={this.props.onKeyDown}
                                defaultValue={this.props.FromDate}
                                value={this.props.FromDate}
                            />
                        </Grid>
                        <Grid item>
                            <CtDtpDOB
                                id='ToDate'
                                label='To Date'
                                width={this.state.cWidth}
                                handleOnChange={this.props.handleOnChangeTrimWalletBookData}
                                onKeyDown={this.props.onKeyDown}
                                defaultAction={this.props.listWalletBook}
                                defaultValue={this.props.ToDate}
                                value={this.props.ToDate}
                            />
                        </Grid>
                        <Grid item>
                            <CtBtn
                                label='Wallet Book'
                                onClick={this.props.listWalletBook}
                                width={this.state.cWidth}
                            />
                        </Grid>
                    </Grid>
                </form>
                <br />
                {
                    this.props.processingStatus && this.props.processingStatus !== 'success' ?
                        <label style={{ fontWeight: 'bolder' }}>{this.props.processingStatus}</label> :
                        <TableList
                            columns={this.state.columns}
                            rows={this.props.rows}
                            rowsCount={this.props.rowsCount}
                            rowsPerPage={this.state.rowsPerPage}
                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                }
            </div>
        )
    }
}
export default HoCtToastContainer(WalletBook)