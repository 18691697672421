import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import Progress from './CustomTool/Progress'
import CtBtn from './CustomTool/CtBtn'
import { Grid, Box } from '@material-ui/core'
import { fetchTree } from './API/API'

class SystemTreeBasicInfo extends Component {

    state = {
        cWidth: 200,
        dataLoadStatus: false,
        name: '',
        memberName: '',
        contactno: '',
        doa: '',
        refcode: '',
        sponsorid: '',
        cfbto:'',
        freeChileNodeCount: '',
        level:'',
        income:0
    }

    //#region Component
    componentDidMount() {
        this.onFormLoad()
    }
    componentWillMount() {
        // this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }
    //#endregion

    //#region function
    onFormLoad = () => {
        console.log(this.props.SystemTreeID);
        const reqData = {
            Op: 'GetSystemTreeBasicInfo',
            ID: this.props.SystemTreeID
        }
        fetchTree(reqData)
            .then(res => {
                // console.log(res.data.treeInfo);
                this.setState({
                    name: res.data.treeInfo[0].name,
                    memberName: res.data.treeInfo[0].memberName,
                    contactno: res.data.treeInfo[0].contactno,
                    doa: res.data.treeInfo[0].doa,
                    refcode: res.data.treeInfo[0].refcode,
                    sponsorid: res.data.treeInfo[0].sponsorid,
                    cfbto: res.data.treeInfo[0].cfamount,
                    freeChileNodeCount: res.data.treeInfo[0].freeChileNodeCount,
                    dataLoadStatus: true,
                    level:res.data.treeInfo[0].level,
                    income:res.data.treeInfo[0].totalincome

                })
                // console.log('res.data.treeInfo.name',res.data.treeInfo[0].name);
            })
    }

    onBtnNewRegistrationClick = () => {
        this.openLinkInNewWindow('Registration:000000RF' + this.state.name.substr(3))
    }

    openLinkInNewWindow = (link) => {
        // this.props.history.push('/' + link)
        link = '/#/' + link
        const newWindow = window.open(link, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    //#endregion

    render() {
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <p><b>ID</b> : {this.state.name}</p>
                <p><b>Name</b> : {this.state.memberName}</p>
                <p><b>Contact No.</b> : {this.state.contactno}</p>
                <p><b>Date of Approve</b> : {this.state.doa}</p>
                <p><b>Reference Code</b> : {this.state.refcode}</p>
                <p><b>Sponsor ID</b> : {this.state.sponsorid}</p>
                <p><b>Carry Forward BTO</b> : {this.state.cfbto}</p>
                <p><b>Level</b> : {this.state.level}</p>
                <p><b>Total Income</b> : {this.state.income}</p>
                {
                    this.state.freeChileNodeCount > 0 ?
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item>
                                <CtBtn label='New Registration' onClick={this.onBtnNewRegistrationClick} />
                            </Grid>
                        </Grid>
                        : ''
                }
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(SystemTreeBasicInfo))