import React, { Component } from 'react'
import { Grid, Box, Divider, Button, Typography, Tooltip } from '@material-ui/core'
import { DoubleArrowOutlined, ListAlt, AccountTreeOutlined, GroupOutlined, PollOutlined } from '@material-ui/icons'
import { fetchAdmin, VerifyIsAdmin, fetchMember, fetchProduct, apiURL } from './API/API'
import CtBtn from './CustomTool/CtBtn'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import AlertResponsiveDialog from './CustomTool/AlertResponsiveDialog'
import Progress from './CustomTool/Progress'
import MaxChildTreeNodeCount from './MaxChildTreeNodeCount'
import CtCardSimple from './CustomTool/CtCardSimple'
import CtTxt from './CustomTool/CtTxt'
import { getIndianFormatedNumber } from "./SystemUtility/SystemUtility"

class AdminDashboard extends Component {

    state = {
        userID: '',
        name: '',
        months: [
            { name: '01 Jan', disabled: false },
            { name: '02 Feb', disabled: false },
            { name: '03 Mar', disabled: false },
            { name: '04 Apr', disabled: false },
            { name: '05 May', disabled: false },
            { name: '06 Jun', disabled: false },
            { name: '07 Jul', disabled: false },
            { name: '08 Aug', disabled: false },
            { name: '09 Sep', disabled: false },
            { name: '10 Oct', disabled: false },
            { name: '11 Nov', disabled: false },
            { name: '12 Dec', disabled: false },
        ],
        years: [],
        royaltyData: {
            month: '',
            year: ''
        },
        alertClubRoyaltyConfirm: null,
        alertClubRoyaltyExistsConfirmToProcess: null,
        alertDirectorClubRoyaltyConfirm: null,
        alertDirectorClubRoyaltyExistsConfirmToProcess: null,
        alertDirectReferralConfirm: null,
        alertDirectReferralExistsConfirmToProcess: null,
        alertJackPotIncomeConfirm: null,
        alertJackPotIncomeExistsConfirmToProcess: null,
        alertTurnOverIncomeConfirm: null,
        alertTurnOverIncomeExistsConfirmToProcess: null,
        alertMaxChildTreeNodeCount: null,
        alertTeamInfo: null,
        dataLoadStatus: false,
        memberCount: 0,
        approvePendingCount: 0,
        cardApproveBtnList: [],
        cardMemberBtnList: [],
        cardTurnoverBtnList: [],
        cardDirectReferalBtnList: [],
        cardProuductBtnList: [],
        cardOfferBtnList: [],
        cardTourBtnList: [],
        cardActivationKitBtnList: [],
        cardInwardBtnList: [],
        cardSalesBtnList: [],
        cardApprovedWithdrawalBtnList: [],
        cardPANBankMissingBtnList: [],
        turnoverFromToDate: '',
        turnoverAmountSum: 0,
        directReferalFromToDate: '',
        directReferalAmountSum: 0,
        productCount: 0,
        offerCount: 0,
        tourCount: 0,
        activationkitCount: 0,
        inwardstockSum: 0,
        inwardSumPeriod:'',
        salesNetAmountSum: 0,
        salesNetAmountPeriod: '',
        curCycleSalesNetAmountSum:0,
        approvedWithdrawalCount: 0,
        BankMissingCount: 0,
        PANMissingCount: 0,
        memberID: '',
    }

    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    loadAdminName = (userID) => {
        VerifyIsAdmin(userID)
            .then(res => {
                if (res.data.name && res.data.name.trim().length > 0) {
                    this.setState({
                        name: res.data.name,
                        userID,
                        dataLoadStatus: true
                    })
                }
            })
    }

    loadYears = () => {
        var years = []
        var d = new Date()
        var sysYear = d.getFullYear()
        var year = 2020
        for (var curYear = sysYear; curYear >= year; curYear--) {
            years.push({ 'name': curYear.toString(), disabled: false })
        }
        this.setState({
            years
        }, () => {
            this.loadAdminName(localStorage.getItem('logInID'))
        })
    }

    componentDidMount() {
        this.loadYears()
        this.getMemberCount()
    }

    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    validateMonthYear = () => {
        let result = false
        if (this.state.royaltyData.month.length <= 0) {
            this.props.toastErrorMsg('Select Month', 'month')
        } else if (this.state.royaltyData.year.length <= 0) {
            this.props.toastErrorMsg('Select Year', 'year')
        } else {
            result = true
        }
        return result
    }

    checkClubRoyaltyExists = () => {
        let Month = this.state.royaltyData.month.substring(0, 2)
        let Year = this.state.royaltyData.year
        this.setState({ alertClubRoyaltyExistsConfirmToProcess: null })
        if (this.validateMonthYear()) {
            this.props.notifyProcessing()
            const reqData = {
                Op: 'IsClubRoyaltyGenerated',
                Month: Month,
                Year: Year,
            }
            fetchAdmin(reqData)
                .then(res => {
                    let msgType = '', message = ''
                    if (
                        res.data &&
                        res.data.Count && res.data.Count.length > 0 &&
                        res.data.Amount && res.data.Amount.length > 0
                    ) {
                        if (
                            res.data.Count.length == 1 && res.data.Count[0] == 0 &&
                            res.data.Amount.length == 1 && res.data.Amount[0] == 0
                        ) {
                            this.processClubRoyalty(false)
                        } else {
                            let alertClubRoyaltyExistsConfirmToProcess = null

                            let lenCount = res.data.Count.length
                            let lenAmount = res.data.Amount.length
                            let alertMessage = ''
                            for (let i = 0, n = lenCount > lenAmount ? lenCount : lenAmount; i < n; i++) {
                                alertMessage += res.data.Count[i] + ' members has provided Rs.' + res.data.Amount[i] + ' each.'
                            }

                            alertClubRoyaltyExistsConfirmToProcess = <AlertResponsiveDialog
                                label={'Confirm to re-process Club Royalty'}
                                labelAgree={'Re-process Club Royalty'}
                                labelDisagree={'Cancel'}
                                alertTitle={'Do you want to Re-Provide Club Royalty? Provided Club Royalty Information has been listed bellow.'}
                                alertMessage={
                                    <div>
                                        <b>Club Royalty for Month : {Month} & Year : {Year} has been provided to </b><br />
                                        {alertMessage}
                                    </div>
                                }
                                handleOnClickYes={() => { this.processClubRoyalty(true) }}
                                defaultOpenDialog={true}
                            />

                            msgType = 'warning'
                            message = 'Confirm Process'

                            this.setState({ alertClubRoyaltyExistsConfirmToProcess }, () => {
                                this.props.updateProcessing(msgType, message)
                            })

                        }
                    } else {
                        msgType = 'error'
                        message = 'No result found for Club Royalty Verification'
                    }
                    if (msgType.length > 0 && message.length > 0) {
                        this.props.updateProcessing(msgType, message)
                    }
                })
        }
    }

    processClubRoyalty = (bolDisplayProcessing) => {
        if (bolDisplayProcessing) {
            this.props.notifyProcessing()
        }
        let msgType = 'info'
        let message = 'Club Royalty to processed'
        this.setState({ alertClubRoyaltyConfirm: null })
        let Month = this.state.royaltyData.month.substring(0, 2)
        let Year = this.state.royaltyData.year
        const reqData = {
            Op: 'GetClubRoyaltyInfoOfMonthYear',
            Month: Month,
            Year: Year,
        }
        fetchAdmin(reqData)
            .then(res => {
                // console.log(res.data);
                // console.log('res.data.New3LevelCompletedID', res.data.New3LevelCompletedID)
                // console.log('res.data.New3LevelCompletedID.length', res.data.New3LevelCompletedID.length)
                // console.log('res.data.New4NHigherLevelIdList', res.data.New4NHigherLevelIdList)
                // console.log('res.data.New4NHigherLevelIdList.length', res.data.New4NHigherLevelIdList.length)
                // console.log('res.data.AmountEachuserWillGet', res.data.AmountEachuserWillGet)
                let alertClubRoyaltyConfirm = null
                msgType = 'info'
                message = 'No data found to process'
                if (res.data.NewRegisteredCount >= 1 && res.data.AmountEachuserWillGet >= 1) {
                    alertClubRoyaltyConfirm = <AlertResponsiveDialog
                        label={'Confirm Club Royalty'}
                        labelAgree={'Provide Club Royalty'}
                        labelDisagree={'Cancel'}
                        alertTitle={'Do you want to Provide Club Royalty as listed information?'}
                        alertMessage={
                            <div className='AlertInfo'>
                                <p>New Regular Approved Registration Count : {res.data.NewRegisteredCount}</p>
                                <p>New 3rd Level Completed Count : {res.data.New3LevelCompletedCount}</p>
                                <p>4th & Higher Level Count who got New 5 Registration : {res.data.New4NHigherLevelCount}</p>
                                <p>Total Amount tobe Distribute : {res.data.TotalAmountToDistribute}</p>
                                <p>Final Amount Each User Will Get : {res.data.AmountEachuserWillGet}</p>
                                <p style={{ textAlign: 'center' }}><b>New 3rd Level Completed ID List</b></p>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                    {
                                        res.data.New3LevelCompletedID.map((ID, index) => {
                                            return (
                                                <Grid item>({index + 1 < 10 ? '0' : ''}{index + 1}) <b>{ID}</b></Grid>
                                            )
                                        })
                                    }
                                </Grid>
                                <p style={{ textAlign: 'center' }}><b>4th & Higher Level ID List</b></p>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                    {
                                        res.data.New4NHigherLevelIDsInDetail.map((Data, index) => {
                                            return (
                                                <Grid item>({index + 1 < 10 ? '0' : ''}{index + 1}) <b>{Data.ID}</b></Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </div>
                        }
                        handleOnClickYes={
                            () => {
                                this.generateClubRoyaltyTransactions(
                                    res.data.New3LevelCompletedID,
                                    res.data.New4NHigherLevelIdList,
                                    res.data.AmountEachuserWillGet,
                                    Month, Year
                                )
                            }
                        }
                        defaultOpenDialog={true}
                    />
                    msgType = 'warning'
                    message = 'Confirm Process'
                }
                this.setState({ alertClubRoyaltyConfirm }, () => {
                    this.props.updateProcessing(msgType, message)
                })
            })
    }

    generateClubRoyaltyTransactions = (
        New3LevelCompletedIDs, New4NHigherLevelIdList, AmountEachuserWillGet, Month, Year
    ) => {
        this.props.notifyProcessing()
        let msgType = 'info'
        let message = 'No data found to process'
        console.log('AmountEachuserWillGet', AmountEachuserWillGet)
        console.log('New3LevelCompletedIDs.length', New3LevelCompletedIDs.length)
        console.log('New4NHigherLevelIdList.length', New4NHigherLevelIdList.length)
        if (AmountEachuserWillGet > 0 && (New3LevelCompletedIDs.length > 0 || New4NHigherLevelIdList.length > 0)) {
            const reqData = {
                Op: 'SaveTranForClubRoyaltyInfo',
                New3LevelCompletedIDs: New3LevelCompletedIDs,
                New4NHigherLevelIdList: New4NHigherLevelIdList,
                AmountEachuserWillGet: AmountEachuserWillGet,
                Month: Month,
                Year: Year
            }
            fetchAdmin(reqData)
                .then(res => {
                    msgType = res.data.msgType
                    message = res.data.message
                    this.props.updateProcessing(msgType, message)
                })
                .catch(error => {
                    console.error('Unknown Error occurred at generateClubRoyaltyTransactions. ' + error)
                })
        }
    }

    checkDirectReferralExists = () => {
        let Month = this.state.royaltyData.month.substring(0, 2)
        let Year = this.state.royaltyData.year
        this.setState({ alertDirectReferralExistsConfirmToProcess: null })
        if (this.validateMonthYear()) {
            this.props.notifyProcessing()
            const reqData = {
                Op: 'IsDirectReferrelGenerated',
                Month: Month,
                Year: Year,
            }
            fetchAdmin(reqData)
                .then(res => {
                    let msgType = '', message = ''
                    if (
                        res.data &&
                        res.data.Count && res.data.Count.length > 0 &&
                        res.data.Amount && res.data.Amount.length > 0
                    ) {
                        if (
                            res.data.Count.length == 1 && res.data.Count[0] == 0 &&
                            res.data.Amount.length == 1 && res.data.Amount[0] == 0
                        ) {
                            this.processDirectReferral(false)
                        } else {
                            let alertDirectReferralExistsConfirmToProcess = null

                            let lenCount = res.data.Count.length
                            let lenAmount = res.data.Amount.length
                            let alertMessage = ''
                            for (let i = 0, n = lenCount > lenAmount ? lenCount : lenAmount; i < n; i++) {
                                alertMessage += res.data.Count[i] + ' members has provided Rs.' + res.data.Amount[i] + ' each.'
                            }

                            alertDirectReferralExistsConfirmToProcess = <AlertResponsiveDialog
                                label={'Confirm to re-process Direct Referrel'}
                                labelAgree={'Re-process Direct Referrel'}
                                labelDisagree={'Cancel'}
                                alertTitle={'Do you want to Re-Provide Direct Referrel? Provided Direct Referrel Information has been listed bellow.'}
                                alertMessage={
                                    <div>
                                        <b>Direct Referrel for Month : {Month} & Year : {Year} has been provided to </b><br />
                                        {alertMessage}
                                    </div>
                                }
                                handleOnClickYes={() => { this.processDirectReferral(true) }}
                                defaultOpenDialog={true}
                            />

                            msgType = 'warning'
                            message = 'Confirm Process'

                            this.setState({ alertDirectReferralExistsConfirmToProcess }, () => {
                                this.props.updateProcessing(msgType, message)
                            })

                        }
                    } else {
                        msgType = 'error'
                        message = 'No result found for Direct Referrel Verification'
                    }
                    if (msgType.length > 0 && message.length > 0) {
                        this.props.updateProcessing(msgType, message)
                    }
                })
        }
    }

    processDirectReferral = (bolDisplayProcessing) => {
        if (bolDisplayProcessing) {
            this.props.notifyProcessing()
        }
        let msgType = 'info'
        let message = 'Direct Referrel to processed'
        this.setState({ alertDirectReferralConfirm: null })
        let Month = this.state.royaltyData.month.substring(0, 2)
        let Year = this.state.royaltyData.year
        const reqData = {
            Op: 'GetDirectReferalIncomeInfo',
            Month: Month,
            Year: Year,
        }
        fetchAdmin(reqData)
            .then(res => {
                let alertDirectReferralConfirm = null
                msgType = 'info'
                message = 'No data found to process'
                if (res.data.ReferalIncomeIdCount >= 1 && res.data.TotalReferanceIncome >= 1) {
                    alertDirectReferralConfirm = <AlertResponsiveDialog
                        label={'Confirm Direct Referrel'}
                        labelAgree={'Provide Direct Referrel'}
                        labelDisagree={'Cancel'}
                        alertTitle={'Do you want to Provide Direct Referrel as listed information?'}
                        alertMessage={
                            <div className='AlertInfo'>
                                <p>Referal Income Id Count : {res.data.ReferalIncomeIdCount}</p>
                                <p>Total Referance Income : {res.data.TotalReferanceIncome}</p>
                                <p>Total Transaction : {res.TotalSrcIncome}</p>
                                <p style={{ textAlign: 'center' }}><b>ID wise Total Referral Income List</b></p>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                    {
                                        res.data.RefIDTotIncome.map((Ref, index) => {
                                            return (
                                                <Grid item>({index + 1 < 10 ? '0' : ''}{index + 1}) <b>{Ref.ID} [Rs.{Ref.Amt}] {'{'} {Ref.srcIDList} {'}'}</b></Grid>
                                            )
                                        })
                                    }
                                </Grid>
                                <p style={{ textAlign: 'center' }}><b>All Transaction Detail List</b></p>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                    {
                                        res.data.AllTranDetailList.map((Tran, index) => {
                                            return (
                                                <Grid item>
                                                    ({index + 1 < 10 ? '0' : ''}{index + 1})
                                                    <b>[Rs.{Tran.srcAmt}] {'>>'} {Tran.srcID} {'>>'} {Tran.destID} [Rs.{Tran.Amt}]</b>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </div>
                        }
                        handleOnClickYes={
                            () => {
                                this.generateDirectReferralTransactions(
                                    res.data.ReferalAmt,
                                    res.data.SourceID,
                                    res.data.DestinationID,
                                    Month, Year
                                )
                            }
                        }
                        defaultOpenDialog={true}
                    />
                    msgType = 'warning'
                    message = 'Confirm Process'
                }
                this.setState({ alertDirectReferralConfirm }, () => {
                    this.props.updateProcessing(msgType, message)
                })
            })
    }

    generateDirectReferralTransactions = (
        ReferalAmt, SourceID, DestinationID, Month, Year
    ) => {
        this.props.notifyProcessing()
        let msgType = 'info'
        let message = 'No data found to process'
        if (ReferalAmt.length > 0 && SourceID.length > 0 && DestinationID.length > 0) {
            const reqData = {
                Op: 'SaveTranForDirectReferalIncome',
                ReferalAmt: ReferalAmt,
                SourceID: SourceID,
                DestinationID: DestinationID,
                Month: Month,
                Year: Year
            }
            fetchAdmin(reqData)
                .then(res => {
                    msgType = res.data.msgType
                    message = res.data.message
                    this.props.updateProcessing(msgType, message)
                })
        }
    }

    checkDirectorClubRoyaltyExists = () => {
        let Month = this.state.royaltyData.month.substring(0, 2)
        let Year = this.state.royaltyData.year
        this.setState({ alertDirectorClubRoyaltyExistsConfirmToProcess: null })
        if (this.validateMonthYear()) {
            this.props.notifyProcessing()
            const reqData = {
                Op: 'IsDirectorClubRoyaltyGenerated',
                Month: Month,
                Year: Year,
            }
            fetchAdmin(reqData)
                .then(res => {
                    let msgType = '', message = ''
                    if (
                        res.data &&
                        res.data.Count && res.data.Count.length > 0 &&
                        res.data.Amount && res.data.Amount.length > 0
                    ) {
                        if (
                            res.data.Count.length == 1 && res.data.Count[0] == 0 &&
                            res.data.Amount.length == 1 && res.data.Amount[0] == 0
                        ) {
                            this.processDirectorClubRoyalty(false)
                        } else {
                            let alertDirectorClubRoyaltyExistsConfirmToProcess = null

                            let lenCount = res.data.Count.length
                            let lenAmount = res.data.Amount.length
                            let alertMessage = ''
                            for (let i = 0, n = lenCount > lenAmount ? lenCount : lenAmount; i < n; i++) {
                                alertMessage += res.data.Count[i] + ' members has provided Rs.' + res.data.Amount[i] + ' each.'
                            }

                            alertDirectorClubRoyaltyExistsConfirmToProcess = <AlertResponsiveDialog
                                label={'Confirm to re-process Director Club Royalty'}
                                labelAgree={'Re-process Director Club Royalty'}
                                labelDisagree={'Cancel'}
                                alertTitle={'Do you want to Re-Provide Director Club Royalty? Provided Director Club Royalty Information has been listed bellow.'}
                                alertMessage={
                                    <div>
                                        <b>Director Club Royalty for Month : {Month} & Year : {Year} has been provided to </b><br />
                                        {alertMessage}
                                    </div>
                                }
                                handleOnClickYes={() => { this.processDirectorClubRoyalty(true) }}
                                defaultOpenDialog={true}
                            />

                            msgType = 'warning'
                            message = 'Confirm Process'

                            this.setState({ alertDirectorClubRoyaltyExistsConfirmToProcess }, () => {
                                this.props.updateProcessing(msgType, message)
                            })

                        }
                    } else {
                        msgType = 'error'
                        message = 'No result found for Director Club Royalty Verification'
                    }
                    if (msgType.length > 0 && message.length > 0) {
                        this.props.updateProcessing(msgType, message)
                    }
                })
        }
    }

    processDirectorClubRoyalty = (bolDisplayProcessing) => {
        if (bolDisplayProcessing) {
            this.props.notifyProcessing()
        }
        let msgType = 'info'
        let message = 'Director Club Royalty to processed'
        this.setState({ alertDirectorClubRoyaltyConfirm: null })
        let Month = this.state.royaltyData.month.substring(0, 2)
        let Year = this.state.royaltyData.year
        const reqData = {
            Op: 'GetDirectorClubRoyaltyInfoOfMonthYear',
            Month: Month,
            Year: Year,
        }
        fetchAdmin(reqData)
            .then(res => {
                let alertDirectorClubRoyaltyConfirm = null
                msgType = 'info'
                message = 'No data found to process'
                if (res.data.NewRegisteredCount >= 1 && res.data.AmountEachuserWillGet >= 1) {
                    alertDirectorClubRoyaltyConfirm = <AlertResponsiveDialog
                        label={'Confirm Director Club Royalty'}
                        labelAgree={'Provide Director Club Royalty'}
                        labelDisagree={'Cancel'}
                        alertTitle={'Do you want to Provide Director Club Royalty as listed information?'}
                        alertMessage={
                            <div className='AlertInfo'>
                                <p>New Regular Approved Registration Count : {res.data.NewRegisteredCount}</p>
                                <p>4th Level Completed Count : {res.data.Past4LevelCompletedCount}</p>
                                <p>Total Amount tobe Distribute : {res.data.TotalAmountToDistribute}</p>
                                <p>Final Amount Each User Will Get : {res.data.AmountEachuserWillGet}</p>
                                <p style={{ textAlign: 'center' }}><b>4th Level Completed ID List</b></p>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                    {
                                        res.data.Past4LevelCompletedID.map((ID, index) => {
                                            return (
                                                <Grid item>({index + 1 < 10 ? '0' : ''}{index + 1}) <b>{ID}</b></Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </div>
                        }
                        handleOnClickYes={
                            () => {
                                this.generateDirectorClubRoyaltyTransactions(
                                    res.data.Past4LevelCompletedID,
                                    res.data.New4NHigherLevelIdList,
                                    res.data.AmountEachuserWillGet,
                                    Month, Year
                                )
                            }
                        }
                        defaultOpenDialog={true}
                    />
                    msgType = 'warning'
                    message = 'Confirm Process'
                }
                this.setState({ alertDirectorClubRoyaltyConfirm }, () => {
                    this.props.updateProcessing(msgType, message)
                })
            })
    }

    generateDirectorClubRoyaltyTransactions = (
        Past4LevelCompletedIDs, New4NHigherLevelIdList, AmountEachuserWillGet, Month, Year
    ) => {
        this.props.notifyProcessing()
        let msgType = 'info'
        let message = 'No data found to process'
        if (AmountEachuserWillGet > 0 && (Past4LevelCompletedIDs.length > 0 || New4NHigherLevelIdList.length > 0)) {
            const reqData = {
                Op: 'SaveTranForDirectorClubRoyaltyInfo',
                Past4LevelCompletedIDs: Past4LevelCompletedIDs,
                New4NHigherLevelIdList: New4NHigherLevelIdList,
                AmountEachuserWillGet: AmountEachuserWillGet,
                Month: Month,
                Year: Year
            }
            fetchAdmin(reqData)
                .then(res => {
                    msgType = res.data.msgType
                    message = res.data.message
                    this.props.updateProcessing(msgType, message)
                })
        }
    }

    ProvideUnPaidLevelIncomeToRegularApprovedMember = () => {
        this.props.notifyProcessing()
        const reqData = {
            Op: 'ProvideUnPaidLevelIncomeToRegularApprovedMember'
        }
        fetchMember(reqData)
            .then(res => {
                this.props.updateProcessing(res.data.msgType, res.data.message)
            })
    }

    processJackPotIncome = (bolDisplayProcessing) => {
        if (bolDisplayProcessing) {
            this.props.notifyProcessing()
        }
        let msgType = 'info'
        let message = 'JackPot Income to processed'
        this.setState({ alertJackPotIncomeConfirm: null })
        let Month = this.state.royaltyData.month.substring(0, 2)
        let Year = this.state.royaltyData.year
        const reqData = {
            Op: 'GetJackPotIncomeInfo',
            Month: Month,
            Year: Year,
        }
        fetchAdmin(reqData)
            .then(res => {
                let alertJackPotIncomeConfirm = null
                msgType = 'info'
                message = 'No data found to process'
                if (res.data.totalIDCount >= 1 && res.data.totalAmount >= 1) {
                    alertJackPotIncomeConfirm = <AlertResponsiveDialog
                        label={'Confirm JackPot Income'}
                        labelAgree={'Provide JackPot Income'}
                        labelDisagree={'Cancel'}
                        alertTitle={'Do you want to Provide JackPot Income as listed information?'}
                        alertMessage={
                            <div className='AlertInfo'>
                                <p>JackPot Income Id Count : {res.data.totalIDCount}</p>
                                <p>Total Total JackPot Income to be Provide : {res.data.totalAmount}</p>
                                <p style={{ textAlign: 'center' }}><b>ID wise Total JackPot Income List</b></p>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                    {
                                        res.data.JackpotIDAmtLevel.map((JI, index) => {
                                            return (
                                                <Grid item>({index + 1 < 10 ? '0' : ''}{index + 1})
                                                    <b>{JI.ID} [Rs.{JI.Amt}] {'{ Level : '} {JI.Level} {' }'}</b>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </div>
                        }
                        handleOnClickYes={
                            () => {
                                this.generateJackPotIncomeTransactions(
                                    res.data.JackpotIDAmtLevel,
                                    Month, Year
                                )
                            }
                        }
                        defaultOpenDialog={true}
                    />
                    msgType = 'warning'
                    message = 'Confirm Process'
                }
                this.setState({ alertJackPotIncomeConfirm }, () => {
                    this.props.updateProcessing(msgType, message)
                })
            })
    }

    generateJackPotIncomeTransactions = (
        JackpotIDAmtLevel, Month, Year
    ) => {
        this.props.notifyProcessing()
        let msgType = 'info'
        let message = 'No data found to process'
        if (JackpotIDAmtLevel.length > 0) {
            const reqData = {
                Op: 'SaveJackpotIncomeTransaction',
                JackpotIDAmtLevel: JackpotIDAmtLevel,
                Month: Month,
                Year: Year
            }
            fetchAdmin(reqData)
                .then(res => {
                    msgType = res.data.msgType
                    message = res.data.message
                    this.props.updateProcessing(msgType, message)
                })
        }
    }

    /* checkJackPotIncomeExists = () => {
        let Month = this.state.royaltyData.month.substring(0, 2)
        let Year = this.state.royaltyData.year
        this.setState({ alertJackPotIncomeConfirm: null })
        if (this.validateMonthYear()) {
            this.props.notifyProcessing()
            const reqData = {
                Op: 'IsJackpotIncomeGenerated',
                Month: Month,
                Year: Year,
            }
            fetchAdmin(reqData)
                .then(res => {
                    let msgType = '', message = ''
                    if (
                        res.data &&
                        res.data.Count && res.data.Count.length > 0 &&
                        res.data.Amount && res.data.Amount.length > 0
                    ) {
                        if (
                            res.data.Count.length == 1 && res.data.Count[0] == 0 &&
                            res.data.Amount.length == 1 && res.data.Amount[0] == 0
                        ) {
                            this.processJackPotIncome(false)
                        } else {
                            let alertJackPotIncomeExistsConfirmToProcess = null

                            let lenCount = res.data.Count.length
                            let lenAmount = res.data.Amount.length
                            let alertMessage = ''
                            for (let i = 0, n = lenCount > lenAmount ? lenCount : lenAmount; i < n; i++) {
                                alertMessage += res.data.Count[i] + ' members has provided Rs.' + res.data.Amount[i] + ' each.'
                            }

                            alertJackPotIncomeExistsConfirmToProcess = <AlertResponsiveDialog
                                label={'Confirm to re-process Jackpot Income'}
                                labelAgree={'Re-process Jackpot Income'}
                                labelDisagree={'Cancel'}
                                alertTitle={'Do you want to Re-Provide Jackpot Income? Provided Jackpot Income Information has been listed bellow.'}
                                alertMessage={
                                    <div>
                                        <b>Jackpot Income for Month : {Month} & Year : {Year} has been provided to </b><br />
                                        {alertMessage}
                                    </div>
                                }
                                handleOnClickYes={() => { this.processJackPotIncome(true) }}
                                defaultOpenDialog={true}
                            />

                            msgType = 'warning'
                            message = 'Confirm Process'

                            this.setState({ alertJackPotIncomeExistsConfirmToProcess }, () => {
                                this.props.updateProcessing(msgType, message)
                            })

                        }
                    } else {
                        msgType = 'error'
                        message = 'No result found for Jackpot Income Verification'
                    }
                    if (msgType.length > 0 && message.length > 0) {
                        this.props.updateProcessing(msgType, message)
                    }
                })
        }
    } */

    checkJackPotIncomeExists = () => {
        this.setState({ alertJackPotIncomeConfirm: null })
        this.props.notifyProcessing()
        this.processJackPotIncome(false)
    }

    handleOnChange = (e) => {
        this.setState({
            royaltyData: { ...this.state.royaltyData, [e.target.name]: e.target.value }
        })
    }

    handleOnChangeForTeamInfo = (e) => {
        this.setState({
            memberID: e.target.value
        })
    }

    SetMemberWalletBalance = () => {
        this.props.notifyProcessing()
        const reqData = { Op: 'SetMemberWalletBalance' }
        fetchAdmin(reqData)
            .then(res => {
                this.props.updateProcessing(res.data.msgType, res.data.message)
            })
            .catch(error => {
                this.props.toastErrorMsg('Unknown Error occured in SetMemberWalletBalance. ' + error)
            })
    }

    getMemberCount = () => {
        // this.props.notifyProcessing()
        const reqData = { Op: 'GetMemberCountNApproveCount' }
        fetchMember(reqData)
            .then(res => {
                // this.props.updateProcessing(res.data.msgType, res.data.message)
                const memberCount = res.data.Approve_Active_Member_Count
                const approvePendingCount = res.data.Approve_Pending_Member_Count
                const turnoverFromToDate = res.data.turnovercommission[0].fromdt + " to " + res.data.turnovercommission[0].todt;
                const turnoverAmountSum = res.data.turnovercommission[0].turnovercommissionsum;
                const directReferalFromToDate = res.data.DirectReferalCount.length > 0 ? res.data.DirectReferalCount[0].tranfromdt + " to " + res.data.DirectReferalCount[0].trantodt : '';
                const directReferalAmountSum = res.data.DirectReferalCount.length > 0 ? res.data.DirectReferalCount[0].amount : '';
                const productCount = res.data.ProductDetail[0].ProductCount;
                const offerCount = res.data.OfferDetail[0].OfferCount;
                const tourCount = res.data.TourDetail[0].TourCount;
                const activationkitCount = res.data.ActivationKitDetail[0].AKCount;
                const inwardstockSum = res.data.InwardDetail[0].InwardSum;
                const inwardSumPeriod = res.data.InwardDetail[0].InwardSumPeriod;
                const salesNetAmountSum = res.data.SalesDetail[0].SalesNetAmountSum;
                const salesNetAmountPeriod = res.data.SalesDetail[0].SalesNetAmountPeriod;
                const curCycleSalesNetAmountSum = res.data.CurCycleSalesNetAmount[0].CurCycleSalesNetAmountSum;
                const approvedWithdrawalCount = res.data.WalletBalanceSum;
                const BankMissingCount = res.data.PANBankDetailMissingCount.BankMissingCount;
                const PANMissingCount = res.data.PANBankDetailMissingCount.PANMissingCount;

                this.setState({
                    memberCount,
                    approvePendingCount,
                    turnoverAmountSum,
                    turnoverFromToDate,
                    directReferalFromToDate,
                    directReferalAmountSum,
                    productCount,
                    offerCount,
                    tourCount,
                    activationkitCount,
                    inwardstockSum,
                    inwardSumPeriod,
                    salesNetAmountSum,
                    salesNetAmountPeriod,
                    curCycleSalesNetAmountSum,
                    approvedWithdrawalCount,
                    BankMissingCount,
                    PANMissingCount,
                })
            })
            .catch(error => {
                this.props.toastErrorMsg('Unknown Error occured in getMemberCount. ' + error)
            })
    }

    maxChildTreeNodeCount = () => {
        let alertMaxChildTreeNodeCount = null
        this.setState({ alertMaxChildTreeNodeCount }, () => {
            alertMaxChildTreeNodeCount = <AlertResponsiveDialog
                // label={'Max Child Tree Node Count'}
                labelAgree={'Set'}
                labelDisagree={'Cancel'}
                // alertTitle={'Max Child Tree Node Count'}
                alertMessage={
                    <MaxChildTreeNodeCount getMaxChildTreeNodeCount={this.getMaxChildTreeNodeCount} />
                }
                handleOnClickYes={() => { this.setMaxChildTreeNodeCount() }}
                defaultOpenDialog={true}
            />
            this.setState({ alertMaxChildTreeNodeCount })
        })
    }

    getMaxChildTreeNodeCount = (MaxNode) => {
        return MaxNode
    }

    setMaxChildTreeNodeCount = () => {
        this.props.notifyProcessing()
        const reqData = {
            Op: 'SaveMaxChildTreeNodeCount',
            MaxNode: ''
        }
        fetchMember(reqData)
            .then(res => {
                this.props.updateProcessing(res.data.msgType, res.data.message)
            })
            .catch(error => {
                this.props.toastErrorMsg('Unknown Error occured in setMaxChildTreeNodeCount. ' + error)
            })
    }

    refreshAllMemberTeamSize = () => {
        this.props.notifyProcessing()
        const reqData = {
            Op: 'RefreshAllMemberTeamSize'
        }
        fetchMember(reqData)
            .then(res => {
                this.props.updateProcessing(res.data.msgType, res.data.message)
            })
            .catch(error => {
                this.props.toastErrorMsg('Unknown Error occured in refreshAllMemberTeamSize. ' + error)
            })
    }

    recalculateStock = () => {
        this.props.notifyProcessing()
        const reqData = {
            Op: 'RecalculateStock'
        }
        fetchProduct(reqData)
            .then(res => {
                this.props.updateProcessing(res.data.msgType, res.data.message)
            })
            .catch(error => {
                this.props.toastErrorMsg('Unknown Error occured in recalculateStock. ' + error)
            })
    }

    openTeamInfo = () => {
        let alertTeamInfo = null
        this.setState({ alertTeamInfo }, () => {
            alertTeamInfo = <AlertResponsiveDialog
                // label={'Max Child Tree Node Count'}
                labelAgree={'Open Team Info'}
                labelDisagree={'Cancel'}
                // alertTitle={'Max Child Tree Node Count'}
                alertMessage={
                    <div>
                        <Grid container direction="row" justify="center" alignItems="right" spacing={3}>
                            <Grid item className="pageTitle">
                                <label htmlFor="Title" className='pageTitleText'>Enter Member ID</label>
                            </Grid>
                        </Grid>
                        <form>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                                <Grid item>
                                    <CtTxt
                                        id='txtMemberID'
                                        label='ID'
                                        handleOnChange={this.handleOnChangeForTeamInfo}
                                        value={this.state.profileData}
                                        width="300"
                                        maxLength='10'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                }
                handleOnClickYes={() => { this.chkForRootMember() }}
                defaultOpenDialog={true}
                onYesClickCloseIfExeSuccessfully={true}
            />
            this.setState({ alertTeamInfo })
        })
    }

    validatedata = () => {
        let validatedata = false
        if (this.state.memberID.length <= 0) {
            this.props.toastErrorMsg('Enter Member ID', 'txtMemberID')
        } else {
            validatedata = true
        }
        return validatedata
    }

    chkForRootMember = () => {
        if (this.validatedata()) {
            this.props.notifyProcessing()
            const reqData = {
                Op: 'CheckRootMemberForTeamMemberPDF',
                memberid: this.state.memberID,
                rootmember: this.props.privilegesList.rootmember
            }
            fetchMember(reqData)
                .then(res => {
                    if (res.data.msgType == "success") {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.openTeamInfoPDF()
                    } else {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                    }

                })
                .catch(error => {
                    this.props.toastErrorMsg('Unknown Error occured in chkForRootMember. ' + error)
                })
        }
    }

    openTeamInfoPDF = () => {
        let reqData = [
            { name: 'Op', value: 'OpenTeamInfoPDF' },
            { name: 'ID', value: this.state.memberID },
        ]
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", apiURL + 'print.php');
        form.setAttribute("target", "view");

        reqData.forEach((data) => {
            console.log("data", data);
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", data.name);
            hiddenField.setAttribute("value", data.value);
            form.appendChild(hiddenField);
            document.body.appendChild(form);
        })
        window.open('', 'view');
        form.submit();
    }

    recalculateMemberIncome = () =>{
        this.props.notifyProcessing()
        const reqData = {
            Op: 'RecalculateMemberIncome'
        }
        fetchMember(reqData)
            .then(res => {
                this.props.updateProcessing(res.data.msgType, res.data.message)
            })
            .catch(error => {
                this.props.toastErrorMsg('Unknown Error occured in recalculateMemberIncome. ' + error)
            })
    }

    render() {

        const AdminOnlyVisibleState = (this.props.privilegesList.admin === '1') ? 'block' : 'none'

        const typoCardContentStyle = { fontSize: '1.2em' }
        if (this.state.cardApproveBtnList.length == 0) {
            this.setState({
                cardApproveBtnList: [{ btnText: <div style={{ display: 'flex' }}><span>Open Approval List</span>&nbsp;<DoubleArrowOutlined style={{ height: '0.9em' }} /></div>, handleOnBtnClick: () => { this.navigateTo('ApproveMemberList:' + this.props.privilegesList.rootmember) } }]
            })
        }
        if (this.state.cardMemberBtnList.length == 0) {
            // alert('this.props.privilegesList.privpersonaltree : '+this.props.privilegesList.privpersonaltree)
            this.setState({
                cardMemberBtnList: [
                    { btnText: <div style={{ display: 'flex' }}><Tooltip title='Open Member List'><ListAlt style={{ height: '0.9em' }} /></Tooltip></div>, handleOnBtnClick: () => { this.navigateTo('MemberList:' + this.props.privilegesList.rootmember) } },

                    { btnText: <div style={{ display: 'flex' }}><Tooltip title='Open Personal Tree'><AccountTreeOutlined style={{ height: '0.9em' }} /></Tooltip></div>, handleOnBtnClick: () => { this.navigateTo('PersonalTree:' + this.props.privilegesList.rootmember) } },

                    { btnText: <div style={{ display: 'flex' }}><Tooltip title='Open Team Info'><GroupOutlined style={{ height: '0.9em' }} /></Tooltip></div>, handleOnBtnClick: () => { this.openTeamInfo() } },

                    { btnText: <div style={{ display: 'flex' }}><Tooltip title='Level wise Member Count'><PollOutlined style={{ height: '0.9em' }} /></Tooltip></div>, handleOnBtnClick: () => { this.navigateTo('LevelWiseMemberCount:' + this.props.privilegesList.rootmember) } }
                ]
            }, () => {
                // alert(this.props.privilegesList.privpersonaltree)
            })
        }
        if (this.state.cardTurnoverBtnList.length == 0) {
            this.setState({
                cardTurnoverBtnList: [{ btnText: <div style={{ display: 'flex' }}><span>Open Turnover Income List</span>&nbsp;<DoubleArrowOutlined style={{ height: '0.9em' }} /></div>, handleOnBtnClick: () => { this.navigateTo('TurnoverCommission:' + this.props.privilegesList.rootmember) } }]
            })
        }
        if (this.state.cardDirectReferalBtnList.length == 0) {
            this.setState({
                cardDirectReferalBtnList: [{ btnText: <div style={{ display: 'flex' }}><span>Open Direct Referal  List</span>&nbsp;<DoubleArrowOutlined style={{ height: '0.9em' }} /></div>, handleOnBtnClick: () => { this.navigateTo('DirectReference:' + this.props.privilegesList.rootmember) } }]
            })
        }
        if (this.state.cardProuductBtnList.length == 0) {
            this.setState({
                cardProuductBtnList: [{ btnText: <div style={{ display: 'flex' }}><span>Open Product List</span>&nbsp;<DoubleArrowOutlined style={{ height: '0.9em' }} /></div>, handleOnBtnClick: () => { this.navigateTo('Product') } }]
            })
        }
        if (this.state.cardOfferBtnList.length == 0) {
            this.setState({
                cardOfferBtnList: [{ btnText: <div style={{ display: 'flex' }}><span>Open Offer List</span>&nbsp;<DoubleArrowOutlined style={{ height: '0.9em' }} /></div>, handleOnBtnClick: () => { this.navigateTo('OfferList') } }]
            })
        }
        if (this.state.cardTourBtnList.length == 0) {
            this.setState({
                cardTourBtnList: [{ btnText: <div style={{ display: 'flex' }}><span>Open Tour List</span>&nbsp;<DoubleArrowOutlined style={{ height: '0.9em' }} /></div>, handleOnBtnClick: () => { this.navigateTo('TourList') } }]
            })
        }
        if (this.state.cardActivationKitBtnList.length == 0) {
            this.setState({
                cardActivationKitBtnList: [{ btnText: <div style={{ display: 'flex' }}><span>Open Activation Kit</span>&nbsp;<DoubleArrowOutlined style={{ height: '0.9em' }} /></div>, handleOnBtnClick: () => { this.navigateTo('ActivationKitList') } }]
            })
        }
        if (this.state.cardInwardBtnList.length == 0) {
            this.setState({
                cardInwardBtnList: [{ btnText: <div style={{ display: 'flex' }}><span>Open Inward Stock List</span>&nbsp;<DoubleArrowOutlined style={{ height: '0.9em' }} /></div>, handleOnBtnClick: () => { this.navigateTo('InwardStock') } }]
            })
        }
        if (this.state.cardSalesBtnList.length == 0) {
            this.setState({
                cardSalesBtnList: [{ btnText: <div style={{ display: 'flex' }}><span>Open Sales List</span>&nbsp;<DoubleArrowOutlined style={{ height: '0.9em' }} /></div>, handleOnBtnClick: () => { this.navigateTo('Sales:' + this.props.privilegesList.rootmember) } }]
            })
        }
        if (this.state.cardApprovedWithdrawalBtnList.length == 0) {
            this.setState({
                cardApprovedWithdrawalBtnList: [{ btnText: <div style={{ display: 'flex' }}><span>Open Withdrawal List</span>&nbsp;<DoubleArrowOutlined style={{ height: '0.9em' }} /></div>, handleOnBtnClick: () => { this.navigateTo('WithdrawalList:' + this.props.privilegesList.rootmember) } }]
            })
        }
        if (this.state.cardPANBankMissingBtnList.length == 0) {
            this.setState({
                cardPANBankMissingBtnList: [{ btnText: <div style={{ display: 'flex' }}><span>Open Missing List</span>&nbsp;<DoubleArrowOutlined style={{ height: '0.9em' }} /></div>, handleOnBtnClick: () => { this.navigateTo('MemberPanNoBankDetailMissingList:PAN:' + this.props.privilegesList.rootmember) } }]
            })
        }

        const bull = <span>•</span>;
        return (
            <div className="AdminDashboard">
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center" style={{ marginTop: '10px' }}>
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' >{this.state.name}</label>
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={5}>
                    {
                        ((this.props.privilegesList.privapprovemember === 'M' || this.props.privilegesList.privapprovemember === 'R') && this.props.privilegesList.privdashboard === 'A') ?
                            <Grid item>
                                <CtCardSimple
                                    minHeightCardContent='110px'
                                    cardContent={
                                        <div>
                                            <Typography gutterBottom>
                                                Pending Approval Count
                                            </Typography>
                                            <Typography variant="h5" component="h2">
                                                {bull} {this.state.approvePendingCount}
                                            </Typography>
                                        </div>
                                    }
                                    btnList={this.state.cardApproveBtnList}
                                />
                            </Grid> : ""
                    }
                    {
                        (this.props.privilegesList.privmember === 'A' && this.props.privilegesList.privdashboard === 'A') ?
                            <Grid item>
                                <CtCardSimple
                                    minHeightCardContent='110px'
                                    cardContent={
                                        <div>
                                            <Typography gutterBottom>
                                                Member Count
                                            </Typography>
                                            <Typography variant="h5" component="h2">
                                                {bull} {this.state.memberCount}
                                            </Typography>
                                        </div>
                                    }
                                    btnList={this.state.cardMemberBtnList}
                                />
                            </Grid> : ""
                    }
                    <Grid item>
                        <CtCardSimple
                            minHeightCardContent='110px'
                            contentPaddingBottomZero={true}
                            cardContent={
                                <div>
                                    <Typography gutterBottom>
                                        Turnover Commission
                                    </Typography>
                                    <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                        {this.state.turnoverFromToDate}
                                    </Typography>
                                    <Typography variant="h5" component="h2" >
                                        {bull} {getIndianFormatedNumber(this.state.turnoverAmountSum)}
                                    </Typography>
                                </div>
                            }
                            btnList={this.state.cardTurnoverBtnList}
                        />
                    </Grid>
                    {
                        (this.props.privilegesList.privdirectreference === 'A' && this.props.privilegesList.privdashboard === 'A') ?
                            <Grid item>
                                <CtCardSimple
                                    minHeightCardContent='110px'
                                    contentPaddingBottomZero={true}
                                    cardContent={
                                        <div>
                                            <Typography gutterBottom>
                                                Direct Referal Income
                                            </Typography>
                                            <Typography variant="h5" component="h2" style={typoCardContentStyle}>
                                                {this.state.directReferalFromToDate}
                                            </Typography>
                                            <Typography variant="h5" component="h2">
                                                {bull} {getIndianFormatedNumber(this.state.directReferalAmountSum)}
                                            </Typography>
                                        </div>
                                    }
                                    btnList={this.state.cardDirectReferalBtnList}
                                />
                            </Grid> : ""
                    }
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={5}>
                    {
                        (this.props.privilegesList.privproduct === 'A' && this.props.privilegesList.privdashboard === 'A') ?
                            <Grid item>
                                <CtCardSimple
                                    cardContent={
                                        <div>
                                            <Typography gutterBottom>
                                                Product
                                            </Typography>
                                            <Typography variant="h5" component="h2">
                                                {bull} {this.state.productCount}
                                            </Typography>
                                        </div>
                                    }
                                    btnList={this.state.cardProuductBtnList}
                                />
                            </Grid> : ""
                    }
                    {
                        (this.props.privilegesList.privoffer === 'A' && this.props.privilegesList.privdashboard === 'A') ?
                            <Grid item>
                                <CtCardSimple
                                    cardContent={
                                        <div>
                                            <Typography gutterBottom>
                                                Offer
                                            </Typography>
                                            <Typography variant="h5" component="h2">
                                                {bull} {this.state.offerCount}
                                            </Typography>
                                        </div>
                                    }
                                    btnList={this.state.cardOfferBtnList}
                                />
                            </Grid> : ""
                    }
                    {
                        (this.props.privilegesList.privtour === 'A' && this.props.privilegesList.privdashboard === 'A') ?
                            <Grid item>
                                <CtCardSimple
                                    cardContent={
                                        <div>
                                            <Typography gutterBottom>
                                                Tour
                                            </Typography>
                                            <Typography variant="h5" component="h2">
                                                {bull} {this.state.tourCount}
                                            </Typography>
                                        </div>
                                    }
                                    btnList={this.state.cardTourBtnList}
                                />
                            </Grid> : ""
                    }
                    {
                        (this.props.privilegesList.privactivationkit === 'A' && this.props.privilegesList.privdashboard === 'A') ?
                            <Grid item>
                                <CtCardSimple
                                    cardContent={
                                        <div>
                                            <Typography gutterBottom>
                                                Activation Kit
                                            </Typography>
                                            <Typography variant="h5" component="h2">
                                                {bull} {this.state.activationkitCount}
                                            </Typography>
                                        </div>
                                    }
                                    btnList={this.state.cardActivationKitBtnList}
                                />
                            </Grid> : ""
                    }
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={5}>
                    {
                        (this.props.privilegesList.privinwardstock === 'A' && this.props.privilegesList.privdashboard === 'A') ?
                            <Grid item>
                                <CtCardSimple
                                    minHeightCardContent='155px'
                                    cardContent={
                                        <div>
                                            <Typography gutterBottom>
                                                {this.state.inwardSumPeriod} Total Inward
                                            </Typography>
                                            <Typography variant="h5" component="h2">
                                                {bull} {this.state.inwardstockSum}
                                            </Typography>
                                        </div>
                                    }
                                    btnList={this.state.cardInwardBtnList}
                                />
                            </Grid> : ""
                    }
                    {
                        ((this.props.privilegesList.privsalesrwm === 'R' || this.props.privilegesList.privsalesrwm === 'W' || this.props.privilegesList.privsalesrwm === 'M') && this.props.privilegesList.privdashboard === 'A') ?
                            <Grid item>
                                <CtCardSimple
                                    minHeightCardContent='155px'
                                    cardContent={
                                        <div>
                                            <Typography>
                                                {this.state.salesNetAmountPeriod} Total Sales
                                            </Typography>
                                            <Typography variant="h5" component="h2" gutterBottom>
                                                {bull} {getIndianFormatedNumber(this.state.salesNetAmountSum)}
                                            </Typography>
                                            <Typography>
                                                Current Cycle Sales
                                            </Typography>
                                            <Typography variant="h5" component="h2">
                                                {bull} {getIndianFormatedNumber(this.state.curCycleSalesNetAmountSum)}
                                            </Typography>
                                        </div>
                                    }
                                    btnList={this.state.cardSalesBtnList}
                                />
                            </Grid> : ""
                    }
                    <Grid item>
                        <CtCardSimple
                            minHeightCardContent='155px'
                            cardContent={
                                <div>
                                    <Typography gutterBottom>
                                        Approve Withdrawal
                                    </Typography>
                                    <Typography variant="h5" component="h2">
                                        {bull} {getIndianFormatedNumber(this.state.approvedWithdrawalCount)}
                                    </Typography>
                                </div>
                            }
                            btnList={this.state.cardApprovedWithdrawalBtnList}
                        />
                    </Grid>
                    {
                        (this.props.privilegesList.privdashboard === 'A') ?
                            <Grid item>
                                <CtCardSimple
                                    minHeightCardContent='155px'
                                    cardContent={
                                        <div>
                                            <Typography gutterBottom>
                                                PAN No  /  Bank Detail Missing
                                            </Typography>
                                            <Typography variant="h5" component="h2">
                                                {bull}
                                                <label
                                                    style={{ fontSize: '23px', color: '#62350b' }}>
                                                    <Button
                                                        style={{ fontSize: '23px', color: '#62350b' }}
                                                        onClick={() => { this.navigateTo('MemberPanNoBankDetailMissingList:PAN') }}
                                                    >{this.state.PANMissingCount} &nbsp;&nbsp;&nbsp;/ </Button>
                                                    <Button
                                                        style={{ fontSize: '23px', color: '#62350b' }}
                                                        onClick={() => { this.navigateTo('MemberPanNoBankDetailMissingList:BANK') }}
                                                    >{this.state.BankMissingCount}</Button>
                                                </label>
                                            </Typography>
                                        </div>
                                    }
                                    btnList={this.state.cardPANBankMissingBtnList}
                                />
                            </Grid> : ""
                    }
                </Grid>
                <br />
                <Divider style={{ marginBottom: '20px' }} />
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>

                    <Grid item style={{ display: AdminOnlyVisibleState }}>
                        <CtBtn label='Refresh Member Team Size' onClick={() => { this.refreshAllMemberTeamSize() }} />
                    </Grid>
                    <Grid item style={{ display: AdminOnlyVisibleState }}>
                        <CtBtn label='Re-calculate Stock' onClick={() => { this.recalculateStock() }} />
                    </Grid>
                    <Grid item style={{ display: AdminOnlyVisibleState }}>
                        <CtBtn label='Re-calculate Member Wallet Balance' onClick={() => { this.SetMemberWalletBalance() }} />
                    </Grid>
                    <Grid item style={{ display: AdminOnlyVisibleState }}>
                        <CtBtn label='Max Child Tree Node Count' onClick={() => { this.maxChildTreeNodeCount() }} />
                    </Grid>
                    <Grid item style={{ display: AdminOnlyVisibleState }}>
                        <CtBtn label='Recalculate Member Income' onClick={() => { this.recalculateMemberIncome() }} />
                    </Grid>
                    {/* <Grid item>
                                <AlertResponsiveDialog
                                    label={'Provide UnPaid Level Income To Regular Approved Member Only'}
                                    labelAgree={'Continue'}
                                    labelDisagree={'Cancel'}
                                    alertTitle={'UnPaid Level Income To Regular Member'}
                                    alertMessage={'Do you want to Provide Unpaid Level Income To Regular Approved Member Only?'}
                                    handleOnClickYes={() => { this.ProvideUnPaidLevelIncomeToRegularApprovedMember() }}
                                />
                            </Grid>
                            <Grid item>
                                <AlertResponsiveDialog
                                    label='Re-Calculate All User Wallet Balance'
                                    labelAgree='Continue'
                                    labelDisagree='Cancel'
                                    alertTitle='Re-Calculate Wallet Balance'
                                    alertMessage='Do you want to Re-Calculate all user Wallet Balance?'
                                    handleOnClickYes={this.SetMemberWalletBalance}
                                // SetMemberWalletBalance
                                />
                            </Grid> : ""
                    } */}

                    <Grid item><Button style={{ fontSize: '12px' }} onClick={() => { this.navigateTo('ChangePassword') }} color="primary">Change Password</Button></Grid>
                </Grid>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.alertClubRoyaltyConfirm}
                        {this.state.alertClubRoyaltyExistsConfirmToProcess}
                        {this.state.alertDirectorClubRoyaltyConfirm}
                        {this.state.alertDirectorClubRoyaltyExistsConfirmToProcess}
                        {this.state.alertDirectReferralConfirm}
                        {this.state.alertDirectReferralExistsConfirmToProcess}
                        {this.state.alertJackPotIncomeConfirm}
                        {this.state.alertJackPotIncomeExistsConfirmToProcess}
                        {this.state.alertMaxChildTreeNodeCount}
                        {this.state.alertTeamInfo}
                    </Box>
                </Grid>
            </div>
        )
    }

}

export default HOCVerifyIsAdmin(HoCtToastContainer(AdminDashboard))