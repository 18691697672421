import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import CtTxt from './CustomTool/CtTxt'
import CtMultiLineText from './CustomTool/CtMultiLineText'
import { Grid, Box } from "@material-ui/core";
import CtBtn from './CustomTool/CtBtn';
import { fetchAdmin, fetchMember } from "./API/API";
import { getIndianFormatedNumber, SUCCESS, ERROR } from './SystemUtility/SystemUtility'
import { REGULAR, GIFT_PLAN } from './SystemConstants'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";

class UserWithdrawal extends Component {

    state = {
        counter: 0,
        txtBalance: 0,
        mlTxtRemark: '',
        tranAmt: 0,
        tdsAmt: 0,
        adminAmt: 0,
        repurchaseAmt: 0,
        dataLoadStatus: false,
        ARD: null,
    }

    //#region Handle
    handleOnChangeNumber = (e) => {
        let value = (isNaN(e.target.value) ? this.props.WalletBalance : Number(e.target.value))
        let tranAmt = 0, tdsAmt = 0, adminAmt = 0, repurchaseAmt = 0
        let amt = this.calculateAmount(value, this.props.Scheme)
        // console.log('amt', amt)
        tranAmt = amt[0].tranAmt
        tdsAmt = amt[0].tdsAmt
        adminAmt = amt[0].adminAmt
        repurchaseAmt = amt[0].repurchaseAmt
        this.setState({
            [e.target.name]: value,
            tranAmt, tdsAmt, adminAmt, repurchaseAmt
        })
    }

    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    //#endregion

    //#region Function

    calculateAmount = (value, scheme) => {
        let tranAmt = 0, tdsAmt = 0, adminAmt = 0, repurchaseAmt = 0, totDed = 0, dedPer = 0.05
        if (value > 0) {
            tdsAmt = (value * dedPer).toFixed(2)
            totDed += Number(tdsAmt)
            tdsAmt = getIndianFormatedNumber(tdsAmt)
            adminAmt = (value * dedPer).toFixed(2)
            totDed += Number(adminAmt)
            adminAmt = getIndianFormatedNumber(adminAmt)
            if (scheme === REGULAR) {
                repurchaseAmt = (value * dedPer).toFixed(2)
                totDed += Number(repurchaseAmt)
                repurchaseAmt = getIndianFormatedNumber(repurchaseAmt)
            }
            tranAmt = getIndianFormatedNumber((value - totDed).toFixed(2))
        }
        let result = []
        result.push({ tranAmt, tdsAmt, adminAmt, repurchaseAmt })
        return result
    }

    validateInput = () => {
        let result = false
        if (Number(this.state.txtBalance) <= 0) {
            this.props.toastErrorMsg('Enter Withdraw Amount greater than zero.', 'txtBalance')
        } else if (
            Number(this.props.WalletBalance) > 0 && Number(this.state.txtBalance) > 0 &&
            Number(this.state.txtBalance) > Number(this.props.WalletBalance)
        ) {
            this.props.toastErrorMsg('Withdrawal Balance must be less or equal to ' + this.props.WalletBalance, 'txtBalance')
        } else if (Number(this.props.WalletBalance) > 0 && Number(this.state.txtBalance) > 0 &&
            Number(this.state.txtBalance) <= Number(this.props.WalletBalance)) {
            result = true
        } else {
            this.props.toastErrorMsg('validate wallet balance failed.')
        }
        return result
    }

    confirmWithdrawal = () => {
        let ARD = null
        this.setState({ ARD }, () => {
            const displayRepurchaeAmt = this.props.Scheme === REGULAR ?
                { display: 'block' } : { display: 'none' }
            ARD = (this.props.UserID > 0 && this.props.WalletBalance > 0 && this.props.Name.length > 0) ?
                <AlertResponsiveDialog
                    labelAgree='Withdraw'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Withdraw Rs.' + this.state.txtBalance + '?'}
                    alertMessage={
                        <div>
                            <p><label>Transaction Amount : {this.state.tranAmt}</label></p>
                            <p style={displayRepurchaeAmt}><lable>Repurchase Amount : {this.state.repurchaseAmt}</lable></p>
                            <p><lable>TDS Charge : {this.state.tdsAmt}</lable></p>
                            <p><lable>Admin Charge : {this.state.adminAmt}</lable></p>
                        </div>
                    }
                    handleOnClickYes={this.handleOnARDYesClick}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                /> : null
            this.setState({ ARD })
        })
    }

    handleOnARDYesClick = () => {
        let result = false
        if (this.validateInput()) {
            this.props.notifyProcessing()
            const reqData = {
                Op: 'GeneratePayoutRequest',
                userID: this.props.UserID,
                vac_scheme: this.props.Scheme,
                dec_wdrl_amt: this.state.txtBalance,
                // remark: this.state.mlTxtRemark,
                // vac_admin_userID: ''
            }
            fetchAdmin(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    if (res.data.msgType == SUCCESS) {
                        result = true
                    }
                })
                .catch(error => {
                    this.props.updateProcessing('error', 'Unknown error occurred in handleOnARDYesClick. ' + error)
                })
            result = true
        }
        return result
    }

    render() {

        const displayRepurchaeAmt = this.props.Scheme === REGULAR ?
            { display: 'block' } : { display: 'none' }
        const disabledWithdraw = Number(this.state.txtBalance) <= 0 ? true : false

        return (
            <div>
                <form>
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Grid item style={{ width: '400px' }}>
                            <p><label>Wallet Balance : {getIndianFormatedNumber(this.props.WalletBalance)}</label></p>
                        </Grid>
                        <Grid item style={{ width: '400px' }}>
                            <CtTxt
                                id='txtBalance'
                                label='Amount'
                                value={this.state.txtBalance}
                                handleOnChange={this.handleOnChangeNumber}
                                with='200'
                                maxLength={9}
                                onKeyDown={this.props.onKeyDown}
                                validateInput={this.validateInput}
                            />
                        </Grid>
                        <Grid item style={{ width: '400px' }}>
                            <p><label>Transaction Amount : {this.state.tranAmt}</label></p>
                            <p style={displayRepurchaeAmt}><lable>Repurchase Amount : {this.state.repurchaseAmt}</lable></p>
                            <p><lable>TDS Charge : {this.state.tdsAmt}</lable></p>
                            <p><lable>Admin Charge : {this.state.adminAmt}</lable></p>
                        </Grid>
                        <Grid item style={{ width: '400px' }}>
                            <CtBtn
                                label='Withdraw'
                                onClick={this.confirmWithdrawal}
                                width={this.state.cWidth}
                                disabled={disabledWithdraw}
                            />
                        </Grid>
                    </Grid>
                </form>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default HoCtToastContainer(UserWithdrawal)