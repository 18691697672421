import React, { Component } from 'react'
import TableList from './CustomTool/TableList'
import CtTxt from './CustomTool/CtTxt'
import CtBtn from './CustomTool/CtBtn'
import CtMultiLineText from './CustomTool/CtMultiLineText'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { fetchAdmin, fetchMember, apiURL } from "./API/API";
import RupeeIcon from "../svg/Rupee.svg";
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { Grid, Box } from "@material-ui/core";
import { getIndianFormatedNumber } from './SystemUtility/SystemUtility'
import Progress from './CustomTool/Progress'
import ReactExport from 'react-data-export';
import { AssessmentOutlined } from '@material-ui/icons';
import TurnoverHistory from './TurnoverHistory'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
class DirectWithdrawal extends Component {

    state = {
        counter: 0,
        columns: [
            { id: 'Action', label: 'Action', minWidth: 10 },
            { id: 'ID', label: 'ID', minWidth: 50 },
            { id: 'Name', label: 'Name', minWidth: 190 },
            { id: 'MobileNo', label: 'Mobile No', minWidth: 70 },
            { id: 'WalletBalance', label: 'Wallet Balance', minWidth: 80, align: 'right', format: (value) => getIndianFormatedNumber(value) },
            { id: 'TranAmt', label: 'Transaction Amount', minWidth: 80, align: 'right', format: (value) => getIndianFormatedNumber(value) },
            { id: 'WalletBalanceOnly', label: 'WalletBalanceOnly', minWidth: 80, align: 'right', hide: 'true' },
            { id: 'BankDetailExists', hide: 'true' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 5,
        actionList: [
            {
                name: 'PayOut',
                icon: <img
                    src={RupeeIcon}
                    style={{ width: '20px', display: localStorage.getItem('privwithdrawal') === 'A' ? 'block' : 'none' }} />,
                link: 'Wdrl:',
                actionType: 'AlertResponsive'
            },
            {
                name: 'Turnover History',
                icon: <AssessmentOutlined />,
                link: 'TurnoverHistory:',
                actionType: 'AlertResponsive'
            }
        ],
        dataRowBgColor: '#f6a8a9',
        dataRowBgColorCondition: {
            actionType: 'Conditional',
            condition: [
                { colID: 'BankDetailExists', relationalOperator: '==', value: 0 }
            ]
        },
        searchData: {
            ID: '',
            Name: '',
            MobileNo: '',
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,

        keyColumn: [
            'ID',
            'WalletBalanceOnly',
            'Name',
            'Scheme',
            'BankName',
            'BranchName',
            'IFSCCode',
            'AcNo',
            'AcType',
            'PANNo'
        ],
        ARD: null,
        curID: 0,
        curName: '',
        curWalletBalance: 0,
        curScheme: '',
        txtBalance: 0,
        mlTxtRemark: '',
        tranAmt: 0,
        tdsAmt: 0,
        adminAmt: 0,
        repurchaseAmt: 0,
        curBankName: '',
        curBranchName: '',
        curIFSCCode: '',
        curAcNo: '',
        curAcType: '',
        curPANNo: '',
        dataLoadStatus: false,
        txtTotalTranAmount: 0,
        txtTotalWalletBalance: 0,
        txtWalletBalance: '',
        multiDataSet: [
            {
                columns: [
                    { title: "Name", width: { wpx: 250 } },//pixels width 
                    { title: "Bank Name", width: { wpx: 250 } },//char width 
                    { title: "IFSC Code", width: { wpx: 100 } },
                    { title: "Account No", width: { wpx: 100 } },
                    { title: "Amount", width: { wpx: 100 } },
                ],
                data: []
            }
        ],
        mlTxtRemarkAll: '',
        arrID_WalletBal: [],
        pendingBankDetail: [],
        TurnoverHistoryMemberID: '',
    }

    //#region Component
    componentDidMount() {
        this.loadWalletInfo()
        this.setSearchColumns()
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnSearchInputChange = (e) => {
        // console.log('handleOnSearchInputChange', [e.target.name], e.target.value, this.state.searchData);
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
            // console.log(this.state.searchData)
        })
    }

    handleOnSearchInputChangeTrim = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value.trim() }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
            // console.log(this.state.searchData)
        })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        // console.log('this.state.searchData', this.state.searchData)
        // console.log('searchRows at the begining of the function', searchRows)
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    ((this.state.searchData.ID.length === 0 || row['ID'].toString().includes(this.state.searchData.ID)) &&
                        (this.state.searchData.Name.length === 0 || row['Name'].toString().includes(this.state.searchData.Name)) &&
                        (this.state.searchData.MobileNo.length === 0 || row['MobileNo'].toString().includes(this.state.searchData.MobileNo.toString())))
                ) {
                    searchRows.push(row)
                    //console.log('searchRows at push in loop', searchRows)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
                // console.log('searchRows on end of Function', searchRows)
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleOnChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }

    handleOnChangeNumber = (e) => {
        let value = (isNaN(e.target.value) ? this.state.curWalletBalance : Number(e.target.value))
        let tranAmt = 0, tdsAmt = 0, adminAmt = 0, repurchaseAmt = 0
        let amt = this.calculateAmount(value, this.state.curScheme)
        // console.log('amt', amt)
        tranAmt = amt[0].tranAmt
        tdsAmt = amt[0].tdsAmt
        adminAmt = amt[0].adminAmt
        repurchaseAmt = amt[0].repurchaseAmt
        this.setState({
            [e.target.name]: value,
            tranAmt, tdsAmt, adminAmt, repurchaseAmt
        })
    }

    handleARDonActionClick = (values) => {
        const curID = values[0].value
        const actionName = values[0].actionName
        const curWalletBalance = values[1].value
        const curName = values[2].value
        const curScheme = values[3].value
        const curBankName = values[4].value
        const curBranchName = values[5].value
        const curIFSCCode = values[6].value
        const curAcNo = values[7].value
        const curAcType = values[8].value
        const curPANNo = values[9].value

        let tranAmt = 0, tdsAmt = 0, adminAmt = 0, repurchaseAmt = 0
        if (actionName === "Turnover History") {
            let TurnoverHistoryMemberID = curID
            this.setState({ TurnoverHistoryMemberID })
        } else if (
            Number(curID) > 0 && Number(curWalletBalance) > 0 &&
            curName.trim().length > 0 && curScheme.trim().length > 0
        ) {
            let amt = this.calculateAmount(curWalletBalance, curScheme)
            // console.log('amt', amt)
            tranAmt = amt[0].tranAmt
            tdsAmt = amt[0].tdsAmt
            adminAmt = amt[0].adminAmt
            repurchaseAmt = amt[0].repurchaseAmt
            this.setState({
                curWalletBalance: 0, curID: 0, curName: '', curScheme: '', txtBalance: 0, mlTxtRemark: '',
                tranAmt, tdsAmt, adminAmt, repurchaseAmt, curBankName, curBranchName, curIFSCCode, curAcNo, curAcType, curPANNo
            }, () => {
                this.setState({
                    curWalletBalance, curID, curName, curScheme, txtBalance: curWalletBalance, mlTxtRemark: '',
                    tranAmt, tdsAmt, adminAmt, repurchaseAmt, curBankName, curBranchName, curIFSCCode, curAcNo, curAcType, curPANNo
                })
            })
        } else {
            this.setState({
                curWalletBalance, curID, curName, curScheme, txtBalance: curWalletBalance, mlTxtRemark: '',
                tranAmt, tdsAmt, adminAmt, repurchaseAmt
            })
        }
    }

    handleOnARDYesClick = () => {
        let result = false
        if (this.validateInput()) {
            this.props.notifyProcessing()
            const reqData = {
                Op: 'ProcessDirectPayoutRequest',
                vac_userID: this.state.curID,
                vac_scheme: this.state.curScheme,
                dec_wdrl_amt: this.state.txtBalance,
                remark: this.state.mlTxtRemark,
                vac_admin_userID: localStorage.getItem('logInID')
            }
            fetchAdmin(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    if (res.data.msgType === 'success') {
                        result = true
                        this.loadWalletInfo()
                    }
                })
                .catch(error => {
                    this.props.updateProcessing('error', 'Unknown error occurred in handleOnARDYesClick. ' + error)
                })
            result = true
        }
        return result
    }
    //#endregion

    //#region Function
    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push({
            cType: 'CtTxtAdornNum',
            id: 'ID',
            label: 'ID',
            adornment: 'WG',
            value: this.state.searchData.ID,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100'
        }, {
            cType: 'CtTxt',
            id: 'Name',
            label: 'Name',
            value: this.state.searchData.Name,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }, {
            cType: 'CtPhoneNumber',
            id: 'MobileNo',
            label: 'MobileNo',
            value: this.state.searchData.MobileNo,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100'
        }, {
            cType: 'Lbl',
            label: 'Wallet Balance',
            width: '50',
        }, {
            cType: 'Lbl',
            label: 'Transaction Amount',
            width: '50',
        }
        )
        this.setState({ searchColumns })
    }

    validateWalletBalance = () => {
        let result = false
        if (this.state.txtWalletBalance != "" && Number(this.state.txtWalletBalance) <= 0) {
            this.props.toastErrorMsg('Enter Wallet Balance greater than zero.', 'txtWalletBalance')
        } else {
            result = true
        }
        return result
    }

    loadWalletInfo = () => {
        this.setState({ dataLoadStatus: false })
        let rootmember = this.props.rootmember
        const reqData = {
            Op: 'GetWalletBalanceMemberList',
            rootmember,
            WalletBalance: Number(this.state.txtWalletBalance)
        }
        fetchMember(reqData)
            .then(res => {
                const rows = res.data.WalletBalanceMember
                const rowsCount = res.data.rowsCount
                const txtTotalTranAmount = res.data.TotalTranAmount
                const txtTotalWalletBalance = res.data.TotalWalletBalance
                let dataCol = [], dataPendingBank = [], arrID_WalletBal = []
                rows.map((col) => {
                    if (col.BankDetailExists && col.BankDetailExists == 1) {
                        dataCol.push(
                            [
                                { value: col.ID && col.ID.length > 0 ? col.ID : '', style: { alignment: { horizontal: "center" } } },
                                { value: col.Name && col.Name.length > 0 ? col.Name : '' },
                                { value: col.IFSCCode && col.IFSCCode.length > 0 ? col.IFSCCode.toString().toUpperCase() : '' },
                                { value: col.BankName && col.BankName.length > 0 ? col.BankName.toString().toUpperCase() : '' },
                                { value: col.AcNo && col.AcNo.length > 0 ? col.AcNo.toString().toUpperCase() : '' },
                                { value: col.TranAmtOnly && Number(col.TranAmtOnly) > 0 ? Number(col.TranAmtOnly) : 0, style: { alignment: { horizontal: "right" }, numFmt: '0.00' } },
                                { value: '' },
                                { value: col.WalletBalanceOnly && Number(col.WalletBalanceOnly) > 0 ? Number(col.WalletBalanceOnly) : 0, style: { alignment: { horizontal: "right" }, numFmt: '0.00' } }
                            ]
                        )
                    } else {
                        dataPendingBank.push(
                            [
                                { value: col.ID && col.ID.length > 0 ? col.ID : '', style: { alignment: { horizontal: "center" } } },
                                { value: col.Name && col.Name.length > 0 ? col.Name : '' },
                                { value: col.PANNo && col.PANNo.length > 0 ? col.PANNo.toString().toUpperCase() : '' },
                                { value: col.BankName && col.BankName.length > 0 ? col.BankName.toString().toUpperCase() : '' },
                                { value: col.BranchName && col.BranchName.length > 0 ? col.BranchName.toString().toUpperCase() : '' },
                                { value: col.IFSCCode && col.IFSCCode.length > 0 ? col.IFSCCode.toString().toUpperCase() : '' },
                                { value: col.AcNo && col.AcNo.length > 0 ? col.AcNo : '' },
                                { value: col.AcType && col.AcType.length > 0 ? col.AcType : '' },
                                { value: col.TranAmtOnly && Number(col.TranAmtOnly) > 0 ? Number(col.TranAmtOnly) : 0, style: { alignment: { horizontal: "right" }, numFmt: '0.00' } }
                            ]
                        )
                    }

                    if (col.BankName && col.BankName.length > 0 && col.IFSCCode && col.IFSCCode.length > 0 && col.AcNo && col.AcNo.length > 0 && col.BranchName && col.BranchName.length > 0 && col.AcType && col.AcType.length > 0 && col.PANNo && col.PANNo.length > 0) {
                        arrID_WalletBal.push(
                            [{ ID: col.ID }, { WalletBal: col.WalletBalanceOnly }]
                        )
                    }
                })
                let multiDataSet = [], headingPendingBank = []
                if (dataCol.length > 0) {
                    multiDataSet = [
                        {
                            columns: [
                                { title: "Member ID", width: { wpx: 100 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },//pixels width 
                                { title: "Name", width: { wpx: 250 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },//pixels width 
                                { title: "IFSC Code", width: { wpx: 100 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },
                                { title: "Bank Name", width: { wpx: 200 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },//char width 
                                { title: "Account No", width: { wpx: 130 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },
                                { title: "Amount", width: { wpx: 100 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },
                                { title: "", width: { wpx: 100 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },
                                { title: "Payout Amount", width: { wpx: 100 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },
                            ],
                            data: dataCol
                        }
                    ]
                }
                if (dataPendingBank.length > 0) {
                    headingPendingBank = [
                        {
                            columns: [
                                { title: "Member ID", width: { wpx: 100 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },//pixels width 
                                { title: "Name", width: { wpx: 220 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },//pixels width 
                                { title: "PAN No", width: { wpx: 100 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },//char width 
                                { title: "Bank Name", width: { wpx: 200 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },//char width 
                                { title: "Branch Name", width: { wpx: 200 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },//char width 
                                { title: "IFSC Code", width: { wpx: 100 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },
                                { title: "Account No", width: { wpx: 130 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },
                                { title: "Account Type", width: { wpx: 130 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },
                                { title: "Amount", width: { wpx: 100 }, style: { alignment: { horizontal: "center" }, font: { bold: true } } },
                            ],
                            data: dataPendingBank
                        }
                    ]
                }
                this.setState({
                    rows,
                    rowsCount,
                    dataLoadStatus: true,
                    txtTotalTranAmount,
                    txtTotalWalletBalance,
                    multiDataSet,
                    arrID_WalletBal,
                    pendingBankDetail: headingPendingBank
                })
            })
            .catch(error => {
                this.props.toastMsg('error', 'Unkonwn Error occured in loadWalletInfo. ' + error)
            })
    }

    loadAccWalletBalance = () => {
        if (this.validateWalletBalance()) {
            this.loadWalletInfo()
        }
    }

    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    calculateAmount = (value, scheme) => {
        let tranAmt = 0, tdsAmt = 0, adminAmt = 0, repurchaseAmt = 0, totDed = 0, dedPer = 0.05
        if (value > 0) {
            tdsAmt = (value * dedPer).toFixed(2)
            totDed += Number(tdsAmt)
            tdsAmt = getIndianFormatedNumber(tdsAmt)
            adminAmt = (value * dedPer).toFixed(2)
            totDed += Number(adminAmt)
            adminAmt = getIndianFormatedNumber(adminAmt)
            tranAmt = getIndianFormatedNumber((value - totDed).toFixed(2))
        }
        let result = []
        result.push({ tranAmt, tdsAmt, adminAmt, repurchaseAmt })
        return result
    }

    validateInput = () => {
        let result = false
        if (Number(this.state.txtBalance) <= 0) {
            this.props.toastErrorMsg('Enter Payout Amount greater than zero.', 'txtBalance')
        } else if (this.state.mlTxtRemark.trim().length <= 0) {
            this.props.toastErrorMsg('Enter Remark.', 'mlTxtRemark')
        } else if (
            Number(this.state.curWalletBalance) > 0 && Number(this.state.txtBalance) > 0 &&
            Number(this.state.txtBalance) > Number(this.state.curWalletBalance)
        ) {
            this.props.toastErrorMsg('Withdrawal Balance must be less or equal to ' + this.state.curWalletBalance, 'txtBalance')
        } else if (Number(this.state.curWalletBalance) > 0 && Number(this.state.txtBalance) > 0 &&
            Number(this.state.txtBalance) <= Number(this.state.curWalletBalance)) {
            result = true
        } else {
            this.props.toastErrorMsg('validate wallet balance failed.')
        }
        return result
    }

    validatePayoutAllInput = () => {
        let result = false
        if (this.state.mlTxtRemarkAll.trim().length <= 0) {
            this.props.toastErrorMsg('Enter Remark.', 'mlTxtRemarkAll')
        } else if (this.state.arrID_WalletBal && this.state.arrID_WalletBal.length <= 0) {
            this.props.toastErrorMsg('No Bank detail found for listed ID', 'mlTxtRemarkAll')
        } else {
            result = true
        }
        return result
    }

    onGeneratePayoutAllBtnClick = () => {
        let result = false
        if (this.validatePayoutAllInput()) {
            this.props.notifyProcessing()
            const reqData = {
                Op: 'ProcessDirectPayoutAllRequest',
                arrID_WalletBal: this.state.arrID_WalletBal,
                vac_scheme: this.state.curScheme,
                remark: this.state.mlTxtRemarkAll,
                vac_admin_userID: localStorage.getItem('logInID')
            }
            fetchAdmin(reqData)
                .then(res => {
                    // this.props.updateProcessing(res.data.msgType, res.data.message)
                    if (res.data.msgType === 'success') {
                        this.props.updateProcessing(res.data.msgType, "All Payout Generated")
                        this.clearRemarkAll()
                        result = true
                        this.loadWalletInfo()
                    } else {
                        this.props.updateProcessing(res.data.msgType, "Error occurred while generating Payout")
                        this.setState({ ARD: null }, () => {
                            let ARD = <AlertResponsiveDialog
                                labelDisagree='Close'
                                alertTitle={'Payout Error Detail'}
                                alertMessage={<div>
                                    {res.data.message.map((curID, index) => {
                                        return <label><b>({index + 1})</b> {curID}<br /></label>
                                    })}
                                </div>}
                                defaultOpenDialog={true}
                            />
                            this.setState({ ARD })
                        })
                    }
                })
                .catch(error => {
                    this.props.updateProcessing('error', 'Unknown error occurred in onGeneratePayoutAllBtnClick. ' + error)
                })
            result = true
        }
        return result
    }

    clearRemarkAll = () => {
        this.setState({
            ...this.state,
            mlTxtRemarkAll: ''
        })
    }
    //#endregion

    render() {

        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)

        return (
            <div className='DirectWithdrawal'>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <form>
                    <Grid container direction="row" justify="left" alignItems="center" spacing={2}>
                        <Grid item>
                            <CtTxt
                                id='txtTotalWalletBalance'
                                label='Total Wallet Balance'
                                handleOnChange={this.handleOnChange}
                                value={this.state.txtTotalWalletBalance}
                                width={150}
                                maxLength='20'
                                onKeyDown={this.props.onKeyDown}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtTotalTranAmount'
                                label='Total Transaction Amount'
                                handleOnChange={this.handleOnChange}
                                value={this.state.txtTotalTranAmount}
                                width={190}
                                maxLength='20'
                                onKeyDown={this.props.onKeyDown}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtWalletBalance'
                                label='Wallet Balance > 0'
                                handleOnChange={this.handleOnChange}
                                value={this.state.txtWalletBalance}
                                width={150}
                                maxLength='20'
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtBtn label='Load' onClick={this.loadAccWalletBalance} />
                        </Grid>
                        <Grid item>
                            {this.state.multiDataSet.length === 0 ?
                                <CtBtn label='Generate Excel' disabled={this.state.multiDataSet.length === 0} /> :
                                <ExcelFile filename="DirectWithdrawal" element={<CtBtn label='Generate Excel' />}>
                                    {
                                        (this.state.multiDataSet && this.state.multiDataSet.length > 0) ?
                                            <ExcelSheet dataSet={this.state.multiDataSet} name="DirectWithdrawal" />
                                            : "" //this.props.toastMsg('success', 'No Bank Detail found')
                                    }
                                </ExcelFile>
                            }
                        </Grid>
                        <Grid item>
                            <AlertResponsiveDialog
                                label={<CtBtn label='Payout All' />}
                                style={{ padding: '0px' }}
                                noOutLine={true}
                                labelAgree='Generate Payout'
                                labelDisagree='Cancel'
                                alertTitle={'Direct Payout to All listed member'}
                                alertMessage={
                                    <form>
                                        <div>
                                            <CtMultiLineText
                                                id='mlTxtRemarkAll'
                                                label='Remark'
                                                value={this.state.mlTxtRemarkAll}
                                                handleOnChange={this.handleOnChange}
                                                width='400'
                                                maxLength={1500}
                                                validateInput={this.validatePayoutAllInput}
                                            />
                                        </div>
                                    </form>
                                }
                                handleOnClickYes={this.onGeneratePayoutAllBtnClick}
                                // defaultOpenDialog={true}
                                handleOnClickNo={this.clearRemarkAll}
                                onYesClickCloseIfExeSuccessfully={true}
                            />
                        </Grid>
                        <Grid item>
                            {this.state.pendingBankDetail.length === 0 ?
                                <CtBtn label='Pending Bank detail' disabled={this.state.pendingBankDetail.length === 0} /> :
                                <ExcelFile filename="PendingBankDetail" element={<CtBtn label='Pending Bank detail' />}>
                                    {
                                        (this.state.pendingBankDetail && this.state.pendingBankDetail.length > 0) ?
                                            <ExcelSheet dataSet={this.state.pendingBankDetail} name="PendingBankDetail" />
                                            : "" //this.props.toastMsg('success', 'No Bank Detail found')
                                    }
                                </ExcelFile>
                            }
                        </Grid>
                    </Grid>
                </form>
                <br />
                {
                    (this.state.rowsCount > 0) ?
                        <TableList
                            columns={this.state.columns}
                            counter={this.state.counter}
                            rows={rows}
                            rowsCount={rowsCount}
                            rowsPerPage={this.state.rowsPerPage}
                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                            onActionClick={this.handleARDonActionClick}
                            dataRowBgColor={this.state.dataRowBgColor}
                            dataRowBgColorCondition={this.state.dataRowBgColorCondition}
                            actionList={this.state.actionList}
                            keyColumn={this.state.keyColumn}
                            handleOnSearchChange={this.handleOnSearchChange}
                            searchColumns={this.state.searchColumns}
                            searchData={this.state.searchData}
                            provideSearch={true}

                        />
                        : "No Direct Withdrawal Details Found"
                }
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {(this.state.curID > 0 && this.state.curWalletBalance > 0 && this.state.curName.length > 0 && this.state.curBankName !== null && this.state.curBankName.length > 0 && this.state.curBranchName !== null && this.state.curBranchName.length > 0 && this.state.curIFSCCode !== null && this.state.curIFSCCode.length > 0 && this.state.curAcNo !== null && this.state.curAcNo.length > 0 && this.state.curAcType !== null && this.state.curAcType.length > 0 && this.state.curPANNo !== null && this.state.curPANNo.length > 0) ?
                            <AlertResponsiveDialog
                                labelAgree='Pay'
                                labelDisagree='Cancel'
                                alertTitle={'Direct Payout to ' + this.state.curName}
                                alertMessage={
                                    <form>
                                        <div>
                                            <CtTxt
                                                id='txtBalance'
                                                label='Payout Amount'
                                                value={this.state.txtBalance}
                                                handleOnChange={this.handleOnChangeNumber}
                                                with='200'
                                                maxLength={9}
                                                onKeyDown={this.props.onKeyDown}
                                                validateInput={this.validateInput}
                                            />
                                        </div>
                                        <div>
                                            <CtMultiLineText
                                                id='mlTxtRemark'
                                                label='Remark'
                                                value={this.state.mlTxtRemark}
                                                handleOnChange={this.handleOnChange}
                                                width='400'
                                                maxLength={1500}
                                                // onKeyDown={this.props.onKeyDown}
                                                validateInput={this.validateInput}
                                            // defaultAction={this.handleOnARDYesClick}
                                            />
                                        </div>
                                        <div>
                                            <p><label>Transaction Amount : {this.state.tranAmt}</label></p>
                                            {/* <p><lable>Repurchase Amount : {this.state.repurchaseAmt}</lable></p> */}
                                            <p><lable>TDS Charge : {this.state.tdsAmt}</lable></p>
                                            <p><lable>Admin Charge : {this.state.adminAmt}</lable></p>
                                        </div>
                                    </form>
                                }
                                handleOnClickYes={this.handleOnARDYesClick}
                                defaultOpenDialog={true}
                                disableAgree={
                                    !(
                                        Number(this.state.txtBalance) > 0 &&
                                        Number(this.state.txtBalance) <= Number(this.state.curWalletBalance) &&
                                        this.state.mlTxtRemark.trim().length > 0
                                    )}
                                onYesClickCloseIfExeSuccessfully={true}
                            /> :
                            (
                                this.state.curID > 0 && this.state.curWalletBalance > 0 && this.state.curName.length > 0 ?
                                    <AlertResponsiveDialog
                                        // labelAgree='Ok'
                                        labelDisagree='Ok'
                                        alertTitle={'PAN No or Bank detail is not entered. First complete those information in member profile and then try Payout process.'}
                                        // alertMessage={                         }
                                        // handleOnClickYes={this.handleOnARDYesClick}
                                        defaultOpenDialog={true}
                                        onYesClickCloseIfExeSuccessfully={true}
                                    />
                                    : ''
                            )
                        }
                        {this.state.ARD}
                    </Box>
                </Grid>
                <br />
                <Grid item>
                    <TurnoverHistory memberid={this.state.TurnoverHistoryMemberID} rootmember={this.props.rootmember} />
                </Grid>
            </div>
        )
    }
}

export default HoCtToastContainer(DirectWithdrawal)