import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import Progress from './CustomTool/Progress'
import { Grid, Box } from '@material-ui/core'
import TableList from './CustomTool/TableList'
import { fetchMember } from './API/API'
import CtDtpDOB from './CustomTool/CtDtpDOB'
import CtTxt from './CustomTool/CtTxt'
import CtBtn from './CustomTool/CtBtn'
import { get_YMD_from_SYS } from './SystemUtility/SystemUtility'
// import { Delete, Edit } from '@material-ui/icons'
// import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";

class AchievementList extends Component {

    state = {
        dataLoadStatus: true,
        formData: {
            dtpFromDate: get_YMD_from_SYS(new Date()),
            dtpToDate: get_YMD_from_SYS(new Date()),
            txtTeam1BTO: '',
            txtTeam2BTO: ''
        },
        columns: [
            { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            { id: 'no', label: 'Sr. No.', align: 'center' },
            { id: 'date', label: 'Date', hide: 'true' },
            { id: 'memberid', label: 'ID' },
            { id: 'referencecode', label: 'Reference Code' },
            { id: 'sponsorid', label: 'Sponsor ID' },
            { id: 'name', label: 'Name', },
            { id: 'mobileno', label: 'Contact No.', align: 'center' },
            { id: 'percentage', label: '%', align: 'right' },
            { id: 'team1bto', label: 'Team 1 BTO', align: 'right' },
            { id: 'team2bto', label: 'Team 2 BTO', align: 'right' },
            { id: 'repurchase', label: 'Self Repurchase DP Total', align: 'center' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 10,
        actionList: [],
        searchData: {
            memberid: '',
            referencecode: '',
            sponsorid: '',
            name: ''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        keyColumn: [
            'memberid'
        ],
        ARD: null,
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.setSearchColumns()
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    ((this.state.searchData.memberid.length === 0 || row['memberid'].toString().includes(this.state.searchData.memberid))) && ((this.state.searchData.referencecode.length === 0 || row['referencecode'].toString().includes(this.state.searchData.referencecode))) && ((this.state.searchData.sponsorid.length === 0 || row['sponsorid'].toString().includes(this.state.searchData.sponsorid))) && ((this.state.searchData.name.length === 0 || row['name'].toString().includes(this.state.searchData.name)))
                ) {
                    searchRows.push(row)
                    // console.log('searchRows at push in loop', searchRows)
                }
            })
            const searchRowsCount = searchRows.length
            // console.log("searchRowsCount",searchRowsCount);
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
                // console.log('searchRows on end of Function', searchRows)
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleARDonActionClick = (values) => {
        /* const EditOfferSrNo = values[0].value,
            actionName = values[0].actionName,
            EditOfferName = values[1].value
        if (actionName === 'Edit Offer') {
            this.navigateTo('OfferEntry:' + EditOfferSrNo)
        } else if (actionName === 'Delete Offer') {
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Offer : ' + EditOfferName + '?'}
                    // alertMessage={}
                    handleOnClickYes={() => { return this.deleteOffer(EditOfferSrNo) }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } */
    }

    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
        })
    }

    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {},
            {
                cType: 'Lbl',
                label: 'Sr. No.',
                width: '50',
            },
            {
                cType: 'CtTxt',
                id: 'memberid',
                label: 'ID',
                value: this.state.searchData.memberid,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'referencecode',
                label: 'Ref. Code',
                value: this.state.searchData.referencecode,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'sponsorid',
                label: 'Sponsor ID',
                value: this.state.searchData.sponsorid,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'name',
                label: 'Name',
                value: this.state.searchData.name,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            }, {
            cType: 'Lbl',
            label: 'Contact No.',
            width: '50',
        }, {
            cType: 'Lbl',
            label: '%',
            width: '50',
        }, {
            cType: 'Lbl',
            label: 'Team 1 BTO',
            width: '50',
        }, {
            cType: 'Lbl',
            label: 'Team 2 BTO',
            width: '50',
        }, {
            cType: 'Lbl',
            label: 'Self Repurchase DP Total',
            width: '50',
        }
        )
        this.setState({ searchColumns })
    }

    validateSelection = () => {
        let validateSelection = false

        if (this.state.formData.dtpFromDate === undefined || this.state.formData.dtpFromDate.length <= 0) {
            this.props.toastErrorMsg('Enter From Date', 'dtpFromDate')
        } else if (this.state.formData.dtpToDate === undefined || this.state.formData.dtpToDate.length <= 0) {
            this.props.toastErrorMsg('Enter To Date', 'dtpToDate')
        } else if (this.state.formData.txtTeam1BTO === undefined || Number(this.state.formData.txtTeam1BTO) <= 0) {
            this.props.toastErrorMsg('Enter Team 1 BTO Amount', 'txtTeam1BTO')
        } else if (this.state.formData.txtTeam2BTO === undefined || Number(this.state.formData.txtTeam2BTO) <= 0) {
            this.props.toastErrorMsg('Enter Team 2 BTO Amount', 'txtTeam2BTO')
        } else {
            validateSelection = true
        }
        return validateSelection
    }

    loadAchievementList = () => {
        if (this.validateSelection() === true) {
            this.setState({ dataLoadStatus: false }, () => {
                let rootmember = ''
                if (this.props.rootmember && this.props.rootmember.length > 0 && Number(this.props.rootmember) > 99999) {
                    rootmember = this.props.rootmember
                }
                const reqData = {
                    Op: 'GetAchievementListForAminOnly',
                    Team1BTO: this.state.formData.txtTeam1BTO,
                    Team2BTO: this.state.formData.txtTeam2BTO,
                    FromDate: this.state.formData.dtpFromDate,
                    ToDate: this.state.formData.dtpToDate,
                    rootmember
                }
                fetchMember(reqData)
                    .then(res => {
                        const rows = res.data.AchievementList
                        const rowsCount = rows.length
                        this.setState({ rows, rowsCount, dataLoadStatus: true }, () => {
                            if (rowsCount <= 0) {
                                this.props.toastMsg('error', 'No records found for the current selection.')
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in loadAchievementList. ' + error)
                    })
            })
        }
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                {/* <br /> */}
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>Achievement List</label>
                    </Grid>
                </Grid>
                <form>
                    <Grid container direction="row" justify="left" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtDtpDOB
                                id='dtpFromDate'
                                label='From Date'
                                width='150'
                                handleOnChange={this.handleOnChangeTrim}
                                defaultValue={this.state.formData.dtpFromDate}
                                value={this.state.formData.dtpFromDate}
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtDtpDOB
                                id='dtpToDate'
                                label='To Date'
                                width='150'
                                handleOnChange={this.handleOnChangeTrim}
                                defaultValue={this.state.formData.dtpToDate}
                                value={this.state.formData.dtpToDate}
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtTeam1BTO'
                                label='Team 1 BTO'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtTeam1BTO}
                                width="150"
                                maxLength='18'
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtTeam2BTO'
                                label='Team 2 BTO'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtTeam2BTO}
                                width="150"
                                maxLength='18'
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtBtn label='Load' width={150} onClick={this.loadAchievementList} />
                        </Grid>
                    </Grid>
                </form>
                <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    // onActionClick={this.handleARDonActionClick}
                    // actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                />
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(AchievementList))
