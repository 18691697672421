import React, { Component } from 'react'
import "./PinDashboard.css"
import HOCVerifyIsLogInUserOrAdmin from './HOC/HOCVerifyIsLogInUserOrAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import CtTxtAdornNum from './CustomTool/CtTxtAdornNum'
import CtBtn from './CustomTool/CtBtn'
import { Grid, Box } from '@material-ui/core'
import { fetchMember } from './API/API'
import Progress from './CustomTool/Progress'
import { PROCESSING, NO_DATA_FOUND } from "./SystemUtility/SystemUtility";
import AlertResponsiveDialog from './CustomTool/AlertResponsiveDialog'

class PinDashboard extends Component {

    state = {
        dataLoadStatus: false,
        cWidth: 120,
        pinStock: 0,
        pinUsed: 0,
        curPIN: '',
        UserID: 0,
        formData: {
            ActivateUserID: '',
            ActivateUserIDVerify: '',
            Name: '',
            Scheme: '',
            ReferenceCode: '',
            SponsorID: '',
        },
        alertActivateUserConfirm: null,
    }

    //#region Component
    componentWillMount() {
        const userID = Number(this.props.match.params.userID.substring(1))
        this.props.validateIsLogInUserOrAdmin(
            this.props.IsAdmin,
            this.props.setActiveLink,
            this.navigateTo,
            userID
        )
    }

    componentDidMount() {
        const UserID = Number(this.props.match.params.userID.substring(1))
        this.setState({ UserID }, () => {
            this.getCurPinStock()
        })
    }
    //#endregion

    //#region handle
    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    getCurPinStock = () => {
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                'Op': 'StockPinCount_UsedPinCount_NextFreePin',
                'UserID': this.state.UserID,
            }
            fetchMember(reqData)
                .then(res => {
                    const pinStock = res.data.PinCount,
                        pinUsed = res.data.UsedPinCount,
                        curPIN = res.data.NextFreePin,
                        dataLoadStatus = true,
                        ActivateUserID = '',
                        ActivateUserIDVerify = '',
                        Name = '',
                        Scheme = '',
                        ReferenceCode = '',
                        SponsorID = ''

                    this.setState({
                        pinStock, pinUsed, curPIN, dataLoadStatus,
                        formData: {
                            ...this.state.formData,
                            ActivateUserID,
                            ActivateUserIDVerify,
                            Name,
                            Scheme,
                            ReferenceCode,
                            SponsorID
                        }
                    })
                })
                .catch(error => {
                    console.log('Unknown error occurred in getCurPinStock. ', error)
                })
        })
    }

    validateUserID = () => {
        let result = false
        if (Number(this.state.formData.ActivateUserID) <= 99999) {
            this.props.toastErrorMsg('Enter valid User ID', 'ActivateUserID')
        } else {
            result = true
        }
        return result
    }

    getInActiveUserInfo = () => {
        if (this.validateUserID()) {
            this.setState({ dataLoadStatus: false }, () => {
                this.props.notifyProcessing()
                const reqData = {
                    'Op': 'GetInActiveUserInfo',
                    'userID': this.state.formData.ActivateUserID
                }
                fetchMember(reqData)
                    .then(res => {
                        const dataLoadStatus = true
                        let Name = '', Scheme = '',
                            ReferenceCode = '', SponsorID = '',
                            msgType = '', message = '', ActivateUserIDVerify = ''

                        msgType = res.data.msgType
                        message = res.data.message

                        if (msgType === 'success') {
                            ActivateUserIDVerify = this.state.formData.ActivateUserID
                            Name = res.data.Name
                            Scheme = res.data.Scheme
                            ReferenceCode = res.data.ReferenceCode
                            SponsorID = res.data.SponsorID
                        }

                        this.setState({
                            dataLoadStatus,
                            formData: {
                                ...this.state.formData, Name, Scheme,
                                ReferenceCode, SponsorID, ActivateUserIDVerify
                            }
                        }, () => {
                            this.props.updateProcessing(msgType, message)
                        })
                    })
                    .catch(error => {
                        console.log('Unknown error occurred in getInActiveUserInfo.', error)
                    })
            })
        }
    }

    activateUserConfirmation = () => {
        this.setState({ alertActivateUserConfirm: null }, () => {
            const alertActivateUserConfirm = <AlertResponsiveDialog
                label={'Confirm To Activate User'}
                labelAgree={'Activate User'}
                labelDisagree={'Cancel'}
                alertTitle={'Do you want to activate bellow described user?'}
                alertMessage={
                    <div style={{ color: '#000000' }}>
                        <p>User ID : {this.state.formData.ActivateUserID}</p>
                        <p>Name : {this.state.formData.Name}</p>
                        <p>Scheme : {this.state.formData.Scheme}</p>
                        <p>SponsorID : {this.state.formData.SponsorID}</p>
                        <p>ReferenceCode : {this.state.formData.ReferenceCode}</p>
                    </div>
                }
                handleOnClickYes={this.activateUser}
                defaultOpenDialog={true}
            />
            this.setState({ alertActivateUserConfirm })
        })
    }

    activateUser = () => {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                'Op': 'UsePin',
                'userID': this.state.UserID,
                'EditID': [this.state.formData.ActivateUserID],
                'pinApproveActiveUserID': this.state.formData.ActivateUserID,
                'vacPin': this.state.curPIN,
                'ActiveStatus': 1,
                'ApproveStatus': 1
            }
            fetchMember(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    if (res.data.msgType === 'success') {
                        this.getCurPinStock()
                    }
                    this.setState({ dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastErrorMsg('error', 'Unknown error occurred in activateUser. ' + error)
                })
        })
    }
    //#endregion

    render() {

        const disabledUsePIN = (
            Number(this.state.pinStock) > 0 &&
            this.state.curPIN.length > 0
        ) ? false : true

        const disabledActivateUser = (
            this.state.formData.Name.length > 0 &&
            this.state.formData.Scheme.length > 0 &&
            Number(this.state.formData.ReferenceCode) > 99999 &&
            Number(this.state.formData.SponsorID) > 99999 &&
            Number(this.state.formData.ActivateUserID) > 99999 &&
            Number(this.state.formData.ActivateUserID) === Number(this.state.formData.ActivateUserIDVerify)
        ) ? false : true

        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' >PIN Stock ( {this.state.pinStock} )</label><br />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="baseline" spacing={5}>
                    <Grid item>
                        <label className='HC'>
                            PIN Used : {this.state.pinUsed}
                        </label>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="baseline" spacing={5}>
                    <Grid item>
                        <CtBtn
                            label='Purchase PIN'
                            onClick={() => { this.navigateTo('BuyPin:' + this.state.UserID) }}
                            width={Number(this.state.cWidth) + 50}
                        />
                    </Grid>
                    <Grid item>
                        <CtBtn
                            label='PIN Book'
                            onClick={() => { this.navigateTo('PinBook:' + this.state.UserID) }}
                            width={this.state.cWidth}
                        />
                    </Grid>
                    <Grid item>
                        <CtBtn
                            label='PIN Transfer'
                            onClick={() => { this.navigateTo('PinTransfer:' + this.state.UserID) }}
                            width={(Number(this.state.cWidth) + 50)}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="baseline" spacing={5}>
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' style={{ color: '#ED1C24' }}>Current PIN ( {this.state.curPIN} )</label><br />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                    <Grid item>
                        <CtTxtAdornNum
                            id='ActivateUserID'
                            label='User ID'
                            adornment='WG'
                            value={this.state.formData.ActivateUserID}
                            handleOnChange={this.handleOnChange}
                            disabled={disabledUsePIN}
                            width={this.state.cWidth}
                        />
                    </Grid>
                    <Grid item>
                        <CtBtn
                            label='Get User Info'
                            onClick={this.getInActiveUserInfo}
                            disabled={disabledUsePIN}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                    <Grid item className='hc' style={{ minWidth: '250px' }}>Name : {this.state.formData.Name}</Grid>
                    <Grid item className='hc' style={{ minWidth: '250px' }}>Scheme : {this.state.formData.Scheme}</Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                    <Grid item className='hc' style={{ minWidth: '250px' }}>Sponsor ID : {this.state.formData.SponsorID}</Grid>
                    <Grid item className='hc' style={{ minWidth: '250px' }}>Reference Code : {this.state.formData.ReferenceCode}</Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                    <Grid item>
                        <CtBtn
                            label='Activate User'
                            onClick={this.activateUserConfirmation}
                            disabled={disabledActivateUser}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.alertActivateUserConfirm}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsLogInUserOrAdmin(HoCtToastContainer(PinDashboard))