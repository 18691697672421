import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { Grid } from '@material-ui/core'
import CtTxtNum from './CustomTool/CtTxtNum'
import CtTxtPWD from './CustomTool/CtTxtPWD'
import CtBtn from './CustomTool/CtBtn'
import { fetchMember } from './API/API'
class LogIn extends Component {

    CtWidth = 250

    state = {
        loginData: {
            LoginID: '',
            Password: '',
        },
        res: [],
    }

    handleOnChange = (e) => {
        this.setState({
            loginData: { ...this.state.loginData, [e.target.name]: e.target.value }
        })
    }

    validateInput = (index = null) => {
        let result = false
        if ((index === null || index === 0) && this.state.loginData.LoginID.length <= 0) {
            this.props.toastErrorMsg('Enter Login ID', 'LoginID')
        } else if ((index === null || index === 0) && this.state.loginData.LoginID.length < 6) {
            this.props.toastErrorMsg('Enter Valid Login ID', 'LoginID')
        } else if ((index === null || index === 1) && this.state.loginData.Password.length <= 0) {
            this.props.toastErrorMsg('Enter Password', 'Password')
        } else if ((index === null || index === 1) && this.state.loginData.Password.length < 4) {
            this.props.toastErrorMsg('Enter Valid Password', 'Password')
        }
        else {
            result = true
        }
        return result
    }

    verifyCredential = () => {
        if (this.validateInput()) {
            this.props.notifyProcessing()

            const reqData = {
                UserID: this.state.loginData.LoginID,
                PWD: this.state.loginData.Password,
                Op: 'VerifyUserCredential',
            }

            fetchMember(reqData)
                .then(res => {
                    this.setState({ res: res.data })
                    // console.log(this.state.res);
                    this.props.updateProcessing(this.state.res.msgType, this.state.res.message)
                    if (this.state.res.msgType === 'success') {
                        this.props.handleUserLoginID(this.state.loginData.LoginID)
                        this.props.history.push('/UserDashboard')
                    }
                })
                .catch(err => {
                    this.props.updateProcessing('error', 'Unknown error occured in Verify Credential.\n\n' + err)
                })
        }
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "LogIn" })
        this.props.handleUserLoginID('')
        document.getElementById('LoginID').focus()
    }

    render() {
        return (
            <div className="LogIn" >
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' >Log In</label>
                    </Grid>
                </Grid>
                <form>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5} >
                        <Grid item>
                            <CtTxtNum
                                id='LoginID'
                                label='Log in ID'
                                value={this.state.loginData.LogInID}
                                handleOnChange={this.handleOnChange}
                                width={this.CtWidth}
                                maxLength={6}
                                onKeyDown={this.props.onKeyDown}
                                validateInput={this.validateInput}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtTxtPWD
                                id='Password'
                                label='Password'
                                handleOnChange={this.handleOnChange}
                                width={this.CtWidth}
                                maxLength={100}
                                onKeyDown={this.props.onKeyDown}
                                validateInput={this.validateInput}
                                defaultAction={this.verifyCredential}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item><CtBtn label='Log in' onClick={this.verifyCredential} /></Grid>
                    </Grid>
                </form>
            </div >
        )
    }
}

export default HoCtToastContainer(LogIn)