import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import Progress from './CustomTool/Progress'
import { Grid } from '@material-ui/core'
import TableList from './CustomTool/TableList'
import { fetchMember, fetchTour } from './API/API'
import CtCmbEditable from './CustomTool/CtCmbEditable'
import CtBtn from './CustomTool/CtBtn'

class TourAchievementList extends Component {

    state = {
        dataLoadStatus: true,
        formData: {
            cmbTourName: '',
        },
        columns: [
            { id: 'Action', name: 'Search', label: 'Action', width: '10px' },
            { id: 'no', label: 'Sr. No.', align: 'center' },
            { id: 'memberid', label: 'ID', align: 'center' },
            { id: 'referencecode', label: 'Ref. Code', align: 'center' },
            { id: 'sponsorid', label: 'Sponsor ID', align: 'center' },
            { id: 'fromdt', label: 'From Date', align: 'center' },
            { id: 'todt', label: 'To Date', align: 'center' },
            { id: 'minteam1bto', label: 'Min. Team 1 BTO', align: 'right' },
            { id: 'minteam2bto', label: 'Min. Team 2 BTO', align: 'right' },
            { id: 'team1bto', label: 'Team 1 BTO', align: 'right' },
            { id: 'team2bto', label: 'Team 2 BTO', align: 'right' },
            { id: 'remainteam1bto', label: 'Remain Team 1 BTO', align: 'right' },
            { id: 'remainteam2bto', label: 'Remain Team 2 BTO', align: 'right' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 10,
        actionList: [],
        searchData: {
            memberid: '',
            referencecode: '',
            sponsorid: ''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        keyColumn: [
            'memberid'
        ],
        ARD: null,
        TourNameList: [
            { name: 'Tour 1' },
            { name: 'Tour 2' }
        ]
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.loadTourName()
        this.setSearchColumns()
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    ((this.state.searchData.memberid.length === 0 || row['memberid'].toString().includes(this.state.searchData.memberid))) && 
                    ((this.state.searchData.referencecode.length === 0 || row['referencecode'].toString().includes(this.state.searchData.referencecode))) && 
                    ((this.state.searchData.sponsorid.length === 0 || row['sponsorid'].toString().includes(this.state.searchData.sponsorid)))
                ) {
                    searchRows.push(row)
                    // console.log('searchRows at push in loop', searchRows)
                }
            })
            const searchRowsCount = searchRows.length
            // console.log("searchRowsCount",searchRowsCount);
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
                // console.log('searchRows on end of Function', searchRows)
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
        })
    }

    handleOnAutoCompleteChange = (e) => {
        // console.log('e.target.name', e.target.name)
        // console.log('e.target.value', e.target.value)
        let value = e.target.value && e.target.value['name'] ? e.target.value['name'] : e.target.value,
            curID = e.target.name
        // console.log('value', value)
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: value }
        })
    }

    handleOnAutoCompleteInputChange = (e) => {
        if (this.state.formData[e.target.id] === null && e.target.value.length > 0) {
            this.setState({
                formData: { ...this.state.formData, [e.target.name]: e.target.value }
            })
        }
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {
                cType: 'Lbl',
                label: 'Sr. No.',
                width: '50',
            },
            {
                cType: 'CtTxt',
                id: 'memberid',
                label: 'ID',
                value: this.state.searchData.memberid,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'referencecode',
                label: 'Ref. Code',
                value: this.state.searchData.referencecode,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'sponsorid',
                label: 'Sponsor ID',
                value: this.state.searchData.sponsorid,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'Lbl',
                label: 'From Date',
                width: '50',
            },
            {
                cType: 'Lbl',
                label: 'To Date',
                width: '50',
            },
            {
                cType: 'Lbl',
                label: 'Min Team 1 BTO',
                width: '50',
            },
            {
                cType: 'Lbl',
                label: 'Min Team 2 BTO',
                width: '50',
            },
            {
                cType: 'Lbl',
                label: 'Team 1 BTO',
                width: '50',
            },
            {
                cType: 'Lbl',
                label: 'Team 2 BTO',
                width: '50',
            },
            {
                cType: 'Lbl',
                label: 'Remain Team 1 BTO',
                width: '50',
            },
            {
                cType: 'Lbl',
                label: 'Remain Team 2 BTO',
                width: '50',
            },
        )
        this.setState({ searchColumns })
    }

    validateSelection = () => {
        let validateSelection = false

        if (this.state.formData.cmbTourName === undefined || this.state.formData.cmbTourName.length <= 0) {
            this.props.toastErrorMsg('Select Tour Name', 'cmbTourName')
        } else {
            validateSelection = true
        }
        return validateSelection
    }

    loadTourName = ()=>{
        const reqData = {
            Op: 'LoadActiveTour'
        }
        fetchTour(reqData)
            .then(res => {
                const TourNameList = res.data.tourname
                this.setState({ TourNameList,  dataLoadStatus: true })
            })
            .catch(error => {
                this.props.toastMsg('error', 'Unkonwn Error occured in LoadActiveTour. ' + error)
            })
    }

    loadTourAchievementList = () => {
        if (this.validateSelection() === true) {
            this.setState({ dataLoadStatus: false }, () => {
                let rootmember = ''
                if (this.props.rootmember && this.props.rootmember.length > 0 && Number(this.props.rootmember) > 99999) {
                    rootmember = this.props.rootmember
                }
                const reqData = {
                    Op: 'LoadTourAchievementList',
                    TourName: this.state.formData.cmbTourName,
                    rootmember
                }
                fetchMember(reqData)
                    .then(res => {
                        const rows = res.data.LoadTourAchievementList
                        const rowsCount = rows.length
                        this.setState({ rows, rowsCount, dataLoadStatus: true }, () => {
                            if (rowsCount <= 0) {
                                this.props.toastMsg('error', 'No records found for the current selection.')
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in loadTourAchievementList. ' + error)
                    })
            })
        }
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                {/* <br /> */}
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>Tour Achievement List</label>
                    </Grid>
                </Grid>
                <form>
                    <Grid container direction="row" justify="left" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtCmbEditable
                                id='cmbTourName'
                                label='Tour Name'
                                freeSolo={true}
                                items={this.state.TourNameList}
                                handleonCmbInputChange={this.handleOnAutoCompleteInputChange}
                                handleOnChange={this.handleOnAutoCompleteChange}
                                // handleOnChange={this.handleOnChange}
                                value={this.state.formData.cmbTourName}
                                width='440'
                                maxLength='100'
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtBtn label='Load' onClick={this.loadTourAchievementList} />
                        </Grid>
                    </Grid>
                </form>
                <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                />
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(TourAchievementList))
