import React, { Component } from 'react'
import { Grid, Divider } from '@material-ui/core'
import CtTextField from '../CustomTool/CtTxt'
import CtBtn from '../CustomTool/CtBtn'
import CtPhoneNumber from '../CustomTool/CtPhoneNumber'
import { LocationOn, PhoneAndroid, Mail } from '@material-ui/icons';
import TextAreaAutoSize from '../CustomTool/TextAreaAutoSize'
import HoCtToastContainer from '../HOC/HoCtToastContainer'
import { validateEmail } from '../SystemUtility/SystemUtility'
import { fetchContact } from '../API/API'

class ContactUs extends Component {

    CtWidth = 200

    state = {
        contactusData: {
            Name: '',
            MobileNo: '',
            Email: '',
            Subject: '',
            Message: '',
        }
    }

    handleOnChange = (e) => {
        this.setState({
            contactusData: { ...this.state.contactusData, [e.target.name]: e.target.value }
        })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            contactusData: { ...this.state.contactusData, [e.target.name]: e.target.value.trim() }
        })
    }

    validateInput = () => {
        let result = false
        if (this.state.contactusData.Name.length <= 0) {
            this.props.toastErrorMsg('Enter Name', 'Name')
        } else if (this.state.contactusData.MobileNo.length <= 0) {
            this.props.toastErrorMsg('Enter Mobile No', 'MobileNo')
        } else if (this.state.contactusData.MobileNo.length !== 10) {
            this.props.toastErrorMsg('Enter Valid Mobile No', 'MobileNo')
        } else if (this.state.contactusData.Email.length <= 0) {
            this.props.toastErrorMsg('Enter Email', 'Email')
        } else if (!validateEmail(this.state.contactusData.Email)) {
            this.props.toastErrorMsg('Enter Valid Email Address', 'Email')
        } else if (this.state.contactusData.Subject.length <= 0) {
            this.props.toastErrorMsg('Enter Subject', 'Subject')
        } else if (this.state.contactusData.Message.length <= 0) {
            this.props.toastErrorMsg('Enter Message', 'Message')
        }
        else {
            result = true
        }
        return result
    }

    submitContactMsg = () => {
        if (this.validateInput()) {
            this.props.notifyProcessing()
            const reqData = {
                Op: 'SendMailNSave',
                Name: this.state.contactusData.Name,
                MobileNo: this.state.contactusData.MobileNo,
                Email: this.state.contactusData.Email,
                Subject: this.state.contactusData.Subject,
                Message: this.state.contactusData.Message,
            }
            fetchContact(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                })
        }
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "ContactUs" })
        document.getElementById('Name').focus()
    }

    detailTitle = (icon, Title) => {
        return (
            <div style={{
                display: 'inline-flex',
                VerticalAlign: 'text-bottom',
                BoxSizing: 'inherit',
                textAlign: 'center',
                AlignItems: 'center',
                color: '#8556A6'
            }} >
                {icon}
                <label style={{ fontSize: '20px' }}>&nbsp;{Title}</label>
                <Divider />
            </div>
        )
    }

    render() {
        return (
            <div className="ContactUs">
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' >Contact Us</label>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="flex-start" spacing={5} >
                    <Grid item>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={0} >
                            <Grid item><b style={{ fontSize: '25px', color: '#672C90' }}>WAYGON</b></Grid>
                            <Grid item>&nbsp;</Grid>
                            <Grid item>
                                {this.detailTitle(<LocationOn />, 'Address')}
                                <Divider />
                            </Grid>
                            <Grid item>Office No. 118/A, Sagar Complex,</Grid>
                            <Grid item>Jashonath Chowk, Motibaug Road,</Grid>
                            <Grid item>Bhavnagar 364002. (Guj.) (India).</Grid>
                            <Grid item>&nbsp;</Grid>
                            <Grid item>
                                {this.detailTitle(<PhoneAndroid />, 'Contact No')}
                                <Divider />
                            </Grid>
                            <Grid item>Mob No : </Grid>
                            <Grid item>&nbsp;</Grid>
                            <Grid item>
                                {this.detailTitle(<Mail />, 'Email Address')}
                                <Divider />
                            </Grid>
                            <Grid item>info@waygonmarketing.com</Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <form>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={5} >
                                <Grid item>
                                    <CtTextField
                                        id='Name'
                                        label='Name'
                                        value={this.state.contactusData.Name}
                                        handleOnChange={this.handleOnChange}
                                        onKeyDown={this.props.onKeyDown}
                                        width={this.CtWidth}
                                        maxLength='100'
                                    />
                                </Grid>
                                <Grid item>
                                    <CtPhoneNumber
                                        id='MobileNo'
                                        label='Mobile No'
                                        value={this.state.contactusData.MobileNo}
                                        handleOnChange={this.handleOnChangeTrim}
                                        onKeyDown={this.props.onKeyDown}
                                        width={this.CtWidth}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={5} >
                                <Grid item>
                                    <CtTextField
                                        id='Email'
                                        label='Email'
                                        value={this.state.contactusData.Email}
                                        handleOnChange={this.handleOnChange}
                                        onKeyDown={this.props.onKeyDown}
                                        width={this.CtWidth}
                                        maxLength='100'
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTextField
                                        id='Subject'
                                        label='Subject'
                                        value={this.state.contactusData.Subject}
                                        handleOnChange={this.handleOnChange}
                                        onKeyDown={this.props.onKeyDown}
                                        width={this.CtWidth}
                                        maxLength='100'
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={5} >
                                <Grid item>
                                    <TextAreaAutoSize
                                        id='Message'
                                        label='Message'
                                        value={this.state.contactusData.Message}
                                        width='440'
                                        handleOnChange={this.handleOnChange}
                                        maxLength='1500'
                                        placeholder='Enter Your Message'
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                                <Grid item><CtBtn label='Submit' onClick={this.submitContactMsg} /></Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default HoCtToastContainer(ContactUs)