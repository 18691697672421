import React, { Component } from 'react'
import CtCmb from './CustomTool/CtCmb'
import CtTxtReset from './CustomTool/CtTxtReset'
import { Grid, Box } from '@material-ui/core'
import Tree, { withStyles } from 'react-vertical-tree'
import { fetchTree } from './API/API'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import Progress from './CustomTool/Progress'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import SystemTreeBasicInfo from './SystemTreeBasicInfo'

const styles = {
    lines: {
        color: '#672C90',
        height: '25px',
    },
    node: {
        backgroundColor: '#fff',
        border: '1px solid #6bcf78',
    },
    text: {
        color: '#ED1C24',
    }
}

const StyledTree = withStyles(styles)(Tree)

class SystemTree extends Component {

    state = {
        treeNodes: [],
        dataLoadStatus: false,
        levels: [
            { name: 1, disabled: false },
            { name: 2, disabled: false },
            { name: 3, disabled: false },
            { name: 4, disabled: false },
            { name: 5, disabled: false },
            { name: 6, disabled: false },
            { name: 7, disabled: false },
            { name: 8, disabled: false },
            { name: 9, disabled: false },
            { name: 10, disabled: false },
            { name: 11, disabled: false },
            { name: 12, disabled: false },
            { name: 13, disabled: false },
            { name: 14, disabled: false },
            { name: 15, disabled: false },
            { name: 'ALL', disabled: false },
        ],
        Level: 2,
        NodeList: [],
        NodeCount: 0,
        RootID: '',
        DefaultRootID: '',
        ARD: null,
    }

    handleOnUndoClick = () => {
        console.log(this.state);
        const RootID = this.state.DefaultRootID
        this.setState({ RootID }, () => { console.log('this.state.RootID', this.state.RootID, 'this.state.DefaultRootID', this.state.DefaultRootID); })
    }

    validateRoot = () => {
        let result = false
        if (this.state.RootID === '') {
            result = true
        } else if (this.state.NodeList.length > 0) {
            if (this.state.NodeList.includes(this.state.RootID)) {
                result = true
            } else {
                this.props.toastErrorMsg('Root ID Must Be Any Valid ID That Display In The Tree.')
            }
        } else {
            this.props.toastErrorMsg('No ID Found That Can Set As Tree Root ID.')
        }
        return result
    }

    populateSystemTree = () => {
        this.setState({ dataLoadStatus: false })
        if (this.validateRoot()) {
            const Level = this.state.Level === 'ALL' ? -1 : this.state.Level
            const reqData = {
                Op: 'SystemTree',
                root: this.state.RootID,
                Level: Level,
            }
            fetchTree(reqData)
                .then(res => {
                    const DefaultRootID = this.state.DefaultRootID === '' ? res.data.nodeInfo.NodeList[0] : this.state.DefaultRootID
                    const setNodeList = this.state.NodeList.length === 0 ? true : false
                    const NodeList = setNodeList ? res.data.nodeInfo.NodeList : this.state.NodeList
                    this.setState({
                        treeNodes: res.data.treeInfo,
                        NodeList,
                        NodeCount: res.data.nodeInfo.NodeCount,
                        RootID: res.data.nodeInfo.NodeList[0],
                        DefaultRootID,
                        dataLoadStatus: true
                    })
                    if (!setNodeList) {
                        let NodeList = this.state.NodeList
                        res.data.nodeInfo.NodeList.map(curID => {
                            if (!NodeList.includes(curID)) {
                                NodeList.push(curID)
                            }
                        })
                        this.setState({ NodeList })
                    }
                })
        }
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "SystemTree" })
        this.populateSystemTree()
    }

    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    handleOnChange = (e) => {
        console.log(this.state)
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (e.target && e.target.name === 'Level') {
                this.populateSystemTree()
            }
            console.log(this.state);
        })
    }

    onTreeNodeClick = (e) => {
        // console.log(this.state.treeNodes[0].id);
        // console.log('e',e)
        // console.log("e['id']",e['id'])
        // console.log(this.state.NodeList);
        let ARD = null,
            ID = e['id']
        this.setState({ ARD }, () => {
            ARD = <AlertResponsiveDialog
                // labelAgree='Delete'
                labelDisagree='Close'
                alertTitle={'System Tree Basic Info'}
                alertMessage={
                    <SystemTreeBasicInfo
                        SystemTreeID={ID}
                    />
                }
                // handleOnClickYes={() => { return this.deleteProduct(
                //     this.props.EditSrNo, this.props.EditProductName
                //     ) }}
                defaultOpenDialog={true}
                onYesClickCloseIfExeSuccessfully={true}
            />
            this.setState({ ARD })
        })
    }

    render() {
        return (
            <div className="SystemTree headingOfPage">
                <head>
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>Ek Parivartan</title>
                </head>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Grid item>
                        <CtCmb
                            id='Level'
                            label='Level'
                            items={this.state.levels}
                            value={this.state.Level}
                            handleOnChange={this.handleOnChange}
                            width='70'
                        />
                    </Grid>
                    <Grid item>
                        <CtTxtReset
                            id='RootID'
                            label='Root ID'
                            adornment='WG'
                            value={this.state.RootID}
                            handleOnChange={this.handleOnChange}
                            handleOnResetClick={this.populateSystemTree}
                            handleOnUndoClick={this.handleOnUndoClick}
                            width='200'
                        />
                    </Grid>
                    <Grid item xs={12} style={{ flex: 'inherit' }}>
                        <StyledTree data={this.state.treeNodes} direction onClick={this.onTreeNodeClick} />
                    </Grid>
                </Grid>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}

export default HOCVerifyIsAdmin(HoCtToastContainer(SystemTree))