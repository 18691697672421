import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import DirectWithdrawal from "./DirectWithdrawal";
import { Grid } from "@material-ui/core";

class WithdrawalList extends Component {

    state = {
        Tabs: []
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "WithdrawalList" })
        let rootmember = ''
        if (
            this.props.match.params.rootmember &&
            this.props.match.params.rootmember.length > 0 &&
            Number(this.props.match.params.rootmember.substring(1)) > 99999
        ) {
            rootmember = Number(this.props.match.params.rootmember.substring(1))
        } else if (this.props.rootmember && this.props.rootmember.length > 0 && Number(this.props.rootmember) > 99999) {
            rootmember = this.props.rootmember
        }
        const Tabs = [
            { label: 'Direct Withdrawal', panel: <DirectWithdrawal navigateTo={this.navigateTo} rootmember={rootmember} /> },
            // { label: 'Approve Withdrawal', panel: <ApproveWithdrawal rootmember={rootmember} /> },
        ]
        this.setState({ Tabs })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }
    //#endregion

    render() {

        let rootmember = ''
        if (
            this.props.match.params.rootmember &&
            this.props.match.params.rootmember.length > 0 &&
            Number(this.props.match.params.rootmember.substring(1)) > 99999
        ) {
            rootmember = Number(this.props.match.params.rootmember.substring(1))
        } else if (this.props.rootmember && this.props.rootmember.length > 0 && Number(this.props.rootmember) > 99999) {
            rootmember = this.props.rootmember
        }

        return (
            <div>
                {/* <Progress color='secondary' display={this.state.dataLoadStatus !== true} /> */}
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>Direct Withdrawal</label>
                    </Grid>
                </Grid>
                <DirectWithdrawal 
                    navigateTo={this.navigateTo} 
                    rootmember={rootmember} 
                    privilegesList={this.props.match.params.privilegesList} />
                {/* <ScrollableTab Tabs={this.state.Tabs} /> */}
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(WithdrawalList))