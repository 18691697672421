import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import Progress from './CustomTool/Progress'
import { Grid, Box } from '@material-ui/core'
import CtTxt from './CustomTool/CtTxt'
import CtCmbEditable from './CustomTool/CtCmbEditable'
import CtBtn from './CustomTool/CtBtn'
import TableList from './CustomTool/TableList'
import { fetchActivationKit } from './API/API'
import { Delete, Edit } from '@material-ui/icons'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";

class ActivationKitList extends Component {

    state = {
        formDataActivationKit: {
            EditAKSrNo: 0,
            EditActivationKitName: '',
            txtActivationKitName: '',
            txtMinDPAmount: '',
            txtFreeMRP:'',
        },
        formDataActivationKitItem: {
            EditSrNo: 0,
            cmbProductName: '',
            txtQuantity: '',
        },
        columnsAK: [
            { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            { id: 'akno', label: 'Sr. No.', align: 'center' },
            { id: 'activationkitname', label: 'Activation Kit Name' },
            { id: 'minbillamt', label: 'Minimum DP Amount', align: 'right' },
            { id: 'freemrp', label: 'Free MRP', align: 'right' },
            { id: 'aksrno', label: 'aksrno', align: 'center', hide: 'true' },
        ],
        columnsItem: [
            { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            { id: 'no', label: 'Sr. No.', align: 'center' },
            { id: 'productname', label: 'Product Name' },
            { id: 'qty', label: 'Quantity', align: 'right' },
            { id: 'srno', label: 'srno', align: 'center', hide: 'true' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 5,
        rowsItem: [],
        rowsCountItem: 0,
        rowsPerPageItem: 5,
        ProductNames: [],
        dataLoadStatus: false,

        searchData: {
            activationkitname: '',
            minbillamt: '',
            freemrp:''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,

        searchDataItem: {
            productname: ''
        },
        searchColumnsItem: [],
        searchRowsItem: [],
        searchOpenItem: false,
        searchRowsCountItem: 0,

        ARD: null,
        keyColumn: [
            'aksrno', 'activationkitname', 'minbillamt','freemrp'
        ],
        keyColumnItem: [
            'srno', 'productname', 'qty'
        ],
        actionList: [
            {
                name: 'Edit Activation Kit',
                icon: <Edit />,
                link: 'ActivationKit:',
                actionType: 'AlertResponsive'
            },
            {
                name: 'Delete Activation Kit',
                icon: <Delete />,
                link: 'DelActivationKit:',
                actionType: 'AlertResponsive'
            },
        ],
        actionListItem: [
            {
                name: 'Edit Activation Kit Item',
                icon: <Edit />,
                link: 'ActivationKit:',
                actionType: 'AlertResponsive'
            },
            {
                name: 'Delete Activation Kit Item',
                icon: <Delete />,
                link: 'DelActivationKit:',
                actionType: 'AlertResponsive'
            },
        ],
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.setSearchColumns()
        this.setSearchColumnsItem()
        this.onFormLoad()
        document.getElementById('txtActivationKitName').focus()
    }
    //#endregion

    //#region Handles
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleChangeRowsPerPageItem = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnAKChange = (e) => {
        this.setState({
            formDataActivationKit: { ...this.state.formDataActivationKit, [e.target.name]: e.target.value }
        })
    }

    handleOnAKItemChange = (e) => {
        this.setState({
            formDataActivationKitItem: { ...this.state.formDataActivationKitItem, [e.target.name]: e.target.value }
        })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formDataActivationKit: { ...this.state.formDataActivationKit, [e.target.name]: e.target.value.trim() }
        })
    }

    handleARDonActionClick = (values) => {
        const EditAKSrNo = values[0].value,
            actionName = values[0].actionName,
            txtActivationKitName = values[1].value,
            txtMinDPAmount = values[2].value,
            txtFreeMRP = values[3].value
        if (actionName === 'Edit Activation Kit') {
            this.setState({
                formDataActivationKit: {
                    ...this.state.formDataActivationKit,
                    EditAKSrNo,
                    EditActivationKitName: txtActivationKitName,
                    txtActivationKitName,
                    txtMinDPAmount,
                    txtFreeMRP,
                },
            }, () => {
                this.loadActivationKitItem(EditAKSrNo)
            })
        } else if (actionName === 'Delete Activation Kit') {
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Activation Kit : ' + txtActivationKitName + '?'}
                    // alertMessage={}
                    handleOnClickYes={() => { return this.deleteAK(EditAKSrNo) }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }

    handleARDonActionItemClick = (values) => {
        const EditSrNo = values[0].value,
            actionName = values[0].actionName,
            cmbProductName = values[1].value,
            txtQuantity = values[2].value
        if (actionName === 'Edit Activation Kit Item') {
            this.setState({
                formDataActivationKitItem: {
                    ...this.state.formDataActivationKitItem,
                    EditSrNo,
                    cmbProductName,
                    txtQuantity
                },
            })
        } else if (actionName === 'Delete Activation Kit Item') {
            let ARD = null, EditAKSrNo = this.state.formDataActivationKit.EditAKSrNo
            this.setState({ ARD }, () => {
                ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Activation Kit Item : ' + cmbProductName + '?'}
                    // alertMessage={}
                    handleOnClickYes={() => { return this.deleteAKItem(EditSrNo, EditAKSrNo) }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    ((this.state.searchData.activationkitname.length === 0 || row['activationkitname'].toString().includes(this.state.searchData.activationkitname))) && ((this.state.searchData.minbillamt.length === 0 || row['minbillamt'].toString().includes(this.state.searchData.minbillamt))) && ((this.state.searchData.freemrp.length === 0 || row['freemrp'].toString().includes(this.state.searchData.freemrp)))/* && ((this.state.searchData.startdate.length === 0 || row['startdate'].toString().includes(this.state.searchData.startdate))) && ((this.state.searchData.expirydate.length === 0 || row['expirydate'].toString().includes(this.state.searchData.expirydate)))*/
                ) {
                    searchRows.push(row)
                    // console.log('searchRows at push in loop', searchRows)
                }
            })
            const searchRowsCount = searchRows.length
            // console.log("searchRowsCount",searchRowsCount);
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
                // console.log('searchRows on end of Function', searchRows)
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleOnSearchChangeItem = (searchOpenItem) => {
        let searchRowsItem = []
        if (searchOpenItem === true && this.state.rowsItem.length > 0) {
            this.state.rowsItem.map((row) => {
                if (
                    searchOpenItem === true &&
                    ((this.state.searchDataItem.productname.length === 0 || row['productname'].toString().includes(this.state.searchDataItem.productname)))
                ) {
                    searchRowsItem.push(row)
                    // console.log('searchRows at push in loop', searchRows)
                }
            })
            const searchRowsCountItem = searchRowsItem.length
            // console.log("searchRowsCount",searchRowsCount);
            this.setState({
                searchOpenItem,
                searchRowsItem,
                searchRowsCountItem,
                counter: this.state.counter + 1,
            }, () => {
                // console.log('searchRows on end of Function', searchRows)
            })
        } else {
            this.setState({ searchOpenItem })
        }
    }

    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
        })
    }

    handleOnSearchInputChangeItem = (e) => {
        this.setState({
            searchDataItem: { ...this.state.searchDataItem, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChangeItem(this.state.searchOpenItem)
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    onFormLoad = () => {
        this.setState({
            dataLoadStatus: false
        }, () => {
            let reqData = {
                Op: 'Load_ActivationKit_ProductName'
            }
            fetchActivationKit(reqData)
                .then(res => {
                    const ProductNames = res.data.ProductName
                    let rows = res.data.ActivationKitList, rowsCount = rows.length
                    const dataLoadStatus = true
                    this.setState({
                        ProductNames, dataLoadStatus, rows, rowsCount
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('onFormLoad', error)
                })
        })
    }

    loadActivationKit = () => {
        this.setState({
            dataLoadStatus: false
        }, () => {
            let reqData = {
                Op: 'LoadActivationKitList'
            }
            fetchActivationKit(reqData)
                .then(res => {
                    const rows = res.data.ActivationKitList,
                        rowsCount = rows.length,
                        dataLoadStatus = true
                    this.setState({
                        rows, rowsCount, dataLoadStatus
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('loadActivationKit', error)
                })
        })
    }

    loadActivationKitItem = (EditAKSrNo) => {
        this.setState({
            dataLoadStatus: false
        }, () => {
            let reqData = {
                Op: 'LoadActivationKitItem',
                EditAKSrNo
            }
            fetchActivationKit(reqData)
                .then(res => {
                    const rowsItem = res.data.ActivationKitItemList,
                        rowsCountItem = rowsItem.length,
                        dataLoadStatus = true
                    this.setState({
                        rowsItem, rowsCountItem, dataLoadStatus
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('loadActivationKitItem', error)
                })
        })
    }

    validateActivationKitdata = () => {
        let validateActivationKitdata = false

        if (this.state.formDataActivationKit.txtActivationKitName.trim().length <= 0) {
            this.props.toastErrorMsg('Enter Activation Kit Name', 'txtActivationKitName')
        } else if (Number(this.state.formDataActivationKit.txtMinDPAmount) <= 0) {
            this.props.toastErrorMsg('Enter Minimum DP Amount', 'txtMinDPAmount')
        } else if (Number(this.state.formDataActivationKit.txtFreeMRP) < 0) {
            this.props.toastErrorMsg('Free MRP cannot be less than 0', 'txtFreeMRP')
        } else {
            validateActivationKitdata = true
        }
        return validateActivationKitdata
    }

    validateAKItemdata = () => {
        let validateAKItemdata = false

        if (this.state.formDataActivationKitItem.cmbProductName.trim().length <= 0) {
            this.props.toastErrorMsg('Select Product Name', 'cmbProductName')
        } else if (Number(this.state.formDataActivationKitItem.txtQuantity) <= 0) {
            this.props.toastErrorMsg('Enter Quantity', 'txtQuantity')
        } else {
            validateAKItemdata = true
        }
        return validateAKItemdata
    }

    saveAkItem = () => {
        let result = false
        if (this.validateActivationKitdata() === true && this.validateAKItemdata() === true) {
            this.props.notifyProcessing()

            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'SaveActivationKit_ActivationKitItem',
                    EditAKSrNo: this.state.formDataActivationKit.EditAKSrNo,
                    EditActivationKitName: this.state.formDataActivationKit.txtActivationKitName,
                    ActivationKitName: this.state.formDataActivationKit.txtActivationKitName,
                    MinDPAmount: this.state.formDataActivationKit.txtMinDPAmount,
                    FreeMRP: this.state.formDataActivationKit.txtFreeMRP,

                    EditSrNo: this.state.formDataActivationKitItem.EditSrNo,
                    ProductName: this.state.formDataActivationKitItem.cmbProductName,
                    Quantity: this.state.formDataActivationKitItem.txtQuantity,
                }
                fetchActivationKit(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        let EditAKSrNo = res.data.EditAKSrNo,
                            EditActivationKitName = this.state.formDataActivationKit.txtActivationKitName,
                            rows, rowsCount,
                            rowsItem = res.data.ActivationKitItemList,
                            rowsCountItem = rowsItem.length
                        if (res.data.ActivationKitList) {
                            rows = res.data.ActivationKitList
                            rowsCount = rows.length
                            this.setState({
                                dataLoadStatus: true,
                                formDataActivationKit: {
                                    ...this.state.formDataActivationKit,
                                    EditAKSrNo,
                                    EditActivationKitName
                                },
                                rows,
                                rowsCount,
                                rowsItem,
                                rowsCountItem
                            }, () => {
                                if (res.data.msgType === 'success') {
                                    result = true
                                    // this.loadActivationKitItem(Number(EditAKSrNo))

                                    this.newAKItem()
                                }
                            })
                        } else {
                            this.setState({
                                dataLoadStatus: true,
                                formDataActivationKit: {
                                    ...this.state.formDataActivationKit,
                                    EditAKSrNo,
                                    EditActivationKitName
                                },
                                rowsItem,
                                rowsCountItem
                            }, () => {
                                if (res.data.msgType === 'success') {
                                    result = true
                                    // this.loadActivationKitItem(Number(EditAKSrNo))

                                    this.newAKItem()
                                }
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('saveAkItem', error)
                    })
            })
        }
        return result
    }

    saveAK = () => {
        let result = false
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                Op: 'UpdateActivationKit',
                EditAKSrNo: this.state.formDataActivationKit.EditAKSrNo,
                EditActivationKitName: this.state.formDataActivationKit.txtActivationKitName,
                ActivationKitName: this.state.formDataActivationKit.txtActivationKitName,
                MinDPAmount: this.state.formDataActivationKit.txtMinDPAmount,
                FreeMRP: this.state.formDataActivationKit.txtFreeMRP,
            }
            fetchActivationKit(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    this.setState({
                        dataLoadStatus: true
                    }, () => {
                        if (res.data.msgType === 'success') {
                            result = true
                            this.loadActivationKit()
                            this.newAK()
                        }
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('saveAK', error)
                })
        })
        return result
    }

    newAK = () => {
        const oldEditAKSrNo = this.state.formDataActivationKit.EditAKSrNo
        const EditAKSrNo = 0,
            EditActivationKitName = '',
            txtActivationKitName = '',
            txtMinDPAmount = '',
            txtFreeMRP = '',
            rowsItem = [],
            rowsCountItem = 0
        this.setState({
            rowsItem,
            rowsCountItem,
            formDataActivationKit: {
                ...this.state.formDataActivationKit,
                EditAKSrNo,
                EditActivationKitName,
                txtActivationKitName,
                txtMinDPAmount,
                txtFreeMRP
            },
        }, () => {
            if (oldEditAKSrNo > 0) {
                this.navigateTo('ActivationKitList')
            }
            document.getElementById('txtActivationKitName').focus()
        })
    }

    newAKItem = () => {
        const EditSrNo = 0,
            cmbProductName = '',
            txtQuantity = ''
        this.setState({
            formDataActivationKitItem: {
                ...this.state.formDataActivationKitItem,
                EditSrNo,
                cmbProductName,
                txtQuantity
            },
        }, () => {
            document.getElementById('cmbProductName').focus()
        })
    }

    onDeleteBtnClick = () => {
        if (Number(this.state.formDataActivationKit.EditAKSrNo) > 0) {
            const EditAKSrNo = this.state.formDataActivationKit.EditAKSrNo,
                EditActivationKitName = this.state.formDataActivationKit.EditActivationKitName
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Activation Kit Name : ' + EditActivationKitName + '?'}
                    // alertMessage={}
                    handleOnClickYes={() => { return this.deleteAK(EditAKSrNo) }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } else {
            this.props.toastMsg("info", "There are no detail to delete")
        }
    }

    deleteAK = (EditAKSrNo) => {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                Op: 'DeleteActivationKit',
                EditAKSrNo: EditAKSrNo,
            }
            fetchActivationKit(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    this.setState({
                        dataLoadStatus: true
                    }, () => {
                        if (res.data.msgType === 'success') {
                            this.loadActivationKit()
                            this.newAK()
                        }
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('deleteAK', error)
                })
        })
        return true
    }

    deleteAKItem = (EditSrNo, EditAKSrNo) => {
        if (Number(EditSrNo) > 0 && Number(EditAKSrNo) > 0) {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'DeleteActivationKitItem',
                    EditSrNo: Number(EditSrNo),
                    EditAKSrNo: EditAKSrNo,
                }
                fetchActivationKit(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.setState({
                            dataLoadStatus: true
                        }, () => {
                            if (res.data.msgType === 'success') {
                                this.loadActivationKitItem(EditAKSrNo)
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('deleteAKItem', error)
                    })
            })
        } else {
            this.props.toastMsg("info", "There are no detail to delete")
        }
        return true
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {},
            {
                cType: 'CtTxt',
                id: 'activationkitname',
                label: 'Activation Kit Name',
                value: this.state.searchData.activationkitname,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'minbillamt',
                label: 'Minimum DP Amount',
                value: this.state.searchData.minbillamt,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '10'
            },
            {
                cType: 'CtTxt',
                id: 'freemrp',
                label: 'Free MRP',
                value: this.state.searchData.freemrp,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '10'
            }
        )
        this.setState({ searchColumns })
    }

    setSearchColumnsItem = () => {
        let searchColumnsItem = []
        searchColumnsItem.push(
            {},
            {
                cType: 'CtTxt',
                id: 'productname',
                label: 'Product Name',
                value: this.state.searchDataItem.productname,
                handleOnChange: this.handleOnSearchInputChangeItem,
                width: '200',
                maxLength: '100'
            }
        )
        this.setState({ searchColumnsItem })
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)

        let rowsItem = (this.state.searchOpenItem) ? (this.state.searchRowsItem) : (this.state.rowsItem)
        let rowsCountItem = (this.state.searchOpenItem) ? (this.state.searchRowsCountItem) : (this.state.rowsCountItem)

        return (
            <div>
                <br />
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <form>
                    <Grid container direction="row" justify="space-evenly" alignItems="flex-start">
                        <Grid item className="pageTitle" xs={12} lg={6}>
                            <label htmlFor="Title" className='pageTitleText'>Activation Kit</label>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtActivationKitName'
                                        label='Activation Kit Name'
                                        handleOnChange={this.handleOnAKChange}
                                        value={this.state.formDataActivationKit.txtActivationKitName}
                                        width='580'
                                        maxLength='100'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtMinDPAmount'
                                        label='Min DP Amount'
                                        handleOnChange={this.handleOnAKChange}
                                        value={this.state.formDataActivationKit.txtMinDPAmount}
                                        width={130}
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtFreeMRP'
                                        label='Free MRP'
                                        handleOnChange={this.handleOnAKChange}
                                        value={this.state.formDataActivationKit.txtFreeMRP}
                                        width={130}
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='Save' onClick={() => { this.saveAK() }} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='New' onClick={this.newAK} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='Delete' onClick={this.onDeleteBtnClick} width={this.state.cWidth} />
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container direction="row" justify="flex-start" alignItems="center"  spacing={3}>
                                <TableList
                                    columns={this.state.columnsAK}
                                    counter={this.state.counter}
                                    rows={rows}
                                    rowsCount={rowsCount}
                                    rowsPerPage={this.state.rowsPerPage}
                                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    onActionClick={this.handleARDonActionClick}
                                    actionList={this.state.actionList}
                                    keyColumn={this.state.keyColumn}
                                    handleOnSearchChange={this.handleOnSearchChange}
                                    searchColumns={this.state.searchColumns}
                                    searchData={this.state.searchData}
                                    provideSearch={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item className="pageTitle" xs={12} lg={6}>
                            <label htmlFor="Title" className='pageTitleText'>Activation Kit Item</label>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item >
                                    <CtCmbEditable
                                        id='cmbProductName'
                                        label='Product Name'
                                        items={this.state.ProductNames}
                                        handleOnChange={this.handleOnAKItemChange}
                                        value={this.state.formDataActivationKitItem.cmbProductName}
                                        width='400'
                                        maxLength='100'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtQuantity'
                                        label='Quantity'
                                        handleOnChange={this.handleOnAKItemChange}
                                        value={this.state.formDataActivationKitItem.txtQuantity}
                                        width="130"
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtBtn label='Add' onClick={() => { this.saveAkItem() }} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='New' onClick={this.newAKItem} width={this.state.cWidth} />
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                <TableList
                                    columns={this.state.columnsItem}
                                    counter={this.state.counter}
                                    rows={rowsItem}
                                    rowsCount={rowsCountItem}
                                    rowsPerPage={this.state.rowsPerPageItem}
                                    handleChangeRowsPerPage={this.handleChangeRowsPerPageItem}
                                    onActionClick={this.handleARDonActionItemClick}
                                    actionList={this.state.actionListItem}
                                    keyColumn={this.state.keyColumnItem}
                                    handleOnSearchChange={this.handleOnSearchChangeItem}
                                    searchColumns={this.state.searchColumnsItem}
                                    searchData={this.state.searchDataItem}
                                    provideSearch={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(ActivationKitList))