import React, { Component } from 'react'
import { fetchMember, VerifyIsAdmin } from './API/API'
import TableList from './CustomTool/TableList'
import ActionTableList from './CustomTool/ActionTableList'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { CheckCircle, Delete } from '@material-ui/icons';
import AlertResponsiveDialog from './CustomTool/AlertResponsiveDialog'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import Progress from './CustomTool/Progress'
import CtMultiLineText from "./CustomTool/CtMultiLineText";

class ApproveMemberList extends Component {

    state = {
        columns: [
            { index: 1, id: 'ID', numeric: false, disablePadding: true, label: 'ID', align: 'left' },
            { index: 2, id: 'Scheme', numeric: false, disablePadding: false, label: 'Scheme', align: 'left' },
            { index: 3, id: 'ReferenceCode', numeric: false, disablePadding: false, label: 'Ref Code', align: 'left' },
            { index: 4, id: 'SponsorId', numeric: false, disablePadding: false, label: 'Spo Id', align: 'left' },
            { index: 5, id: 'Name', numeric: false, disablePadding: false, label: 'Name', align: 'left' },
            { index: 6, id: 'MobileNo', numeric: false, disablePadding: false, label: 'Mobile No', align: 'left' },
        ],
        keyColumn: 'ID',
        rows: [
            {
                ID: '',
                Scheme: '',
                ReferenceCode: '',
                SponsorId: '',
                Name: '',
                MobileNo: '',
            }
        ],
        selectedRows: [],
        rowsCount: 0,
        tableHeading: 'Member to be Approve',
        res: [],
        displayDeleteReconfirm: 0,
        deleteReconfirmIdList: [],
        dataLoadStatus: false,
        CtMTxtReason: '',
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "ApproveMemberList" })
        this.listMembers()
    }

    loadAdminName = (userID) => {
        VerifyIsAdmin(userID)
            .then(res => {
                if (res.data.name && res.data.name.trim().length > 0) {
                    this.setState({ name: res.data.name, userID })
                } else {
                    this.navigateTo('wgar')
                }
            })
    }

    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    listMembers = () => {
        let rootmember = ''
        if (
            this.props.match.params.rootmember &&
            this.props.match.params.rootmember.length > 0 &&
            Number(this.props.match.params.rootmember.substring(1)) > 99999
        ) {
            rootmember = Number(this.props.match.params.rootmember.substring(1))
        } else if (this.props.rootmember && this.props.rootmember.length > 0 && Number(this.props.rootmember) > 99999) {
            rootmember = this.props.rootmember
        }
        const reqData = {
            Op: 'ApprovalPendingMemberList',
            rootmember
        }
        fetchMember(reqData)
            .then(res => {
                console.log(res.data);
                this.setState({
                    rows: res.data,
                    rowsCount: 0,
                    dataLoadStatus: true
                }, () => {
                    const dataCount = (this.state.res.msgType === 'NAN' && this.state.res.message === 'NAN') ? 0 : (
                        (res.data.length && res.data.length > 0) ? res.data.length : 0
                    )
                    this.displayOutput()
                    this.setState({ rowsCount: dataCount }, () => {
                        console.log(this.state.rowsCount)
                        this.displayOutput()
                    })
                })
            })
    }

    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    outputMemberListContent = () => {
        let output
        if (this.state.rowsCount > 0) {
            output = <TableList
                columns={this.state.columns}
                rows={this.state.rows}
                rowsCount={this.state.rowsCount}
                onCellDoubleClick={this.navigateTo}
            />
        } else {
            output = "No Member Details Found"
        }
        return output
    }

    handleApprove = () => {
        this.props.notifyProcessing()
        const reqData = {
            Op: 'SetApproveNActiveStatus',
            EditID: this.state.selectedRows,
            ActiveStatus: 1,
            ApproveStatus: 1,
            PerformBGTransNTree: true,
        }
        fetchMember(reqData)
            .then(res => {
                this.setState({ res: res.data })
                this.props.updateProcessing(this.state.res.msgType, this.state.res.message)
                if (this.state.res.msgType === 'success') {
                    this.listMembers()
                }
            })
            .catch(err => {
                this.props.updateProcessing('error', 'Unknown error occured in Approve Member.\n\n' + err)
            })
    }

    handleDelete = () => {
        this.props.notifyProcessing()
        const reqData = {
            Op: 'GetChildListOfIDListFromRegistration',
            EditID: this.state.selectedRows,
        }
        fetchMember(reqData)
            .then(res => {
                // res.data.IDList
                this.props.updateProcessing('warning', 'Do you want to continue?')
                const displayDeleteReconfirm = 1
                const deleteReconfirmIdList = res.data.IDList
                const CtMTxtReason = ''
                this.setState({
                    displayDeleteReconfirm, deleteReconfirmIdList, CtMTxtReason
                })
            })
    }

    handleCheckedChange = (selectedRows) => {
        this.setState({ selectedRows })
    }

    displayOutput = (privapprovemember) => {
        return (
            (this.state.rowsCount > 0) ? (
                <ActionTableList
                    headCells={this.state.columns}
                    colIds={this.state.colIds}
                    keyColumn={this.state.keyColumn}
                    rows={this.state.rows}
                    tableHeading={this.state.tableHeading}
                    visibleApprove={privapprovemember === "M" ? true : false}
                    visibleEdit={false}
                    visibleDelete={privapprovemember === "M" ? true : false}
                    handleApprove={this.handleApprove}
                    handleDelete={this.handleDelete}
                    handleCheckedChange={this.handleCheckedChange}
                    approveLabel={<CheckCircle style={{ color: '#0000FF' }} />}
                    deleteLabel={<Delete style={{ color: '#FF0000' }} />}
                    approveAlertLabelAgree={'Yes'}
                    approveAlertLabelDisagree={'No'}
                    approveAlertTitleAction={'Approve'}
                    deleteAlertLabelAgree={'Yes'}
                    deleteAlertLabelDisagree={'No'}
                    deleteAlertTitleAction={'Delete'}
                />
            ) : ('No Member Remain to Approve')
        )
    }

    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    generateDeleteMemberFinalMsgWithReason = () => {
        const DeleteIDList = this.state.deleteReconfirmIdList.map((id, index) => {
            return (
                <div>
                    ({index < 9 ? '0' : ''}{index + 1}) {id}
                </div>
            )
        })

        return (
            <div>
                {DeleteIDList}
                <CtMultiLineText
                    id='CtMTxtReason'
                    label='Reason'
                    value={this.state.CtMTxtReason}
                    handleOnChange={this.handleOnChange}
                    width='500'
                    maxLength='750'
                />
            </div>
        )
    }

    displayDeleteReconfirm = () => {
        return (
            (this.state.displayDeleteReconfirm === 1) ? (
                <AlertResponsiveDialog
                    label={'Confirm To Delete Registration Request'}
                    labelAgree={'Delete Permanently'}
                    labelDisagree={'Don\'t Delete'}
                    alertTitle={'Do you want to Delete Listed Registration Permanently?'}
                    alertMessage={this.generateDeleteMemberFinalMsgWithReason()}
                    handleOnClickYes={this.handleDeletePermanently}
                    handleOnClickNo={this.resetDisplayDeleteReconfirm}
                    defaultOpenDialog={true}
                    disableAgree={this.state.CtMTxtReason.trim().length === 0}
                />
            ) : (null)
        )
    }

    handleDeletePermanently = () => {
        this.resetDisplayDeleteReconfirm()
        this.props.notifyProcessing()
        const reqData = {
            Op: 'DeleteNotApproveMember',
            EditID: this.state.deleteReconfirmIdList,
            DeleteReason: this.state.CtMTxtReason.trim()
        }
        fetchMember(reqData)
            .then(res => {
                this.props.updateProcessing(res.data.msgType, res.data.message)
                if (res.data.msgType === 'success') {
                    this.listMembers()
                }
            })
    }

    resetDisplayDeleteReconfirm = () => {
        const displayDeleteReconfirm = 0
        this.setState({ displayDeleteReconfirm })
    }

    render() {

        const privapprovemember = this.props.privapprovemember

        return (
            <div className="ApproveMemberList">
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                {
                    this.displayOutput(privapprovemember)
                }
                {
                    this.displayDeleteReconfirm()
                }
            </div>
        )
    }
}

export default HOCVerifyIsAdmin(HoCtToastContainer(ApproveMemberList))