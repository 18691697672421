import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { fetchAdmin, fetchMember } from "./API/API";
import CtTxtPWD from './CustomTool/CtTxtPWD'
import CtBtn from './CustomTool/CtBtn'
import 'react-toastify/dist/ReactToastify.css'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import HOCVerifyIsLogInUserOrAdmin from './HOC/HOCVerifyIsLogInUserOrAdmin'

class ChangePassword extends Component {

    CtWidth = 250

    state = {
        changeData: {
            CurrentPassword: '',
            NewPassword: '',
            ConfirmNewPassword: '',
        }
    }

    handleOnChange = (e) => {
        this.setState({
            changeData: { ...this.state.changeData, [e.target.name]: e.target.value }
        })
    }

    validatePassword = () => {
        let result = false
        if (this.state.changeData.CurrentPassword.length <= 0) {
            this.props.toastErrorMsg('Enter Current Password.', 'CurrentPassword')
        } else if (this.state.changeData.NewPassword.length <= 0) {
            this.props.toastErrorMsg('Enter New Password.', 'NewPassword')
        } else if (this.state.changeData.NewPassword.length < 4) {
            this.props.toastErrorMsg('Enter New Password that contains atleast Four Characters.', 'NewPassword')
        } else if (this.state.changeData.ConfirmNewPassword.length <= 0) {
            this.props.toastErrorMsg('Enter Confirm New Password.', 'ConfirmNewPassword')
        } else if (this.state.changeData.NewPassword !== this.state.changeData.ConfirmNewPassword) {
            this.props.toastErrorMsg('New Password & Confirm Password must be same.', 'NewPassword')
        } else {
            result = true
        }
        return result
    }

    changePassowrd = () => {
        if (this.validatePassword()) {
            this.props.notifyProcessing()
            if (localStorage.getItem('logInUserType') === 'A') {
                const reqData = {
                    Op: 'ChangePWD',
                    AdminID: localStorage.getItem('logInID'),
                    CurPWD: this.state.changeData.CurrentPassword,
                    NewPWD: this.state.changeData.NewPassword,
                    NewConfPWD: this.state.changeData.ConfirmNewPassword
                }
                fetchAdmin(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.navigateTo('wgar')
                        }
                    })
            } else {
                const reqData = {
                    Op: 'ChangePWD',
                    UserID: localStorage.getItem('logInID'),
                    CurPWD: this.state.changeData.CurrentPassword,
                    NewPWD: this.state.changeData.NewPassword,
                    NewConfPWD: this.state.changeData.ConfirmNewPassword
                }
                fetchMember(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.navigateTo('Login')
                        }
                    })
            }
        }
    }

    componentWillMount() {
        this.props.validateIsLogInUserOrAdmin(
            this.props.IsAdmin,
            this.props.setActiveLink,
            this.navigateTo
        )
    }

    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "ChangePassword" })
        document.getElementById('CurrentPassword').focus()
    }

    render() {
        return (
            <div className="ChangePassword" >
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText' >Change Password</label>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={5} >
                    <Grid item>
                        <CtTxtPWD
                            id='CurrentPassword'
                            label='Current Password'
                            value={this.state.changeData.CurrentPassword}
                            handleOnChange={this.handleOnChange}
                            width={this.CtWidth}
                            maxLength='100'
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={5} >
                    <Grid item>
                        <CtTxtPWD
                            id='NewPassword'
                            label='New Password'
                            value={this.state.changeData.NewPassword}
                            handleOnChange={this.handleOnChange}
                            width={this.CtWidth}
                            maxLength='100'
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={5} >
                    <Grid item>
                        <CtTxtPWD
                            id='ConfirmNewPassword'
                            label='Confirm New Password'
                            value={this.state.changeData.ConfirmNewPassword}
                            handleOnChange={this.handleOnChange}
                            width={this.CtWidth}
                            maxLength='100'
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                    <Grid item><CtBtn label='Set Password' onClick={this.changePassowrd} /></Grid>
                </Grid>
            </div >
        )
    }
}

export default HOCVerifyIsLogInUserOrAdmin(HoCtToastContainer(ChangePassword))