import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import Progress from './CustomTool/Progress'
import { Grid, Box } from '@material-ui/core'
import CtTxt from './CustomTool/CtTxt'
import CtCmbEditable from './CustomTool/CtCmbEditable'
import CtDtpDOB from './CustomTool/CtDtpDOB'
import CtCheckBox from './CustomTool/CtCheckBox'
import CtBtn from './CustomTool/CtBtn'
import TableList from './CustomTool/TableList'
import { fetchOffer } from './API/API'
import { get_YMD_from_SYS, get_YMD_from_DMY } from './SystemUtility/SystemUtility'
import { Delete, Edit } from '@material-ui/icons'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";

class OfferEntry extends Component {

    state = {
        formDataOffer: {
            EditOfferSrNo: 0,
            EditOfferName: '',
            txtOfferName: '',
            txtMinDPAmount: '',
            dtpStartDate: get_YMD_from_SYS(new Date()),
            dtpExpiryDate: get_YMD_from_SYS(new Date()),
            chkOfferComingSoon: false,
            txtFreeMRP: '',
        },
        formDataOfferItem: {
            EditSrNo: 0,
            cmbProductName: '',
            txtQuantity: '',
        },
        columns: [
            { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            { id: 'no', label: 'Sr. No.', align: 'center' },
            { id: 'productname', label: 'Product Name' },
            { id: 'qty', label: 'Quantity', align: 'right' },
            { id: 'srno', label: 'srno', align: 'center', hide: 'true' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 5,
        ProductNames: [],
        dataLoadStatus: false,
        cWidth: 80,
        ARD: null,
        keyColumn: [
            'srno', 'productname', 'qty'
        ],
        actionList: [
            {
                name: 'Edit Offer Item',
                icon: <Edit />,
                link: 'Offer:',
                actionType: 'AlertResponsive'
            },
            {
                name: 'Delete Offer Item',
                icon: <Delete />,
                link: 'DelOffer:',
                actionType: 'AlertResponsive'
            },
        ],
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.onFormLoad()
        document.getElementById('txtOfferName').focus()
    }
    //#endregion

    //#region Handles
    handleOnOfferChange = (e) => {
        this.setState({
            formDataOffer: { ...this.state.formDataOffer, [e.target.name]: e.target.value }
        })
    }

    handleOnChkChange = (accepted) => {
        this.setState({
            formDataOffer: {
                ...this.state.formDataOffer,
                chkOfferComingSoon: accepted
            }
        })
    }

    handleOnOfferItemChange = (e) => {
        this.setState({
            formDataOfferItem: { ...this.state.formDataOfferItem, [e.target.name]: e.target.value }
        })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formDataOffer: { ...this.state.formDataOffer, [e.target.name]: e.target.value.trim() }
        })
    }

    handleARDonActionClick = (values) => {
        const EditSrNo = values[0].value,
            actionName = values[0].actionName,
            cmbProductName = values[1].value,
            txtQuantity = values[2].value
        if (actionName === 'Edit Offer Item') {
            this.setState({
                formDataOfferItem: {
                    ...this.state.formDataOfferItem,
                    EditSrNo,
                    cmbProductName,
                    txtQuantity
                },
            })
        } else if (actionName === 'Delete Offer Item') {
            let ARD = null, EditOfferSrNo = this.state.formDataOffer.EditOfferSrNo
            this.setState({ ARD }, () => {
                ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Offer Item : ' + cmbProductName + '?'}
                    // alertMessage={}
                    handleOnClickYes={() => { return this.deleteOfferItem(EditSrNo, EditOfferSrNo) }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    onFormLoad = () => {
        this.setState({
            dataLoadStatus: false
        }, () => {
            let reqData = {
                Op: 'LoadProductName'
            },
                EditOfferSrNo = 0,
                EditOfferName = '',
                txtOfferName = '',
                txtMinDPAmount = '',
                dtpStartDate = get_YMD_from_SYS(new Date()),
                dtpExpiryDate = get_YMD_from_SYS(new Date()),
                chkOfferComingSoon = false,
                txtFreeMRP = ''
            if (this.props.match.params.EditOfferSrNo && Number(this.props.match.params.EditOfferSrNo.substring(1)) > 0) {
                EditOfferSrNo = Number(this.props.match.params.EditOfferSrNo.substring(1))
                reqData = {
                    Op: 'Load_Offer_ProductName',
                    EditOfferSrNo
                }
            }
            fetchOffer(reqData)
                .then(res => {
                    const ProductNames = res.data.ProductName
                    let rows = [], rowsCount = 0
                    const dataLoadStatus = true
                    if (EditOfferSrNo > 0) {
                        EditOfferName = res.data.offermasterList[0].offername
                        txtOfferName = EditOfferName
                        txtMinDPAmount = res.data.offermasterList[0].mintotalamount
                        dtpStartDate = get_YMD_from_DMY(res.data.offermasterList[0].startdate, "/")
                        dtpExpiryDate = get_YMD_from_DMY(res.data.offermasterList[0].expirydate, "/")
                        chkOfferComingSoon = (res.data.offermasterList[0].dispcommingsoon === "0" ? false : true)
                        txtFreeMRP = res.data.offermasterList[0].freemrp
                        rows = res.data.offeritemList
                        rowsCount = rows.length
                    }
                    this.setState({
                        ProductNames, dataLoadStatus, rows, rowsCount,
                        formDataOffer: {
                            ...this.state.formDataOffer,
                            EditOfferSrNo,
                            EditOfferName,
                            txtOfferName,
                            txtMinDPAmount,
                            dtpStartDate,
                            dtpExpiryDate,
                            chkOfferComingSoon,
                            txtFreeMRP
                        }
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('onFormLoad', error)
                })
        })
    }

    loadOfferItem = (EditOfferSrNo) => {
        this.setState({
            dataLoadStatus: false
        }, () => {
            let reqData = {
                Op: 'LoadOfferItem',
                EditOfferSrNo
            }
            fetchOffer(reqData)
                .then(res => {
                    const rows = res.data.offeritemList,
                        rowsCount = rows.length,
                        dataLoadStatus = true
                    this.setState({
                        rows, rowsCount, dataLoadStatus
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('loadOfferItem', error)
                })
        })
    }

    validateOfferdata = () => {
        let validateOfferdata = false

        if (this.state.formDataOffer.txtOfferName.trim().length <= 0) {
            this.props.toastErrorMsg('Enter Offer Name', 'txtOfferName')
        } else if (Number(this.state.formDataOffer.txtMinDPAmount) <= 0) {
            this.props.toastErrorMsg('Enter Minimum DP Amount', 'txtMinDPAmount')
        } else if (this.state.formDataOffer.dtpStartDate === undefined || this.state.formDataOffer.dtpStartDate.length <= 0) {
            this.props.toastErrorMsg('Enter Start Date', 'dtpStartDate')
        } else if (this.state.formDataOffer.dtpExpiryDate === undefined || this.state.formDataOffer.dtpExpiryDate.length <= 0) {
            this.props.toastErrorMsg('Enter Expiry Date', 'dtpExpiryDate')
        } else if (Number(this.state.formDataOffer.txtFreeMRP) < 0) {
            this.props.toastErrorMsg('MRP cannot be less than 0', 'txtFreeMRP')
        } else {
            validateOfferdata = true
        }
        return validateOfferdata
    }

    validateOfferItemdata = () => {
        let validateOfferItemdata = false

        if (this.state.formDataOfferItem.cmbProductName.trim().length <= 0) {
            this.props.toastErrorMsg('Select Product Name', 'cmbProductName')
        } else if (Number(this.state.formDataOfferItem.txtQuantity) <= 0) {
            this.props.toastErrorMsg('Enter Quantity', 'txtQuantity')
        } else {
            validateOfferItemdata = true
        }
        return validateOfferItemdata
    }

    saveOfferItem = () => {
        let result = false
        if (this.validateOfferdata() === true && this.validateOfferItemdata() === true) {
            this.props.notifyProcessing()

            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'SaveOffer_OfferItem',
                    EditOfferSrNo: this.state.formDataOffer.EditOfferSrNo,
                    EditOfferName: this.state.formDataOffer.txtOfferName,
                    OfferName: this.state.formDataOffer.txtOfferName,
                    MinDPAmount: this.state.formDataOffer.txtMinDPAmount,
                    StartDate: this.state.formDataOffer.dtpStartDate,
                    ExpiryDate: this.state.formDataOffer.dtpExpiryDate,
                    OfferComingSoon: this.state.formDataOffer.chkOfferComingSoon === true ? 1 : 0,
                    FreeMRP: this.state.formDataOffer.txtFreeMRP,

                    EditSrNo: this.state.formDataOfferItem.EditSrNo,
                    ProductName: this.state.formDataOfferItem.cmbProductName,
                    Quantity: this.state.formDataOfferItem.txtQuantity,
                }
                fetchOffer(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        let EditOfferSrNo = res.data.EditOfferSrNo,
                            EditOfferName = this.state.formDataOffer.txtOfferName
                        this.setState({
                            dataLoadStatus: true,
                            formDataOffer: {
                                ...this.state.formDataOffer,
                                EditOfferSrNo,
                                EditOfferName
                            }
                        }, () => {
                            if (res.data.msgType === 'success') {
                                result = true
                                this.loadOfferItem(EditOfferSrNo)
                                this.newOfferItem()
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('saveOfferItem', error)
                    })
            })
        }
    }

    saveOffer = () => {
        let result = false
        if (this.validateOfferdata() === true) {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'UpdateOffer',
                    EditOfferSrNo: this.state.formDataOffer.EditOfferSrNo,
                    EditOfferName: this.state.formDataOffer.txtOfferName,
                    OfferName: this.state.formDataOffer.txtOfferName,
                    MinDPAmount: this.state.formDataOffer.txtMinDPAmount,
                    StartDate: this.state.formDataOffer.dtpStartDate,
                    ExpiryDate: this.state.formDataOffer.dtpExpiryDate,
                    OfferComingSoon: this.state.formDataOffer.chkOfferComingSoon === true ? 1 : 0,
                    FreeMRP: this.state.formDataOffer.txtFreeMRP,
                }
                fetchOffer(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.setState({
                            dataLoadStatus: true
                        }, () => {
                            if (res.data.msgType === 'success') {
                                result = true
                                this.newOffer()
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('saveOffer', error)
                    })
            })
        }
        // if (this.validateSales() === true) {

    }

    newOffer = () => {
        const oldEditOfferSrNo = this.state.formDataOffer.EditOfferSrNo
        const EditOfferSrNo = 0,
            EditOfferName = '',
            txtOfferName = '',
            txtMinDPAmount = '',
            txtFreeMRP = '',
            dtpStartDate = get_YMD_from_SYS(new Date()),
            dtpExpiryDate = get_YMD_from_SYS(new Date()),
            chkOfferComingSoon = false,
            rows = [],
            rowsCount = 0
        this.setState({
            rows,
            rowsCount,
            formDataOffer: {
                ...this.state.formDataOffer,
                EditOfferSrNo,
                EditOfferName,
                txtOfferName,
                txtMinDPAmount,
                dtpStartDate,
                dtpExpiryDate,
                chkOfferComingSoon,
                txtFreeMRP
            },
        }, () => {
            if (oldEditOfferSrNo > 0) {
                this.navigateTo('OfferEntry')
            }
            document.getElementById('txtOfferName').focus()
        })
    }

    newOfferItem = () => {
        const EditSrNo = 0,
            cmbProductName = '',
            txtQuantity = ''
        this.setState({
            formDataOfferItem: {
                ...this.state.formDataOfferItem,
                EditSrNo,
                cmbProductName,
                txtQuantity
            },
        }, () => {
            document.getElementById('cmbProductName').focus()
        })
    }

    onDeleteBtnClick = () => {
        if (Number(this.state.formDataOffer.EditOfferSrNo) > 0) {
            const EditOfferSrNo = this.state.formDataOffer.EditOfferSrNo,
                EditOfferName = this.state.formDataOffer.EditOfferName
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Offer : ' + EditOfferName + '?'}
                    // alertMessage={}
                    handleOnClickYes={() => { return this.deleteOffer(EditOfferSrNo) }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } else {
            this.props.toastMsg("info", "There are no detail to delete")
        }
    }

    deleteOffer = (EditOfferSrNo) => {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                Op: 'DeleteOffer',
                EditOfferSrNo: EditOfferSrNo,
            }
            fetchOffer(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    this.setState({
                        dataLoadStatus: true
                    }, () => {
                        if (res.data.msgType === 'success') {
                            this.newOffer()
                        }
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('deleteProduct', error)
                })
        })
        return true
    }

    deleteOfferItem = (EditSrNo, EditOfferSrNo) => {
        if (Number(EditSrNo) > 0 && Number(EditOfferSrNo) > 0) {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'DeleteOfferItem',
                    EditSrNo: Number(EditSrNo),
                    EditOfferSrNo: EditOfferSrNo,
                }
                fetchOffer(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.setState({
                            dataLoadStatus: true
                        }, () => {
                            if (res.data.msgType === 'success') {
                                this.loadOfferItem(EditOfferSrNo)
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('deleteProduct', error)
                    })
            })
        } else {
            this.props.toastMsg("info", "There are no detail to delete")
        }
        return true
    }

    onClose = (link) => {
        this.navigateTo('OfferList')
    }
    //#endregion */

    render() {
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <br />
                <form>
                    <Grid container direction="row" justify="space-evenly" alignItems="flex-start">
                        <Grid item className="pageTitle" xs={12} lg={6}>
                            <label htmlFor="Title" className='pageTitleText' >Offer</label>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtOfferName'
                                        label='Offer Name'
                                        handleOnChange={this.handleOnOfferChange}
                                        value={this.state.formDataOffer.txtOfferName}
                                        width='560'
                                        maxLength='100'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtMinDPAmount'
                                        label='Min DP Amount'
                                        handleOnChange={this.handleOnOfferChange}
                                        value={this.state.formDataOffer.txtMinDPAmount}
                                        width={this.state.cWidth + 150}
                                        maxLength='10'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtDtpDOB
                                        id='dtpStartDate'
                                        label='Start Date'
                                        width='150'
                                        handleOnChange={this.handleOnChangeTrim}
                                        value={this.state.formDataOffer.dtpStartDate}
                                        defaultValue={this.state.formDataOffer.dtpStartDate}
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtDtpDOB
                                        id='dtpExpiryDate'
                                        label='Expiry Date'
                                        width='150'
                                        handleOnChange={this.handleOnChangeTrim}
                                        defaultValue={this.state.formDataOffer.dtpExpiryDate}
                                        value={this.state.formDataOffer.dtpExpiryDate}
                                    // onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtCheckBox
                                        id='chkOfferComingSoon'
                                        name='chkOfferComingSoon'
                                        label='Offer Coming Soon'
                                        checked={this.state.formDataOffer.chkOfferComingSoon}
                                        handleCheckChange={this.handleOnChkChange}
                                    />
                                </Grid>
                                <Grid item style={{ display: 'none' }}>
                                    <CtTxt
                                        id='txtFreeMRP'
                                        label='Free MRP'
                                        handleOnChange={this.handleOnOfferChange}
                                        value={this.state.formDataOffer.txtFreeMRP}
                                        width={this.state.cWidth + 150}
                                        maxLength='10'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtBtn label='Save' onClick={() => { this.saveOffer() }} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='New' onClick={this.newOffer} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='Delete' onClick={this.onDeleteBtnClick} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='Close' onClick={this.onClose} width={this.state.cWidth} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className="pageTitle" xs={12} lg={6}>
                            <label htmlFor="Title" className='pageTitleText' >Offer Item</label>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item >
                                    <CtCmbEditable
                                        id='cmbProductName'
                                        label='Product Name'
                                        items={this.state.ProductNames}
                                        handleOnChange={this.handleOnOfferItemChange}
                                        value={this.state.formDataOfferItem.cmbProductName}
                                        width='400'
                                        maxLength='100'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtQuantity'
                                        label='Quantity'
                                        handleOnChange={this.handleOnOfferItemChange}
                                        value={this.state.formDataOfferItem.txtQuantity}
                                        width="130"
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtBtn label='Add' onClick={() => { this.saveOfferItem() }} width={this.state.cWidth} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='New' onClick={this.newOfferItem} width={this.state.cWidth} />
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                <TableList
                                    columns={this.state.columns}
                                    counter={this.state.counter}
                                    rows={this.state.rows}
                                    rowsCount={this.state.rowsCount}
                                    rowsPerPage={this.state.rowsPerPage}
                                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    onActionClick={this.handleARDonActionClick}
                                    actionList={this.state.actionList}
                                    keyColumn={this.state.keyColumn}
                                    handleOnSearchChange={this.handleOnSearchChange}
                                    searchColumns={this.state.searchColumns}
                                    searchData={this.state.searchData}
                                    provideSearch={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(OfferEntry))