import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { Grid, Box } from '@material-ui/core'
import CtCmbEditable from './CustomTool/CtCmbEditable'
import CtCheckBox from './CustomTool/CtCheckBox'
import CtTxt from './CustomTool/CtTxt'
import CtBtn from './CustomTool/CtBtn'
import { fetchSales, fetchProduct } from "./API/API"

class SalesItemEntry extends Component {

    state = {
        cWidth: 200,
        dataLoadStatus: false,

        itemInfo: {
            txtRate: 0,
            txtMRP: 0,
            cmbGSTPer: '',
            txtBTO: 0,
            cmbProductName: '',
        },

        formData: {
            EditSalesDate: '',
            EditBillNo: '',
            EditAcYear: '',
            EditItemSrNo: '',
            cmbProductName: '',
            txtBatchNo: '',
            txtQuantity: 0,
            txtRate: 0,
            txtMRP: 0,
            txtBasicAmount: 0,
            txtDiscountPer: 0,
            txtDiscountAmount: 0,
            txtTaxableAmount: 0,
            cmbGSTPer: '',
            txtCGST: 0,
            txtSGST: 0,
            txtIGST: 0,
            txtTotalAmount: 0,
            txtBTO: 0,
            txtTotalBTO: 0,
            txtTotalFreeMRP: 0,
            chkFreeMRP: false,
        },
        txtBillNo: 0,
        FreeMRP: 0,
        AvailableFreeMRP: 0,
        ProductNames: [],
        GSTPer: [],
    }

    //#region Component
    componentDidMount() {
        this.onFormLoad()
        document.getElementById('cmbProductName').focus()
    }

    componentWillMount() {
        // this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }
    //#endregion

    //#region Handles
    handleOnChange = (e) => {
        const curID = e.target.name
        const curValue = e.target.value
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        }, () => {
            if (curID === 'txtQuantity' || curID === 'txtRate' || curID === 'txtBTO') {
                // alert("curID === 'txtQuantity' || curID === 'txtRate' || curID === 'txtBTO'")
                this.setTaxableToFreeMRPData()
            } else if (curID == "cmbProductName") {
                // alert('curID == "cmbProductName"')
                this.getProductDetail()
            } else if (curID == "cmbGSTPer") {
                // alert('curID == "cmbGSTPer"')
                this.calculate_CGST_SGST_IGST()
            }
        })
    }

    handleOnChkChange = (checkedStatus) => {
        this.setState({
            formData: {
                ...this.state.formData,
                chkFreeMRP: checkedStatus
            }
        }, () => {
            this.loadFreeMRPProduct(checkedStatus)
        })
    }
    //#endregion

    //#region Function 

    calculateBasicAmount = () => {
        /* const Qty = Number(this.state.formData.txtQuantity),
            Rate = Number(this.state.formData.txtRate)
        let txtBasicAmount = Qty * Rate
        txtBasicAmount = txtBasicAmount.toFixed(2)
        this.setState({
            formData: {
                ...this.state.formData,
                txtBasicAmount
            }
        }, () => {
            // this.calculateDisAmount()
        }) */
    }

    calculateTotalBTO = () => {
        const Qty = Number(this.state.formData.txtQuantity),
            BTO = Number(this.state.formData.txtBTO)
        let txtTotalBTO = 0
        if (BTO >= 0) {
            txtTotalBTO = Qty * BTO
            txtTotalBTO = txtTotalBTO.toFixed(2)
        }
        this.setState({
            formData: {
                ...this.state.formData,
                txtTotalBTO
            }
        })
    }

    getTotalBTO = (Qty, BTO) => {
        Qty = Number(Qty)
        BTO = Number(BTO)
        let txtTotalBTO = 0
        if (BTO >= 0) {
            txtTotalBTO = Qty * BTO
            txtTotalBTO = txtTotalBTO.toFixed(2)
        }
        return txtTotalBTO
    }

    calculateDisAmount = () => {
        /* const txtBasicAmount = Number(this.state.formData.txtBasicAmount),
            txtDiscountPer = Number(this.state.formData.txtDiscountPer)
        let txtDiscountAmount = 0
        if (Number(txtDiscountPer) > 0) {
            txtDiscountAmount = ((txtBasicAmount * txtDiscountPer) / 100)
            txtDiscountAmount = txtDiscountAmount.toFixed(2)
        }
        this.setState({
            formData: { ...this.state.formData, txtDiscountAmount }
        }, () => {
            this.calculateTaxableAmount()
        }) */
    }

    calculateTotalFreeMRP = (checkedStatus = this.state.formData.chkFreeMRP) => {
        let txtTotalFreeMRP = 0
        if (checkedStatus) {
            const txtQuantity = Number(this.state.formData.txtQuantity),
                txtMRP = Number(this.state.formData.txtMRP)

            if (Number(txtMRP) >= 0 && Number(txtQuantity) >= 0) {
                txtTotalFreeMRP = txtQuantity * txtMRP
            }
            txtTotalFreeMRP = txtTotalFreeMRP.toFixed(2)

        }
        this.setState({
            formData: { ...this.state.formData, txtTotalFreeMRP }
        })
    }

    getTotalFreeMRP = (checkedStatus) => {
        let txtTotalFreeMRP = 0
        if (checkedStatus) {
            const txtQuantity = Number(this.state.formData.txtQuantity),
                txtMRP = Number(this.state.formData.txtMRP)

            if (Number(txtMRP) >= 0 && Number(txtQuantity) >= 0) {
                txtTotalFreeMRP = txtQuantity * txtMRP
            }
            txtTotalFreeMRP = txtTotalFreeMRP.toFixed(2)

        }
        return txtTotalFreeMRP
    }

    calculateTaxableAmount = () => {
        const txtQuantity = Number(this.state.formData.txtQuantity),
            txtRate = Number(this.state.formData.txtRate)
        let newtxtTaxableAmount = 0
        if (Number(txtRate) >= 0 && Number(txtQuantity) >= 0) {
            newtxtTaxableAmount = txtQuantity * txtRate
        }
        newtxtTaxableAmount = newtxtTaxableAmount.toFixed(2)
        const txtTaxableAmount = newtxtTaxableAmount
        this.setState({
            formData: {
                ...this.state.formData,
                txtTaxableAmount
            }
        }, () => {
            this.calculate_CGST_SGST_IGST()
        })
    }

    getTaxableAmount = (txtQuantity, txtRate) => {
        txtQuantity = Number(txtQuantity)
        txtRate = Number(txtRate)
        let txtTaxableAmount = 0
        if (Number(txtRate) >= 0 && Number(txtQuantity) >= 0) {
            txtTaxableAmount = txtQuantity * txtRate
        }
        txtTaxableAmount = txtTaxableAmount.toFixed(2)
        return txtTaxableAmount
    }

    calculate_CGST_SGST_IGST = () => {
        const txtTaxableAmount = Number(this.state.formData.txtTaxableAmount),
            cmbGSTPer = Number(this.state.formData.cmbGSTPer.substring(0, this.state.formData.cmbGSTPer.length - 1))
        let txtCGST = 0, txtSGST = 0, txtIGST = 0
        //RoundOff((Taxable * (GST% / 2))/100,2)
        if (Number(txtTaxableAmount) >= 0 && Number(cmbGSTPer) >= 0) {
            txtCGST = (txtTaxableAmount * (cmbGSTPer / 2)) / 100
            txtCGST = txtCGST.toFixed(2)
            txtSGST = txtCGST //(txtTaxableAmount * (cmbGSTPer / 2)) / 100
        }
        this.setState({
            formData: { ...this.state.formData, txtCGST, txtSGST, txtIGST }
        }, () => {
            this.calculateTotalAmount()
        })
    }

    get_CGST_SGST_IGST = (txtTaxableAmount, numGSTPer) => {
        let txtCGST = 0, txtSGST = 0, txtIGST = 0
        //RoundOff((Taxable * (GST% / 2))/100,2)
        if (Number(txtTaxableAmount) >= 0 && Number(numGSTPer) >= 0) {
            txtCGST = (txtTaxableAmount * (numGSTPer / 2)) / 100
            txtCGST = txtCGST.toFixed(2)
            txtSGST = txtCGST //(txtTaxableAmount * (numGSTPer / 2)) / 100
        }
        return { txtCGST, txtSGST, txtIGST }
    }

    getTotalAmount = (txtTaxableAmount, txtCGST, txtSGST, txtIGST) => {
        let txtTotalAmount = Number(txtTaxableAmount) + Number(txtCGST) + Number(txtSGST) + Number(txtIGST)
        return txtTotalAmount.toFixed(2)
    }

    calculateTotalAmount = () => {
        const txtTaxableAmount = Number(this.state.formData.txtTaxableAmount),
            txtCGST = Number(this.state.formData.txtCGST),
            txtSGST = Number(this.state.formData.txtSGST),
            txtIGST = Number(this.state.formData.txtIGST)
        let txtTotalAmount = 0
        if (Number(txtTaxableAmount) > 0) {
            txtTotalAmount = txtTaxableAmount + txtCGST + txtSGST + txtIGST
            txtTotalAmount = txtTotalAmount.toFixed(2)
        } this.setState({
            formData: { ...this.state.formData, txtTotalAmount }
        }, () => {
            this.calculateTotalBTO()
        })
    }

    onFormLoad = () => {
        this.setState({
            dataLoadStatus: false
        }, () => {
            let reqData = {
                Op: 'Load_ProductName_GSTPer'
            },
                EditSalesDate = '',
                EditBillNo = '',
                EditAcYear = '',
                EditItemSrNo = 0,
                cmbProductName = '',
                txtBatchNo = '',
                txtQuantity = '',
                txtRate = '',
                txtBasicAmount = '',
                txtDiscountPer = '',
                txtDiscountAmount = '',
                txtTaxableAmount = '',
                cmbGSTPer = '',
                txtCGST = '',
                txtSGST = '',
                txtIGST = '',
                txtTotalAmount = '',
                txtBTO = 0,
                txtTotalBTO = 0,
                txtBillNo = this.props.txtBillNo,
                FreeMRP = this.props.FreeMRP,
                AvailableFreeMRP = this.props.AvailableFreeMRP,
                chkFreeMRP = false,
                txtMRP = 0,
                txtTotalFreeMRP = 0

            if (Number(this.props.EditBillNo) > 0) {
                EditBillNo = this.props.EditBillNo
                EditAcYear = this.props.EditAcYear
                EditItemSrNo = this.props.EditItemSrNo
                EditSalesDate = this.props.EditSalesDate
            }
            if (Number(this.props.EditItemSrNo) > 0) {
                EditItemSrNo = this.props.EditItemSrNo
                /* EditBillNo = this.props.EditBillNo
                EditAcYear = this.props.EditAcYear
                EditItemSrNo = this.props.EditItemSrNo
                EditSalesDate = this.props.EditSalesDate */
                reqData = {
                    Op: 'Load_ProductName_SalesItemData',
                    EditItemSrNo,
                    EditBillNo,
                    EditAcYear,
                    EditSalesDate
                }
            }
            fetchSales(reqData)
                .then(res => {
                    const ProductNames = res.data.ProductName,
                        GSTPer = res.data.GSTPer,
                        dataLoadStatus = true
                    if (EditItemSrNo > 0) {
                        cmbProductName = res.data.SalesItemList[0].ProductName
                        txtBatchNo = res.data.SalesItemList[0].BatchNo
                        txtQuantity = res.data.SalesItemList[0].Qty
                        txtRate = res.data.SalesItemList[0].Rate
                        txtBasicAmount = res.data.SalesItemList[0].BasicAmount
                        txtDiscountPer = res.data.SalesItemList[0].DiscountPer
                        txtDiscountAmount = res.data.SalesItemList[0].DiscountAmount
                        txtTaxableAmount = res.data.SalesItemList[0].TaxableAmount
                        cmbGSTPer = res.data.SalesItemList[0].GSTPer
                        txtCGST = res.data.SalesItemList[0].CGST
                        txtSGST = res.data.SalesItemList[0].SGST
                        txtIGST = res.data.SalesItemList[0].IGST
                        txtTotalAmount = res.data.SalesItemList[0].TotalAmount
                        txtBTO = res.data.SalesItemList[0].BTO
                        txtTotalBTO = res.data.SalesItemList[0].TotalBTO
                        txtMRP = res.data.SalesItemList[0].MRP
                        txtTotalFreeMRP = res.data.SalesItemList[0].FreeMRP
                        AvailableFreeMRP = Number(txtTotalFreeMRP) + Number(AvailableFreeMRP)
                        if (txtTotalFreeMRP > 0) {
                            chkFreeMRP = true
                        }
                    }
                    this.setState({
                        dataLoadStatus, ProductNames, GSTPer, txtBillNo, FreeMRP, AvailableFreeMRP,
                        formData: {
                            ...this.state.formData,
                            EditSalesDate,
                            EditBillNo,
                            EditAcYear,
                            EditItemSrNo,
                            cmbProductName,
                            txtBatchNo,
                            txtQuantity,
                            txtRate,
                            txtBasicAmount,
                            txtDiscountPer,
                            txtDiscountAmount,
                            txtTaxableAmount,
                            cmbGSTPer,
                            txtCGST,
                            txtSGST,
                            txtIGST,
                            txtTotalAmount,
                            txtBTO,
                            txtTotalBTO,
                            txtMRP,
                            txtTotalFreeMRP,
                            chkFreeMRP
                        }
                    }, () => {
                        if (EditItemSrNo > 0 && chkFreeMRP === true) {
                            this.loadFreeMRPProduct(chkFreeMRP)
                        }
                        document.getElementById('cmbProductName').focus()
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('onFormLoad', error)
                })
        })
    }

    getProductDetail = () => {
        if (!this.state.formData.cmbProductName || this.state.formData.cmbProductName.trim().length <= 0) {
            //this.props.toastErrorMsg('Select Product Name', 'cmbProductName')
        } else {
            this.setState({
                dataLoadStatus: false
            }, () => {
                let reqData = {
                    Op: 'GetProductDetail',
                    ProductName: this.state.formData.cmbProductName
                },
                    txtRate = '',
                    txtDiscountPer = '',
                    txtDiscountAmount = '',
                    cmbGSTPer = ''
                fetchProduct(reqData)
                    .then(res => {
                        const dataLoadStatus = true
                        if (res.data.ProductDetail && res.data.ProductDetail.length > 0) {
                            this.setState({
                                dataLoadStatus,
                                itemInfo: {
                                    ...this.state.itemInfo,
                                    txtRate: res.data.ProductDetail[0].SalesRate,
                                    txtMRP: res.data.ProductDetail[0].MRP,
                                    cmbGSTPer: res.data.ProductDetail[0].GSTPer,
                                    cmbProductName: this.state.formData.cmbProductName,
                                    txtBTO: Number(res.data.ProductDetail[0].BTO)
                                },
                                formData: {
                                    ...this.state.formData,
                                    txtRate: this.state.formData.chkFreeMRP ? 0 : res.data.ProductDetail[0].SalesRate,
                                    txtMRP: res.data.ProductDetail[0].MRP,
                                    cmbGSTPer: res.data.ProductDetail[0].GSTPer,
                                    txtBTO: Number(this.state.formData.chkFreeMRP ? 0 : res.data.ProductDetail[0].BTO)
                                }
                            }, () => {
                                this.setTaxableToFreeMRPData()
                            })
                        }else{
                            this.setState({
                                dataLoadStatus,
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('getProductDetail', error)
                    })
            })
        }
    }

    validateSalesItem = () => {
        let validateSalesItem = false

        if ((this.state.formData.cmbProductName.trim().length <= 0)) {
            this.props.toastErrorMsg('Select Product Name', 'cmbProductName')
        } else if (Number(this.state.formData.txtQuantity) <= 0) {
            this.props.toastErrorMsg('Enter Quantity', 'txtQuantity')
        } else if (this.state.formData.chkFreeMRP === false && Number(this.state.formData.txtRate) <= 0) {
            this.props.toastErrorMsg('Enter Rate', 'txtRate')
        } else if (this.state.formData.cmbGSTPer.trim().length <= 0) {
            this.props.toastErrorMsg('Select GST %', 'cmbGSTPer')
        } else if (this.state.formData.chkFreeMRP === true && (Number(this.state.formData.txtTotalFreeMRP) > Number(this.state.AvailableFreeMRP))) {
            this.props.toastErrorMsg('Total Free MRP cannot be greater than Available Free MRP : ' + this.state.AvailableFreeMRP, 'txtQuantity')
        } else {
            validateSalesItem = true
        }
        return validateSalesItem
    }

    saveSalesItem = () => {
        let result = false,
            txtTotalBasicAmount = 0,
            txtTotalDiscountAmount = 0,
            txtTotalTaxable = 0,
            txtTotalCGST = 0,
            txtTotalSGST = 0,
            txtTotalIGST = 0,
            txtTotalGrandTotal = 0,
            txtTotalRoundOff = 0,
            txtTotalNetAmount = 0,
            EditBillNo = '',
            EditAcYear = '',
            StrBillNo = '',
            txtWalletAmount = 0,
            txtFinalAmount = 0,
            txtTotalSalesBTO = 0,
            txtAvailableFreeMRP
        if (this.validateSalesItem() === true) {
            this.props.notifyProcessing()

            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'SaveSales_SalesItem',
                    Date: this.props.EditSalesDate,
                    EditBillNo: this.state.formData.EditBillNo,
                    EditAcYear: this.state.formData.EditAcYear,
                    StrBillNo: this.state.txtBillNo,
                    BuyerID: this.props.BuyerID,
                    BuyerName: this.props.BuyerName,
                    ServiceID: this.props.ServiceID,
                    ServiceName: this.props.ServiceName,
                    ActivationKit: this.props.ActivationKit,
                    OfferList: this.props.mselOfferList,
                    FreeMRP: this.state.FreeMRP,
                    AvailableFreeMRP: this.state.AvailableFreeMRP,
                    TotalBasicAmt: 0,
                    TotalDisAmt: 0,
                    TaxableAmt: 0,
                    TotalCGST: 0,
                    TotalSGST: 0,
                    TotalIGST: 0,
                    GrandTotal: 0,
                    RoundOff: 0,
                    NetAmount: 0,
                    WalletAmount: 0,
                    FinalAmount: 0,
                    TotalSalesBTO: 0,

                    EditItemSrNo: this.state.formData.EditItemSrNo,
                    ProductName: this.state.formData.cmbProductName,
                    BatchNo: this.state.formData.txtBatchNo,
                    Qty: Number(this.state.formData.txtQuantity),
                    Rate: Number(this.state.formData.txtRate),
                    BasicAmt: Number(this.state.formData.txtBasicAmount),
                    DisPer: Number(this.state.formData.txtDiscountPer),
                    DisAmt: Number(this.state.formData.txtDiscountAmount),
                    TaxableAmt: Number(this.state.formData.txtTaxableAmount),
                    GSTper: this.state.formData.cmbGSTPer,
                    CGST: Number(this.state.formData.txtCGST),
                    SGST: Number(this.state.formData.txtSGST),
                    IGST: Number(this.state.formData.txtIGST),
                    TotalAmt: Number(this.state.formData.txtTotalAmount),
                    BTO: Number(this.state.formData.txtBTO),
                    TotalBTO: Number(this.state.formData.txtTotalBTO),
                    MRP: Number(this.state.formData.txtMRP),
                    ItemFreeMRP: Number(this.state.formData.txtTotalFreeMRP)
                }
                fetchSales(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.setState({
                                dataLoadStatus: true,
                                txtBillNo: res.data.StrBillNo,
                                formData: {
                                    ...this.state.formData,
                                    EditSalesDate: this.props.EditSalesDate,
                                    EditBillNo: res.data.EditBillNo,
                                    EditAcYear: res.data.EditAcYear
                                },
                                AvailableFreeMRP: res.data.AvailableFreeMRP
                            }, () => {
                                txtTotalSalesBTO = res.data.TotalSalesBTO
                                txtTotalBasicAmount = res.data.TotalBasicAmt
                                txtTotalDiscountAmount = res.data.TotalDiscountAmt
                                txtTotalTaxable = res.data.TaxableAmt
                                txtTotalCGST = res.data.TotalCGST
                                txtTotalSGST = res.data.TotalSGST
                                txtTotalIGST = res.data.TotalIGST
                                txtTotalGrandTotal = res.data.GrandTotal
                                txtTotalRoundOff = res.data.RoundOff
                                txtTotalNetAmount = res.data.NetAmt
                                EditBillNo = res.data.EditBillNo
                                EditAcYear = res.data.EditAcYear
                                StrBillNo = res.data.StrBillNo
                                txtAvailableFreeMRP = res.data.AvailableFreeMRP
                                result = true
                                // call function which will recalculate Free MRP & available Free MRP
                                this.props.setTotalInfo(
                                    txtTotalBasicAmount,
                                    txtTotalDiscountAmount,
                                    txtTotalTaxable,
                                    txtTotalCGST,
                                    txtTotalSGST,
                                    txtTotalIGST,
                                    txtTotalGrandTotal,
                                    txtTotalRoundOff,
                                    txtTotalNetAmount,
                                    EditBillNo,
                                    EditAcYear,
                                    StrBillNo,
                                    txtTotalSalesBTO,
                                    txtAvailableFreeMRP
                                )
                                this.newSalesItem(txtAvailableFreeMRP)
                                this.props.loadSalesItemList(EditBillNo, EditAcYear)
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('saveSalesItem', error)
                    })
            })
        }
    }

    newSalesItem = (AvailableFreeMRP = null) => {
        if (AvailableFreeMRP === null) {
            AvailableFreeMRP = Number(this.props.getTxtAvailableFreeMRPFromSales())
        }
        const oldEditItemSrNo = this.state.formData.EditItemSrNo
        const EditItemSrNo = 0,
            cmbProductName = '',
            txtBatchNo = '',
            txtQuantity = 0,
            txtRate = 0,
            txtBasicAmount = 0,
            txtDiscountPer = 0,
            txtDiscountAmount = 0,
            txtTaxableAmount = 0,
            cmbGSTPer = '',
            txtCGST = 0,
            txtSGST = 0,
            txtIGST = 0,
            txtTotalAmount = 0,
            txtBTO = 0,
            txtTotalBTO = 0,
            txtMRP = 0,
            txtTotalFreeMRP = 0,
            chkFreeMRP = false
        this.setState({
            formData: {
                ...this.state.formData,
                EditItemSrNo,
                cmbProductName,
                txtBatchNo,
                txtQuantity,
                txtRate,
                txtBasicAmount,
                txtDiscountPer,
                txtDiscountAmount,
                txtTaxableAmount,
                cmbGSTPer,
                txtCGST,
                txtSGST,
                txtIGST,
                txtTotalAmount,
                txtBTO,
                txtTotalBTO,
                txtMRP,
                txtTotalFreeMRP,
                chkFreeMRP,
            },
            itemInfo: {
                txtRate: 0,
                txtMRP: 0,
                cmbGSTPer: '',
                txtBTO: 0,
                cmbProductName: '',
            },
            AvailableFreeMRP
        }, () => {
            this.loadFreeMRPProduct(chkFreeMRP)
            document.getElementById('cmbProductName').focus()
        })
    }

    getTaxableToFreeMRPData = (
        txtQuantity = this.state.formData.txtQuantity,
        txtRate = this.state.formData.txtRate,
        checkedStatus = this.state.formData.chkFreeMRP,
        txtBTO = this.state.formData.txtBTO
    ) => {
        let txtTaxableAmount = this.getTaxableAmount(txtQuantity, txtRate),
            tax = this.get_CGST_SGST_IGST(
                txtTaxableAmount,
                Number(this.state.formData.cmbGSTPer.substring(0, this.state.formData.cmbGSTPer.length - 1))
            ),
            txtCGST = tax.txtCGST,
            txtSGST = tax.txtSGST,
            txtIGST = tax.txtIGST,
            txtTotalAmount = this.getTotalAmount(txtTaxableAmount, txtCGST, txtSGST, txtIGST),
            txtTotalFreeMRP = this.getTotalFreeMRP(checkedStatus),
            txtTotalBTO = this.getTotalBTO(this.state.formData.txtQuantity, txtBTO)
        return { txtTaxableAmount, txtCGST, txtSGST, txtIGST, txtTotalAmount, txtTotalFreeMRP, txtTotalBTO }
    }

    setTaxableToFreeMRPData = () => {
        let calcData = this.getTaxableToFreeMRPData()
        const txtTaxableAmount = calcData.txtTaxableAmount,
            txtCGST = calcData.txtCGST,
            txtSGST = calcData.txtSGST,
            txtIGST = calcData.txtIGST,
            txtTotalAmount = calcData.txtTotalAmount,
            txtTotalFreeMRP = calcData.txtTotalFreeMRP,
            txtTotalBTO = calcData.txtTotalBTO
        this.setState({
            formData: {
                ...this.state.formData,
                txtTaxableAmount,
                txtCGST,
                txtSGST,
                txtIGST,
                txtTotalAmount,
                txtTotalFreeMRP,
                txtTotalBTO
            }
        })
    }

    loadFreeMRPProduct = (checkedStatus) => {
        this.setState({
            dataLoadStatus: false
        }, () => {
            let FreeMRP = checkedStatus ? this.state.AvailableFreeMRP : 0
            let reqData = {
                Op: 'LoadFreeMRPProduct',
                FreeMRP
            }
            fetchSales(reqData)
                .then(res => {
                    let ProductNames = res.data.ProductName,
                        dataLoadStatus = true,
                        cmbProductName = checkedStatus ? this.state.formData.cmbProductName : this.state.itemInfo.cmbProductName
                    let curIndex = -1,
                        txtBTO = checkedStatus ? 0 : this.state.itemInfo.txtBTO,
                        txtRate = checkedStatus ? 0 : this.state.itemInfo.txtRate
                    /* let txtTaxableAmount = this.getTaxableAmount(this.state.formData.txtQuantity, txtRate),
                        tax = this.get_CGST_SGST_IGST(
                            txtTaxableAmount,
                            Number(this.state.formData.cmbGSTPer.substring(0, this.state.formData.cmbGSTPer.length - 1))
                        ),
                        txtCGST = tax.txtCGST,
                        txtSGST = tax.txtSGST,
                        txtIGST = tax.txtIGST,
                        txtTotalAmount = this.getTotalAmount(txtTaxableAmount, txtCGST, txtSGST, txtIGST),
                        txtTotalFreeMRP = this.getTotalFreeMRP(checkedStatus),
                        txtTotalBTO = this.getTotalBTO(this.state.formData.txtQuantity, txtBTO) */

                    let calcData = this.getTaxableToFreeMRPData(
                        this.state.formData.txtQuantity,
                        txtRate,
                        checkedStatus,
                        txtBTO
                    )
                    const txtTaxableAmount = calcData.txtTaxableAmount,
                        txtCGST = calcData.txtCGST,
                        txtSGST = calcData.txtSGST,
                        txtIGST = calcData.txtIGST,
                        txtTotalAmount = calcData.txtTotalAmount,
                        txtTotalFreeMRP = calcData.txtTotalFreeMRP,
                        txtTotalBTO = calcData.txtTotalBTO

                    ProductNames.forEach((Product, index) => {
                        if (Product.name == cmbProductName) {
                            curIndex = index
                        }
                    });
                    if (curIndex === -1) {
                        cmbProductName = ''
                    }
                    this.setState({
                        ProductNames, dataLoadStatus,
                        formData: {
                            ...this.state.formData,
                            cmbProductName,
                            txtBTO,
                            txtRate,
                            txtTaxableAmount,
                            txtCGST,
                            txtSGST,
                            txtIGST,
                            txtTotalAmount,
                            txtTotalFreeMRP,
                            txtTotalBTO
                        }
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('loadFreeMRPProduct', error)
                })
        })
    }
    //#endregion

    render() {
        return (
            <div>
                <form>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        <Grid item>
                            <CtCheckBox
                                id='chkFreeMRP'
                                name='chkFreeMRP'
                                label={'Free MRP : ' + Number(this.state.AvailableFreeMRP)}
                                disabled={Number(this.state.AvailableFreeMRP) <= 0}
                                checked={this.state.formData.chkFreeMRP}
                                handleCheckChange={this.handleOnChkChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtCmbEditable
                                id='cmbProductName'
                                label='Product Name'
                                items={this.state.ProductNames}
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.cmbProductName}
                                width={this.state.cWidth + 100}
                                maxLength='100'
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtBatchNo'
                                label='Batch No.'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtBatchNo}
                                width={this.state.cWidth - 70}
                                maxLength='100'
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtTxt
                                id='txtQuantity'
                                label='Quantity'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtQuantity}
                                width="130"
                                maxLength='18'
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtRate'
                                label='Rate'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtRate}
                                width="130"
                                maxLength='18'
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtTaxableAmount'
                                label='Taxable Amount'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtTaxableAmount}
                                width="130"
                                maxLength='18'
                                onKeyDown={this.props.onKeyDown}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtCmbEditable
                                id='cmbGSTPer'
                                label='GST %'
                                items={this.state.GSTPer}
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.cmbGSTPer}
                                width="130"
                                maxLength='50'
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtCGST'
                                label='CGST'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtCGST}
                                width="130"
                                maxLength='18'
                                onKeyDown={this.props.onKeyDown}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtSGST'
                                label='SGST'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtSGST}
                                width="130"
                                maxLength='18'
                                onKeyDown={this.props.onKeyDown}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtTxt
                                id='txtIGST'
                                label='IGST'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtIGST}
                                width="130"
                                maxLength='18'
                                onKeyDown={this.props.onKeyDown}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtTotalAmount'
                                label='Total Amount'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtTotalAmount}
                                width="130"
                                maxLength='18'
                                onKeyDown={this.props.onKeyDown}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtBTO'
                                label='BTO'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtBTO}
                                width="130"
                                maxLength='18'
                                onKeyDown={this.props.onKeyDown}
                                disabled={true}
                            />
                        </Grid>
                        <Box item style={{ width: '170px' }} display={{ xs: 'none', sm: 'block' }}>
                            &nbsp;
                        </Box>
                    </Grid>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtTxt
                                id='txtTotalBTO'
                                label='Total BTO'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtTotalBTO}
                                width="130"
                                maxLength='18'
                                onKeyDown={this.props.onKeyDown}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtMRP'
                                label='MRP'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtMRP}
                                width="130"
                                maxLength='18'
                                onKeyDown={this.props.onKeyDown}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item>
                            <CtTxt
                                id='txtTotalFreeMRP'
                                label='Total Free MRP'
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.txtTotalFreeMRP}
                                width="130"
                                maxLength='18'
                                onKeyDown={this.props.onKeyDown}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtBtn label='Save' width={this.state.cWidth - 150} onClick={this.saveSalesItem} />
                        </Grid>
                        <Grid item>
                            <CtBtn label='New' width={this.state.cWidth - 150} onClick={() => { this.newSalesItem(null) }} />
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(SalesItemEntry))