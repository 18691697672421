import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        // backgroundColor: theme.palette.background.paper,
    },
}));

export default function UserAchievementList({ AchievementList }) {
    const classes = useStyles();

    return (
        <List className={classes.root}>
            {
                AchievementList.map((row) => {
                    return (
                        <ListItem key={row['ledger']}>
                            <ListItemText primary={row['ledger'] + " : " + row['value']} />
                        </ListItem>
                    )
                })
            }
        </List>
    );
}