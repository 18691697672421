import React, { Component } from 'react'
import { Grid, Box } from '@material-ui/core'
import CtCmb from './CustomTool/CtCmb'
import CtTxt from './CustomTool/CtTxt'
import CtTxtPWD from './CustomTool/CtTxtPWD'
import CtTxtReset from './CustomTool/CtTxtReset'
import CtCheckBox from './CustomTool/CtCheckBox'
import CtBtn from './CustomTool/CtBtn'
import CtPhoneNumber from './CustomTool/CtPhoneNumber'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import CtDtpDOB from './CustomTool/CtDtpDOB'
import CtTxtAdornNum from './CustomTool/CtTxtAdornNum'
import 'react-toastify/dist/ReactToastify.css'
import { fetchMember } from './API/API'
import { getAgeOfDOB } from './SystemUtility/SystemUtility'
import AlertResponsiveDialog from './CustomTool/AlertResponsiveDialog'
import { REGULAR, GIFT_PLAN, LEG_LEFT, LEG_RIGHT } from './SystemConstants'
import CtCmbEditable from './CustomTool/CtCmbEditable'
import TextAreaAutoSize from './CustomTool/TextAreaAutoSize'

class Registration extends Component {

  state = {
    schemes: [
      { name: REGULAR, disabled: false }
      // { name: GIFT_PLAN, disabled: false }
    ],
    tnc: false,
    toastId: null,
    formData: {
      Scheme: REGULAR,
      ID: '1',
      ReferenceCode: '',
      SponsorID: '',
      Name: '',
      MobileNo: '',
      DOB: '',
      Password: '',
      CPassword: '',
      cmbCity: '',
      PanCardNo: '',
      Nominee: '',
      NomineeRelation: '',
      Pincode: '',
      State: '',
      District: '',
      Address: '',
      Leg: ''
    },
    linkData: {
      Scheme: '',
      ReferenceCode: '',
      Sponsor: '',
      SponsorID: '',
      MobileNo: '',
    },
    res: [],
    alertTNC: null,
    City: [],
    District: [],
    State: [],
    cmbNomineeRelation: [],
    LegList: [
      { name: LEG_LEFT, disabled: false },
      { name: LEG_RIGHT, disabled: false },
    ]
  }

  alertTNC = (defaultOpenDialog = false) => {
    return (
      <AlertResponsiveDialog
        label='Read Terms & Conditions'
        labelAgree='I Agree'
        labelDisagree='Cancel'
        alertTitle='Terms & Conditions'
        alertMessage={
          <div>
            <p>(1) Subject to Bhavnagar Jurisdiction only.</p>
            <p>(2) The Company will have the power to block the ID of the direct seller who will take any action against the Company.</p>
            <p>(3) Amount over Rs.500 will show up in your account and you will be paid out on the 10th of the following month.</p>
            <p>(4) The Company will be able to change the plan whenever the need arises.</p>
            <p>(5) You have to provide every details / information whenever the Company asks.</p>
            <p>(6) If you do not activate your ID within 15 days after registration, then your ID will be blocked.</p>
            <p>(7) The Company will be able to change the discount whenever the need arises.</p>
          </div>
        }
        handleOnClickYes={() => { this.settnc(true) }}
        handleOnClickNo={() => { this.settnc(false) }}
        defaultOpenDialog={defaultOpenDialog}
      />
    )
  }

  handletncChange = (accepted) => {
    this.settnc(accepted, true)
  }

  settnc = (tnc, defaultOpenDialog = false) => {
    const alertTNC = tnc === true ? this.alertTNC(defaultOpenDialog) : null
    this.setState({ tnc, alertTNC })
  }

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value }
    })
  }

  handleOnAutoCompleteChange = (e) => {
    // console.log('e.target.name', e.target.name)
    // console.log('e.target.value', e.target.value)
    let value = e.target.value && e.target.value['name'] ? e.target.value['name'] : e.target.value
    // console.log('value', value)
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: value }
    })
  }

  handleOnAutoCompleteInputChange = (e) => {
    if (this.state.formData[e.target.id] === null && e.target.value.length > 0) {
      this.setState({
        formData: { ...this.state.formData, [e.target.name]: e.target.value }
      })
    }
  }

  handleOnChangeTrim = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
    })
  }

  validateInput = () => {
    let result = false
    if (this.props.IsAdmin === false && this.state.formData.ReferenceCode.length <= 0) {
      this.props.toastErrorMsg('Enter Reference Code', 'ReferenceCode')
    } else if (this.props.IsAdmin === false && this.state.formData.ReferenceCode.length !== 6) {
      this.props.toastErrorMsg('ReferenceCode must be of 6 digits', 'ReferenceCode')
    } else if (this.state.formData.SponsorID.length <= 0) {
      this.props.toastErrorMsg('Enter Sponsor ID', 'SponsorID')
    } else if (this.state.formData.SponsorID.length !== 6) {
      this.props.toastErrorMsg('Enter must be of 6 digits', 'SponsorID')
    } else if (this.state.formData.Leg.length == 0 || this.state.formData.Leg == "") {
      this.props.toastErrorMsg('Select Leg for Sponsor ID', 'Leg')
    } else if (this.state.formData.Name.length <= 0) {
      this.props.toastErrorMsg('Enter Name', 'Name')
    } else if (this.state.formData.MobileNo.length <= 0) {
      this.props.toastErrorMsg('Enter Mobile No', 'MobileNo')
    } else if (this.state.formData.MobileNo.length < 10) {
      this.props.toastErrorMsg('Enter Valid Mobile No', 'MobileNo')
    } else if (this.state.formData.cmbCity.length <= 0) {
      this.props.toastErrorMsg('Enter Taluka', 'cmbCity')
    } else if (this.state.formData.District.length <= 0) {
      this.props.toastErrorMsg('Enter District', 'District')
    } else if (this.state.formData.State.length <= 0) {
      this.props.toastErrorMsg('Enter State', 'State')
    } else if (this.state.formData.DOB.length <= 0) {
      this.props.toastErrorMsg('Enter Date of Birth', 'DOB')
    } else if (getAgeOfDOB(this.state.formData.DOB) < 18) {
      this.props.toastErrorMsg('Person with Age of 18 Year or elder can only Registered', 'DOB')
      // } else if (this.state.formData.PanCardNo.length == 0) {
      //   this.props.toastErrorMsg('Enter PAN Card No', 'PanCardNo')
    } else if (this.state.formData.PanCardNo.length > 0 && this.state.formData.PanCardNo.length != 10) {
      this.props.toastErrorMsg('PAN Card No must be of 10 digit or character', 'PanCardNo')
    } else if (this.state.formData.Password.length <= 0) {
      this.props.toastErrorMsg('Enter Password', 'Password')
    } else if (this.state.formData.Password.length < 4) {
      this.props.toastErrorMsg('Enter Password of Atleast 4 Charaters', 'Password')
    } else if (this.state.formData.CPassword.length <= 0) {
      this.props.toastErrorMsg('Enter Confirm Password', 'CPassword')
    } else if (this.state.formData.Password !== this.state.formData.CPassword) {
      this.props.toastErrorMsg('Password and Confirm Password must be same', 'Password')
    } else {
      result = true
    }
    return result
  }

  submitRegistration = () => {
    if (this.validateInput()) {
      this.props.notifyProcessing()

      const reqData = {
        Scheme: this.state.formData.Scheme,
        ID: this.state.formData.ID,
        ReferenceCode: this.state.formData.ReferenceCode,
        SponsorID: this.state.formData.SponsorID,
        Name: this.state.formData.Name,
        MobileNo: this.state.formData.MobileNo,
        Password: this.state.formData.Password,
        Op: 'NewRegistration',
        IsAdmin: this.props.IsAdmin,
        DOB: this.state.formData.DOB,
        City: this.state.formData.cmbCity,
        PanCardNo: this.state.formData.PanCardNo,
        Nominee: this.state.formData.Nominee,
        NomineeRelation: this.state.formData.NomineeRelation,
        Pincode: this.state.formData.Pincode,
        State: this.state.formData.State,
        District: this.state.formData.District,
        Address: this.state.formData.Address,
        Leg: this.state.formData.Leg,
      }

      fetchMember(reqData)
        .then(res => {
          this.setState({ res: res.data })
          this.props.updateProcessing(this.state.res.msgType, this.state.res.message)
          if (this.state.res.msgType === 'success') {
            this.clearFields()
          }
        })
        .catch(err => {
          this.props.updateProcessing('error', 'Unknown error occured in Save Registration.\n\n' + err)
        })
    }
  }

clearFields = () => {
    this.setNewID()
    let ReferenceCode = ''
    let SponsorID = ''
    let Name = ''
    let MobileNo = ''
    let DOB = ''
    let Password = ''
    let CPassword = ''
    let cmbCity = ''
    let PanCardNo = ''
    let Nominee = ''
    let NomineeRelation = ''
    let Address = ''
    let Pincode = ''
    let District = ''
    let State = ''
    let Leg = ''

    this.setState({
      formData: {
        ...this.state.formData, ReferenceCode, SponsorID, Name, MobileNo, DOB, Password, CPassword, cmbCity, PanCardNo, Nominee, NomineeRelation, Address, Pincode, District, State, Leg
      },
      linkData: {
        ...this.state.linkData,
        Scheme: '',
        ReferenceCode: '',
        Sponsor: '',
        SponsorID: '',
        MobileNo: '',
      },
    }, () => {
      this.setState({ tnc: false })
      // this.navigateTo('Registration')
      document.getElementById('ReferenceCode').focus()
    })
  }

  setNewID = () => {
    let result = 0
    while (result <= 99999) {
      result = Math.ceil(Math.random() * 1000000)
      const reqData = {
        Op: 'IsIDExists',
        ID: result
      }
      fetchMember(reqData)
        .then(res => {
          if (res.data.IsIDExists === true) {
            this.setNewID()
          } else {
            this.setID(result)
            this.loadRegistrationCity_District_State()
            this.loadNomineeRelation()
          }
        })
    }
    this.setID(result)
  }

  setID = (ID) => {
    this.setState({
      formData: { ...this.state.formData, ID }
    })
  }

  setGiftPlanEnable = () => {
    if (this.props.IsAdmin === true) {
      const schemes = [
        { name: REGULAR, disabled: false },
        // { name: GIFT_PLAN, disabled: false }
      ]
      this.setState({ schemes })
    }
    // console.log(this.state.schemes);
  }

  setLinkData = () => {
    if (this.props.match.params.regLink && this.props.match.params.regLink.length > 0) {
      let regLink = this.props.match.params.regLink
      console.log('regLink', regLink)
      console.log('regLink.length', regLink.length)
      let ReferenceCode = regLink.substring(1, 7)
      console.log('ReferenceCode', ReferenceCode)
      let Scheme = regLink.substring(7, 8) === 'R' ? REGULAR : GIFT_PLAN
      console.log('Scheme', Scheme)
      let SponsorID = regLink.substring(8, 9) === 'A' ? '' : regLink.substring(9, 15)
      console.log('SponsorID', SponsorID)
      let Sponsor = regLink.substring(8, 9) === 'A' ? 'Auto' : 'FIX'
      console.log('Sponsor', Sponsor)
      const idxMobNo = regLink.indexOf('M')
      let MobileNo = idxMobNo > 0 ? regLink.substring(idxMobNo + 1, idxMobNo + 11) : ''
      if (Sponsor === 'FIX') {
        this.setLinkDataInState(Scheme, ReferenceCode, Sponsor, SponsorID, MobileNo)
      } else {
        const reqData = {
          Op: 'GetFirstNFreeChildSponsorIDsOfUserID',
          NoOfChildRequired: 1,
          userID: ReferenceCode
        }
        fetchMember(reqData)
          .then(res => {
            // console.log(res.data.SponsorIdList.length, res.data.SponsorIdList[0])
            if (res.data.SponsorIdList.length > 0) {
              SponsorID = res.data.SponsorIdList[0]
              document.getElementById('ReferenceCode').focus()
            } else {
              SponsorID = ReferenceCode
              this.props.toastMsg('warning', 'Due to unavailability of Free Sponsor ID child, system has set Reference Code as Sponsor ID also. If you cannot complete the Registration Process, then ask for new link from your Registration Link Provider.')
            }
            this.setLinkDataInState(Scheme, ReferenceCode, Sponsor, SponsorID, MobileNo)
          })
          .catch(error => {
            this.props.toastErrorMsg('Unknown Error occurred in SetLinkData @ Registration. ' + error)
          })
      }
    }
  }

  setLinkDataInState = (Scheme, ReferenceCode, Sponsor, SponsorID, MobileNo) => {
    // console.log(this.state.linkData)
    this.setState({
      linkData: {
        ...this.state.linkData,
        Scheme, ReferenceCode, Sponsor, SponsorID, MobileNo
      },
      formData: {
        ...this.state.formData, MobileNo, Scheme, ReferenceCode, SponsorID
      }
    }, () => {
      // console.log(this.state.linkData)
    })
  }

  loadRegistrationCity_District_State = () => {
    this.setState({
      dataLoadStatus: false
    }, () => {
      const reqData = {
        Op: 'LoadRegistrationCity_State_District'
      }
      fetchMember(reqData)
        .then(res => {
          let City = res.data.CityList
          let State = res.data.StateList
          let District = res.data.DistrictList
          const dataLoadStatus = true
          this.setState({ City, State, District, dataLoadStatus })
        })
        .catch(error => {
          this.props.toastErrorMsg('Unknown Error occurred in loadRegistrationCity_District_State @ Registration. ' + error)
        })
    })
  }

  loadNomineeRelation = () => {
    this.setState({
      dataLoadStatus: false
    }, () => {
      const reqData = {
        Op: 'LoadNomineeRelation'
      }
      fetchMember(reqData)
        .then(res => {
          let cmbNomineeRelation = res.data.NomineeRelationList
          const dataLoadStatus = true
          this.setState({ cmbNomineeRelation, dataLoadStatus })
        })
        .catch(error => {
          this.props.toastErrorMsg('Unknown Error occurred in loadNomineeRelation @ Registration. ' + error)
        })
    })
  }

  componentDidMount() {
    this.props.setActiveLink({ LinkSlug: "Registration" })
    this.setGiftPlanEnable();
    document.getElementById('ReferenceCode').focus()
    this.setNewID()
    this.setLinkData()
  }

  navigateTo = (link) => {
    this.props.history.push('/' + link)
  }

  render() {

    const RegLinkDisabled = false //this.state.linkData.ReferenceCode && Number(this.state.linkData.ReferenceCode) > 99999 ? true : false
    const RegLinkSponsorIDDisabled = this.state.linkData.Sponsor && this.state.linkData.Sponsor.length > 0 ? true : false
    console.log("RegLinkSponsorIDDisabled",RegLinkSponsorIDDisabled);
    return (
      <div className="Registration">
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item className="pageTitle">
            <label htmlFor="Title" className='pageTitleText' >Registration Form</label>
          </Grid>
        </Grid>
        <form>
          <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
            <Grid item>
              <CtCmb
                id='Scheme'
                label='Scheme'
                items={this.state.schemes}
                value={this.state.formData.Scheme}
                // value={Scheme}
                disabled={RegLinkDisabled}
                handleOnChange={this.handleOnChange}
                width='200'
              />
            </Grid>
            <Grid item>
              <CtTxtReset
                id='ID'
                label='ID'
                disabled={true}
                adornment='WG'
                value={this.state.formData.ID}
                handleOnResetClick={this.setNewID}
                width='200'
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
            <Grid item>
              <CtTxtAdornNum
                id='ReferenceCode'
                label='Reference Code'
                adornment='WG'
                value={this.state.formData.ReferenceCode}
                // value={ReferenceCode}
                disabled={RegLinkDisabled}
                handleOnChange={this.handleOnChange}
                onKeyDown={this.props.onKeyDown}
                width='120'
              />
            </Grid>
            <Grid item>
              <CtTxtAdornNum
                id='SponsorID'
                label='Sponsor ID'
                adornment='WG'
                value={this.state.formData.SponsorID}
                // value={SponsorID}
                disabled={RegLinkSponsorIDDisabled}
                handleOnChange={this.handleOnChange}
                onKeyDown={this.props.onKeyDown}
                width='120'
              />
            </Grid>
            <Grid item>
              <CtCmb
                id='Leg'
                label='Leg'
                items={this.state.LegList}
                value={this.state.formData.Leg}
                handleOnChange={this.handleOnChange}
                width='120'
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
            <Grid item>
              <CtTxt
                id='Name'
                label='Name'
                value={this.state.formData.Name}
                handleOnChange={this.handleOnChange}
                onKeyDown={this.props.onKeyDown}
                width='200'
                maxLength='100'
              />
            </Grid>
            <Grid item>
              <CtPhoneNumber
                id='MobileNo'
                label='Mobile No'
                value={this.state.formData.MobileNo}
                handleOnChange={this.handleOnChangeTrim}
                onKeyDown={this.props.onKeyDown}
                width='200'
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
            <Grid item>
              <TextAreaAutoSize
                id='Address'
                label='Address'
                value={this.state.formData.Address}
                width='440'
                handleOnChange={this.handleOnChange}
                maxLength='500'
                placeholder='Address'
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
            <Grid item>
              <CtTxt
                id='Pincode'
                label='Pincode'
                handleOnChange={this.handleOnChange}
                value={this.state.formData.Pincode}
                onKeyDown={this.props.onKeyDown}
                width='200'
                maxLength='6'
              />
            </Grid>
            <Grid item>
              <CtCmbEditable
                id='cmbCity'
                label='Taluka'
                freeSolo={true}
                items={this.state.City}
                handleOnChange={this.handleOnChange}
                value={this.state.formData.cmbCity}
                width="200"
                maxLength='100'
                onKeyDown={this.props.onKeyDown}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
            <Grid item>
              <CtCmbEditable
                id='District'
                label='District'
                freeSolo={true}
                items={this.state.District}
                handleOnChange={this.handleOnChange}
                value={this.state.formData.District}
                width="200"
                maxLength='50'
                onKeyDown={this.props.onKeyDown}
              />
            </Grid>
            <Grid item>
              <CtCmbEditable
                id='State'
                label='State'
                freeSolo={true}
                items={this.state.State}
                handleOnChange={this.handleOnChange}
                value={this.state.formData.State}
                width="200"
                maxLength='50'
                onKeyDown={this.props.onKeyDown}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
            <Grid item>
              <CtDtpDOB
                id='DOB'
                label='Date of birth'
                width='200'
                handleOnChange={this.handleOnChangeTrim}
                onKeyDown={this.props.onKeyDown}
                defaultValue={this.state.formData.DOB}
                value={this.state.formData.DOB}
              />
            </Grid>
            <Grid item>
              <CtTxt
                id='PanCardNo'
                label='PAN No'
                handleOnChange={this.handleOnChange}
                value={this.state.formData.PanCardNo}
                onKeyDown={this.props.onKeyDown}
                width='200'
                maxLength='10'
                isUpperCase={true}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
            <Grid item>
              <CtTxt
                id='Nominee'
                label='Nominee'
                handleOnChange={this.handleOnChange}
                value={this.state.formData.Nominee}
                onKeyDown={this.props.onKeyDown}
                width='200'
                maxLength='50'
              />
            </Grid>
            <Grid item>
              <CtCmbEditable
                id='NomineeRelation'
                label='Nominee Relation'
                freeSolo={true}
                items={this.state.cmbNomineeRelation}
                handleOnChange={this.handleOnChange}
                value={this.state.formData.NomineeRelation}
                width="200"
                maxLength='50'
                onKeyDown={this.props.onKeyDown}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
            <Grid item>
              <CtTxtPWD
                id='Password'
                label='Password'
                handleOnChange={this.handleOnChange}
                onKeyDown={this.props.onKeyDown}
                width='200'
                maxLength='100'
                value={this.state.formData.Password}
              />
            </Grid>
            <Grid item>
              <CtTxtPWD
                id='CPassword'
                label='Confirm Password'
                handleOnChange={this.handleOnChange}
                onKeyDown={this.props.onKeyDown}
                width='200'
                maxLength='100'
                value={this.state.formData.CPassword}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
            <Grid item>
              <CtCheckBox
                id='TermsNCon'
                name='TermsNCon'
                label='Agreed To Terms & Conditions'
                checked={this.state.tnc}
                handleCheckChange={this.handletncChange}
              />
            </Grid>
            <Grid item>
              {/* {this.alertTNC()} */}
              <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                {this.state.alertTNC}
              </Box>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
            <Grid item>
              <CtBtn label='Register' disabled={!this.state.tnc} onClick={this.submitRegistration} />
            </Grid>
          </Grid>
        </form>
      </div>
    )
  }
}

export default HoCtToastContainer(Registration)