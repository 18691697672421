import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CtCheckBox = ({
  id, label, checked, handleCheckChange, disabled,
  onKeyDown, defaultAction, validateInput
}) => {

  const [state, setState] = React.useState({
    checkedB: false
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleCheckedChange = () => {
    handleCheckChange(!checked)
  }

  const handleOnKeyDown = (onKeyDown) ? ((event) => { onKeyDown(event, 1, defaultAction, validateInput) }) : (null)
  let disableStatus = false
  let color = '#8556A6'
  if (disabled && disabled === true) {
    disableStatus = true
    color = null
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          id={id}
          checked={checked}
          onChange={handleCheckedChange}
          onKeyDown={handleOnKeyDown}
          name={id}
          disabled={disableStatus}
          // color="primary"
          style={{ color: color }}
        />
      }
      label={label}
    />
  )
}

export default CtCheckBox