import React from 'react'
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const CtTxtNum = ({
    id, label, disabled, value, width, handleOnChange,
    onKeyDown, defaultAction, validateInput
    /* , maxLength */
}) => {

  const handleOnKeyDown = (onKeyDown)?((event) => { onKeyDown(event, 1, defaultAction, validateInput) }):(null)
  const useWidthStyle = { width: width + 'px' }
    /* const applyMaxLength = { maxLength: maxLength }
    const applyMaxLengthMissing = { maxLength: 1 } */
    return (
        <TextField
            id={id}
            name={id}
            label={label}
            disabled={disabled}
            value={value}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            style={width > 0 ? useWidthStyle : {}}
            // inputProps={maxLength > 0 ? applyMaxLength : applyMaxLengthMissing}
            InputProps={{
                inputComponent: TextMaskCustom
            }}
        />
    )
}

export default CtTxtNum