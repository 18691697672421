import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import { Search, Close, Add } from '@material-ui/icons'
import { Tooltip, IconButton } from '@material-ui/core'
import CtTxtAdornNum from './CtTxtAdornNum'
import CtCmb from './CtCmb'
import CtTxt from './CtTxt'
import CtPhoneNumber from './CtPhoneNumber'

class TableList extends Component {

  state = {
    page: 0,
    // rowsPerPage: 25,
    rows: [
      {
        ID: '',
        Scheme: '',
        ReferenceCode: '',
        SponsorId: '',
        Name: '',
        MobileNo: '',
      }
    ],
    rowsCount: 0,
    columns: [],
    searchColumns: [],
    searchOpen: false,
    searchData: {},
    provideSearch: this.props.provideSearch,
  }

  listResult = "Processing the List"

  handleChangeColumnsRows = () => {
    const columns = this.props.columns
    const rows = this.props.rows
    const rowsCount = this.props.rowsCount
    this.setState({ columns, rows, rowsCount })
  }

  setPage = (pageNumber) => {
    this.setState({ page: pageNumber })
  }

  setRowsPerPage = (rowsPerPageNumber) => {
    this.setState({ rowsPerPage: rowsPerPageNumber })
  }

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event, tblIndex) => {
    this.setRowsPerPage(+event.target.value);
    this.setPage(0);
    if (this.props.handleChangeRowsPerPage) {
      this.props.handleChangeRowsPerPage(event.target.value, tblIndex)
    }
  };

  componentDidMount() {
    this.handleChangeColumnsRows()
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      rows: newProps.rows,
      rowsCount: newProps.rowsCount,
      searchColumns: newProps.searchColumns,
      searchData: newProps.searchData,
      provideSearch: newProps.provideSearch
    })
  }

  displayCurrentCell = (index, colFormat, value, row) => {
    let cellValue = ''
    if (index === 0 && this.props.actionList && this.props.actionList.length > 0) {
      cellValue = this.props.actionList.map((action) => {

        let result = null
        if (
          (action.actionType && action.actionType === 'Conditional') ||
          (action.condition && action.condition.length > 0)
        ) {
          let conditionFinalResult = false, iterationResults = []

          action.condition.forEach((curcondition) => {
            let conditionResult = false
            if (curcondition.relationalOperator === undefined || curcondition.relationalOperator == '==') {
              if (row[curcondition.colID] == curcondition.value)
                conditionResult = true
            } else if (curcondition.relationalOperator && curcondition.relationalOperator == '===') {
              if (row[curcondition.colID] === curcondition.value)
                conditionResult = true
            } else if (curcondition.relationalOperator && curcondition.relationalOperator == '!=') {
              if (row[curcondition.colID] != curcondition.value)
                conditionResult = true
            } else if (curcondition.relationalOperator && curcondition.relationalOperator == '!==') {
              if (row[curcondition.colID] !== curcondition.value)
                conditionResult = true
            } else if (curcondition.relationalOperator && curcondition.relationalOperator == '<=') {
              if (row[curcondition.colID] <= curcondition.value)
                conditionResult = true
            } else if (curcondition.relationalOperator && curcondition.relationalOperator == '<') {
              if (row[curcondition.colID] < curcondition.value)
                conditionResult = true
            } else if (curcondition.relationalOperator && curcondition.relationalOperator == '>=') {
              if (row[curcondition.colID] >= curcondition.value)
                conditionResult = true
            } else if (curcondition.relationalOperator && curcondition.relationalOperator == '>') {
              if (row[curcondition.colID] > curcondition.value)
                conditionResult = true
            }
            iterationResults.push({
              conditionResult,
              logicalOperator: curcondition.logicalOperator && curcondition.logicalOperator.length > 0 ? curcondition.logicalOperator : null
            })
          })

          if (iterationResults.length === 1) {
            conditionFinalResult = iterationResults[0].conditionResult
          } else {
            iterationResults.forEach((curResult) => {
              if (curResult.logicalOperator === null) {
                conditionFinalResult = curResult.conditionResult
              } else {
                if (curResult.logicalOperator === '&&') {
                  conditionFinalResult = (conditionFinalResult && curResult.conditionResult)
                } else {
                  conditionFinalResult = (conditionFinalResult || curResult.conditionResult)
                }
              }
            })
          }

          // console.log('conditionFinalResult @ index', index, conditionFinalResult)
          // console.log('iterationResults @ index', index, iterationResults);
          // console.log('action',action)
          // console.log('row',row)

          if (conditionFinalResult === true) {
            result = this.getActionIcon(action, row)
          }
        } else {
          result = this.getActionIcon(action, row)
        }

        return result
      })
    }
    else if (colFormat && typeof value === 'number') {
      cellValue = colFormat(value)
    } /* else if (colFormat) {
      cellValue = colFormat(value)
    } */ else {
      cellValue = value
    }
    return (cellValue)
  }

  checkCondition = (action, row) => {
    let result = false
    if (
      (action.actionType && action.actionType === 'Conditional') ||
      (action.condition && action.condition.length > 0)
    ) {
      let conditionFinalResult = false, iterationResults = []

      action.condition.forEach((curcondition) => {
        // console.log("curcondition.relationalOperator", curcondition.relationalOperator);
        // console.log("row[curcondition.colID]", row[curcondition.colID]);
        // console.log("curcondition.value", curcondition.value);
        let conditionResult = false
        if (curcondition.relationalOperator === undefined || curcondition.relationalOperator == '==') {
          if (row[curcondition.colID] == curcondition.value)
            conditionResult = true
        } else if (curcondition.relationalOperator && curcondition.relationalOperator == '===') {
          if (row[curcondition.colID] === curcondition.value)
            conditionResult = true
        } else if (curcondition.relationalOperator && curcondition.relationalOperator == '!=') {
          if (row[curcondition.colID] != curcondition.value)
            conditionResult = true
        } else if (curcondition.relationalOperator && curcondition.relationalOperator == '!==') {
          if (row[curcondition.colID] !== curcondition.value)
            conditionResult = true
        } else if (curcondition.relationalOperator && curcondition.relationalOperator == '<=') {
          if (row[curcondition.colID] <= curcondition.value)
            conditionResult = true
        } else if (curcondition.relationalOperator && curcondition.relationalOperator == '<') {
          if (row[curcondition.colID] < curcondition.value)
            conditionResult = true
        } else if (curcondition.relationalOperator && curcondition.relationalOperator == '>=') {
          if (row[curcondition.colID] >= curcondition.value)
            conditionResult = true
        } else if (curcondition.relationalOperator && curcondition.relationalOperator == '>') {
          if (row[curcondition.colID] > curcondition.value)
            conditionResult = true
        }
        iterationResults.push({
          conditionResult,
          logicalOperator: curcondition.logicalOperator && curcondition.logicalOperator.length > 0 ? curcondition.logicalOperator : null
        })
      })

      if (iterationResults.length === 1) {
        conditionFinalResult = iterationResults[0].conditionResult
      } else {
        iterationResults.forEach((curResult) => {
          if (curResult.logicalOperator === null) {
            conditionFinalResult = curResult.conditionResult
          } else {
            if (curResult.logicalOperator === '&&') {
              conditionFinalResult = (conditionFinalResult && curResult.conditionResult)
            } else {
              conditionFinalResult = (conditionFinalResult || curResult.conditionResult)
            }
          }
        })
      }

      if (conditionFinalResult === true) {
        result = true
      } else {
        result = false
      }
    } else {
      result = true
    }
    return result
  }

  getActionIcon = (action, row) => {
    return (
      <Tooltip title={action.name}>
        <IconButton
          style={{ padding: '0px', margin: '0px 5px' }}
          onClick={() => {
            if (this.props.keyColumn && this.props.keyColumn.length > 0) {
              if (action.actionType && action.actionType === 'AlertResponsive') {
                let values = []
                this.props.keyColumn.map((keyCol) => {
                  let value = row[keyCol]
                  values.push({ 'value': value, actionName: action.name })
                })
                this.props.onActionClick(values)
              } else {
                let keyValue = row[this.props.keyColumn[0]]
                this.props.onActionClick(action.link + keyValue)
              }
            } else {
              this.props.toastMsg('error', 'Technical info loss to apply action')
            }
          }}
        >
          {action.icon}
        </IconButton>
      </Tooltip>
    )
  }

  onSearchClick = () => {
    this.setState({
      searchOpen: (!(this.state.searchOpen))
    }, () => {
      this.props.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
    })
  }

  render() {
    const {
      classes, keyColumn, width, height, maxHeight, minHeight, rowsPerPage,
      dataRowBgColor, dataRowBgColorCondition } = this.props
    const fullWidth = { width: '100%' }
    const fixWidthHeight = { width: width + 'px', height: height + 'px' }
    const defaultMaxMinHeight = { maxHeight: '440px', minHeight: '440px' }
    const maxMinHeight = { maxHeight: maxHeight + 'px', minHeight: minHeight + 'px' }

    let rowStyle = {}

    return (
      <Paper style={width && width > 0 && height && height > 0 ? { fixWidthHeight } : { fullWidth }}>
        <TableContainer style={minHeight && maxHeight && minHeight > 0 && maxHeight > 0 ?
          { maxMinHeight } : { defaultMaxMinHeight }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {this.props.columns.map((column, index) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    //Red Shade ED1C24, f44336, ef9a9a, ff8a80
                    //Violage Shade 8556A6
                    style={
                      column.hide == 'true' ? { display: 'none', minWidth: column.minWidth, width: column.width, backgroundColor: '#EF7F1A' } :
                        { minWidth: column.minWidth, width: column.width, backgroundColor: '#EF7F1A' }
                    }
                  // { minWidth: column.minWidth, backgroundColor: '#ff8a80' },
                  >
                    {
                      (index === 0 && this.props.onNewEntryClick) ?
                        <Tooltip title={this.props.toolTipNewEntry}>
                          <IconButton
                            onClick={this.props.onNewEntryClick}
                            style={{ padding: '0px', margin: '0px 5px' }}
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                        : ''
                    }
                    {
                      (index === 0 && this.state.provideSearch && this.state.provideSearch === true) ? (
                        this.state.searchOpen === true ?
                          <Tooltip title={'Close Search'}>
                            <IconButton
                              onClick={this.onSearchClick}
                              style={{ padding: '0px', margin: '0px 5px' }}
                            >
                              <Close />
                            </IconButton>
                          </Tooltip> :
                          <Tooltip title={'Search'}>
                            <IconButton
                              onClick={this.onSearchClick}
                              style={{ padding: '0px', margin: '0px 5px' }}
                            >
                              <Search />
                            </IconButton>
                          </Tooltip>
                      ) : (
                        (
                          index > 0 && this.state.searchOpen === true &&
                          this.state.provideSearch && this.state.provideSearch === true
                        ) ? (
                          this.state.searchColumns.map((col, scIndex) => {
                            return (
                              (col.cType === 'CtTxtAdornNum' && index === (scIndex + 1)) ? (
                                <CtTxtAdornNum
                                  id={col.id}
                                  label={col.label}
                                  adornment={col.adornment}
                                  value={this.state.searchData[col.id]}
                                  handleOnChange={col.handleOnChange}
                                  width={col.width}
                                />
                              ) : ((col.cType === 'CtCmb' && index === (scIndex + 1)) ? (
                                <CtCmb
                                  id={col.id}
                                  label={col.label}
                                  items={col.items}
                                  value={this.state.searchData[col.id]}
                                  handleOnChange={col.handleOnChange}
                                  width={col.width}
                                />
                              ) : ((col.cType === 'CtTxt' && index === (scIndex + 1)) ? (
                                <CtTxt
                                  id={col.id}
                                  label={col.label}
                                  value={this.state.searchData[col.id]}
                                  handleOnChange={col.handleOnChange}
                                  width={col.width}
                                  maxLength={col.maxLength}
                                />
                              ) : ((col.cType === 'CtPhoneNumber' && index === (scIndex + 1)) ? (
                                <CtPhoneNumber
                                  id={col.id}
                                  label={col.label}
                                  value={this.state.searchData[col.id]}
                                  handleOnChange={col.handleOnChange}
                                  width={col.width}
                                  maxLength={col.maxLength}
                                />
                              ) : ((col.cType === 'Lbl' && index === (scIndex + 1)) ? col.label : ('')))))
                            )
                          })
                        ) : (column.label)
                      )
                    }
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.state.rows ?
                  this.state.rows.slice(this.state.page * rowsPerPage, this.state.page * rowsPerPage + rowsPerPage).map((row, rowIndex) => {

                    if (dataRowBgColor && dataRowBgColor.length > 0) {

                      let applyBgColor = false
                      rowStyle = {}

                      if (
                        dataRowBgColorCondition
                        //&& dataRowBgColorCondition.length > 0
                      ) {
                        if (this.checkCondition(dataRowBgColorCondition, row)) {
                          applyBgColor = true
                          {/* console.log('if checkcondition returns true') */ }
                        } else {
                          applyBgColor = false
                          {/* console.log('if checkcondition returns false') */ }
                        }
                      } else {
                        applyBgColor = true
                        {/* console.log('dataRowBgColorCondition not found') */ }
                      }

                      {/* console.log('applyBgColor', applyBgColor) */ }

                      if (applyBgColor === true) {
                        rowStyle.backgroundColor = dataRowBgColor
                      }

                      {/* console.log('rowStyle',rowStyle) */ }
                    }

                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.ID} style={rowStyle} >
                        {
                          this.props.columns.map((column, index) => {
                            let value = row[column.id], dynamicRowDataID
                            const keyValue = row[keyColumn]

                            if (
                              column.dynamicRowDataID && column.dynamicRowDataID.length > 0
                            ) {
                              if (row.dynamicRowDataID) {
                                value = row.dynamicRowDataID[column.dynamicRowDataID]
                              } else {
                                value = ''
                              }
                            }

                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={
                                  column.hide == 'true' ? { display: 'none' } : {}
                                }
                              >
                                {/* {this.displayCurrentCell(index, column.format, value, row)} */}
                                {column.type && column.type === "CtTxt" ? (
                                  <CtTxt
                                    id={column.dynamicRowDataID}//  + (rowIndex + 1)}
                                    label={column.inputLabel}
                                    value={this.displayCurrentCell(index, column.format, value, row)}
                                    handleOnChange={(e) => column.handleOnChange(e, rowIndex)}
                                    width={150}
                                    maxLength='20'
                                  // onKeyDown={this.props.onKeyDown}
                                  />
                                ) : this.displayCurrentCell(index, column.format, value, row)}
                              </TableCell>
                            )
                          })
                        }
                      </TableRow>
                    )
                  })
                  : ''
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={this.state.rowsCount}
          rowsPerPage={rowsPerPage}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={(e, tblIndex) => { this.handleChangeRowsPerPage(e, tblIndex) }}
        />
      </Paper>
    );
  }
}

export default TableList