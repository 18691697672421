import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import Progress from './CustomTool/Progress'
import { Grid, Box } from '@material-ui/core'
import TableList from './CustomTool/TableList'
import { fetchMember } from './API/API'
import { FormatListBulletedOutlined } from '@material-ui/icons'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import CtBtn from './CustomTool/CtBtn'

class LevelWiseMemberCount extends Component {

    state = {
        dataLoadStatus: false,
        columnsMemberCount: [
            { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            { id: 'no', label: 'Sr. No.', align: 'center' },
            { id: 'levelname', label: 'Level Name' },
            { id: 'income', label: 'Income', align: 'right' },
            { id: 'membercount', label: 'Member Count', align: 'right' },
        ],
        rowsMemberCount: [],
        rowsMemberCountCount: 0,
        rowsMemberCountPerPage: 13,
        actionList: [
            {
                name: 'List Member Detail',
                icon: <FormatListBulletedOutlined />,
                link: 'Level:',
                actionType: 'AlertResponsive'
            }
        ],
        searchData: {
            levelname: ''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        keyColumn: [
            'levelname', 'income'
        ],

        columnsMemberDetail: [
            { id: 'Action', name: 'Search', label: 'Action', width: '50px' },
            { id: 'no', label: 'Sr. No.', align: 'center', width: '70px' },
            { id: 'memberid', label: 'Member ID', align: 'center', width: '150px' },
            { id: 'membername', label: 'Name', width: '250px' },
            { id: 'membermobileno', label: 'Mobile No', align: 'center', width: '120px' },
            { id: 'incomeachieve', label: 'Income Achieve', align: 'right', width: '150px' },
            { id: 'levelname', label: 'Level Name', width: '250px' },
        ],
        rowsMemberDetail: [],
        rowsMemberDetailCount: 0,
        rowsMemberDetailPerPage: 10,
        searchMemberDetailData: {
            memberid: '', membername: '', membermobileno: ''
        },
        searchMemberDetailColumns: [],
        searchMemberDetailRows: [],
        searchMemberDetailOpen: false,
        searchMemberDetailRowsCount: 0,
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.setSearchColumns()
        this.setSearchMemberDetailColumns()
        this.loadLevelWiseMemberCount()
    }
    //#endregion

    //#region Handle
    handleChangeMemberCountRowsPerPage = (rowsMemberCountPerPage) => {
        this.setState({ rowsMemberCountPerPage })
    }

    handleChangeMemberDetailRowsPerPage = (rowsMemberDetailPerPage) => {
        this.setState({ rowsMemberDetailPerPage })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rowsMemberCount.length > 0) {
            this.state.rowsMemberCount.map((row) => {
                if (
                    searchOpen === true &&
                    ((this.state.searchData.levelname.length === 0 || row['levelname'].toString().toLowerCase().includes(this.state.searchData.levelname.toLowerCase())))
                ) {
                    searchRows.push(row)
                    // console.log('searchRows at push in loop', searchRows)
                }
            })
            const searchRowsCount = searchRows.length
            // console.log("searchRowsCount",searchRowsCount);
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
                // console.log('searchRows on end of Function', searchRows)
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleARDonActionClick = (values) => {
        const levelname = values[0].value,
            actionName = values[0].actionName,
            income = values[1].value
        if (actionName === 'List Member Detail' && levelname === 'All Member') {
            this.loadLevelWiseMemberDetail(income, levelname)
        } else if (actionName === 'List Member Detail') {
            this.loadLevelWiseMemberDetail(income)
        }
    }

    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
        })
    }

    handleOnSearchMemberDetailChange = (searchMemberDetailOpen) => {
        let searchMemberDetailRows = []
        if (searchMemberDetailOpen === true && this.state.rowsMemberDetail.length > 0) {
            this.state.rowsMemberDetail.map((row) => {
                if (
                    searchMemberDetailOpen === true &&
                    ((this.state.searchMemberDetailData.memberid.length === 0 || row['memberid'].toString().includes(this.state.searchMemberDetailData.memberid))) &&
                    ((this.state.searchMemberDetailData.membername.length === 0 || row['membername'].toString().toLowerCase().includes(this.state.searchMemberDetailData.membername.toLowerCase()))) &&
                    ((this.state.searchMemberDetailData.membermobileno.length === 0 || row['membermobileno'].toString().includes(this.state.searchMemberDetailData.membermobileno)))
                ) {
                    searchMemberDetailRows.push(row)
                    // console.log('searchMemberDetailRows at push in loop', searchMemberDetailRows)
                }
            })
            const searchMemberDetailRowsCount = searchMemberDetailRows.length
            // console.log("searchRowsCount",searchRowsCount);
            this.setState({
                searchMemberDetailOpen,
                searchMemberDetailRows,
                searchMemberDetailRowsCount,
                counter: this.state.counter + 1,
            }, () => {
                // console.log('searchRows on end of Function', searchRows)
            })
        } else {
            this.setState({ searchMemberDetailOpen })
        }
    }

    handleOnSearchMemberDetailInputChange = (e) => {
        this.setState({
            searchMemberDetailData: { ...this.state.searchMemberDetailData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchMemberDetailChange(this.state.searchMemberDetailOpen)
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {
                cType: 'Lbl',
                label: 'Sr. No.',
                // width: '100',
            },
            {
                cType: 'CtTxt',
                id: 'levelname',
                label: 'Level Name',
                value: this.state.searchData.levelname,
                handleOnChange: this.handleOnSearchInputChange,
                width: '250',
                maxLength: '100'
            },
            {
                cType: 'Lbl',
                label: 'Income',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Member Count',
                // width: '100',
            }
        )
        this.setState({ searchColumns })
    }

    setSearchMemberDetailColumns = () => {
        let searchMemberDetailColumns = []
        searchMemberDetailColumns.push(
            {
                cType: 'Lbl',
                label: 'Sr. No.',
            },
            {
                cType: 'CtTxt',
                id: 'memberid',
                label: 'Member ID',
                value: this.state.searchMemberDetailData.memberid,
                handleOnChange: this.handleOnSearchMemberDetailInputChange,
                width: '110',
                maxLength: '6'
            },
            {
                cType: 'CtTxt',
                id: 'membername',
                label: 'Member Name',
                value: this.state.searchMemberDetailData.membername,
                handleOnChange: this.handleOnSearchMemberDetailInputChange,
                width: '200',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'membermobileno',
                label: 'Member Mobile No',
                value: this.state.searchMemberDetailData.membermobileno,
                handleOnChange: this.handleOnSearchMemberDetailInputChange,
                width: '150',
                maxLength: '10'
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Income Achieve',
                // width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Level Name',
                // width: '100',
            }
        )
        this.setState({ searchMemberDetailColumns })
    }

    loadLevelWiseMemberCount = () => {
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = { Op: 'LoadLevelWiseMemberCount' }
            fetchMember(reqData)
                .then(res => {
                    const rowsMemberCount = res.data.LevelWiseMemberCount
                    const rowsMemberCountCount = rowsMemberCount.length
                    this.setState({
                        rowsMemberCount,
                        rowsMemberCountCount,
                        dataLoadStatus: true,
                        rowsMemberDetail: [],
                        rowsMemberDetailCount: 0
                    })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in loadLevelWiseMemberCount. ' + error)
                })
        })
    }

    loadLevelWiseMemberDetail = (income, levelname = '') => {
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                Op: 'LoadLevelWiseMemberDetail',
                LevelIncome: income,
                LevelName: levelname
            }
            fetchMember(reqData)
                .then(res => {
                    const rowsMemberDetail = res.data.LevelWiseMemberDetail
                    const rowsMemberDetailCount = rowsMemberDetail.length
                    this.setState({ rowsMemberDetail, rowsMemberDetailCount, dataLoadStatus: true })
                })
                .catch(error => {
                    // this.props.toastMsg('error', 'Unkonwn Error occured in loadLevelWiseMemberDetail. ' + error)
                    console.log(error);
                })
        })
    }

    recalculateMemberIncome = () => {
        this.props.notifyProcessing()
        const reqData = {
            Op: 'RecalculateMemberIncome'
        }
        fetchMember(reqData)
            .then(res => {
                this.props.updateProcessing(res.data.msgType, res.data.message)
                this.setState({
                    ...this.state,
                    rowsMemberDetail: [],
                    rowsMemberDetailCount: 0,
                    dataLoadStatus: true
                }, () => {
                    this.loadLevelWiseMemberCount()
                })
            })
            .catch(error => {
                this.props.toastErrorMsg('Unknown Error occured in recalculateMemberIncome. ' + error)
            })
    }
    //#endregion

    render() {
        let rowsMemberCount = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rowsMemberCount)
        let rowsMemberCountCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsMemberCountCount)

        let rowsMemberDetail = (this.state.searchMemberDetailOpen) ? (this.state.searchMemberDetailRows) : (this.state.rowsMemberDetail)
        let rowsMemberDetailCount = (this.state.searchMemberDetailOpen) ? (this.state.searchMemberDetailRowsCount) : (this.state.rowsMemberDetailCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>Level Wise Member Count</label>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item>
                        <CtBtn label='Refresh Count' onClick={() => { this.loadLevelWiseMemberCount() }} />
                    </Grid>
                    <Grid item>
                        <CtBtn label='Recalculate Member Income' onClick={() => { this.recalculateMemberIncome() }} />
                    </Grid>
                </Grid>
                <br />
                <TableList
                    columns={this.state.columnsMemberCount}
                    counter={this.state.counter}
                    rows={rowsMemberCount}
                    rowsCount={rowsMemberCountCount}
                    rowsPerPage={this.state.rowsMemberCountPerPage}
                    handleChangeRowsPerPage={this.handleChangeMemberCountRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                />
                <br />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>Level Wise Member Detail</label>
                    </Grid>
                </Grid>
                <TableList
                    columns={this.state.columnsMemberDetail}
                    counter={this.state.counter}
                    rows={rowsMemberDetail}
                    rowsCount={rowsMemberDetailCount}
                    rowsPerPage={this.state.rowsMemberDetailPerPage}
                    handleChangeRowsPerPage={this.handleChangeMemberDetailRowsPerPage}
                    // onActionClick={this.handleARDonActionClick}
                    // actionList={this.state.actionList}
                    // keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchMemberDetailChange}
                    searchColumns={this.state.searchMemberDetailColumns}
                    searchData={this.state.searchMemberDetailData}
                    provideSearch={true}
                />
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(LevelWiseMemberCount))
