import React, { Component } from 'react'
import { Grid, Box } from '@material-ui/core'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import CtCmb from './CustomTool/CtCmb'
import { fetchMember, apiURL } from "./API/API";

class MaxChildTreeNodeCount extends Component {

    state = {
        dataLoadStatus: false,
        ARD: null,
        MaxChildTreeNodeCount: [
            { name: "2", disabled: false },
            { name: "3", disabled: true },
            { name: "4", disabled: true }
        ],
        formData: {
            cmbMaxChildTreeNodeCount: '2',
        },
    }

    //#region Component
    componentWillMount() {
        // this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        // this.props.setActiveLink({ LinkSlug: "SalesEntry" })
    }
    //#endregion

    //#region handle
    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        }, () => {
            this.props.getMaxChildTreeNodeCount(this.state.formData.cmbMaxChildTreeNodeCount)
        })
    }
    //#endregion

    //#region function
     
    //#endregion

    render() {
        return (
            <div>
                <Grid container direction="row" justify="center" alignItems="right" spacing={3}>
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>Max Child Tree Node Count</label>
                    </Grid>
                </Grid>
                <form>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item>
                            <CtCmb
                                id='cmbMaxChildTreeNodeCount'
                                label='Tree Node Count'
                                items={this.state.MaxChildTreeNodeCount}
                                handleOnChange={this.handleOnChange}
                                value={this.state.formData.cmbMaxChildTreeNodeCount}
                                width="300"
                                maxLength='10'
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

export default HOCVerifyIsAdmin(HoCtToastContainer(MaxChildTreeNodeCount))