import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import Progress from './CustomTool/Progress'
import { Grid, Box } from '@material-ui/core'
import TableList from './CustomTool/TableList'
import { fetchAdmin } from './API/API'
import { Delete, Edit } from '@material-ui/icons'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";

class UserList extends Component {

    state = {
        dataLoadStatus: false,
        columns: [
            { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            { id: 'no', label: 'Sr. No.', align: 'center' },
            { id: 'Name', label: 'Name' },
            { id: 'MobileNo', label: 'Mobile No.', align: 'center' },
            { id: 'UserID', label: 'User ID', align: 'right' },
            { id: 'emailid', label: 'Email ID', align: 'center' },
            { id: 'ActiveStatus', label: 'Status' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 5,
        actionList: [
            {
                name: 'Edit User',
                icon: <Edit />,
                link: 'User:',
                actionType: 'AlertResponsive'
            },
            {
                name: 'Delete User',
                icon: <Delete />,
                link: 'DelUser:',
                actionType: 'AlertResponsive'
            },
        ],
        searchData: {
            Name: '',
            MobileNo: '',
            UserID: '',
            emailid: '',
            ActiveStatus: ''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        keyColumn: [
            'UserID'
        ],
        ARD: null,
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
        console.log('componentWillMount executed')
    }

    componentDidMount() {
        this.setSearchColumns()
        this.adminList()
    }
    //#endregion

    //#region Handle
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    ((this.state.searchData.Name.length === 0 || row['Name'].toString().includes(this.state.searchData.Name))) && ((this.state.searchData.MobileNo.length === 0 || row['MobileNo'].toString().includes(this.state.searchData.MobileNo))) && ((this.state.searchData.UserID.length === 0 || row['UserID'].toString().includes(this.state.searchData.UserID))) && ((this.state.searchData.emailid.length === 0 || row['emailid'].toString().includes(this.state.searchData.emailid))) && ((this.state.searchData.ActiveStatus.length === 0 || row['ActiveStatus'].toString().includes(this.state.searchData.ActiveStatus)))
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleARDonActionClick = (values) => {
        const actionName = values[0].actionName,
            EditUserID = values[0].value
        if (actionName === 'Edit User') {
            this.navigateTo('UserEntry:' + EditUserID)
        } else if (actionName === 'Delete User') {
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete User : ' + EditUserID + '?'}
                    // alertMessage={}
                    handleOnClickYes={() => { return this.deleteUser(EditUserID) }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }

    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    setSearchColumns = () => {
        console.log('setSearchColumns')
        let searchColumns = []
        searchColumns.push(
            {},
            {
                cType: 'CtTxt',
                id: 'Name',
                label: 'Name',
                value: this.state.searchData.Name,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'MobileNo',
                label: 'MobileNo',
                value: this.state.searchData.MobileNo,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '10'
            },
            {
                cType: 'CtTxt',
                id: 'UserID',
                label: 'User ID',
                value: this.state.searchData.UserID,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'emailid',
                label: 'Email ID',
                value: this.state.searchData.emailid,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '50'
            }
        )
        this.setState({ searchColumns })
    }

    adminList = () => {
        console.log('adminList called')
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = { Op: 'AdminList' }
            fetchAdmin(reqData)
                .then(res => {
                    console.log('res.data', res.data);
                    const rows = res.data
                    const rowsCount = rows.length
                    this.setState({ rows, rowsCount, dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in adminList. ' + error)
                })
        })
    }

    deleteUser = (EditUserID) => {
        if (EditUserID.length > 0) {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'DeleteUser',
                    edituserid: EditUserID
                }
                fetchAdmin(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.setState({
                            dataLoadStatus: true
                        }, () => {
                            if (res.data.msgType === 'success') {
                                this.adminList()
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('deleteUser', error)
                    })
            })
        } else {
            this.props.toastMsg("info", "There are no detail to delete")
        }

        return true
    }

    onNewEntryClick = () => {
        this.navigateTo('UserEntry')
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>User List</label>
                    </Grid>
                </Grid>
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={this.onNewEntryClick}
                    toolTipNewEntry='New User Entry'
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(UserList))
