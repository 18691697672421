import React, { Component } from 'react'
import HOCVerifyIsAdmin from './HOC/HOCVerifyIsAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { fetchSales, apiURL } from "./API/API";
import Progress from './CustomTool/Progress'
import { Grid, Box } from '@material-ui/core'
import CtTxt from './CustomTool/CtTxt'
import CtMultiLineText from './CustomTool/CtMultiLineText'
import CtCmbEditable from './CustomTool/CtCmbEditable'
// import CtBtn from './CustomTool/CtBtn'
import CtDtpDOB from './CustomTool/CtDtpDOB'
import CtTxtReset from './CustomTool/CtTxtReset'
import TableList from './CustomTool/TableList'
import { Delete, Edit } from '@material-ui/icons'
import SalesItemEntry from './SalesItemEntry'
import SalesDelete from './SalesDelete'
import SalesItemDelete from './SalesItemDelete'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { get_YMD_from_SYS, getDateAcYearfromDate } from './SystemUtility/SystemUtility'
import CtBtn from './CustomTool/CtBtn'
import CtMultiSelect from './CustomTool/CtMultiSelect'

class SalesEntry extends Component {

    state = {
        cWidth: 200,
        dataLoadStatus: false,
        offerFreeMrpList: [],
        activationFreeMrp: [],
        ActivationKitList: [],
        freemrpactivation: 0,
        freemrpoffer: 0,
        formData: {
            EditBillNo: '',
            EditAcYear: '',
            EditStrBillNo: '',
            dtpDate: get_YMD_from_SYS(new Date()),
            txtBillNo: '',
            txtBuyerID: '',
            txtBuyerName: '',
            txtServiceID: '',
            txtServiceName: '',
            txtTotalBasicAmount: 0,
            txtTotalDiscountAmount: 0,
            txtTotalTaxable: 0,
            txtTotalCGST: 0,
            txtTotalSGST: 0,
            txtTotalIGST: 0,
            txtTotalGrandTotal: 0,
            txtTotalRoundOff: 0,
            txtTotalNetAmount: 0,
            txtRepurchaseWallet: 0,
            txtWalletAmount: 0,
            txtFinalAmount: 0,
            txtTotalSalesBTO: 0,
            cmbSalesCategory: 'Normal',
            txtAppliedOfferDetails: 'Get Extra on Purchase of Rs. 1,000/- or More, Get Extra Repurchase Income of Rs. 150/-, Valid till 31/03/2021 08:00 PM',
            cmbActivationKit: '',
            mselOfferList: [],
            txtFreeMRP: 0,
            txtAvailableFreeMRP: 0,
            oldBuyerID: '',
        },
        mselOfferListItems: [],
        ValidateIDStatus: {
            BuyerIDStatus: '',
            ServiceIDStatus: '',
        },
        SalesCategories: [
            { 'name': 'Normal' },
            { 'name': 'Activation MRP 2000' },
            { 'name': 'Activation DP 3600' },
            { 'name': 'Free Activation' },
        ],
        columns: [
            { id: 'Action', name: 'Search', label: 'Action', width: '100px' },
            { id: 'SrNo', label: 'Sr. No.', align: 'center' },
            { id: 'ProductName', label: 'Product Name' },
            { id: 'BatchNo', label: 'Batch No.' },
            { id: 'Qty', label: 'Quantity', align: 'right' },
            { id: 'Rate', label: 'Rate', align: 'right' },
            { id: 'TaxableAmount', label: 'Taxable Amount', align: 'right' },
            { id: 'GSTPer', label: 'GST %', align: 'center' },
            { id: 'CGST', label: 'CGST', align: 'right' },
            { id: 'SGST', label: 'SGST', align: 'right' },
            { id: 'IGST', label: 'IGST', align: 'right' },
            { id: 'TotalAmount', label: 'Total Amount', align: 'right' },
            { id: 'BTO', label: 'BTO', align: 'right' },
            { id: 'TotalBTO', label: 'Total BTO', align: 'right' },
            { id: 'MRP', label: 'MRP', align: 'right' },
            { id: 'FreeMRP', label: 'Free MRP', align: 'right' },
            { id: 'ItemSrNo', label: 'Total Amount', align: 'right', hide: 'true' },
        ],
        rows: [],
        rowsCount: 0,
        rowsPerPage: 50,
        actionList: [
            {
                name: 'Edit Sales Item',
                icon: <Edit />,
                link: 'SalesItem:',
                actionType: 'AlertResponsive',//Conditional',
                condition: [
                    { colID: 'Rate', value: 0, relationalOperator: '!=' },
                    { colID: 'FreeMRP', value: 0, relationalOperator: '!=', logicalOperator: '||' }
                ]
            },
            {
                name: 'Delete Sales Item',
                icon: <Delete />,
                link: 'DelSalesItem:',
                actionType: 'AlertResponsive',//Conditional',
                condition: [
                    { colID: 'Rate', value: 0, relationalOperator: '!=' },
                    { colID: 'FreeMRP', value: 0, relationalOperator: '!=', logicalOperator: '||' }
                ]
            },
        ],
        searchData: {
            ProductName: ''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,

        keyColumn: [
            'ItemSrNo', 'ProductName'
        ],
        loadingFormData: false,
        ARD: null,
        rootmember: ''
    }

    //#region Component
    componentWillMount() {
        this.props.validateIsAdmin(this.props.setActiveLink, this.navigateTo)
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "SalesEntry" })
        this.setSearchColumns()
        this.onFormLoad()
    }
    //#endregion

    //#region Handles
    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    ((this.state.searchData.ProductName.length === 0 || row['ProductName'].toString().includes(this.state.searchData.ProductName)))
                ) {
                    searchRows.push(row)
                    //console.log('searchRows at push in loop', searchRows)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
                // console.log('searchRows on end of Function', searchRows)
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen)
        })
    }

    handleOnChange = (e) => {
        const curID = e.target.name
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        }, () => {
            if (curID === "txtWalletAmount") {
                this.calculateFinalAmount()
            } else if (curID == "txtServiceID") {
                if (this.state.formData.txtServiceID.length < 6) {
                    this.setState({
                        formData: { ...this.state.formData, txtServiceName: "" }
                    })
                }
            } else if (curID == "mselOfferList") {
                this.calcOfferFreeMRP()
            } else if (curID == "cmbActivationKit") {
                if (this.state.loadingFormData === true) {
                    this.setState({ loadingFormData: false })
                } else {
                    this.calcActivationKitFreeMRP()
                }
            }
        })
    }

    calcActivationKitFreeMRP = () => {
        let curIndex = -1
        this.state.ActivationKitList.forEach((ActivationKit, index) => {
            if (ActivationKit.name == this.state.formData.cmbActivationKit) {
                curIndex = index
            }
        });
        const freemrpactivation = Number(curIndex >= 0 ? this.state.activationFreeMrp[curIndex] : 0)
        const txtFreeMRP = freemrpactivation + Number(this.state.freemrpoffer)
        this.setState({ freemrpactivation, formData: { ...this.state.formData, txtFreeMRP } }, () => {
            this.calcAvailFreeMRP()
        })
    }

    getActivationKitFreeMRP = () => {
        let curIndex = -1
        this.state.ActivationKitList.forEach((ActivationKit, index) => {
            if (ActivationKit.name == this.state.formData.cmbActivationKit) {
                curIndex = index
            }
        });
        const freemrpactivation = Number(curIndex >= 0 ? this.state.activationFreeMrp[curIndex] : 0)
        return freemrpactivation
    }

    calcOfferFreeMRP = () => {
        let freemrpoffer = 0
        this.state.formData.mselOfferList.forEach((offer) => {
            const index = this.state.mselOfferListItems.indexOf(offer)
            if (index >= 0) {
                freemrpoffer += Number(this.state.offerFreeMrpList[index])
            }
        })
        const txtFreeMRP = freemrpoffer + Number(this.state.freemrpactivation)
        this.setState({ freemrpoffer, formData: { ...this.state.formData, txtFreeMRP } }, () => {
            this.calcAvailFreeMRP()
        })
    }

    getOfferFreeMRP = () => {
        let freemrpoffer = 0
        this.state.formData.mselOfferList.forEach((offer) => {
            const index = this.state.mselOfferListItems.indexOf(offer)
            if (index >= 0) {
                freemrpoffer += Number(this.state.offerFreeMrpList[index])
            }
        })
        return freemrpoffer
    }

    calcAvailFreeMRP = () => {
        const usedItemFreeMRP = this.getTotalofItemUsedMRP()
        const txtAvailableFreeMRP = this.state.formData.txtFreeMRP - usedItemFreeMRP
        this.setState({ formData: { ...this.state.formData, txtAvailableFreeMRP } })
    }

    getAvailFreeMRP = () => {
        const usedItemFreeMRP = this.getTotalofItemUsedMRP()
        const txtAvailableFreeMRP = this.state.formData.txtFreeMRP - usedItemFreeMRP
        return txtAvailableFreeMRP
    }

    handleARDonActionClick = (values) => {
        const EditItemSrNo = values[0].value,
            EditBillNo = this.state.formData.EditBillNo,
            EditAcYear = this.state.formData.EditAcYear,
            StrBillNo = this.state.formData.txtBillNo,
            actionName = values[0].actionName
        if (actionName === 'Edit Sales Item') {
            this.validateIDStatusBuyer(() => { this.openSalesItemEntry(EditItemSrNo, EditBillNo, EditAcYear) }, false)
        } else if (actionName === 'Delete Sales Item') {
            const EditProductName = values[1].value
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <SalesItemDelete
                    EditBillNo={EditBillNo}
                    EditAcYear={EditAcYear}
                    StrBillNo={StrBillNo}
                    EditItemSrNo={EditItemSrNo}
                    ProductName={EditProductName}
                    SalesDate={this.state.formData.dtpDate}
                    onDeleteItemSuccess={this.loadSalesItemList}
                    setTotalInfo={this.setTotalInfo}
                    mselOfferList={this.state.formData.mselOfferList}
                    FreeMRP={this.state.formData.txtFreeMRP}
                    ActivationKit={this.state.formData.cmbActivationKit}
                />
                this.setState({ ARD })
            })
        }
    }

    openSalesItemEntry = (EditItemSrNo, EditBillNo, EditAcYear) => {
        let ARD = null
        this.setState({ ARD }, () => {
            ARD = <AlertResponsiveDialog
                // labelAgree='Delete'
                labelDisagree='Cancel'
                alertTitle={'Sales Item Entry'}
                alertMessage={
                    <SalesItemEntry
                        FreeMRP={this.state.formData.txtFreeMRP}
                        AvailableFreeMRP={this.state.formData.txtAvailableFreeMRP}
                        EditItemSrNo={EditItemSrNo}
                        EditBillNo={EditBillNo}
                        EditAcYear={EditAcYear}
                        EditSalesDate={this.state.formData.dtpDate}
                        txtBillNo={this.state.formData.txtBillNo}
                        BuyerID={this.state.formData.txtBuyerID}
                        BuyerName={this.state.formData.txtBuyerName}
                        ServiceID={this.state.formData.txtServiceID}
                        ServiceName={this.state.formData.txtServiceName}
                        ActivationKit={this.state.formData.cmbActivationKit}
                        setTotalInfo={this.setTotalInfo}
                        loadSalesItemList={this.loadSalesItemList}
                        mselOfferList={this.state.formData.mselOfferList}
                        getTxtAvailableFreeMRPFromSales={this.getTxtAvailableFreeMRPFromSales}
                    />
                }
                defaultOpenDialog={true}
                onYesClickCloseIfExeSuccessfully={true}
            />
            this.setState({ ARD })
        })
    }

    handleOnChangeTrim = (e) => {
        const curID = e.target.name
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        }, () => {
            if (Number(this.state.formData.EditBillNo) > 0 && curID === "dtpDate") {
                this.getBillNoOnDateChange()
            }
        })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {},
            {
                cType: 'CtTxt',
                id: 'ProductName',
                label: 'Product Name',
                value: this.state.searchData.ProductName,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            }
        )
        this.setState({ searchColumns })
    }

    onNewEntryClick = () => {
        // if (this.validateSales() === true) {
        let ARD = null
        this.setState({ ARD }, () => {
            ARD = <AlertResponsiveDialog
                labelDisagree='Cancel'
                alertTitle={'Sales Item Entry'}
                alertMessage={
                    <SalesItemEntry
                        FreeMRP={this.state.formData.txtFreeMRP}
                        AvailableFreeMRP={this.state.formData.txtAvailableFreeMRP}
                        EditItemSrNo={0}
                        EditBillNo={this.state.formData.EditBillNo}
                        EditAcYear={this.state.formData.EditAcYear}
                        EditSalesDate={this.state.formData.dtpDate}
                        txtBillNo={this.state.formData.txtBillNo}
                        BuyerID={this.state.formData.txtBuyerID}
                        BuyerName={this.state.formData.txtBuyerName}
                        ServiceID={this.state.formData.txtServiceID}
                        ServiceName={this.state.formData.txtServiceName}
                        ActivationKit={this.state.formData.cmbActivationKit}
                        setTotalInfo={this.setTotalInfo}
                        loadSalesItemList={this.loadSalesItemList}
                        mselOfferList={this.state.formData.mselOfferList}
                        getTxtAvailableFreeMRPFromSales={this.getTxtAvailableFreeMRPFromSales}
                    />
                }
                defaultOpenDialog={true}
                onYesClickCloseIfExeSuccessfully={true}
            />
            this.setState({ ARD })
        })
        // }
    }

    onFormLoad = () => {
        this.setState({
            dataLoadStatus: false,
            loadingFormData: true
        }, () => {
            // alert('loadingFormData : ' + this.state.loadingFormData)
            let reqData = {
                Op: 'Load_ActivationList_OfferList'
            },
                EditDate = '',
                EditBillNo = 0,
                EditAcYear = 0,
                EditStrBillNo = '',
                dtpDate = get_YMD_from_SYS(new Date()),
                txtBillNo = '',
                txtBuyerID = '',
                txtBuyerName = '',
                txtServiceID = '',
                txtServiceName = '',
                txtTotalBasicAmount = '',
                txtTotalDiscountAmount = '',
                txtTotalTaxable = '',
                txtTotalCGST = '',
                txtTotalSGST = '',
                txtTotalIGST = '',
                txtTotalGrandTotal = '',
                txtTotalRoundOff = '',
                txtTotalNetAmount = '',
                txtWalletAmount = '',
                txtFinalAmount = '',
                txtRepurchaseWallet = 0,
                txtTotalSalesBTO = 0,
                cmbActivationKit = '',
                mselOfferList = [],
                txtFreeMRP = 0,
                txtAvailableFreeMRP = 0,
                oldBuyerID = ''
            let rootmember = this.props.privilegesList.rootmember
            if (this.props.match.params.EditNewSrNo && (this.props.match.params.EditNewSrNo.substring(1).length) > 0) {
                var res = this.props.match.params.EditNewSrNo.split(":")
                //EditBillNo + ":" + EditDate + ":" + ":" + BillNo + ":" + EditAcYear
                EditBillNo = res[1]
                EditDate = res[2]
                EditAcYear = res[4]
                reqData = {
                    Op: 'LoadSalesData_ActivationList_OfferList',
                    EditBillNo,
                    EditAcYear,
                }
            }
            fetchSales(reqData)
                .then(res => {
                    const dataLoadStatus = true
                    const ActivationKitList = res.data.activationkitname
                    const mselOfferListItems = res.data.offernameList
                    const offerFreeMrpList = res.data.offerfreemrp
                    const activationFreeMrp = res.data.activationfreemrp
                    if (EditBillNo > 0) {
                        // EditSrNo = res.data.InwardStockList[0].SrNo
                        // EditDate = res.data.InwardStockList[0].Date
                        dtpDate = EditDate
                        // txtIntBillNo = res.data.SalesList[0].IntBillNo
                        EditStrBillNo = res.data.SalesList[0].StrBillNo
                        txtBillNo = res.data.SalesList[0].StrBillNo
                        txtBuyerID = res.data.SalesList[0].BuyerID
                        oldBuyerID = txtBuyerID
                        txtBuyerName = res.data.SalesList[0].BuyerName
                        txtServiceID = res.data.SalesList[0].ServiceID
                        txtServiceName = res.data.SalesList[0].ServiceName
                        txtTotalBasicAmount = res.data.SalesList[0].TotalBasicAmt
                        txtTotalDiscountAmount = res.data.SalesList[0].TotalDiscountAmt
                        txtTotalTaxable = res.data.SalesList[0].TaxableAmt
                        txtTotalCGST = res.data.SalesList[0].CGST
                        txtTotalSGST = res.data.SalesList[0].SGST
                        txtTotalIGST = res.data.SalesList[0].IGST
                        txtTotalGrandTotal = res.data.SalesList[0].GrandTotal
                        txtTotalRoundOff = res.data.SalesList[0].RoundOff
                        txtTotalNetAmount = res.data.SalesList[0].NetAmt
                        txtWalletAmount = res.data.SalesList[0].WalletAmount
                        txtFinalAmount = res.data.SalesList[0].FinalAmount
                        txtTotalSalesBTO = res.data.SalesList[0].TotalSalesBTO
                        txtRepurchaseWallet = res.data.SalesList[0].RepourchaseAmount
                        cmbActivationKit = res.data.SalesList[0].ActivationKitName
                        mselOfferList = res.data.SalesList[0].OfferName
                        txtFreeMRP = res.data.SalesList[0].FreeMRP
                        txtAvailableFreeMRP = res.data.SalesList[0].AvailableFreeMRP
                    }
                    this.setState({
                        dataLoadStatus,
                        ActivationKitList,
                        mselOfferListItems,
                        offerFreeMrpList,
                        activationFreeMrp,
                        rootmember,
                        formData: {
                            ...this.state.formData,
                            EditDate,
                            EditBillNo,
                            EditAcYear,
                            EditStrBillNo,
                            dtpDate,
                            txtBillNo,
                            txtBuyerID,
                            txtBuyerName,
                            txtServiceID,
                            txtServiceName,
                            txtTotalBasicAmount,
                            txtTotalDiscountAmount,
                            txtTotalTaxable,
                            txtTotalCGST,
                            txtTotalSGST,
                            txtTotalIGST,
                            txtTotalGrandTotal,
                            txtTotalRoundOff,
                            txtTotalNetAmount,
                            txtWalletAmount,
                            txtFinalAmount,
                            txtTotalSalesBTO,
                            mselOfferList,
                            cmbActivationKit,
                            txtFreeMRP,
                            txtAvailableFreeMRP,
                            oldBuyerID
                        }
                    }, () => {
                        if (EditBillNo > 0) {
                            let freemrpactivation = this.getActivationKitFreeMRP(),
                                freemrpoffer = this.getOfferFreeMRP(),
                                txtAvailableFreeMRP = this.getAvailFreeMRP()
                            this.setState({
                                freemrpactivation,
                                freemrpoffer,
                                formData: {
                                    ...this.state.formData,
                                    txtAvailableFreeMRP
                                }
                            }, () => {
                                this.loadSalesItemList(EditBillNo, EditAcYear)
                            })
                        }
                    })
                    document.getElementById('dtpDate').focus()
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('onFormLoad', error)
                })
        })
    }

    loadSalesItemList = (EditBillNo, EditAcYear) => {
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                Op: 'SalesItemList',
                EditBillNo,
                EditAcYear
            }

            fetchSales(reqData)
                .then(res => {
                    const rows = res.data.SalesItemList
                    const rowsCount = rows.length
                    this.setState({ rows, rowsCount, dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in loadSalesItemList. ' + error)
                })
        })
    }

    getNameOfID = (CtrlName) => {
        let Op = "GetNameFromID"
        if (CtrlName === 'txtBuyerName') {
            Op += "_GetRePurchaseWalletBalance"
            this.checkValidRegistrationID(
                this.state.formData.txtBuyerID, this.state.formData.cmbActivationKit,
                this.validateBuyerID,
                () => { this.setIDInfo(CtrlName, Op, this.state.formData.txtBuyerID) },
                true
            )
        }
    }

    setIDInfo = (CtrlName, Op, IDData) => {
        let txtRepurchaseWallet = this.state.formData.txtRepurchaseWallet
        this.props.notifyProcessing()
        if (IDData !== "") {
            this.setState({
                dataLoadStatus: false
            }, () => {
                let reqData = ""
                if (CtrlName === "txtBuyerName" && this.state.formData.EditBillNo > 0) {
                    reqData = {
                        Op,
                        ID: IDData,
                        BillNo: this.state.formData.EditBillNo,
                        AcYear: this.state.formData.EditAcYear,
                        rootmember: this.state.rootmember
                    }
                } else {
                    reqData = {
                        Op,
                        ID: IDData,
                        rootmember: this.state.rootmember
                    }
                }
                fetchSales(reqData)
                    .then(res => {
                        const dataLoadStatus = true
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (CtrlName === "txtBuyerName") {
                            txtRepurchaseWallet = Number(res.data.RePurchaseWalletBalance)
                        }
                        this.setState({
                            dataLoadStatus,
                            formData: {
                                ...this.state.formData,
                                [CtrlName]: res.data.Name,
                                txtRepurchaseWallet
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastUnknownErrorMsg('getNameOfID', error)
                    })
            })
        }
    }

    getBillNoOnDateChange = () => {
        let DateData = this.state.formData.dtpDate
        // console.log("this.state.formData.dtpDate",this.state.formData.dtpDate);
        if (DateData.length > 0) {
            let getYear = Number(getDateAcYearfromDate(DateData))
            // console.log('getYear', getYear)
            // console.log('this.state.formData.EditAcYear', this.state.formData.EditAcYear)
            if (getYear === Number(this.state.formData.EditAcYear)) {
                // console.log("this.state.formData.EditStrBillNo", this.state.formData.EditStrBillNo);
                this.setState({
                    formData: {
                        ...this.state.formData,
                        txtBillNo: this.state.formData.EditStrBillNo
                    }
                })
            } else {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        txtBillNo: ''
                    }
                })
            }
        }
    }

    validateBuyerID = (checkValidRegistrationIDResult, nextFunctionToBeCalled) => {
        let result = false
        if (this.state.formData.txtBuyerID === undefined || this.state.formData.txtBuyerID.trim().length <= 0) {
            this.props.toastErrorMsg('Select Buyer ID', 'txtBuyerID')
        } else if (this.state.formData.txtBuyerID.length > 0 && checkValidRegistrationIDResult === false) {
            this.props.toastErrorMsg('Select valid Buyer ID', 'txtBuyerID')
        } else {
            result = true
            nextFunctionToBeCalled()
        }
        return result
    }

    checkValidRegistrationID = (StrID, ActivationKit, nextValidationCall, nextFunctionToBeCalled,
        retOnlyName = false, validateAvailFreeMRP = true) => {
        let result = false
        this.setState({ dataLoadStatus: false }, () => {
            let reqData
            if (retOnlyName) {
                let NoCheckingForActivationKit = 'false'
                if (
                    this.state.formData.oldBuyerID !== undefined && Number(this.state.formData.oldBuyerID) > 0 && this.state.formData.txtBuyerID && Number(this.state.formData.txtBuyerID) > 0 &&
                    this.state.formData.oldBuyerID == this.state.formData.txtBuyerID
                ) {
                    NoCheckingForActivationKit = 'true'
                }
                reqData = {
                    Op: 'CheckValidRegistrationID',
                    StrID,
                    ActivationKit,
                    'NoCheckingForActivationKit': NoCheckingForActivationKit//ActivationKit.length > 0 ? 'false' : 'true'
                }
            } else {
                let NoCheckingForActivationKit = 'true'
                if (this.state.formData.EditBillNo && Number(this.state.formData.EditBillNo) > 0 && (this.state.formData.oldBuyerID && Number(this.state.formData.oldBuyerID) > 0 && this.state.formData.oldBuyerID != this.state.formData.txtBuyerID)) {
                    NoCheckingForActivationKit = 'false'
                    StrID = this.state.formData.txtBuyerID
                } else if (this.state.formData.EditBillNo !== undefined && Number(this.state.formData.EditBillNo) <= 0 && this.state.formData.oldBuyerID !== undefined && Number(this.state.formData.oldBuyerID) <= 0 && this.state.formData.txtBuyerID && Number(this.state.formData.txtBuyerID) > 0) {
                    NoCheckingForActivationKit = 'false'
                    StrID = this.state.formData.txtBuyerID
                }
                reqData = {
                    Op: 'CheckValidRegistrationID',
                    StrID,
                    ActivationKit,
                    'NoCheckingForActivationKit': NoCheckingForActivationKit //this.state.formData.EditBillNo && Number(this.state.formData.EditBillNo) > 0 ? 'true' : 'false'
                }
            }
            fetchSales(reqData)
                .then(res => {
                    this.setState({
                        dataLoadStatus: true
                    }, () => {
                        if (res.data.message === 'valid') {
                            result = true
                            nextValidationCall(result, nextFunctionToBeCalled, validateAvailFreeMRP)
                        } else if (res.data.message === 'invalid') {
                            // this.props.updateProcessing('error', 'Invalid Buyer ID or ')
                        } else {
                            this.props.toastMsg(res.data.msgType, res.data.message)
                            // this.props.updateProcessing(res.data.msgType, res.data.message)
                        }
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('checkValidRegistrationID', error)
                })
        })
    }

    validateIDStatusBuyer = (FinalFunctionCallOnSalesValidationTree, validateAvailFreeMRP = true) => {
        this.checkValidRegistrationID(
            this.state.formData.txtBuyerID,
            this.state.formData.cmbActivationKit,
            this.validateSales,
            FinalFunctionCallOnSalesValidationTree,
            false,
            validateAvailFreeMRP
        )
    }

    validateIDStatusService = (BuyerIDStatus, FinalFunctionCallOnSalesValidationTree) => {
        // FinalFunctionCallOnSalesValidationTree()
        this.validateSales(FinalFunctionCallOnSalesValidationTree)
    }

    updateServiceIDStatus = (ServiceIDStatus, FinalFunctionCallOnSalesValidationTree) => {
        this.setState({
            ValidateIDStatus: {
                ...this.state.ValidateIDStatus,
                ServiceIDStatus
            }
        }, () => {
            this.validateSales(FinalFunctionCallOnSalesValidationTree)
        })
    }

    validateServiceID = (checkValidRegistrationIDResult, nextFunctionToBeCalled) => {
        let result = false

        if (this.state.formData.txtServiceID !== null && (this.state.formData.txtServiceID === undefined || (this.state.formData.txtServiceID.length > 0 && this.state.formData.txtServiceID.length < 6))) {
            this.props.toastErrorMsg('Select valid Service ID', 'txtServiceID')
        } else if (this.state.formData.txtServiceID.length > 0 && checkValidRegistrationIDResult === false) {
            this.props.toastErrorMsg('Select valid Service ID', 'txtServiceID')
        } else {
            result = true
            nextFunctionToBeCalled()
        }
        return result
    }

    validateSales = (notUsedInfunctionOnlyForCall, FinalFunctionCallOnSalesValidationTree, validateAvailFreeMRP = true) => {
        let validateSales = false
        if (this.state.formData.dtpDate === undefined || this.state.formData.dtpDate.length <= 0) {
            this.props.toastErrorMsg('Enter Date', 'dtpDate')
        } else if (this.state.formData.EditBillNo === undefined || (Number(this.state.formData.EditBillNo) > 0 && this.state.formData.txtBillNo.trim().length <= 0)) {
            this.props.toastErrorMsg('Enter Bill No.', 'txtBillNo')
            // } else if (this.validateBuyerID() === false) {
        } else if (this.state.ValidateIDStatus.BuyerIDStatus === false) {
            this.props.toastErrorMsg('Enter Valid Buyer ID', 'txtBuyerID')
        } else if (this.state.formData.txtBuyerName === undefined || this.state.formData.txtBuyerName.trim().length <= 0) {
            this.props.toastErrorMsg('Select Buyer Name', 'txtBuyerName')
        } else if (this.state.formData.txtWalletAmount === undefined || Number(this.state.formData.txtWalletAmount) > Number(this.state.formData.txtRepurchaseWallet)) {
            this.props.toastErrorMsg('Wallet Amount must not be greater than Repurchase Wallet', 'txtWalletAmount')
        } else if (this.state.formData.txtWalletAmount === undefined || Number(this.state.formData.txtWalletAmount) > Number(this.state.formData.txtTotalNetAmount)) {
            this.props.toastErrorMsg('Wallet Amount must not be greater than Net Amount', 'txtWalletAmount')
        } else if (validateAvailFreeMRP && Number(this.state.formData.txtAvailableFreeMRP) < 0) {
            this.props.toastErrorMsg('Used Free MRP must not be greater than Free MRP', 'txtAvailableFreeMRP')
        } else {
            validateSales = true
            FinalFunctionCallOnSalesValidationTree()
        }
        return validateSales
    }

    saveSales = (bolOpenReport = false) => {
        let result = false
        // if (this.validateSales() === true) {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                Op: 'UpdateSales',
                EditBillNo: this.state.formData.EditBillNo,
                EditAcYear: this.state.formData.EditAcYear,
                Date: this.state.formData.dtpDate,
                StrBillNo: this.state.formData.txtBillNo,
                BuyerID: this.state.formData.txtBuyerID,
                BuyerName: this.state.formData.txtBuyerName,
                ServiceID: this.state.formData.txtServiceID,
                ServiceName: this.state.formData.txtServiceName,
                TotalBasicAmt: this.state.formData.txtTotalBasicAmount,
                TotalDisAmt: this.state.formData.txtTotalDiscountAmount,
                TaxableAmt: this.state.formData.txtTotalTaxable,
                TotalCGST: this.state.formData.txtTotalCGST,
                TotalSGST: this.state.formData.txtTotalSGST,
                TotalIGST: this.state.formData.txtTotalIGST,
                GrandTotal: this.state.formData.txtTotalGrandTotal,
                RoundOff: this.state.formData.txtTotalRoundOff,
                NetAmount: this.state.formData.txtTotalNetAmount,
                WalletAmount: this.state.formData.txtWalletAmount,
                FinalAmount: this.state.formData.txtFinalAmount,
                TotalSalesBTO: this.state.formData.txtTotalSalesBTO,
                ActivationKit: this.state.formData.cmbActivationKit,
                FreeMRP: this.state.formData.txtFreeMRP,
                AvailableFreeMRP: this.state.formData.txtAvailableFreeMRP,
                OfferList: this.state.formData.mselOfferList
            }
            fetchSales(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    this.setState({
                        dataLoadStatus: true
                    }, () => {
                        if (res.data.msgType === 'success') {
                            if (bolOpenReport == true) {
                                this.printBill(this.state.formData.txtBillNo, this.state.formData.EditAcYear)
                            }
                            result = true
                            this.newSales()
                        }
                    })
                })
                .catch(error => {
                    this.props.toastUnknownErrorMsg('saveSales', error)
                })
        })
        // }
    }

    printBill = (StrBillNo, AcYear) => {
        const fileName = StrBillNo.replace(/\//g, '-') + '.pdf'
        StrBillNo = StrBillNo.replace("/", '.')
        let reqData = [
            { name: 'Op', value: 'OpenReportFromList' },
            { name: 'StrBillNo', value: StrBillNo },
            { name: 'AcYear', value: Number(AcYear) },
            { name: 'fileName', value: fileName },
        ]

        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", apiURL + 'salesprint.php');

        form.setAttribute("target", "view");

        reqData.forEach((data) => {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", data.name);
            hiddenField.setAttribute("value", data.value);
            form.appendChild(hiddenField);
            document.body.appendChild(form);
        })

        window.open('', 'view');

        form.submit();
        // this.props.updateProcessing('success', 'PDF Open in next Tab.')
    }

    newSales = () => {
        const oldEditBillNo = this.state.formData.EditBillNo
        const EditBillNo = 0,
            EditAcYear = 0,
            EditStrBillNo = '',
            dtpDate = get_YMD_from_SYS(new Date()),
            txtBillNo = '',
            txtBuyerID = '',
            txtBuyerName = '',
            txtServiceID = '',
            txtServiceName = '',
            txtTotalBasicAmount = 0,
            txtTotalDiscountAmount = 0,
            txtTotalTaxable = 0,
            txtTotalCGST = 0,
            txtTotalSGST = 0,
            txtTotalIGST = 0,
            txtTotalGrandTotal = 0,
            txtTotalRoundOff = 0,
            txtTotalNetAmount = 0,
            txtRepurchaseWallet = 0,
            txtWalletAmount = 0,
            txtFinalAmount = 0,
            txtTotalSalesBTO = 0,
            rows = [],
            rowsCount = 0,
            cmbActivationKit = '',
            mselOfferList = [],
            txtFreeMRP = 0,
            txtAvailableFreeMRP = 0,
            freemrpactivation = 0,
            freemrpoffer = 0
        this.setState({
            rows,
            rowsCount,
            formData: {
                ...this.state.formData,
                EditBillNo,
                EditAcYear,
                EditStrBillNo,
                dtpDate,
                txtBillNo,
                txtBuyerID,
                txtBuyerName,
                txtServiceID,
                txtServiceName,
                txtTotalBasicAmount,
                txtTotalDiscountAmount,
                txtTotalTaxable,
                txtTotalCGST,
                txtTotalSGST,
                txtTotalIGST,
                txtTotalGrandTotal,
                txtTotalRoundOff,
                txtTotalNetAmount,
                txtRepurchaseWallet,
                txtWalletAmount,
                txtFinalAmount,
                txtTotalSalesBTO,
                cmbActivationKit,
                mselOfferList,
                txtFreeMRP,
                txtAvailableFreeMRP,
            },
            freemrpactivation,
            freemrpoffer
        }, () => {
            if (oldEditBillNo > 0) {
                this.navigateTo('SalesEntry')
            }
            document.getElementById('dtpDate').focus()
        })
    }

    deleteSales = () => {
        let EditBillNo = this.state.formData.EditBillNo,
            EditAcYear = this.state.formData.EditAcYear,
            BillNo = this.state.formData.txtBillNo,
            ARD = null
        this.setState({ ARD }, () => {
            ARD = <SalesDelete
                EditBillNo={EditBillNo}
                BillNo={BillNo}
                EditAcYear={EditAcYear}
                onDeleteSuccess={this.newSales}
            />
            this.setState({ ARD })
        })
    }

    onClose = (link) => {
        this.navigateTo('Sales')
    }

    setTotalInfo = (txtTotalBasicAmount,
        txtTotalDiscountAmount,
        txtTotalTaxable,
        txtTotalCGST,
        txtTotalSGST,
        txtTotalIGST,
        txtTotalGrandTotal,
        txtTotalRoundOff,
        txtTotalNetAmount,
        EditBillNo,
        EditAcYear,
        StrBillNo,
        txtTotalSalesBTO,
        txtAvailableFreeMRP) => {
        this.setState({
            formData: {
                ...this.state.formData,
                txtTotalBasicAmount,
                txtTotalDiscountAmount,
                txtTotalTaxable,
                txtTotalCGST,
                txtTotalSGST,
                txtTotalIGST,
                txtTotalGrandTotal,
                txtTotalRoundOff,
                txtTotalNetAmount,
                EditBillNo,
                EditAcYear,
                txtBillNo: StrBillNo,
                EditStrBillNo: StrBillNo,
                txtTotalSalesBTO,
                txtAvailableFreeMRP,
                oldBuyerID: this.state.formData.txtBuyerID
            }
        }, () => {
            this.calculateFinalAmount()
        })
    }

    calculateFinalAmount = () => {
        const txtTotalNetAmount = Number(this.state.formData.txtTotalNetAmount),
            txtWalletAmount = Number(this.state.formData.txtWalletAmount),
            txtRepurchaseWallet = Number(this.state.formData.txtRepurchaseWallet)
        let txtFinalAmount = 0
        // console.log("txtTotalNetAmount", txtTotalNetAmount);
        // console.log("txtWalletAmount", txtWalletAmount);
        // console.log("txtRepurchaseWallet", txtRepurchaseWallet);

        if (txtWalletAmount > txtRepurchaseWallet) {
            this.props.toastErrorMsg('Wallet Amount must not be greater than Repurchase Wallet', 'txtWalletAmount')
        } else if (txtWalletAmount > txtTotalNetAmount) {
            this.props.toastErrorMsg('Wallet Amount must not be greater than Net Amount', 'txtWalletAmount')
        } else {
            if (Number(txtTotalNetAmount) > 0) {
                txtFinalAmount = txtTotalNetAmount - txtWalletAmount
                txtFinalAmount = txtFinalAmount.toFixed(2)
            } this.setState({
                formData: { ...this.state.formData, txtFinalAmount }
            })
        }
    }

    getTotalofItemUsedMRP = () => {
        let usedItemMRP = 0
        if (this.state.rowsCount > 0) {
            this.state.rows.map((row) => {
                usedItemMRP += Number(row['FreeMRP'])
            })
        }
        // console.log("usedItemMRP",usedItemMRP);
        return usedItemMRP
    }

    getTxtAvailableFreeMRPFromSales = () => {
        return this.state.formData.txtAvailableFreeMRP
    }

    getActionListComponent = () => {
        if (this.props.privilegesList.privsalesrwm === "M") {
            return this.state.actionList
        } else {
            return []
        }
    }

    getNewIconComponent = () => {
        if (this.props.privilegesList.privsalesrwm === "W" || this.props.privilegesList.privsalesrwm === "M") {
            return (() => { this.validateIDStatusBuyer(this.onNewEntryClick) })
        } else {
            return null
        }
    }

    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)

        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justify="center" alignItems="right" spacing={3}>
                    <Grid item className="pageTitle">
                        <label htmlFor="Title" className='pageTitleText'>Sales Entry</label>
                    </Grid>
                </Grid>
                <form>
                    <Grid container direction="row" justify="space-evenly" alignItems="flex-start" spacing={5}>
                        <Grid item>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtDtpDOB
                                        id='dtpDate'
                                        label='Date'
                                        width='150'
                                        handleOnChange={this.handleOnChangeTrim}
                                        defaultValue={this.state.formData.dtpDate}
                                        value={this.state.formData.dtpDate}
                                        onKeyDown={this.props.onKeyDown}
                                    // disabled={this.state.disabledProfileData.DOBDS}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtBillNo'
                                        label='Bill No.'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtBillNo}
                                        width={this.state.cWidth - 100}
                                        maxLength='20'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtRepurchaseWallet'
                                        label='Repurchase Wallet'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtRepurchaseWallet}
                                        width={this.state.cWidth + 20}
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxtReset
                                        id='txtBuyerID'
                                        label='Buyer ID'
                                        adornment='WG'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtBuyerID}
                                        width={this.state.cWidth - 50}
                                        handleOnResetClick={() => { this.getNameOfID('txtBuyerName') }}
                                        maxLength='6'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtBuyerName'
                                        label='Buyer Name'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtBuyerName}
                                        width={this.state.cWidth + 150}
                                        maxLength='100'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item >
                                    <CtCmbEditable
                                        id='cmbActivationKit'
                                        label='Activation Kit'
                                        items={this.state.ActivationKitList}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.cmbActivationKit}
                                        width="250"
                                        maxLength='100'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={this.state.formData.txtBillNo.length !== 0}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtMultiSelect
                                        id='mselOfferList'
                                        label='Offer List'
                                        items={this.state.mselOfferListItems}
                                        handleOnChange={this.handleOnChange}
                                        selectedItems={this.state.formData.mselOfferList}
                                        width="250"
                                        maxLength='1000'
                                    // onKeyDown={this.props.onKeyDown}
                                    // style={{}}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                {
                                    ((this.props.privilegesList.privsalesrwm === 'W' && this.props.match.params.EditNewSrNo === undefined) || this.props.privilegesList.privsalesrwm === 'M') ?
                                        <Grid item>
                                            <CtBtn label='Save' width={this.state.cWidth - 120} onClick={() => {
                                                this.validateIDStatusBuyer(this.saveSales)
                                            }} />
                                        </Grid> : ""
                                }
                                {
                                    ((this.props.privilegesList.privsalesrwm === 'W' && this.props.match.params.EditNewSrNo === undefined) || this.props.privilegesList.privsalesrwm === 'M') ?
                                        <Grid item>
                                            <CtBtn label='Save & Print' width={this.state.cWidth - 50} onClick={() => {
                                                this.validateIDStatusBuyer(() => { this.saveSales(true) })
                                            }} />
                                        </Grid> : ""
                                }
                                <Grid item>
                                    <CtBtn label='New' width={this.state.cWidth - 120} onClick={this.newSales} />
                                </Grid>
                                {
                                    (this.props.privilegesList.privsalesrwm === 'M') ?
                                        <Grid item>
                                            <CtBtn label='Delete' width={this.state.cWidth - 120} onClick={this.deleteSales} />
                                        </Grid> : ""
                                }
                                <Grid item>
                                    <CtBtn label='Close' width={this.state.cWidth - 120} onClick={this.onClose} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item style={{display:'none'}}>
                                    <CtTxt
                                        id='txtFreeMRP'
                                        label='Free MRP'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtFreeMRP}
                                        width="120"
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item style={{display:'none'}}>
                                    <CtTxt
                                        id='txtAvailableFreeMRP'
                                        label='Avail. Free MRP'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtAvailableFreeMRP}
                                        width="120"
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtTotalTaxable'
                                        label='Taxable'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtTotalTaxable}
                                        width="120"
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtTotalCGST'
                                        label='CGST'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtTotalCGST}
                                        width="120"
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtTotalSGST'
                                        label='SGST'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtTotalSGST}
                                        width="120"
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtTotalIGST'
                                        label='IGST'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtTotalIGST}
                                        width="120"
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtTotalGrandTotal'
                                        label='Grand Total'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtTotalGrandTotal}
                                        width="120"
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtTotalRoundOff'
                                        label='Round Off'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtTotalRoundOff}
                                        width="120"
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtTotalNetAmount'
                                        label='Net Amount'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtTotalNetAmount}
                                        width="120"
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                                <Grid item>
                                    <CtTxt
                                        id='txtWalletAmount'
                                        label='Wallet Amount'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtWalletAmount}
                                        width="120"
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtFinalAmount'
                                        label='Final Amount'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtFinalAmount}
                                        width="120"
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtTotalSalesBTO'
                                        label='Total Sales BTO'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtTotalSalesBTO}
                                        width="120"
                                        maxLength='18'
                                        onKeyDown={this.props.onKeyDown}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <br />
                {/* <Grid container direction="row" justify="left" alignItems="center" spacing={3}> */}
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.getActionListComponent()}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={this.getNewIconComponent()}
                    toolTipNewEntry='New Sales Item Entry'
                />
                {/* </Grid> */}
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default HOCVerifyIsAdmin(HoCtToastContainer(SalesEntry))