import React, { Component } from 'react'

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { PROCESSING, NO_DATA_FOUND, get_YMD_from_SYS, getIndianFormatedNumber } from "./SystemUtility/SystemUtility";
import { fetchMember } from './API/API'
import HOCVerifyIsLogInUserOrAdmin from './HOC/HOCVerifyIsLogInUserOrAdmin'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import WalletBook from './WalletBook'
import UserWithdrawal from './UserWithdrawal'

//#region TabControll
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
//#endregion


class WalletDashboard extends Component {

    state = {
        value: 0,

        Tabs: [
            { label: 'Wallet Book' },
            // { label: 'Withdrawal' }
        ],

        dataLoadStatus: false,
        UserID: 0,
        Name: '',
        WalletBalance: 0,
        Scheme: '',
        //#region WalletBook
        walletBookData: {
            FromDate: '',
            ToDate: '',
            rows: [],
            rowsCount: 0,
            processingStatus: PROCESSING,
        },
        //#endregion
    }

    //#region Component
    componentWillMount() {
        const userID = Number(this.props.match.params.userID.substring(1))
        this.props.validateIsLogInUserOrAdmin(
            this.props.IsAdmin,
            this.props.setActiveLink,
            this.navigateTo,
            userID
        )
    }

    componentDidMount() {
        this.props.setActiveLink({ LinkSlug: "Wallet Dashboard" })
        let todayDt = get_YMD_from_SYS(new Date()),
            UserID = Number(this.props.match.params.userID.substring(1))
        this.setState({
            UserID,
            walletBookData: {
                ...this.state.walletBookData,
                FromDate: todayDt,
                ToDate: todayDt
            }
        }, () => {
            this.listWalletBook(false)
        })
    }
    //#endregion

    //#region HandleChange
    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    handleOnChangeTrimWalletBookData = (e) => {
        this.setState({
            walletBookData: { ...this.state.walletBookData, [e.target.name]: e.target.value.trim() }
        })
    }
    //#endregion

    //#region Function

    navigateTo = (link) => {
        this.props.history.push('/' + link)
    }

    listWalletBook = (WalletBookOnly = true) => {
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                'Op': WalletBookOnly === true ? 'WalletBook' : 'WalletBook & BasicInfo',
                'UserID': this.state.UserID,
                'FromDate': this.state.walletBookData.FromDate,
                'ToDate': this.state.walletBookData.ToDate
            }
            fetchMember(reqData)
                .then(res => {
                    let dataLoadStatus = true,
                        rows = [],
                        rowsCount = 0,
                        processingStatus = NO_DATA_FOUND
                    if (res.data.msgType === 'success') {
                        rows = res.data.WalletBook
                        rowsCount = rows.length
                        processingStatus = 'success'
                    }
                    this.props.toastMsg(res.data.msgType, res.data.message)
                    if (WalletBookOnly) {
                        this.setState({
                            dataLoadStatus,
                            walletBookData: {
                                ...this.state.walletBookData,
                                rows, rowsCount, processingStatus
                            }
                        })
                    } else {
                        let Name = res.data.name,
                            Scheme = res.data.Scheme,
                            WalletBalance = res.data.CurWalletBalance

                        this.setState({
                            dataLoadStatus,
                            Name, Scheme, WalletBalance,
                            walletBookData: {
                                ...this.state.walletBookData,
                                rows, rowsCount, processingStatus
                            }
                        })
                    }
                })
                .catch(error => {
                    this.props.toastErrorMsg('Unknown error occurred')
                    console.log('Unknown Error Occurred in listWalletBook. ', error)
                })
        })
    }
    //#endregion

    render() {
        return (
            <div style={{ flexGrow: 1, width: '100%' }}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {
                            this.state.Tabs.map((tab, index) => {
                                return (
                                    <Tab label={tab.label} {...a11yProps(index)} />
                                )
                            })
                        }
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.value} index={0}>
                    <WalletBook
                        navigateTo={this.navigateTo}
                        UserID={this.props.match.params.userID.substring(1)}
                        handleOnChangeTrim={this.handleOnChangeTrim}
                        rows={this.state.walletBookData.rows}
                        rowsCount={this.state.walletBookData.rowsCount}
                        FromDate={this.state.walletBookData.FromDate}
                        ToDate={this.state.walletBookData.ToDate}
                        setWalletBookData={this.setWalletBookData}
                        processingStatus={this.state.walletBookData.processingStatus}
                        handleOnChangeTrimWalletBookData={this.handleOnChangeTrimWalletBookData}
                        listWalletBook={this.listWalletBook}
                        dataLoadStatus={this.state.dataLoadStatus}
                    />
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    <UserWithdrawal navigateTo={this.navigateTo}
                        UserID={this.props.match.params.userID.substring(1)}
                        Name={this.state.Name}
                        WalletBalance={this.state.WalletBalance}
                        Scheme={this.state.Scheme}

                    />
                </TabPanel>
            </div>
        )
    }
}
export default HOCVerifyIsLogInUserOrAdmin(HoCtToastContainer(WalletDashboard))